import { NonIdealState } from '@blueprintjs/core';
import * as React from 'react';

export interface Props {
}

export class ComingSoon extends React.Component<Props> {
  public render() {
    return (
      <NonIdealState
        title="Coming soon"
        className="coming-soon"
      />
    );
  }
}
