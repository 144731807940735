import * as React from 'react';
import { MainLayout } from '../layouts/main';
import { AutoSizer } from 'react-virtualized';
import AssetCategoryChart from '../components/assets/category_chart';
import AssetAgeChart from '../components/assets/age_chart';
import AssetCostCenterChart from '../components/assets/cost_center_chart';
import { FlexRow } from '@patterns/ui';
import { AssetLifecycleChart } from '../components/assets/lifecycle_chart';
import { WithTranslation } from 'react-i18next';

export interface Props extends WithTranslation {
}

export default class AssetReports extends React.Component<Props> {
  public render() {
    return (
      <MainLayout title="Asset Reports">
        <FlexRow flex={1}>
          <div className="f-1">
            <AutoSizer>{({ height, width }) => (
              <AssetCategoryChart height={height} width={width}/>
            )}</AutoSizer>
          </div>
          <div className="f-1">
            <AutoSizer>{({ height, width }) => (
              <AssetAgeChart height={height} width={width}/>
            )}</AutoSizer>
          </div>
          <div className="f-1">
            <AutoSizer>{({ height, width }) => (
              <AssetCostCenterChart height={height} width={width}/>
            )}</AutoSizer>
          </div>
        </FlexRow>
        <FlexRow flex={1}>
          <div className="f-1">
            <AutoSizer>{({ height, width }) => (
              <AssetLifecycleChart height={height} width={width} {...this.props}/>
            )}</AutoSizer>
          </div>
        </FlexRow>
      </MainLayout>
    );
  }
}
