import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Breadcrumbs } from '../components/breadcrumb';
import ReclaimedContractAssets from '../components/eol/reclaimed';
import { MainLayout } from '../layouts/main';

export function PurchasedLeases({ t }: WithTranslation) {
  return (
    <MainLayout title={t('purchased_assets.title')}>
      <Breadcrumbs path={`${t('end_of_lease')} / title={t('purchased_assets.title')}`} title={t('purchased_assets.title')}/>
      <ReclaimedContractAssets />
    </MainLayout>
  )
}

export default withTranslation()(PurchasedLeases);