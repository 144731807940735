import * as React from 'react';
import { Icon, Button } from '@blueprintjs/core';
import { PurchaseInvoiceAttachment } from '../../models/purchase_invoice_attachment';
import { formatDateTime, download } from '@patterns/core';
import { FlexRow } from '@patterns/ui';

import { session } from '../../session';
import { PDFPreview } from '../pdf_preview';
import { ConfirmButton } from '../confirm_button';

export interface Props {
  attachment: PurchaseInvoiceAttachment
  onDelete?: (attachment: PurchaseInvoiceAttachment) => void
}

export function PurchaseInvoiceAttachmentView ({ attachment, onDelete }: Props) {
  const [show, setShow] = React.useState(false);

  const onClick = (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
    evt.stopPropagation();
    evt.preventDefault();
    setShow(true);
  }

  return (
    <div className="attachment-row" onClick={onClick}>
      <div className="attachment-icon">
        <Icon icon="document" intent="primary"/>
      </div>

      <div className="attachment-title">
        <FlexRow>
          <div className="f-2 ai-c d-f">
            <span>{ attachment.name }</span>
          </div>
          <div style={{ width: 212 }} className="p-r-12">
            <FlexRow className="jc-e">{ Math.round(attachment.size / 1000) } kb</FlexRow>
            <FlexRow className="jc-e">{ formatDateTime(attachment.createdAt) }</FlexRow>
          </div>
        </FlexRow>
      </div>

      <div className="attachment-actions">
        <Button
          minimal
          title=""
          icon="download"
          onClick={() => download(`/api/purchase_invoice_attachments/${attachment.id}?token=${session.token}`)}
        />
      </div>

      { onDelete && <div className="attachment-actions">
        <ConfirmButton
          minimal
          title=""
          confirmTitle=""
          icon="trash"
          onConfirm={() => onDelete(attachment)}/>
      </div> }

      { show && <PDFPreview 
        name={attachment.name}
        url={`/api/purchase_invoice_attachments/${attachment.id}?token=${session.token}`}
        onClose={() => setShow(false)}
      />}
    </div>
  );
}
