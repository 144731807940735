import * as React from 'react';
import { Dialog, Classes, FormGroup, InputGroup, Button, Switch } from '@blueprintjs/core';
import { FlexColumn } from '@patterns/ui';
import deepEqual from 'deep-equal';
import produce from 'immer';
import { Organization } from '../models/organization';
import { FlexRow } from '@patterns/ui';
import { ConfirmDelete } from './confirm_delete';

export interface Props {
  organization: Organization
  isOpen: boolean
  onClose: () => void
  onSave: (organization: Organization) => void
  onDelete: (organization: Organization) => void
}

export interface PureState {
  name: string
  description: string
  email: string
  street: string
  street2: string
  city: string
  postCode: string
  country: string
  businessID: string
  note: string
  keepSessions: boolean
}

export interface State extends PureState {
  showToken: boolean
}

export class OrganizationDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { 
      name, description, email, street, street2, city, postCode, country ,businessID, note, keepSessions
    } = this.props.organization;

    this.state = { 
      name, description, email, street, street2, city, postCode, country ,businessID, note,
      showToken: false, keepSessions
    }
    
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.organization, prevProps.organization)) {
      const { 
        name, description, email, street, street2, city, postCode, country ,businessID, note, keepSessions
      } = this.props.organization;
      this.setState({ 
        name, description, email, street, street2, city, postCode, country ,businessID, note, keepSessions
      })
    }
  }

  async save() {
    const organization = produce(this.props.organization, organization => {
      organization.name = this.state.name;
      organization.description = this.state.description;
      organization.email = this.state.email;
      organization.street = this.state.street;
      organization.street2 = this.state.street2;
      organization.city = this.state.city;
      organization.postCode = this.state.postCode;
      organization.country = this.state.country;
      organization.businessID = this.state.businessID;
      organization.note = this.state.note;
      organization.keepSessions = this.state.keepSessions;
    });
    this.props.onSave(organization);
  }

  private update = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const key = evt.currentTarget.name as keyof PureState;
    const state = {} as State
    if (key !== 'keepSessions') {
      state[key] = evt.currentTarget.value;
    } else {
      state.keepSessions = evt.currentTarget.checked;
    }
    this.setState(state)
  }

  public render() {
    return (
      <Dialog 
        title="Organizations"
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        canOutsideClickClose={false}
        className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <FlexColumn flex={1}>
            <FormGroup inline label="Name">
              <InputGroup 
                fill
                name="name"
                value={this.state.name}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label="Description">
              <InputGroup 
                fill
                name="description"
                value={this.state.description}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label="Email">
              <InputGroup 
                fill
                name="email"
                value={this.state.email}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label="Street">
              <InputGroup 
                fill
                name="street"
                value={this.state.street}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label="Street 2">
              <InputGroup 
                fill
                name="street2"
                value={this.state.street2}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label="Post Code">
              <InputGroup 
                fill
                name="postCode"
                value={this.state.postCode}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label="City">
              <InputGroup 
                fill
                name="city"
                value={this.state.city}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label="Country">
              <InputGroup 
                fill
                name="country"
                value={this.state.country}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label="Business ID">
              <InputGroup 
                fill
                name="businessID"
                value={this.state.businessID}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label="Note">
              <InputGroup 
                fill
                name="note"
                value={this.state.note}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label="Keep Sessions">
              <Switch
                name="note"
                checked={this.state.keepSessions}
                onChange={this.update}
              />
            </FormGroup>
          </FlexColumn>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <FlexRow className="f-1">
            <FlexRow className="f-1 jc-s">
              <ConfirmDelete
                outlined={true}
                title="Delete"
                onConfirm={() => this.props.onDelete(this.props.organization)}
              />
            </FlexRow>
            <FlexRow className="f-2 jc-e">
              <Button 
                outlined 
                icon="cross"
                text="Close" 
                onClick={this.props.onClose} 
                className="m-r-12"
              />
              <Button 
                outlined 
                icon="tick"
                text="Save" 
                intent="primary" 
                onClick={() => this.save()}
              />
            </FlexRow>
          </FlexRow>
        </div>
      </Dialog>
    );
  }
}
