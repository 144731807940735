import { Button, ButtonGroup, Checkbox, ControlGroup, FormGroup, Icon, InputGroup, MenuItem, Switch, Tag } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { download, formatDate, parseDate } from '@patterns/core';
import { Alignment, Column, ColumnType, DataTable, FilterStates } from '@patterns/datatable';
import { CreateButton, FlexColumn, Toolbar } from '@patterns/ui';
import produce from 'immer';
import { capitalize, debounce } from 'lodash';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { axios, session } from '../session';
import AssetCategoryChart from '../components/assets/category_chart';
import AssetDialog from '../components/assets/asset_dialog';
import AssetPreviewDialog from '../components/assets/asset_preview.dialog';
import { Importer } from '../components/importer';
import { MainLayout } from '../layouts/main';
import { Asset } from '../models/asset';
import { Category } from '../models/category';
import { CostCenter } from '../models/cost_center';
import { CustomFormFieldTypeMap } from '../models/custom_form_field';
import { AssetRepository, DeviceRepository } from '../repository';
import { Device } from '../models/device';
import DeviceDialog from '../components/devices/device.dialog';
import { Employee } from '../models/employee';
import CategoryCell from '../components/category.cell';
import EOLCategoryChart from '../components/eol_category_chart';
import { NumberWidget } from '../components/number_widget';
import { motion } from 'framer-motion';
import { Breadcrumbs } from '../components/breadcrumb';
import AssetCostCenterChart from '../components/assets/cost_center_chart';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Contract } from '../models/contract';
import ContractDetail from '../components/contract_detail';

type AssetState =  'leased' | 'reclaimed' | 'all';
const AssetStates = ['leased', 'reclaimed', 'all'] as AssetState[];

const AssetStateIntents = {
  'reclaimed': 'none',
  'leased': 'success',
  'all': 'primary'
}

const AssetStateSelect = Select.ofType<AssetState>();

const AssetsTable = DataTable.ofType<Asset>();

type Facade = 'contract' | 'lifecycle' | 'financial' | 'technical';

const FacadeColumns = {
  contract: ['type', 'name', 'category', 'contract', 'employee', 'actions'],
  financial: ['type', 'name', 'actions'],
  lifecycle: ['type', 'name', 'category', 'lifecycle', 'purchaseDate', 'age', 'remaining', 'purchaseDate', 'warrantyEnd', 'actions'],
  technical: ['type', 'name', 'category', 'actions'],
} as { [id: string]: string[] }

export type HelperType = 'categories' | 'cost_centers';

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

export interface Props extends WithTranslation, RouteComponentProps {
  id: string
}

export interface State {
  age?: number
  category: Category
  contract: Contract
  columns: Column<Asset>[]
  costCenter: CostCenter
  device: Device
  employee: Employee
  selected: Asset
  selection: Asset[]
  items: Asset[]
  facade: Facade
  showAll: boolean
  showEOL: boolean
  showFree: boolean
  showDevice: boolean
  showDialog: boolean
  showEditDialog: boolean
  stateType: AssetState
  query: string
  showContract: boolean
  showHelper: boolean
  helper: HelperType
  showImporter: boolean
  statsTotal: number
  statsNotInUse: number
  statsEol: number
  syncKey: number
  total: number
  unfiltered: number
}

export class Assets extends React.Component<Props, State> {

  cancelToken: any
  table = React.createRef<DataTable<Asset>>();
  eolCategoryChart = React.createRef<React.ReactElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      category: new Category({}),
      contract: new Contract({}),
      columns: this.getColumns(),
      costCenter: new CostCenter({}),
      device: new Device({}),
      employee: new Employee({}),
      facade: 'contract' as Facade,
      items: [],
      selected: new Asset({}),
      selection: [],
      showAll: true,
      showContract: false,
      showEOL: false,
      showFree: false,
      showDialog: false,
      showDevice: false,
      showEditDialog: false,
      stateType: 'all' as AssetState,
      query: (props.match.params as any).id || '',
      showHelper: true,
      helper: 'categories',
      showImporter: false,
      statsEol: 0,
      statsNotInUse: 0,
      statsTotal: 0,
      syncKey: 0,
      total: 0,
      unfiltered: 0
    } as State
  }

  componentDidMount() {
    this.setFacade();
    this.fetchStats();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const stateObservables = ['query', 'category', 'age', 'costCenter'] as (keyof State)[];
    stateObservables.forEach(observable => {
      if (prevState[observable] !== this.state[observable]) {
        this.forceFetch();
      }
    })
  }

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'type',
        title: t('status'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        width: 120,
        format: asset => {
          if (asset.state === 'reclaimed') {
            return <Tag intent="warning" style={{ fontSize: 11 }}>{t('reclaimed').toUpperCase()}</Tag>
          } else if (asset.state === 'returned') {
            return <Tag intent="danger" style={{ fontSize: 11 }}>{t('extended_leases.return').toUpperCase()}</Tag>
          } else {
            return <Tag intent="success" style={{ fontSize: 11 }}>
              { (asset.state !== 'reclaimed') && t('leased').toUpperCase() }
            </Tag>
          }
        }
      },
      {
        id: 'name',
        title: t('assets.make_and_model'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 2,
        format: asset => <strong>{ asset.name }</strong>
      },
      {
        id: 'category',
        title: t('category'),
        type: ColumnType.Custom,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => <CategoryCell category={asset.category}/>
      },
      {
        id: 'contract',
        title: t('contract_number'),
        type: ColumnType.Custom,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => {
          if (asset.contract.isDummy || asset.contract.getTitle() === 'owned' || asset.contract.isExpired) {
            return <span/>
          }

          return <Button
            minimal
            rightIcon="chevron-right"
            intent="none"
            small
            text={asset.contract.getTitle()}
            onClick={evt => {
              evt.preventDefault();
              evt.stopPropagation();
              this.setState({ contract: asset.contract, showContract: true })}
            }
          />
        }
      },
      {
        id: 'lifecycle',
        title: t('assets.lifecycle'),
        type: ColumnType.Number,
        sortable: true,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        width: 130,
        format: asset => <Tag intent="none">{ asset.lifecycle } mo</Tag>
      },
      {
        id: 'purchaseDate',
        title: t('assets.purchase_date'),
        type: ColumnType.Date,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        width: 130
      },
      {
        id: 'age',
        title: t('assets.age'),
        type: ColumnType.Number,
        sortable: true,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        width: 114,
        format: asset => {
          const intent = asset.age < asset.lifecycle ? 'success' : 'danger';
          // return <Tag intent={intent}>{ asset.age } mo</Tag>
          // const total = asset.age + asset.remaining;
          // const percentage = asset.age / total;
          // return <ProgressBar value={percentage} intent={intent} animate={false}/>
          return <Tag intent={intent}>{ asset.age } { t('mo') }</Tag>
        }
      },
      {
        id: 'warrantyEnd',
        title: t('assets.warranty_end'),
        type: ColumnType.Date,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        width: 130
      },
      {
        id: 'office',
        title: t('office'),
        type: ColumnType.Custom,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => {
          // if (asset.employee.exists) {
          //   return <div onClick={async (evt: any) => {
          //     evt.preventDefault();
          //     evt.stopPropagation();
          //     const device = await DeviceRepository.get(`by_serial_number/${asset.serialNumber}`);
          //     this.setState({ device, showDevice: true })
          //   }}>

          //   </div>
          // }
          if (asset.office && asset.office.name && asset.office.name.length > 0) {
            return asset.office.name
          }

          return <span/>
        }
      },
      {
        id: 'employee',
        title: t('user'),
        type: ColumnType.Custom,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => {
          // if (asset.employee.exists) {
          //   return <div onClick={async (evt: any) => {
          //     evt.preventDefault();
          //     evt.stopPropagation();
          //     const device = await DeviceRepository.get(`by_serial_number/${asset.serialNumber}`);
          //     this.setState({ device, showDevice: true })
          //   }}>

          //   </div>
          // }
          if (asset.employee && asset.employee.name && asset.employee.name.length > 0) {
            return <><Icon icon="user" className='m-r-6'/>
              {asset.employee.name}
            </>
          }
          return <span/>
        }
      },
      {
        id: 'note',
        title: t('note'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => <span>{ asset.note }</span>,
      },
      {
        id: 'fields.processor',
        title: t('fleet.cpu'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => <span>{ asset.fields?.cpu }</span>
      },
      {
        id: 'fields.serial',
        title: t('fleet.serial_number'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => <span>{ asset.serialNumber }</span>
      },
      {
        id: 'fields.manufacturerName',
        title: t('fleet.manufacturer'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => <span>{ asset.fields?.manufacturerName }</span>
      },
      {
        id: 'fields.modelName',
        title: t('fleet.model'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => <span>{ asset.fields?.modelName }</span>
      },
      {
        id: 'fields.productNumber',
        title: t('fleet.product_number'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => <span>{ asset.fields?.productNumber }</span>
      },
      {
        id: 'fields.memory',
        title: t('fleet.memory'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => <span>{ asset.fields?.memory }</span>
      },
      {
        id: 'fields.hard_drive',
        title: t('fleet.disk'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => <span>{ asset.fields?.hard_drive }</span>
      },
      {
        id: 'fields.display_size',
        title: t('fleet.display_size'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: asset => <span>{ (asset.fields?.display_size as string || '').replace(/"/g, '') }"</span>
      },
      {
        id: 'actions',
        title: t('actions'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        headerClass: 'hide-header',
        flex: 1,
        format: asset => <div className="d-f f-r ai-c jc-c" onClick={(evt: any) => {
          this.toggleIsUsed(asset);
          evt.preventDefault();
          evt.stopPropagation();
        }}>
          <Switch
            className="m-b-0 m-t-2"
            innerLabel={t('assets.free')}
            innerLabelChecked={t('assets.in_use')}
            checked={asset.isUsed}
            onChange={evt => {
              evt.preventDefault();
              evt.stopPropagation();
            }}
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            }}
          />
          { session.user.role !== 'user' && <Button minimal text={t('edit')} icon="edit" onClick={(evt: any) => {
            evt.preventDefault();
            evt.stopPropagation();
            this.edit(asset);
          }}/> }
        </div>
      }
    ] as Column<Asset>[]
  }

  // asset.manufacturer = body.manufacturer;
  // asset.model = body.model;
  // asset.productNumber = body.productNumber;
  // asset.type = body.type;
  // asset.cpu = body.cpu;
  // asset.memory = body.memory;
  // asset.disk = body.disk;
  // asset.displaySize = body.displaySize;

  private edit = (asset: Asset) => this.setState({ selected: asset, showEditDialog: true })

  private assetStateRenderer = (stateType: AssetState, options: any) => <MenuItem
    key={`menu-item-state-type-${stateType}`}
    onClick={options.handleClick}
    intent={AssetStateIntents[stateType] as 'primary' | 'success' | 'warning' | 'danger' | 'none'}
    text={capitalize(this.props.t(stateType))}
  />

  private onAssetStateChange = (stateType: AssetState) => {
    this.setState({ stateType }, () => this.table.current?.fetch());
  }

  private create = () => this.setState({ showEditDialog: true, selected: new Asset({
    state: 'reclaimed'
  }) })

  private fetchStats = async () => {
    const params = [] as string[];
    if (typeof this.state.age !== 'undefined') {
      params.push(`age=${this.state.age}`)
    }

    if (this.state.category.exists) {
      params.push(`category=${this.state.category.id}`)
    }

    const response = await axios.get(`/assets/stats?${params.join('&')}`);
    const eolResponse = await axios.get('/assets/eol?pageSize=1');

    this.setState({
      statsTotal: response.data.total,
      statsEol: eolResponse.data.count,
      statsNotInUse: response.data.notInUse,
    })
  }

  private hasFilters = () => {
    const { category, employee, costCenter, query, age, showEOL, showFree } = this.state;

    return category.exists ||
      employee.exists ||
      costCenter.exists ||
      query.length > 0 ||
      typeof age !== 'undefined' ||
      showEOL ||
      showFree
  }

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const params = [] as string[];
    if (this.state.category.exists) {
      params.push(`category_id=${this.state.category.id}`)
    }

    if (this.state.employee.exists) {
      params.push(`employee_id=${this.state.employee.id}`)
    }

    if (this.state.costCenter.exists) {
      params.push(`cost_center_id=${this.state.costCenter.id}`)
    }

    if (this.state.query.length > 0) {
      params.push(`query=${this.state.query}`)
    }

    if (typeof this.state.age !== 'undefined') {
      params.push(`age=${this.state.age}`)
    }

    if (this.state.stateType) {
      params.push(`state=${this.state.stateType}`)
    }

    if (this.state.showFree) {
      params.push('showFree=true')
    }

    if (this.state.showEOL) {
      params.push('showEOL=true')
    }

    const { items, total, unfiltered, cancelToken } = await AssetRepository.index(page, pageSize, sort, sortDir, filters, this.cancelToken, params.join('&'));
    this.cancelToken = cancelToken;
    this.setState({ items, syncKey: new Date().getTime(), total, unfiltered });
    return { items, total, }
  }

  private forceFetch = debounce(() => {
    this.table.current?.fetch();
  }, 500)

  private setFacade = async (evt?: any, _facade: Facade = 'contract') => {
    const facade = evt ? evt.currentTarget.name as Facade : _facade;
    this.setState({ facade }, () => {
      if (facade !== 'contract') {
        const newColumns = produce(this.getColumns(), newColumns => {
          newColumns.forEach(column => {
            column.visible = FacadeColumns[facade].includes(column.id)
          })
        });
        this.setState({ columns: newColumns })
      } else {
        const newColumns = produce(this.getColumns(), newColumns => {
          newColumns.forEach(column => {
            column.visible = FacadeColumns[facade].includes(column.id)
          })
          if (this.state.category.exists && Array.isArray(this.state.category.fields)) {
            this.state.category.fields.filter(f => f.list).forEach(field => {
              newColumns.push({
                id: field.key,
                title: field.label_en,
                type: CustomFormFieldTypeMap[field.type],
                alignment: Alignment.Left,
                filterable: true,
                sortable: true,
                visible: true,
                flex: 1,
                format: asset => {
                  if (field.type === 'text' || field.type === 'textarea') {
                    return <span>{ asset.fields[field.key] }</span>
                  }

                  if (field.type === 'number') {
                    return <span>{ asset.fields[field.key] }</span>
                  }

                  if (field.type === 'date') {
                    return <span>{ formatDate(parseDate(asset.fields[field.key] as string)) }</span>
                  }

                  if (field.type === 'checkbox') {
                    return <Checkbox checked={asset.fields[field.key] === true}/>
                  }

                  if (field.type === 'switch') {
                    return <Switch className="m-b-0" checked={asset.fields[field.key] === true}/>
                  }

                  return <span/>
                }
              } as Column<Asset>)
            })
          }
        });
        this.setState({ columns: newColumns })
      }
    })


  }

  private onCategorySelect = (category?: Category) => {
    this.setState({ category: category ? category : new Category({}) }, () => {
      this.setFacade(null, this.state.facade);
    })
  }

  private onCostCenterSelect = (costCenter?: CostCenter) => this.setState({ costCenter: costCenter ? costCenter : new CostCenter({}) })

  private onClose = () => this.setState({ showDialog: false })

  private onEditClose = () => this.setState({ showEditDialog: false }, () => this.table.current?.fetch())

  private onSelect = (selected: Asset)  => {
    // this.props.history.push(`/assets/${selected.id}`)
    this.setState({ selected, showDialog: true });
  }

  private onSelectionChange = (selection: Asset[]) => {
    this.setState({ selection })
  }

  private onQueryChange = (evt: any) => {
    const query = evt.currentTarget.value;
    this.setState({ query })
  }

  private toggleHelper = () => this.setState({ showHelper: !this.state.showHelper})

  private onQueryReset = () => this.setState({ query: '' })

  private onImporterClose = () => {
    this.setState({ showImporter: false });
    this.table.current?.fetch();
  }

  private onDelete = async (asset: Asset) => {
    await AssetRepository.delete(asset.id);
    this.table.current?.fetch();
    this.setState({showDialog: false, showEditDialog: false});
  }

  private onShowDevice = async () => {
    const device = await DeviceRepository.get(`by_serial_number/${this.state.selected.serialNumber}`)
    this.setState({ showDevice: true, showDialog: false, device })
  }

  private onDeviceClose = async () => {
    this.setState({ showDevice: false })
  }

  private onDeviceDelete = async () => {
    await DeviceRepository.delete(this.state.device.id);
    this.setState({ showDevice: false })
  }

  private onEmployeeSelect = (employee: Employee) => this.setState({ employee }, () => this.table.current?.fetch())

  private onPieCategoryClick = (item: any) => {
    if (!item.selected) {
      const category = new Category({
        id: item.id,
        name_en: item.name
      });
      this.setState({ category }, () => this.onFiltersUpdate());
    } else {
      this.setState({ category: new Category() }, () => this.onFiltersUpdate());
    }
  }

  private onPieAgeClick = (item: any) => {
    this.setState({
      category: new Category({ id: item.options.id, name_en: item.options.name, name_fi: item.options.name }),
      showEOL: true
    }, () => {
      this.onFiltersUpdate()
    })
  }

  private onPieCostCenterClick = (item: any) => {
    const costCenter = item.id === this.state.costCenter.id ? new CostCenter({}) : new CostCenter({ id: item.id, name: item.name })
    this.setState({ costCenter }, () => this.onFiltersUpdate() )
  }

  private onContractClose = () => this.setState({ showContract: false })

  private onFiltersUpdate = async () => {
    this.fetchStats();
    this.table.current?.fetch();
  }

  private resetFilters = async () => {
    this.setState({
      category: new Category({}),
      employee: new Employee({}),
      costCenter: new CostCenter({}),
      age: undefined,
      query: '',
      showFree: false,
      showEOL: false,
      showAll: true
    }, () => this.onFiltersUpdate())
  }

  private toggleIsUsed = async (asset: Asset) => {
    asset.isUsed = !asset.isUsed;
    await AssetRepository.save(asset);
    this.table.current?.fetch();
    this.fetchStats();
  }

  private toggleAll = () => this.setState({
    showAll: true,
    showFree: false,
    showEOL: false
  }, () => this.table.current?.fetch())

  private toggleEOL = () => this.setState({
    showAll: false,
    showFree: false,
    showEOL: true
  }, () => this.table.current?.fetch())

  private toggleFree = () => this.setState({
    showAll: false,
    showFree: true,
    showEOL: false
  }, () => this.table.current?.fetch())

  private export = () => {
    const params = [] as string[];
    if (this.state.category.exists) {
      params.push(`category_id=${this.state.category.id}`)
    }

    if (this.state.employee.exists) {
      params.push(`employee_id=${this.state.employee.id}`)
    }

    if (this.state.costCenter.exists) {
      params.push(`cost_center_id=${this.state.costCenter.id}`)
    }

    if (this.state.query.length > 0) {
      params.push(`query=${this.state.query}`)
    }

    if (typeof this.state.age !== 'undefined') {
      params.push(`age=${this.state.age}`)
    }

    if (this.state.stateType) {
      params.push(`state=${this.state.stateType}`)
    }

    if (this.state.showFree) {
      params.push('showFree=true')
    }

    if (this.state.showEOL) {
      params.push('showEOL=true')
    }

    params.push(`lang=${this.props.i18n.language}`);

    download(`/api/assets/export_human.xlsx?${params.join('&')}`, session.token)
  }

  public render() {
    const { t } = this.props;
    const params = [] as string[];
    if (typeof this.state.age !== 'undefined') {
      params.push(`age=${this.state.age}`)
    }
    if (this.state.category.exists) {
      params.push(`category=${this.state.category.id}`)
    }
    params.push(`syncKey=${this.state.syncKey}`);

    const hasFilters = this.hasFilters();

    return <MainLayout title={t('assets.title')}>
      <Breadcrumbs path={`${t('assets.title')} / ${t('assets.registry')}`} title={t('assets.registry')}/>

        <div style={{ overflowY: 'auto' }}>
          <motion.ul className="container m-24 d-f f-r"
            variants={container}
            initial="hidden"
            animate="visible"
            style={{ maxHeight: 110, height: 110 }}>
          <motion.li key={`dash-users`} className="item m-r-12 blue-alpha f-1 d-f" variants={item}>
            <NumberWidget title={t('assets.total_assets')} value={this.state.statsTotal.toString()} color="" icon="mobile-phone" onClick={this.toggleAll} isActive={this.state.showAll}/>
          </motion.li>
          <motion.li key={`dash-assets`} className="item m-r-12 blue-alpha f-1 d-f" variants={item}>
            <NumberWidget title={t('assets.eol_assets')} value={this.state.statsEol.toString()} color="" icon="warning-sign" onClick={this.toggleEOL} isActive={this.state.showEOL}/>
          </motion.li>
          <motion.li key={`dash-contracts`} className="blue-alpha f-1 d-f" variants={item}>
            <NumberWidget title={t('assets.free_assets')} value={this.state.statsNotInUse.toString()} color="" icon="user"  onClick={this.toggleFree} isActive={this.state.showFree}/>
          </motion.li>
        </motion.ul>

        <div className="d-f f-r m-l-24 m-r-24" style={{ height: 480 }}>
          <div className="f-1">
            <AutoSizer>{({ height, width }) => (
              <AssetCategoryChart height={height} width={width} legend={false} onPointClick={this.onPieCategoryClick} params={params.join('&')}/>
            )}</AutoSizer>
          </div>
          <div className="f-1 m-l-12 m-r-12">
            <AutoSizer>{({ height, width }) => (
              <EOLCategoryChart height={height} width={width} legend={false} onPointClick={this.onPieAgeClick} params={params.join('&')}/>
            )}</AutoSizer>
          </div>
          <div className="f-1">
            <AutoSizer>{({ height, width }) => (
              <AssetCostCenterChart height={height} width={width} legend={false} onPointClick={this.onPieCostCenterClick}  params={params.join('&')}/>
            )}</AutoSizer>
          </div>
        </div>
        {/* <div className="d-f f-r" style={{ height: 420 }}>
          <div className="f-2">
            <AutoSizer>{({ height, width }) => (
              <AssetLifecycleChart height={height} width={width}/>
            )}</AutoSizer>
          </div>
        </div> */}
        <div className="d-f f-r">
          {/* { this.state.showHelper && <div className="d-f f-c"> */}
            {/* <Toolbar style={{ paddingLeft: 0, paddingRight: 5 }}>
              <div className="d-f f-r" style={{ height: 360 }}>
                <ButtonGroup>
                  <Button
                    intent={ this.state.helper === 'categories' ? "primary" : 'none'}
                    minimal
                    text="Categories"
                    onClick={() => this.setState({
                      helper: 'categories',
                      category: new Category({}),
                      costCenter: new CostCenter({})
                    }, () => {
                      this.forceFetch()
                    })}
                  />
                  <Button
                    intent={ this.state.helper === 'cost_centers' ? "primary" : 'none'}
                    minimal
                    text="Cost Centers"
                    onClick={() => this.setState({
                      helper: 'cost_centers',
                      category: new Category({}),
                      costCenter: new CostCenter({})
                    }, () => {
                      this.forceFetch()
                    })}
                  />
                </ButtonGroup>
              </div>
              <div className="w-48">
                <Button
                  intent="primary"
                  minimal
                  icon="double-chevron-left"
                  onClick={this.toggleHelper}
                />
              </div>
            </Toolbar> */}
            {/* { this.state.helper === 'categories' && <CategoriesTree
              onSelect={this.onCategorySelect}
              showNewButton={false}
            /> }
            { this.state.helper === 'cost_centers' && <CostCentersTree
              onSelect={this.onCostCenterSelect}
              showNewButton={false}
            /> } */}
          {/* </div> } */}
          <FlexColumn flex={4} className="patterns-datatable-autoheight p-24">
            <Toolbar style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
              <div className="f-2 jc-s ai-c d-f">
                <span className="bold m-r-24 m-l-24">{t('assets.title')}</span>
                { !this.state.showHelper && <Button
                  intent="primary"
                  minimal
                  icon="double-chevron-right"
                  onClick={this.toggleHelper}
                /> }
                <ControlGroup>
                  <FormGroup inline={true}>
                    <InputGroup
                      placeholder={t('search_placeholder')}
                      value={this.state.query}
                      onChange={this.onQueryChange}
                      style={{ color: 'white', fontWeight: 'normal' }}
                    />
                  </FormGroup>
                  <Button minimal icon="cross" onClick={this.onQueryReset} intent={ this.state.query.length > 0 ? 'success' : 'none' }/>
                </ControlGroup>

                { this.state.showEOL && <Tag
                  className="m-l-12"
                  intent="primary"
                  style={{color: 'white'}}
                  rightIcon={<Icon onClick={() => this.setState({ showEOL: false }, () => this.onFiltersUpdate())} icon="cross"/>}>
                    EOL Assets
                </Tag> }

                { this.state.showFree && <Tag
                  className="m-l-12"
                  intent="primary"
                  style={{color: 'white'}}
                  rightIcon={<Icon onClick={() => this.setState({ showFree: false }, () => this.onFiltersUpdate())} icon="cross"/>}>Free Assets</Tag> }

                { this.state.costCenter.exists && <Tag
                  className="m-l-12"
                  intent="primary"
                  style={{color: 'white'}}
                  rightIcon={<Icon onClick={() => this.setState({ costCenter: new CostCenter({}) }, () => this.onFiltersUpdate())} icon="cross"/>}>{ this.state.costCenter.name }</Tag> }

                { this.state.category.exists && <Tag
                  className="m-l-12 m-r-12"
                  intent="primary"
                  style={{color: 'white'}}
                  rightIcon={<Icon onClick={() => this.setState({ category: new Category({}) }, () => this.onFiltersUpdate())} icon="cross"/>}>{ this.state.category.name_en }</Tag> }

                <Button
                  disabled={!hasFilters}
                  minimal
                  text={t('reset_filters')}
                  intent="primary"
                  onClick={this.resetFilters}
                />

                {/* <ButtonGroup> */}
                  {/* <Button minimal text="Contract" name="contract" onClick={this.setFacade} intent={ this.state.facade === 'contract' ? 'primary' : 'none' }/> */}
                  {/* <Button minimal text="Lifecycle" name="lifecycle" onClick={this.setFacade} intent={ this.state.facade === 'lifecycle' ? 'primary' : 'none' }/> */}
                  {/* <Button minimal text="Financial" name="financial" onClick={this.setFacade}  intent={ this.state.facade === 'financial' ? 'primary' : 'none' }/> */}
                  {/* <Button minimal text="Technical" name="technical" onClick={this.setFacade} intent={ this.state.facade === 'technical' ? 'primary' : 'none' }/> */}
                {/* </ButtonGroup> */}
              </div>
              <div className="f-1 jc-e ai-c d-f m-r-24">
                <div className='m-r-24'>
                  { this.state.total } / { this.state.unfiltered } {t('results')}
                </div>

                <AssetStateSelect
                  activeItem={this.state.stateType}
                  items={AssetStates}
                  itemRenderer={this.assetStateRenderer}
                  onItemSelect={this.onAssetStateChange}
                  filterable={false}
                ><Button
                  minimal
                  rightIcon="chevron-down"
                  intent={AssetStateIntents[this.state.stateType] as "primary" | "success" | "warning" | "danger"}
                  text={capitalize(t(this.state.stateType))}/>
                </AssetStateSelect>
                {/* <EmployeeSelect
                  minimal={true}
                  hint={t('select_user')}
                  activeItem={this.state.employee}
                  onSelect={this.onEmployeeSelect}
                />
                <CategorySelect
                  inline={true}
                  category={this.state.category}
                  onChange={this.onCategorySelect}
                /> */}
              </div>
            </Toolbar>
            <div className="d-f f-r disable-flex">
              <FlexColumn className="f-1 d-f full-height">
                <AssetsTable
                  id="assets-registry"
                  ref={this.table}
                  columns={this.state.columns}
                  fetch={this.fetch}
                  onItemSelect={this.onSelect}
                  onSelectionChange={this.onSelectionChange}
                  sort="name"
                  sortDir="asc"
                  multiple={false}
                  actions={ this.state.selection.length > 0 ? <ButtonGroup>
                    <Button minimal intent="primary" text={t('assets.assign_contract')} icon="link"/>
                  </ButtonGroup> : <span/>}
                  tableActions={<ButtonGroup style={{ justifySelf: 'center' }}>
                    {/* <Button minimal icon="cloud-download" text="PDF" onClick={() => download('/api/assets/export_human.pdf', session.token)}/> */}
                    <Button minimal icon="cloud-download" text="EXCEL" onClick={() => this.export() }/>
                    {/* <Button minimal icon="cloud-download" text="CSV" onClick={() => download('/api/assets/export_human.csv', session.token)}/> */}
                    <Button minimal icon="cloud-upload" text={t('import')} onClick={() => this.setState({ showImporter: true })}/>
                  </ButtonGroup>}
                />
              </FlexColumn>
            </div>
          </FlexColumn>
        </div>
      </div>

      <Importer
        isOpen={this.state.showImporter}
        onClose={this.onImporterClose}
        url="/assets/import"
      />

      <CreateButton onClick={this.create}/>

      <AssetPreviewDialog
        asset={this.state.selected}
        isOpen={this.state.showDialog}
        onClose={this.onClose}
      />

      <AssetDialog
        asset={this.state.selected}
        isOpen={this.state.showEditDialog}
        onClose={this.onEditClose}
        onDelete={this.onDelete}
      />

      <DeviceDialog
        device={this.state.device}
        isOpen={this.state.showDevice}
        onClose={this.onDeviceClose}
        onDelete={this.onDeviceDelete}
      />

      <ContractDetail
        contract={this.state.contract}
        isOpen={this.state.showContract}
        onClose={this.onContractClose}
      />
    </MainLayout>
  }
}

export default withRouter(withTranslation()(Assets))
