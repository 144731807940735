import * as React from 'react';
import { Button, ButtonGroup, FormGroup, InputGroup, ControlGroup, Tabs, Tab } from '@blueprintjs/core';
import { CreateButton, Toolbar, FlexColumn, FlexRow } from '@patterns/ui';
import { download, formatDate } from '@patterns/core';
import { DataTable, ColumnType, Column, Alignment, FilterStates } from '@patterns/datatable';
import { debounce } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { Contract } from '../models/contract';
import { ContractRepository } from '../repository';
import { Category } from '../models/category';
import { axios, session } from '../session';
import { MainLayout } from '../layouts/main';
import { Lessee } from '../models/lessee';
import { Importer } from '../components/importer';
import Proposals from '../components/contracts/proposals';
import { Breadcrumbs } from '../components/breadcrumb';
import { motion } from 'framer-motion';
import { NumberWidget } from '../components/number_widget';
import { monthDiff, priceFormat } from '../common';
import { withTranslation, WithTranslation } from 'react-i18next';
import ContractDetail from '../components/contract_detail';
import moment from 'moment';

const ContractsTable = DataTable.ofType<Contract>();

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

export type EolStats = {
  thisQuarter: number;
  nextQuarter: number;
  future: number;
}

export interface Props extends WithTranslation, RouteComponentProps {
  id: string
}

export interface State {
  category: Category
  columns: Column<Contract>[]
  contractsCount: 0
  eolStats: EolStats
  lessee: Lessee
  query: string
  selected: Contract
  selection: Contract[]
  showDialog: boolean
  // stateType: ContractState
  showImporter: boolean
  tab: string
}

export class Contracts extends React.Component<Props, State> {
  
  cancelToken: any
  table = React.createRef<DataTable<Contract>>();

  constructor(props: Props) {
    super(props);
    this.state = {
      category: new Category({}),
      columns: this.getColumns(),
      contractsCount: 0,
      eolStats: {
        thisQuarter: 0,
        nextQuarter: 0,
        future: 0
      } as EolStats,
      lessee: new Lessee({}),
      query: (props.match.params as any).id || '',
      selected: new Contract({}),
      selection: [],
      showDialog: false,
      showImporter: false,
      tab: 'contracts'
      // stateType: 'leased' as ContractState,
    }
  }

  componentDidMount() {
    this.fetchStats()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const stateObservables = ['query', 'category'] as (keyof State)[];
    stateObservables.forEach(observable => {
      if (prevState[observable] !== this.state[observable]) {
        this.forceFetch();
      }
    });
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.forceFetch()
    }
  }

  private create = () => this.setState({ showDialog: true, selected: new Contract({
    startDate: new Date(),
    validTill: new Date()
  }) })

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'state',
        title: t('contracts.remaining'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        width: 128,
        format: contract => {
          return <div className={`contract-${contract.color}`} style={{ textAlign: 'right', width: '100%' }}>
            {monthDiff(new Date(), contract.validTill)} {t('contracts.months')}
          </div>
        }
      },
      {
        id: 'number',
        title: t('contracts.contract_no'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        width: 200,
        format: contract => <div className="f-1 d-f jc-s ai-c">
          <strong>{ contract.isOwned ? t('contract_ended') : contract.number }</strong>
          {/* <Tooltip2>
            <Tag className="m-l-12" minimal intent={contract.purchaseInvoiceAttachments.length === 0 ? 'danger' : 'primary'}>
              { contract.purchaseInvoiceAttachments.length }
            </Tag>
            <span>{t('contracts.attachments')}</span>
          </Tooltip2> */}
    {/* 
          { !contract.acceptDeliveryReady && <Tooltip>
            <Icon className="m-l-12" intent="success" icon="tick"/>
            <span>Accepted Delivery</span>
          </Tooltip>}
    
          { !contract.forceReclamation && <Tooltip>
            <Icon className="m-l-12" intent="warning" icon="redo"/>
            <span>Forced Reclamation</span>
          </Tooltip>} */}
        </div>
      },
      {
        id: 'startDate',
        title: t('contracts.start_date'),
        type: ColumnType.Date,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        width: 130
      },
      {
        id: 'validTill',
        title: t('contracts.end_date'),
        type: ColumnType.Custom,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        width: 130,
        format: contract => formatDate(moment(contract.validTill).toDate())
      },
      {
        id: 'term',
        title: t('contracts.term'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        width: 110,
        format: contract => <span>{ monthDiff(contract.startDate, contract.validTill) + 1 }</span>
      },
      {
        id: 'leaseMonetaryMonth',
        title: t('contracts.rental_per_month'), 
        type: ColumnType.Number,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Right,
        width: 150,
        format: contract => priceFormat(contract.leaseMonetaryMonth)
      },
      {
        id: 'leasingCompany',
        title: t('leasing_company'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: false,
        alignment: Alignment.Left,
        flex: 1,
        format: contract => <span>{ contract.leasingCompany.name }</span>
      },
      {
        id: 'supplier',
        title: t('supplier'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: false,
        alignment: Alignment.Left,
        flex: 1,
        format: contract => <span>{ contract.supplier.name }</span>
      },
      {
        id: 'lessee',
        title: t('lessee'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        flex: 1,
        format: contract => <span>{ contract.lessee.name }</span>
      },
      {
        id: 'registration',
        title: t('contracts.registration_number'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: false,
        alignment: Alignment.Left,
        flex: 1,
        format: contract => <span>{ contract.lessee?.businessID ?? '' }</span>
      }
    ] as Column<Contract>[]
  }

  private fetchStats = async () => {
    const eolResponse = await axios.get('/contracts/eol_stats');
    const response = await axios.get('/dashboard');
    const data = response.data;
    const contractsCount = data.contracts  < 0 ? 0 : data.contracts;
    this.setState({
      eolStats: eolResponse.data as EolStats,
      contractsCount
    })
  }

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    let extra = `query=${this.state.query}`;

    if (session.lesseeId) {
      extra += `&lessee_id=${session.lesseeId}`
    }

    extra += `&active=${window.location.pathname.includes('expired_contracts') ? 'false' : 'true'}`;

    const { items, total, cancelToken } = await ContractRepository.index(page, pageSize, sort, sortDir, filters, this.cancelToken, extra);
    this.cancelToken = cancelToken;
    return { items, total }
  }

  private onReload = () => this.forceFetch();

  private forceFetch = debounce(() => {
    this.table.current?.fetch()
  }, 500)

  private onClose = () => {
    localStorage.removeItem('asset-builder');
    this.setState({ showDialog: false });
    this.table.current?.fetch();
  }

  private onImporterClose = () => {
    this.setState({ showImporter: false });
    this.table.current?.fetch();
  }

  private onLesseeSelect = (lessee: Lessee) => this.setState({ lessee }, () => this.table.current?.fetch())

  private onSelect = (selected: Contract) => { 
    if (session.user.isAdmin || session.user.isSuperuser) {
      this.setState({ showDialog: true, selected })
    }
  }

  private onSelectionChange = (selection: Contract[]) => this.setState({ selection })
  
  private onQueryChange = (evt: any) => {
    const query = evt.currentTarget.value;
    this.setState({ query })
  }

  private onQueryReset = () => this.setState({ query: '' })

  private export = () => {
    const params = [] as string[];
    if (this.state.query && this.state.query.length > 0) {
      params.push(`query=${this.state.query}`)
    }

    params.push(`lang=${this.props.i18n.language}`);

    const _params = params.length > 0 ? `?${params.join('&')}` : '';
    download(`/api/contracts/export.xlsx${_params}`, (session as any).token);
  }

  public render() {
    const { t } = this.props;
    return <MainLayout title={t('contracts.title')}>
      <Breadcrumbs path={`${'contracts.leases'} / ${t('contracts.title')}`} title={t('contracts.title')}/>
      <motion.ul className="container m-24 d-f f-r"
        variants={container}
        initial="hidden"
        animate="visible"
        style={{ maxHeight: 110, height: 110 }}>
        <motion.li key={`dash-users`} className="item m-r-24 blue-alpha f-1 d-f" variants={item}>
          <NumberWidget title={t('contracts.in_total')} value={this.state.contractsCount} color="" icon="document-share"/>
        </motion.li>
        <motion.li key={`dash-assets`} className="item blue-alpha f-1 d-f" variants={item}>
          <div className="dashboard-widget-wrapper">
            <div className="dashboard-widget-number">
              <FlexRow style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                <div className="f-1">
                  <h1>{ t('contracts.expiring_contracts') }</h1>
                  <div className="dashboard-widget-number-content m-t-6" style={{ fontSize: 14 }}>
                    <FlexRow>
                      <div className="f-1" style={{ borderRight: '1px solid rgba(255, 255, 255, 0.3)'}}>
                        <div style={{ opacity: 0.75 }}>{t('this_quarter')}</div>
                        <strong style={{ fontSize: 22 }}>{ this.state.eolStats.thisQuarter }</strong>
                      </div>
                      <div className="f-1" style={{ borderRight: '1px solid rgba(255, 255, 255, 0.3)'}}>
                        <div style={{ opacity: 0.75 }}>{t('next_quarter')}</div>
                        <strong style={{ fontSize: 22 }}>{ this.state.eolStats.nextQuarter }</strong>
                      </div>
                      <div className="f-1" style={{ borderRight: '1px solid rgba(255, 255, 255, 0.3)'}}>
                      <div style={{ opacity: 0.75 }}>{t('over_6_months')}</div>
                        <strong style={{ fontSize: 22 }}>{ this.state.eolStats.future }</strong>
                      </div>
                    </FlexRow>
                  </div>
                </div>
              </FlexRow>
            </div>
          </div>
        </motion.li>
      </motion.ul>
      <Tabs selectedTabId={this.state.tab} className="custom-tabs" onChange={tab => this.setState({ tab: tab.toString() })}>
        <Tab title={ t('contracts.title') } id="contracts" panel={
          <FlexColumn className="m-l-24 m-r-24 m-b-24">
            <Toolbar className="table-toolbar">
              <div className="f-2 jc-s d-f">
                <ControlGroup>
                  <FormGroup inline={true}>
                    <InputGroup 
                      placeholder={t('search_placeholder')}
                      value={this.state.query}
                      onChange={this.onQueryChange}
                    />
                  </FormGroup>
                  <Button minimal icon="cross" onClick={this.onQueryReset} intent={ this.state.query.length > 0 ? 'success' : 'none' }/>
                </ControlGroup>
                {/* <FormGroup inline={true} className="m-l-12">
                  <LesseeSelect
                    hint={t('contracts.filter_by_lessee')}
                    cancelable={true}
                    minimal={true}
                    activeItem={this.state.lessee}
                    onSelect={this.onLesseeSelect}
                  />
                </FormGroup> */}
                <ButtonGroup style={{ justifySelf: 'center' }}>
                  <Button minimal icon="cloud-download" text={t('export')} onClick={() => this.export()}/>
                  { session.user.isAdmin && <Button minimal icon="cloud-upload" text={t('import')} onClick={() => this.setState({ showImporter: true })}/> }
                </ButtonGroup>
              </div>
            </Toolbar>
            
            <div className="contracts-table disable-flex">
              <ContractsTable
                id="contracts-table"
                ref={this.table}
                columns={this.getColumns()}
                fetch={this.fetch}
                onItemSelect={this.onSelect}
                onSelectionChange={this.onSelectionChange}
                sort="validTill"
                sortDir="asc"
                multiple={false}
              />
            </div>

            { session.user.isAdmin && <CreateButton onClick={this.create} /> }

            <ContractDetail 
              contract={this.state.selected}
              isOpen={this.state.showDialog}
              onClose={this.onClose}
            />
            {/* <CreateContractDialog 
              isOpen={this.state.showDialog}
              onClose={this.onClose}
            /> */}
          </FlexColumn>
        }/>

        { session.user.isAdmin && <Tab title={t('contracts.proposals')} id="proposals" panel={
          <Proposals onReload={this.onReload}/>
        }/> }
      </Tabs>

      <Importer
        isOpen={this.state.showImporter} 
        onClose={this.onImporterClose} 
        url="/contracts/import"
      />
    </MainLayout> 
  }
}

export default withTranslation()(Contracts)
