import * as React from 'react';
import { MainLayout } from '../layouts/main';
import { PurchaseInvoiceRepository } from '../repository';
import { FlexRow, CreateButton, Notifier } from '@patterns/ui';
import { DataTable, Column, ColumnType } from '@patterns/datatable';
import { PurchaseInvoice } from '../models/purchase_invoice';
import PurchaseOrderDetail from  '../components/purchase_invoices/detail';
import { Icon, Button, Popover, PopoverInteractionKind, PopoverPosition, Tabs, Tab } from '@blueprintjs/core';
import { Contract } from '../models/contract';
import ContractDetail from '../components/contract_detail';
import { download } from '@patterns/core';
import { axios, session } from '../session';
import { PurchaseInvoiceAttachment } from '../models/purchase_invoice_attachment';
import { RejectReasonDialog } from '../components/purchase_invoices/reject_reason.dialog';
import PurchaseInvoiceList from '../components/purchase_invoices/list';
import { ShowReasonDialog } from '../components/purchase_invoices/show_reason.dialog';
import { PDFPreview } from '../components/pdf_preview';
import { Breadcrumbs } from '../components/breadcrumb';
import { withTranslation, WithTranslation } from 'react-i18next';
import PurchasePool from '../components/purchase_pool';
import Pool from '../components/purchase_invoices/pool';

export const PurchaseInvoicesTable = DataTable.ofType<PurchaseInvoice>();

export interface Props extends WithTranslation {}

export interface State {
  attachment: PurchaseInvoiceAttachment
  items: PurchaseInvoice[]
  showDialog: boolean
  showContract: boolean
  showPreview: boolean
  page: number
  pages: number
  selected: PurchaseInvoice
  selectedContract: Contract
  showReason: boolean
  showReasonRequest: boolean
  showPool: boolean
  tab: string
}

export class PurchaseInvoices extends React.Component<Props, State> {
  table = React.createRef<DataTable<PurchaseInvoice>>();
  cancelToken: any;
  
  state = {
    items: [] as PurchaseInvoice[],
    page: 1,
    pages: 0,
    selected: new PurchaseInvoice({}),
    selectedContract: new Contract(),
    showContract: false,
    showDialog: false,
    showReason: false,
    showReasonRequest: false,
    showPool: false
  } as State

  componentDidMount() {
    this.fetch()
  }

  private getColumns = () => {
    const { t } = this.props;
    return [
      { 
        id: 'date',
        title: t('purchase_invoices.invoice_date'),
        type: ColumnType.Date,
        visible: true,
        width: 130,
        sortable: true
      },
      { 
        id: 'code',
        title: t('purchase_invoices.invoice_code'),
        type: ColumnType.Text,
        visible: true,
        width: 130,
        sortable: true
      },
      { 
        id: 'title',
        title: t('title'),
        type: ColumnType.Custom,
        visible: true,
        flex: 2,
        sortable: true
      },
      { 
        id: 'isAccepted',
        title: t('purchase_invoices.accepted'),
        type: ColumnType.Custom,
        visible: true,
        width: 100,
        sortable: false,
        format: invoice => invoice.isAccepted ? <Icon icon="tick" intent="success" className="m-r-12"/> : <span/>
      },
      { 
        id: 'contract',
        title: t('purchase_invoices.contract'),
        type: ColumnType.Custom,
        visible: true,
        width: 130,
        sortable: true,
        format: invoice => <Button
          small
          minimal
          icon="link" 
          intent="success"
          text={ invoice.contract ? invoice.contract.number : '' }
          onClick={(evt: any) => {
            evt.preventDefault();
            evt.stopPropagation();
            this.setState({
              selectedContract: invoice.contract,
              showContract: true
            })
          }}
        />
      },
      { 
        id: 'supplier',
        title: t('purchase_invoices.supplier'),
        type: ColumnType.Custom,
        visible: true,
        flex: 1,
        sortable: true,
        format: invoice => <span>
          <strong>{ invoice.contract.supplier.name }</strong>
        </span>
      },
      { 
        id: 'createdBy',
        title: t('created_by'),
        type: ColumnType.Custom,
        visible: true,
        flex: 1,
        sortable: true,
        format: invoice => <span>
          <Icon icon="user" intent="none" className="m-r-12"/>
          <strong>{ invoice.createdBy.name }</strong>
        </span>
      },
      { 
        id: 'attachments',
        title: t('purchase_invoices.attachments'),
        type: ColumnType.Custom,
        visible: true,
        flex: 1,
        sortable: true,
        format: invoice => {
          return invoice.attachments.map((attachment, index) => <Popover
            interactionKind={PopoverInteractionKind.HOVER}
            position={PopoverPosition.BOTTOM}
            key={`invoice-${invoice.id}-attachment-${index}`} 
            content={attachment.name}>
            <Button
              icon="link"
              intent="success"
              minimal
              onClick={(evt: any) => {
                evt.stopPropagation();
                evt.preventDefault();
                download(`/api/purchase_invoice_attachments/${attachment.id}?token=${session.token}`);
              }}
            />
          </Popover>)
        }
      }
    ] as Column<PurchaseInvoice>[];
  }
  private reload() {
    // this.table.current?.fetch();
    this.fetch();
  }

  private onClose = () => {
    this.setState({ showDialog: false });
    // this.table.current?.fetch()
    this.reload()
  }

  private onSelect = async (selected: PurchaseInvoice) => this.setState({ selected, showDialog: true })

  private create = async () => this.setState({ selected: new PurchaseInvoice({}), showDialog: true })

  private edit = async (selected: PurchaseInvoice) => this.setState({ selected, showDialog: true })

  private fetch = async () => {
    const { items } = await PurchaseInvoiceRepository.index(1, 1000, 'createdAt', 'DESC', {});
    this.setState({ items })
  }
  
  private accept = async (invoice: PurchaseInvoice) => {
    if (invoice.isRejected) {
      // reason button action
      this.setState({ selected: invoice, showReason: true })
    } else {
      await axios.put(`/purchase_invoices/${invoice.id}/accept`)
      Notifier.success('Purchas Invoice was Accepted')
      this.fetch();
    }
  }

  private reject = async (invoice: PurchaseInvoice) => {
    this.setState({ showPreview: false, showReasonRequest: true }, () => {
      this.fetch().then(() => Notifier.success('Purchas Invoice was Accepted'))
    })
  }

  private preview = async (invoice: PurchaseInvoice) => {
    if (invoice.attachments.length > 0) {
      // this.setState({
      //   attachment: invoice.attachments[0],
      //   selected: invoice,
      //   showPreview: true
      // })
      download(`/api/purchase_invoice_attachments/${invoice.attachments[0].id}?token=${session.token}`)
    } else {
      Notifier.failure(this.props.t('purchase_invoices.attachment_not_available'))
    }
  }
  
  private onContractClose = () => this.setState({ showContract: false }, this.reload)

  private onReasonClose = () => this.setState({ showReasonRequest: false, showReason: false }, this.reload)

  private onDocumentLoadSuccess = ({ numPages }: any) => {
    this.setState({ pages: numPages })
  }

  private onShowPool = () => this.setState({ showPool: true })

  private onPoolClose = () => this.setState({ showPool: false })

  private onTabChange = (tab: string) => this.setState({ tab })

  private onReason = (invoice: PurchaseInvoice) => this.setState({ showReason: true, selected: invoice })

  public render() {
    const { t } = this.props;
    return (
      <MainLayout title={t('purchase_invoices.title')}>
        <Breadcrumbs path={`t('store.title') / ${t('purchase_invoices.title')}`} title={t('purchase_invoices.title')}/>
        {/* <Button text="Purchase Pool" className="m-l-24" onClick={this.onShowPool}/> */}
        <Tabs selectedTabId={this.state.tab} onChange={this.onTabChange} className="custom-tabs" renderActiveTabPanelOnly={true}>
          <Tab id="pending" title={t('purchase_invoices.pending')} panel={
            <PurchaseInvoiceList 
              type="pending"
              invoices={this.state.items}
              onAccept={this.accept}
              onEdit={this.edit}
              onReject={this.reject}
              onSelect={this.preview}
              onReason={this.onReason}
            />
          }/>
          <Tab id="accepted" title={t('purchase_invoices.accepted')} panel={
            <PurchaseInvoiceList 
              type="accepted"
              invoices={this.state.items}
              onAccept={this.accept}
              onEdit={this.edit}
              onReject={this.reject}
              onSelect={this.preview}
              onReason={this.onReason}
            />
          }/>
          <Tab id="rejected" title={t('purchase_invoices.rejected')} panel={
            <PurchaseInvoiceList 
              type="rejected"
              invoices={this.state.items}
              onAccept={this.accept}
              onEdit={this.edit}
              onReject={this.reject}
              onSelect={this.preview}
              onReason={this.onReason}
            />
          }/>
          <Tab id="pool" title={t('purchase_invoices.pool')} panel={
            <Pool
              isOpen={true}
              onClose={() => {}}
              onSubmit={() => {}}
              residualValue={0}
            />
          }/>
        </Tabs>

        <CreateButton onClick={this.create} />

        <PurchaseOrderDetail
          invoice={this.state.selected}
          isOpen={this.state.showDialog}
          onClose={this.onClose}
        />

        <ContractDetail 
          isOpen={this.state.showContract}
          contract={this.state.selectedContract}
          onClose={this.onContractClose}
        />

        { this.state.showPreview && <PDFPreview 
          onClose={() => this.setState({ showPreview: false })}
          name={this.state.attachment.name}
          url={`/api/purchase_invoice_attachments/${this.state.attachment.id}?token=${session.token}`}
          actions={
            <FlexRow className="ai-c jc-c m-6">
              {/* <Button text={t('store_settings.accept')} intent="success" className="m-r-24" onClick={() => this.accept(this.state.selected)}/> */}
              {/* <Button text={t('store_settings.reject')} intent="danger" onClick={() => this.reject(this.state.selected)}/> */}
            </FlexRow>
          }
        /> }

      <RejectReasonDialog
        invoice={this.state.selected}
        isOpen={this.state.showReasonRequest}
        onClose={this.onReasonClose}
      />

      <ShowReasonDialog 
        invoice={this.state.selected}
        isOpen={this.state.showReason}
        onClose={this.onReasonClose}
      />

      <PurchasePool
        isOpen={this.state.showPool}
        onClose={this.onPoolClose}
        onSubmit={() => {}}
        residualValue={0}
      /> 

      </MainLayout>
    );
  }
}

export default withTranslation()(PurchaseInvoices)