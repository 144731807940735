import * as React from 'react';
import { deepEqual } from '@patterns/core';

import { MainLayout } from '../layouts/main';
import ContractReports from './contract_reports';
import { RouteComponentProps } from 'react-router-dom';
import TCOReports from '../components/reports/tcoreports';
import Budget from '../components/reports/budget';
import { endOfQuarter, startOfQuarter } from '../common';
import { Breadcrumbs } from '../components/breadcrumb';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface Params {
  tab: 'leases' | 'tco' | 'budgeting' | 'extra_costs'
}

export interface Props extends WithTranslation, RouteComponentProps {

}

export interface State {
  extend: number,
  since: Date
  tab: string
  till: Date
}

export class Reports extends React.Component<Props, State> {
  state = {
    since: startOfQuarter(new Date()),
    till: endOfQuarter(new Date()),
    extend: 0,
    tab: 'financial'
  }

  componentDidMount() {
    this.setState({
      tab: (this.props.match.params as Params).tab
    })
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    if (!deepEqual(this.props.match.params, prevProps.match.params)) {
      this.setState({
        tab: (this.props.match.params as Params).tab
      })
    }
  }

  private onTabChange = (tab: string) => { 
    this.props.history.push(`/reports/${tab}`)
  }

  public render() {
    const { t } = this.props;
    return (
      <MainLayout title="Reports">
        { this.state.tab === 'leases' && <Breadcrumbs path="Reports / Leases" title={t('leases_report.title')}/> }
        { this.state.tab === 'tco' && <Breadcrumbs path="Reports / TCO" title={t('tco_reports.title')}/> }
        { this.state.tab === 'budgeting' && <Breadcrumbs path="Reports / Budgeting" title={t('budgeting_reports.title')}/> }
        <div className={"p-r-24"}>
          {/* <FlexRow className="m-l-24 m-t-24">
            <TabButton id="leases" text="Leases" isActive={this.state.tab === 'leases'} onClick={this.onTabChange}/>
            <TabButton id="tco" text="TCO" isActive={this.state.tab === 'tco'} onClick={this.onTabChange}/>
            <TabButton id="budgeting" text="Budgeting" isActive={this.state.tab === 'budgeting'} onClick={this.onTabChange}/>
          </FlexRow> */}
          {/* <FlexRow flex={1} className="ai-c jc-e h-100p">
            <QuarterSelect 
              date={this.state.since}
              onSelect={this.onSinceSelect}
            />
            <div className="jc-c ai-c m-l-24" style={{ width: 300 }}>
              <div style={{ maxHeight: 32, maxWidth: 300}}>
                <Slider value={this.state.extend} stepSize={1} max={12} onChange={this.onSliderChange}/>
              </div>
            </div>
          </FlexRow> */}
        </div>
        { this.state.tab === 'leases' && <ContractReports 
          since={this.state.since}
          till={this.state.till} 
          {...this.props}
        /> }
        {/* { this.state.tab === 'extra_costs' && <ExtraCosts />} */}
        { this.state.tab === 'tco' && <TCOReports since={this.state.since} till={this.state.till} />}
        { this.state.tab === 'budgeting' && <Budget start={this.state.since} end={this.state.till} />}
      </MainLayout>
    );
  }
}

export default withTranslation()(Reports)