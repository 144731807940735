import { BaseModel, Selectable, Resource } from '@patterns/core'

@Resource('devices')
export class Device extends BaseModel implements Selectable {
  data: any

  constructor(data: any = {}) {
    super(data);
    this.data = data.data || {
      system: {
        manufacturer: '-',
        model: '-',
        version: '-',
        serial: '-',
        uuid: '-',
        sku: '-'
      },
      os: {
        platform: '-',
        distro: '-',
        release: '-',
        codename: '-',
        kernel: '-',
        arch: '-',
        hostname: '-',
        codepage: '-',
        logofile: '-',
        serial: '-',
        build: '-',
        servicepack: '-',
        uefi: false
      },
      cpu: {
        manufacturer: '-',
        brand: '-',
        vendor: '-',
        family: '-',
        model: '-',
        stepping: '-',
        revision: '-',
        voltage: '-',
        speed: '-',
        speedmin: '-',
        speedmax: '-',
        governor: '-',
        cores: 0,
        physicalCores: 0,
        processors: 0,
        socket: '-',
        cache: {
          l1d: 0,
          l1i: 0,
          l2: 0,
          l3: 0
        },
        flags: '-'
      },
      graphics: {
        controllers: [
          {
            vendor: '-',
            model: '-',
            bus: '-',
            vram: 0,
            vramDynamic: false
          }
        ]
      }
    }

    if (!this.data.location) {
      this.data.location = {
        lat: 60.652859,
        lng: 25.310471
      }
    }
  }

  getLabel() { return null }
  getTitle() { return this.id }
}