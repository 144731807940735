import * as React from 'react';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import ExtraServicesDialog from '../extra_services/extra_services.dialog';
import { ExtraServiceRepository } from '../../repository';
import { ExtraService } from '../../models/extra_service';

import { Notifier, Toolbar } from '@patterns/ui';
import { Button } from '@blueprintjs/core';
import { priceFormat } from '../../common';
import { withTranslation, WithTranslation } from 'react-i18next';

const ExtraServicesTable = DataTable.ofType<ExtraService>();

export interface Props extends WithTranslation {
}

export interface State {
  selected: ExtraService
  showDialog: boolean
}

export class ExtraServices extends React.Component<Props, State> {
  cancelToken: any;

  state = {
    selected: new ExtraService({}),
    showDialog: false
  } as State

  table = React.createRef<any>();

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'name',
        title: t('name'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ device.name }</span>
      },
      {
        id: 'price',
        title: t('price'),
        type: ColumnType.Number,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ priceFormat(device.price) }</span>
      },
      {
        id: 'createdAt',
        title: t('created_at'),
        type: ColumnType.DateTime,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        width: 130,
        visible: true
      },
      {
        id: 'updatedAt',
        title: t('updated_at'),
        type: ColumnType.DateTime,
        sortable: false,
        filterable: false,
        alignment: Alignment.Left,
        width: 130,
        visible: true
      }
    ] as Column<ExtraService>[]    
  }
  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await ExtraServiceRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken
    );
    this.cancelToken = cancelToken;
    return { items, total }
  }

  private onSelect = (selected: ExtraService) => this.setState({ 
    selected,
    showDialog: true 
  })

  private onDelete = async (device: ExtraService) => {
    await ExtraServiceRepository.delete(device.id);
    Notifier.success(this.props.t('store_settings.replacement_device_deleted'));
    this.setState({
      showDialog: false
    })
    this.table.current?.fetch();
  }

  private create = () => this.setState({
    selected: new ExtraService({}),
    showDialog: true
  })

  private onClose = async (changed: boolean) => { 
    this.setState({ showDialog: false }, () => {
      if (changed) {
        this.table.current?.fetch()
      }
    })
  }

  public render() {
    return (
      <div className="m-l-24 m-r-24">
        <Toolbar>
          <div className="f-1 jc-s ai-c d-f m-l-12">
            <Button intent="primary" text={this.props.t('store_settings.add_extra_service')} icon="plus" onClick={this.create}/>
          </div>
        </Toolbar>
        
        <ExtraServicesTable 
          id="extra-services-table"
          ref={this.table}
          columns={this.getColumns()}
          fetch={this.fetch}
          sort="id"
          sortDir="asc"
          onItemSelect={this.onSelect}
        />

        <ExtraServicesDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onDelete={this.onDelete}
          extraService={this.state.selected}
        />
      </div>
    );
  }
}

export default withTranslation()(ExtraServices);