import * as React from 'react';
import { Button, IconName } from '@blueprintjs/core'

export interface Props {
  title?: string
  confirmTitle?: string
  minimal?: boolean
  outlined?: boolean
  icon?: IconName
  onConfirm: () => void
  style?: React.CSSProperties
}

export function ConfirmButton({ confirmTitle, onConfirm, title, minimal = false, outlined = false, icon, style }: Props) {
  const [ isPressed, setIsPressed ] = React.useState(false) 

  React.useEffect(() => {
    let handle: number; 
    if (isPressed) {
      handle = window.setTimeout(() => {
        setIsPressed(false);
        handle = 0;
      }, 10000)
    }

    return () => {
      if (handle !== 0) {
        window.clearTimeout(handle)
      }
    }
  }, [isPressed]);

  return (
    <Button
      style={ style }
      minimal={ minimal }
      outlined={ outlined }
      text={ isPressed ? ( confirmTitle || ( confirmTitle || '' ) ) : title }
      icon={ isPressed ? "tick" : ( icon ? icon : "trash" ) }
      intent={ isPressed ? "danger" : "warning" }
      onClick={(evt: React.MouseEvent<HTMLElement, MouseEvent>) => { 
        evt.stopPropagation();
        evt.preventDefault()
        if (isPressed) {
          onConfirm();
        } else {
          setIsPressed(true) }
        }
      } 
    />
  );
}
