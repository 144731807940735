import * as React from 'react';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import ProductsDialog from '../components/products/product.dialog';
import { ProductRepository } from '../repository';
import { Product } from '../models/product';
import { Notifier, Toolbar } from '@patterns/ui';

import { Button } from '@blueprintjs/core';
import { priceFormat } from '../common';
import { withTranslation, WithTranslation } from 'react-i18next';

const ProductsTable = DataTable.ofType<Product>();

export interface Props extends WithTranslation {
}

export interface State {
  selected: Product
  showDialog: boolean
}

export class Products extends React.Component<Props, State> {
  cancelToken: any;

  state = {
    selected: new Product({}),
    showDialog: false
  } as State

  table = React.createRef<any>();

  private getColumns = () => {
    const { t, i18n: { language } } = this.props;
    return [
      {
        id: 'name',
        title: t('name'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ device.name }</span>
      },
      {
        id: 'image',
        title: t('image'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        alignment: Alignment.Center,
        width: 82,
        visible: true,
        format: device => <img
          className="table-cell-image"
          src={device.image} 
          alt=""
        />
      },
      {
        id: 'sku',
        title: t('fleet.sku'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ device.sku }</span>
      },
      {
        id: 'ean',
        title: t('ean_code'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
        format: device => <span>{ device.ean }</span>
      },
      {
        id: 'price',
        title: t('price'),
        type: ColumnType.Number,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ priceFormat(device.price) }</span>
      },
      {
        id: 'category',
        title: t('category'),
        type: ColumnType.Number,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ language === 'fi' ? device.category.name_fi : device.category.name_en }</span>
      },
      {
        id: 'createdAt',
        title: t('created_at'),
        type: ColumnType.DateTime,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        width: 130,
        visible: true
      },
      {
        id: 'updatedAt',
        title: t('updated_at'),
        type: ColumnType.DateTime,
        sortable: false,
        filterable: false,
        alignment: Alignment.Left,
        width: 130,
        visible: true
      }
    ] as Column<Product>[]
  }
  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await ProductRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken
    );
    this.cancelToken = cancelToken;
    return { items, total }
  }

  private onSelect = (selected: Product) => this.setState({ 
    selected,
    showDialog: true
  })

  private onDelete = async (device: Product) => {
    await ProductRepository.delete(device.id);
    Notifier.success(this.props.t('store_settings.replacement_device_deleted'));
    this.setState({
      showDialog: false
    })
    this.table.current?.fetch();
  }

  private create = () => this.setState({
    selected: new Product({}),
    showDialog: true
  })

  private onClose = async (changed: boolean) => { 
    this.setState({ showDialog: false }, () => {
      if (changed) {
        this.table.current?.fetch()
      }
    })
  }

  public render() {
    return (
      <div className="m-l-24 m-r-24">
        <Toolbar>
          <div className="f-1 jc-s ai-c d-f m-l-12">
            <Button intent="primary" text={this.props.t('store_settings.add_product')} icon="plus" onClick={this.create}/>
          </div>
        </Toolbar>
        
        <ProductsTable 
          id="products-table"
          ref={this.table}
          columns={this.getColumns()}
          fetch={this.fetch}
          sort="id"
          sortDir="asc"
          onItemSelect={this.onSelect}
        />

        <ProductsDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onDelete={this.onDelete}
          product={this.state.selected}
        />
      </div>
    );
  }
}

export default withTranslation()(Products)