import { Button, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Alignment, Column, ColumnType, DataTable, FilterStates } from '@patterns/datatable';
import { Notifier } from '@patterns/ui';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AutoSizer } from 'react-virtualized';
import { AppContext } from '../app_context';
import logoApple from '../assets/apple-gray.png';
import logoFujitsu from '../assets/fujitsu-symbol.png';
import logoWindows from '../assets/logo_windows.png';
import logoOsx from '../assets/osx.png';
import { Breadcrumbs } from '../components/breadcrumb';
import DeviceDialog from '../components/devices/device.dialog';
import DeviceManufacturerChart from '../components/devices/manufacturer_chart';
import OnlineDevicesChart from '../components/devices/online_devices_chart';
import OperatingSystemsChart from '../components/devices/operating_systems_chart';
import Fleet from '../components/reports/fleet';
import { MainLayout } from '../layouts/main';
import { Device } from '../models/device';
import { DeviceRepository } from '../repository';

const DevicesTable = DataTable.ofType<Device>();

export type ReportItem = { name: string, y: number, percentage: number }

const generateColumns = () => {
  const os = [ 'platform', 'distro', 'release', 'codename', 'kernel', 'arch', 'hostname', 'codepage', 'logofile', 'build', 'servicepack', 'uefi' ];

  return os.map(column => ({
    id: column,
    title: column,
    type: ColumnType.Text,
    alignment: Alignment.Left,
    flex: 1,
    visible: false,
    format: (device: Device) => <span>{ device.data.os[column] }</span>
  } as Column<Device>))
}

export interface Props extends WithTranslation {
}

export interface State {
  selected: Device
  showDialog: boolean
  showToken: boolean
}

export class Devices extends React.Component<Props, State> {
  static contextType = AppContext;

  cancelToken: any;

  state = {
    selected: new Device({}),
    showDialog: false,
    showToken: false
  } as State

  tableRef = React.createRef<any>();
  inputRef = React.createRef<HTMLInputElement>();
  
  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'id',
        title: 'ID',
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        width: 80,
        visible: false,
        format: device => <span>{ device.id }</span>
      },
      {
        id: 'manufacturer',
        title: t('fleet.manufacturer'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <div className="d-f jc-c ai-c p-6">
          { device.data.system && device.data.system.manufacturer.toLowerCase() === 'fujitsu' && <img src={logoFujitsu} style={{ height: 24 }} alt="logo" className="m-r-12"/> }
          { device.data.system && device.data.system.manufacturer.toLowerCase() === 'apple inc.' && <img src={logoApple} style={{ height: 24 }} alt="logo" className="m-r-12"/> }
          { device.data.system && device.data.system.manufacturer }
        </div>
      },
      {
        id: 'model',
        title: t('fleet.model'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ device.data.system && device.data.system.model }</span>
      },
      {
        id: 'serial',
        title: t('fleet.serial_number'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ device.data.system && device.data.system.serial }</span>
      },
      {
        id: 'uuid',
        title: 'UUID',
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ device.data.system && device.data.system.uuid }</span>
      },
      {
        id: 'sku',
        title: t('fleet.sku'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ device.data.system && device.data.system.sku }</span>
      },
      {
        id: 'os',
        title: t('fleet.operating_system'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        width: 100,
        visible: true,
        format: device => <div className="d-f jc-c ai-c p-6">
          { device.data.os && device.data.os.platform === 'win32' && <img src={logoWindows} style={{ height: 24 }} alt="logo"/> }
          { device.data.os && device.data.os.platform === 'darwin' && <img src={logoOsx} style={{ height: 24 }} alt="logo"/> }
        </div>
      },
      ...generateColumns(),
      {
        id: 'createdAt',
        title: t('created_at'),
        type: ColumnType.DateTime,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        width: 130,
        visible: true
      },
      {
        id: 'updatedAt',
        title: t('updated_at'),
        type: ColumnType.DateTime,
        sortable: false,
        filterable: false,
        alignment: Alignment.Left,
        width: 130,
        visible: true
      }
    ] as Column<Device>[]
  }

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await DeviceRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken
    );
    this.cancelToken = cancelToken;
    return { items, total }
  }


  private onSelect = (selected: Device) => this.setState({ 
    selected,
    showDialog: true 
  })

  private delete = async (device: Device) => {
    await DeviceRepository.delete(device.id);
    Notifier.success('Device was successfully removed');
    this.setState({
      showDialog: false
    })
    this.tableRef.current?.fetch();
  }

  private onClose = () => this.setState({ showDialog: false })

  private onTokenClick = async (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    await navigator.clipboard.writeText(this.context.session.user.organization.desktopToken);
    Notifier.success("Desktop Client Token was successfully copied to your Clipboard");
  }

  private toggleShowToken = () => this.setState({ showToken: !this.state.showToken })

  public render() {
    const { t } = this.props
    const token = this.context.session.user.organization.desktopToken;
    return (
      <MainLayout title="Devices">
        <div className="">
        <Breadcrumbs path={`${t('assets')} / ${t('fleet.title')}`} title={t('fleet.title')}/>

          <Fleet />
          
          <div className="p-t-12 p-l-24 p-r-24 m-24 token-widget">
            <FormGroup inline label={this.props.t('client_token')} className="w-100p">
              <InputGroup
                inputRef={this.inputRef}
                fill
                name="desktopToken"
                contentEditable={false}
                value={this.state.showToken ? token : '1234567890123456'}
                onChange={() => {}}
                onClick={this.onTokenClick}
                type={this.state.showToken ? "text" : "password"}
                rightElement={<Tooltip2 content={`${this.state.showToken ? "Hide" : "Show"} Token`}>
                  <Button
                    icon={this.state.showToken ? "unlock" : "lock"}
                    intent={Intent.WARNING}
                    minimal={true}
                    onClick={this.toggleShowToken}
                    className="p-l-24"
                  />
                </Tooltip2>}
              />
            </FormGroup>
          </div>

          <div className="d-f f-r m-t-24" style={{ height: 490 }}>
            <div className="f-1 p-l-24 p-r-12">
              <AutoSizer className="f-1">{({ height, width }) => (
                <OperatingSystemsChart height={468} width={width} legend={true}/>
              )}</AutoSizer>
            </div>
            <div className="f-1 p-l-12 p-r-12">
              <AutoSizer>{({ height, width }) => (
                <DeviceManufacturerChart height={468} width={width} legend={true}/>
              )}</AutoSizer>
            </div>
            <div className="f-1 p-l-12 p-r-24">
              <AutoSizer className="f-1">{({ height, width }) => (
                <OnlineDevicesChart height={468} width={width} legend={true}/>
              )}</AutoSizer>
            </div>
          </div>

          <div className="m-l-24 m-r-24 m-t-12">
            <DevicesTable
              id="devices-table"
              ref={this.tableRef}
              columns={this.getColumns()}
              fetch={this.fetch}
              sort="id"
              sortDir="asc"
              onItemSelect={this.onSelect}
            />
          </div>

          <DeviceDialog
            device={this.state.selected}
            isOpen={this.state.showDialog}
            onClose={this.onClose}
            onDelete={this.delete}
          />
        </div>
      </MainLayout>
    );
  }
}

export default withTranslation()(Devices)
