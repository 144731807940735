import { Eventable } from './eventable'

export enum Events {
  LOADING_START = 'loading:start',
  LOADING_STOP = 'loading:stop',
  NIGHT_MODE_TOGGLE = 'theme:night-mode-toggle'
}

export class EventBus extends Eventable {
  startLoading() {
    this.notify(Events.LOADING_START)
  }
  stopLoading() {
    this.notify(Events.LOADING_STOP)
  }
  toggleNightMode() {
    this.notify(Events.NIGHT_MODE_TOGGLE)
  }
}

const instance = new EventBus()
export default instance
