 import * as React from 'react';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import { Contact, ContactType } from '../../models/contact';
import ContactDialog from '../categories/contact_dialog';
import { ButtonGroup, Button } from '@blueprintjs/core';
import { ApiRepository } from '../../api_repository';
import { download } from '@patterns/core';
import { Notifier, Toolbar } from '@patterns/ui';
import { withTranslation, WithTranslation } from 'react-i18next';
import LesseeDialog from '../lessee.dialog';

export interface Props<T extends Contact> extends WithTranslation {
  id: string
  type: ContactType
  title: string
  repository: ApiRepository<T>
  button: string
}

export interface State {
  selected: Contact
  showDialog: boolean
}

export class ContactsView<T extends Contact> extends React.Component<Props<T>, State> {
  cancelToken: any;
  private ContactsTable = DataTable.ofType<T>();

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      selected: new this.props.repository.klass({}),
      showDialog: false
    } as State
  }

  tableRef = React.createRef<any>();

  public static ofType<T extends Contact>() {
    return ContactsView as new (props: Props<T>) => ContactsView<T>
  }

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'name',
        title: t('name'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: contact => <strong>{ contact.name }</strong>
      },
      {
        id: 'description',
        title: t('description'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true
      },
      {
        id: 'email',
        title: t('email'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true
      },
      {
        id: 'city',
        title: t('city'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true
      },
      {
        id: 'businessID',
        title: t('business_id'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true
      },
      {
        id: 'updatedAt',
        title: t('updated_at'),
        type: ColumnType.DateTime,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        width: 140,
        visible: true
      }
    ] as Column<T>[]
  }

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await this.props.repository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken, `type=${this.props.type}`
    );
    this.cancelToken = cancelToken
    return { items, total }
  }

  private reload() {
    this.tableRef.current?.fetch();
  }

  private onSelect = (selected: Contact) => this.setState({ 
    selected,
    showDialog: true 
  })

  private create = () => this.setState({
    selected: new this.props.repository.klass({}) as Contact,
    showDialog: true
  })

  private onClose = () => this.setState({ showDialog: false })

  private onSave = async (contact: Contact) => {
    await this.props.repository.save(contact as T);
    this.setState({
      showDialog: false
    });
    this.reload();
    Notifier.success(this.props.t('settings.contact_save_success'))
  }

  private onDelete = async (contact: Contact) => {
    await this.props.repository.delete(contact.id);
    this.setState({ showDialog: false })
    this.reload()
  }

  public render() {
    const { t } = this.props;
    const isLessee = this.props.type === ContactType.LESSEE;

    return (
      <div className="m-l-24 m-r-24">
        <Toolbar>
          <div className="f-1 jc-s ai-c d-f m-l-12">
            <Button intent="primary" text={t(this.props.button)} icon="plus" onClick={this.create}/>
          </div>
        </Toolbar>

        <this.ContactsTable
          id={this.props.id}
          ref={this.tableRef}
          columns={this.getColumns()}
          fetch={this.fetch}
          sort="name"
          sortDir="asc"
          multiple={true}
          onItemSelect={this.onSelect}
          tableActions={<ButtonGroup style={{ justifySelf: 'center' }}>
            <Button minimal icon="cloud-download" text={t('export')} onClick={() => download('/api/contacts/export/0', '')}/>
            <Button minimal icon="cloud-upload" text={t('import')}/>
          </ButtonGroup>}
        />

        {/* <CreateButton onClick={this.create} /> */}

        { !isLessee && <ContactDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onSave={this.onSave}
          onDelete={this.onDelete}
          contact={this.state.selected}
        /> }

        { isLessee && <LesseeDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onSave={this.onSave}
          onDelete={this.onDelete}
          contact={this.state.selected}
        /> }
      </div>
    );
  }
}

export default withTranslation()(ContactsView)