import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import produce from 'immer';
import { deepEqual } from '@patterns/core';
import { axios } from '../common';
import { AppContext, AppContextType } from '../app_context';
import { Highcharts, HighChartsTheme } from '../highcharts';

export interface Props {
  title: string;
  url: string;
  legend?: boolean;
  height: number;
  width: number;
  onPointClick?: (data: any) => void;
}

export interface State {
  options: Highcharts.Options
}

export class PieWidget extends React.Component<Props, State> {
  static contextType = AppContext;
  private contextCopy = produce(this.context, () => {});

  private options: Highcharts.Options = {
    chart: {
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      plotBorderWidth: undefined,
      plotShadow: false,
      type: 'pie',
      animation: false,
      options3d: {
        enabled: true,
        alpha: 0
      }
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          connectorWidth: 0,
          style: {
            fontWeight: 'normal'
          }
        },
        events: {
          click: (e) => {
            if (this.props.onPointClick) {
              this.props.onPointClick(e.point);
            }
          }
        },
        size: (this.props.width / 2),
        innerSize: this.props.width / 2,
        depth: 45
      },
      series: {
        animation: false  
      }
    },
    series: [{
      innerSize: '50%',
      name: 'Share',
      type: 'pie',
      showInLegend: true,
      data: [
          { name: 'Loading...', y: 0 }
      ]
    }]
  }

  constructor(props: Props, context: AppContextType) {
    super(props);
    this.options.title = {...context.chartTheme, text: '' }

    const options = HighChartsTheme(this.options);
    // if (!options.legend) {
    //   options.legend = {}
    // }
    // options.legend.enabled = props.legend === true;
    this.state = { options }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.contextCopy, this.context)) {
      this.contextCopy = this.context;
      const options = HighChartsTheme(this.options);
      if (!options.legend) {
        options.legend = {}
      }
      options.legend.enabled = this.props.legend === true;
      this.setState({ options })
    }
  }

  componentDidMount() {
    this.fetch()
  }

  public fetch = async () => {
    const response = await axios.get(this.props.url);
    const items = response.data;
    const data = items.length === 0 ? [{ name: 'None', y: 0 }] : response.data;
    
    const isEmpty = data.length === 1 && data[0].id === 'none';

    const options = HighChartsTheme(produce(this.state.options, options => {
      options.title!.text = '';
      options.plotOptions!.pie!.size = this.props.width / 4;
      options.plotOptions!.pie!.innerSize = this.props.width / 5;
      (options as any).series[0].data = isEmpty ? [{ id: 'None', y: 0 }] : data;
    }));
    this.setState({ options })
  }

  public render() {
    const { height, width, title } = this.props;
    
    return (
      <div className="chart-container" style={{ height, width }}>
        <div className="chart-inner-title">
          { title }
        </div>
        <div className="chart-inner-container">
          <HighchartsReact
            highcharts={Highcharts}
            options={this.state.options}
            immutable={true}
          />
        </div>
      </div>
    );
  }
}
