import * as React from 'react';
import { Dialog, Classes, Button, FormGroup, NumericInput, InputGroup, Divider } from '@blueprintjs/core';
import { Office } from '../../models/office';
import { deepEqual } from '@patterns/core';
import { OfficeRepository } from '../../repository';
import produce from 'immer';
import { ConfirmButton, FlexRow, FlexColumn, Notifier } from '@patterns/ui';
import { AppContext } from '../../app_context';
import axios from 'axios';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface Props extends WithTranslation {
  isOpen: boolean
  onClose: (changed: boolean) => void
  onDelete: (device: Office) => void
  office: Office
}

export interface State {
  name: string;
  address: string;
  street: string;
  street_2: string;
  city: string;
  postalCode: string;
  lat: string;
  lng: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
}

export class OfficesDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props)
    this.state = {
      name: props.office.name,
      street: props.office.street,
      street_2: props.office.street_2,
      city: props.office.city,
      postalCode: props.office.postalCode,
      address: props.office.address,
      lat: props.office.lat.toString(),
      lng: props.office.lng.toString(),
      contactName: props.office.contactName,
      contactEmail: props.office.contactEmail,
      contactPhone: props.office.contactPhone
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.office, prevProps.office)) {
      const { 
        name, street, street_2, city, postalCode, lat, lng, contactName, contactPhone, contactEmail
      } = this.props.office;
      this.setState({ 
        name, street, street_2, city, postalCode, 
        lat: lat.toString(), lng: lng.toString(),
        contactName, contactPhone, contactEmail
      })
    }
  }

  private delete = async () => this.props.onDelete(this.props.office)

  private onClose = () => this.props.onClose(false)

  private save = async () => {
    const office = produce(this.props.office, office => {
      office.name = this.state.name;
      office.address = this.state.address;
      office.street = this.state.street;
      office.street_2 = this.state.street_2;
      office.city = this.state.city;
      office.postalCode = this.state.postalCode;
      office.lat = parseFloat(this.state.lat);
      office.lng = parseFloat(this.state.lng);
      office.contactName = this.state.contactName;
      office.contactEmail = this.state.contactEmail;
      office.contactPhone = this.state.contactPhone;
    });
    await OfficeRepository.save(office);
    Notifier.success(this.props.t('settings.office_save_success'));
    this.props.onClose(true)
  }

  private onLatChange = (value: number, valueString: string) => {
    this.setState({ 
      lat: valueString
    })
  }

  private onLngChange = (value: number, valueString: string) => this.setState({ 
    lng: valueString
  })

  private geocode = async () => {
    const { street, street_2, city, postalCode } = this.state;
    const elements = [street, street_2, city, postalCode, 'FINLAND'].filter(f => f).join(', ');
    const query = encodeURI(elements);

    const result = await axios.get(`https://nominatim.openstreetmap.org/search?q=${query}&format=json`, {
      headers: {
        'User-Agent': 'JIT LeaseX Client'
      }
    })
    if (result.data.length > 0) {
      const item = result.data[0];
      this.setState({ lat: item.lat, lng: item.lon })
    }
  }

  private onStreetChange = (street: string) => this.setState({ street }, this.geocode)

  private onStreet2Change = (street_2: string) => this.setState({ street_2 }, this.geocode)

  private onCityChange = (city: string) => this.setState({ city }, this.geocode)

  private onPostalCodeChange = (postalCode: string) => this.setState({ postalCode }, this.geocode)

  private onNameChange = (name: string) => this.setState({ name })

  private onContactNameChange = (contactName: string) => this.setState({ contactName })

  private onContactEmailChange = (contactEmail: string) => this.setState({ contactEmail })

  private onContactPhoneChange = (contactPhone: string) => this.setState({ contactPhone })

  public render() {
    const { t } = this.props;
    return (
      <Dialog canOutsideClickClose={false} title={t('office')} isOpen={this.props.isOpen} onClose={this.onClose} className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <FlexColumn>
            <FormGroup inline={true} label={t('name')}>
              <InputGroup 
                value={this.state.name}
                onChange={(evt: any) => this.onNameChange(evt.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup inline={true} label={t('street')}>
              <InputGroup 
                value={this.state.street}
                onChange={(evt: any) => this.onStreetChange(evt.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup inline={true} label={t('street_2')}>
              <InputGroup 
                value={this.state.street_2}
                onChange={(evt: any) => this.onStreet2Change(evt.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup inline={true} label={t('postalCode')}>
              <InputGroup 
                value={this.state.postalCode}
                onChange={(evt: any) => this.onPostalCodeChange(evt.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup inline={true} label={t('city')}>
              <InputGroup 
                value={this.state.city}
                onChange={(evt: any) => this.onCityChange(evt.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup inline={true} label={t('latitude')}>
              <NumericInput 
                buttonPosition="none"
                fill={true}
                value={this.state.lat}
                onValueChange={this.onLatChange}
              />
            </FormGroup>
            <FormGroup inline={true} label={t('longitude')}>
              <NumericInput 
                buttonPosition="none"
                fill={true}
                value={this.state.lng}
                onValueChange={this.onLngChange}
              />
            </FormGroup>
            <Divider />
            <h3>{t('contact_person')}</h3>
            <FormGroup inline={true} label={t('name')}>
              <InputGroup
                value={this.state.contactName}
                onChange={(evt: any) => this.onContactNameChange(evt.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup inline={true} label={t('email')}>
              <InputGroup
                value={this.state.contactEmail}
                onChange={(evt: any) => this.onContactEmailChange(evt.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup inline={true} label={t('phone')}>
              <InputGroup
                value={this.state.contactPhone}
                onChange={(evt: any) => this.onContactPhoneChange(evt.currentTarget.value)}
              />
            </FormGroup>
          </FlexColumn>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <FlexRow flex={1}>
              <ConfirmButton
                title={t('delete')}
                confirmTitle={t('confirm_delete')}
                onConfirm={this.delete}
              />
            </FlexRow>
            <Button text={t('cancel')} onClick={this.onClose}/>
            <Button text={t('save')} onClick={this.save} intent="success"/>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(OfficesDialog)