import { Notifier } from '@patterns/ui';
import * as React from 'react';
import { AppContext } from '../../app_context';
import DeviceDialog from '../devices/device.dialog';
import { Device } from '../../models/device';
import { ReportItem } from '../../pages/devices';
import { DeviceRepository } from '../../repository';
import { axios } from '../../session';
import { MapView } from '../map';
import { NumberWidget } from '../number_widget';
import { ProgressChart } from '../progress_chart';
import { motion } from 'framer-motion';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface Props extends WithTranslation {}

export interface State {
  selected: Device
  showDialog: boolean,
  online: ReportItem[]
}


const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

export class Fleet extends React.Component<Props, State> {
  static contextType = AppContext;

  cancelToken: any;

  state = {
    selected: new Device({}),
    showDialog: false,
    online: []
  } as State

  tableRef = React.createRef<any>();
  inputRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    this.fetchCharts();
  }

  private delete = async (device: Device) => {
    await DeviceRepository.delete(device.id);
    Notifier.success(this.props.t('fleet.remove_device_success'));
    this.setState({
      showDialog: false
    })
    this.tableRef.current?.fetch();
  }

  private onClose = () => this.setState({ showDialog: false })

  private calcPercentages = (items: ReportItem[]) => {
    const total = items.reduce((total, item) => total += item.y, 0);
    items.forEach(item => {
      item.percentage = item.y / total
    })
  }

  private fetchCharts = async () => {
    const online = (await axios.get('/devices/online_chart')).data as ReportItem[];
    this.calcPercentages(online);
    this.setState({
      online
    });
  }

  private getOnline() {
    const item = this.state.online.find(i => i.name === this.props.t('fleet.online'))
    return item ? item.y : 0
  }

  private getOffline() {
    const item = this.state.online.find(i => i.name === this.props.t('fleet.offline'))
    return item ? item.y : 0
  }

  private getUnregistered() {
    const item = this.state.online.find(i => i.name === this.props.t('fleet.unknown'))
    return item ? item.y : 0
  }

  public render() {
    const { t } = this.props;
    return (
      <div>
          <motion.ul className="container m-24 d-f f-r"
            variants={container}
            initial="hidden"
            animate="visible" 
            style={{ maxHeight: 110, height: 110 }}>
          <motion.li key={`dash-users`} className="item m-r-24 blue-alpha f-1 d-f" variants={item}>
            <NumberWidget title={t('fleet.devices_online')} value={this.getOnline()} color="" icon="cloud-upload"/>
          </motion.li>
          <motion.li key={`dash-assets`} className="item m-r-24 blue-alpha f-1 d-f" variants={item}>
            <NumberWidget title={t('fleet.devices_offline')} value={this.getOffline()} color="" icon="cloud-download"/>
          </motion.li>
          <motion.li key={`dash-contracts`} className="blue-alpha f-1 d-f" variants={item}>
            <NumberWidget title={t('fleet.devices_unregistered')} value={this.getUnregistered()} color="" icon="cloud"/>
          </motion.li>
        </motion.ul>
{/* 
        <div className="d-f f-r" style={{ height: 110 }}>
          <div className="f-1">
            <NumberWidget value={this.getOnline()} title="Devices ONLINE" icon="cloud-upload" color="" className="m-l-24 m-r-12 blue-alpha"/>
          </div>
          <div className="f-1">
            <NumberWidget value={this.getOffline()} title="Devices OFFLINE" icon="cloud-download" color="" className="m-l-12 m-r-12 blue-alpha"/>
          </div>
          <div className="f-1">
            <NumberWidget value={this.getUnregistered()} title="Devices UNREGISTERED" icon="cloud" color="" className="m-l-12 m-r-24 blue-alpha"/>
          </div>
        </div> */}

        <div className="d-f f-r m-b-24 m-t-12">
          <div className="f-1">
            <ProgressChart url="/devices/operating_system_chart" title={t('fleet.operating_systems')} intent="success" className="m-l-24 m-r-12"/>
          </div>
          <div className="f-1">
            <ProgressChart url="/devices/manufacturer_chart" title={t('fleet.manufacturers')} intent="warning" className="m-l-12 m-r-12"/>
          </div>
          <div className="f-1">
            <ProgressChart url="/devices/cpu_chart" title={t('fleet.cpus')} intent="primary" className="m-l-12 m-r-24"/>
          </div>
        </div>

        <div className="d-f f-r m-l-24 m-r-24" style={{ height: 400, borderRadius: 7 }}>
          <MapView lat={60.652859} lng={25.310471}/>
        </div>

        <DeviceDialog
          device={this.state.selected}
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onDelete={this.delete}
        />
      </div>
    );
  }
}

export default withTranslation()(Fleet)