import * as React from 'react';
import { Button, Classes, Dialog, FormGroup, InputGroup, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { FlexRow } from '@patterns/ui';
import { axios, session } from '../../session';
import SiemensCustomerForm, { SiemensCustomer } from './siemens_customer.form';
import { SiemensProposal } from './calculation.form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { LesseeRepository } from '../../repository';
import { Lessee } from '../../models/lessee';
import {PriceList, PricelistSelect} from "../pricelist_select";

const SellerSelect = Select.ofType<SiemensSeller>();

const DefaultOffice = {
  "id": 132896,
  "name": "JIT Consulting Oy",
  "postalAddress": {
    "id": 873160,
    "address1": "Tiilipojanlenkki 4 A",
    "address2": "",
    "city": "Vantaa",
    "postalCode": "01720",
    "phoneNumber": "020-7738860",
    "email": ""
  }
} as SiemensOffice;

export type SiemensOffice = {
  id: number;
  name: string;
  postalAddress: {
    id: number;
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    phoneNumber: string;
    email: string;
  };
}

export type SiemensRole = {
  id: string;
  name: string;
}

export type SiemensSeller = {
  id: number;
  firstName: string;
  lastName: string;
  title?: string;
  email: string;
  phone?: string;
  cellPhone?: string;
  fax: null;
  role: SiemensRole;
  blocked: boolean;
  blockedReason: null;
  loginPresent: true;
  officeIds: number[];
}

export type Stage = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export interface Props extends WithTranslation {
  isOpen: boolean;
  onClose: (proposal?: SiemensProposal) => void;
}

export interface State {
  allSellers: SiemensSeller[]
  creditApplicationLabel: string
  customer?: SiemensCustomer
  office: SiemensOffice
  seller: SiemensSeller
  priceList: PriceList
  lessee: Lessee
  offices: SiemensOffice[]
  sellers: SiemensSeller[]
}

export class CreateProposalDialog extends React.Component<Props, State> {
  form = React.createRef<any>();

  state = {
    allSellers: [],
    creditApplicationLabel: '',
    office: DefaultOffice,
    seller: {} as SiemensSeller,
    lessee: new Lessee({}),
    priceList: {
      id: 14736,
      name: 'Puitevuokra 2020'
    } as PriceList,
    offices: [],
    sellers: [],
  } as State

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isOpen === false && this.props.isOpen === true) {
      this.setState({
        creditApplicationLabel: '',
        office: DefaultOffice as SiemensOffice,
      })
    }
  }

  private create = async () => {
    if (!this.state.customer) {
      return
    }

    if (!this.state.office) {
      return
    }

    if (!this.state.seller) {
      return
    }

    const registrationNumber = this.state.customer.businessPartnerRef ? this.state.customer.businessPartnerRef.registrationNumber : this.state.customer.businessPartner!.registrationNumber
    const companyType = this.state.customer.businessPartnerRef ? this.state.customer.businessPartnerRef.companyType : this.state.customer.businessPartner!.companyType

    const proposal = {
      officeId: this.state.office.id,
      salesContactId: this.state.seller.id,
      label: this.state.creditApplicationLabel,
      customerBpRef: {
        registrationNumber: parseInt(registrationNumber),
        companyType
      }
    }

    const response = await axios.post('/siemens/proposals', proposal);
    this.props.onClose(response.data);
  }

  private fetch = async () => {
    try {
      const officesRes = await axios.get('/siemens/offices');
      const sellersRes = await axios.get('/siemens/sellers');

      const sellers = (sellersRes.data.sellers  as SiemensSeller[]).filter(seller => seller.officeIds.includes(this.state.office.id));
      const polus = sellers.find(seller => seller.lastName === 'Polus' && seller.firstName === 'Jarno');
      const lessee = await LesseeRepository.get(session.lesseeId!);
      this.setState({
        lessee,
        seller: polus!,
        sellers,
        offices: officesRes.data.offices as SiemensOffice[],
        allSellers: sellersRes.data.sellers as SiemensSeller[]
      })
    } catch (e) {
      console.error('siemens error (proposal dialog)', e)
    }
  }

  private renderSeller = (seller: SiemensSeller, options: any) => <MenuItem
    key={`seller-item-${options.index}`}
    text={`${seller.firstName} ${seller.lastName}`}
    label={`${seller.role.name}`}
    onClick={options.handleClick}
  />

  private onCustomerChange = (customer: SiemensCustomer) => { this.setState({ customer }) }

  private onSellerSelect = (seller: SiemensSeller) => this.setState({ seller })

  private onPriceListSelect = (priceList: PriceList | undefined) => this.setState({ priceList: priceList ?? { id: 0, name: '' } })

  private onCreditApplicationLabelChange = (evt: React.FormEvent<HTMLInputElement>) => this.setState({
    creditApplicationLabel: evt.currentTarget.value
  })

  private close = () => this.props.onClose()

  public render() {
    const { t } = this.props;
    let canContinue = false;
    const sellerName = this.state.seller.lastName ? `${this.state.seller.firstName} ${this.state.seller.lastName}` : t('select_seller');
    return <Dialog canOutsideClickClose={false} isOpen={this.props.isOpen} onClose={this.close} title={t('create_siemens_proposal')}  style={{ width: 1076 }} className="bp3-dark">
      <div className={Classes.DIALOG_BODY}>

        <div className="wizard-form m-24 align-left separator-bottom">
          <React.Fragment>
            <h2>{t('basic_information')}</h2>
            <FlexRow className={"labels-right"}>
              <div className="f-1">
                <FormGroup label={t('seller')} className="f-1" inline={true}>
                  <SellerSelect
                    query={sellerName}
                    activeItem={this.state.seller}
                    itemRenderer={this.renderSeller}
                    items={this.state.sellers}
                    onItemSelect={this.onSellerSelect}
                  >
                    <Button
                      disabled={typeof this.state.office.name === 'undefined'}
                      minimal
                      text={this.state.seller.lastName ? `${this.state.seller.firstName} ${this.state.seller.lastName}` : t('select_seller') }
                      rightIcon="chevron-down"
                    />
                  </SellerSelect>
                </FormGroup>
              </div>
              <div className="f-1">

              </div>
            </FlexRow>

            <FormGroup label={t('credit_application_label')} className="wide-label m-t-12">
              <InputGroup
                value={this.state.creditApplicationLabel}
                onChange={this.onCreditApplicationLabelChange}
              />
            </FormGroup>
          </React.Fragment>
        </div>


        <div className="m-24">
          <SiemensCustomerForm lessee={this.state.lessee} proposal={{} as SiemensProposal} onChange={this.onCustomerChange} />
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              intent={ canContinue ? 'primary' : 'none' }
              text={t('create_proposal')}
              className="m-r-24"
              onClick={this.create}
            />
          </div>
        </div>
      </div>
    </Dialog>
  }
}

export default withTranslation()(CreateProposalDialog)
