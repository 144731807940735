import { Button, Classes, Dialog } from '@blueprintjs/core';
import * as React from 'react';
import { PurchaseInvoice } from '../../models/purchase_invoice';

export interface Props {
  invoice: PurchaseInvoice
  isOpen: boolean
  onClose: () => void
}

export interface State {

}

export class ShowReasonDialog  extends React.Component<Props> {
  public render() {
    return (
      <Dialog canOutsideClickClose={false} title="Reject Reason" isOpen={this.props.isOpen} onClose={this.props.onClose} className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <p>{ this.props.invoice.rejectionReason }</p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Close" icon="cross" onClick={this.props.onClose}/>
          </div>
        </div>
      </Dialog>
    );
  }
}
