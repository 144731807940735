import { BaseModel, Selectable, Resource, deserializeDate } from '@patterns/core'
import { deserializeString } from '@patterns/core'
import { CostCenter } from './cost_center';
import { ContractAttachment } from './contract_attachment';
import { Asset } from './asset';
import { Supplier } from './supplier';
import { Lessee } from './lessee';
import { LeasingCompany } from './leasing_company';
import { PurchaseInvoice } from './purchase_invoice';
import { diffDays, monthDiff } from '../common';
import moment from 'moment';

@Resource('contracts')
export class Contract extends BaseModel implements Selectable {
  number: string
  startDate: Date
  validTill: Date
  leaseMonetaryMonth: number
  extendedLeasingPrice: number
  reclaimPrice: number
  forceReclamation: boolean
  acceptDeliveryReady: boolean
  validity: number
  leasingCompany: LeasingCompany
  costCenter: CostCenter
  supplier: Supplier
  lessee: Lessee
  attachments: ContractAttachment[]
  assets: Asset[]
  purchaseInvoices: PurchaseInvoice[]
  note: string
  isDummy: boolean

  constructor(data: any = {}) {
    super(data);
    this.number = deserializeString(data.number);
    this.startDate = deserializeDate(data.startDate);
    this.validTill = deserializeDate(data.validTill);
    this.leaseMonetaryMonth = data.leaseMonetaryMonth;
    this.extendedLeasingPrice = data.extendedLeasingPrice;
    this.reclaimPrice = data.reclaimPrice;
    this.forceReclamation = data.forceReclamation;
    this.acceptDeliveryReady = data.acceptDeliveryReady;
    this.note = deserializeString(data.note);
    this.validity = data.validity;
    this.leasingCompany = new LeasingCompany(data.leasingCompany);
    this.costCenter = new CostCenter(data.costCenter || {});
    this.supplier = new Supplier(data.supplier || {});
    this.lessee = new Lessee(data.lessee || {});
    this.isDummy = data.isDummy || false;
    
    if (data.attachments) {
      this.attachments = data.attachments.map((attachment: any) => new ContractAttachment(attachment))
    } else {
      this.attachments = []
    }

    if (data.assets) {
      this.assets = data.assets.map((asset: any) => new Asset(asset));
    } else {
      this.assets = []
    }

    if (data.purchaseInvoices) {
      this.purchaseInvoices = data.purchaseInvoices.map((invoice: any) =>new PurchaseInvoice(invoice))
    } else {
      this.purchaseInvoices = []
    }
  }

  get purchaseInvoiceAttachments() {
    return this.purchaseInvoices.map(i => i.attachments)
  }

  get remainingDays() {
    return diffDays(this.validTill, new Date())
  }
  
  get remainingMonths() {
    const now = new Date();
    if (this.validTill.getTime() < now.getTime()) {
      return 0
    }
    
    return monthDiff(new Date(), this.validTill);
  }

  get isExpired() {
    const now = new Date().getTime()
    return moment(this.validTill).subtract(1, 'month').toDate().getTime() < now
  }

  get color() {
    const thisQuarterStart = moment().startOf('quarter').toDate();
    const thisQuarterEnd = moment().endOf('quarter').toDate();
    const nextQuarterStart = moment().add(1, 'quarter').startOf('quarter').toDate();
    const nextQuarterEnd = moment().add(1, 'quarter').endOf('quarter').toDate();

    if (this.validTill.getTime() <= thisQuarterEnd.getTime()) {
      return 'red'
    }

    if (this.validTill.getTime() >= nextQuarterStart.getTime() && this.validTill.getTime() <= nextQuarterEnd.getTime()) {
      return 'orange'
    }

    return 'green'
  }

  get isOwned() {
    return ['dummy', 'JIT123123123'].includes(this.number) || this.isExpired;
  }

  getLabel() { return this.lessee.name }
  getTitle() { 
    if (this.isOwned) {
      return 'owned';
    }
    
    return this.number 
  }
}
