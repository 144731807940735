import Highcharts from 'highcharts'
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";

require('highcharts/highcharts-3d')(Highcharts);
highchartsMore(Highcharts);
solidGauge(Highcharts);

export { Highcharts }

export const setHighChartTheme = (dark: boolean) => {
  const options = HighchartsLightTheme;
  options.credits = { enabled: false };
  Highcharts.setOptions(options);
  Highcharts.setOptions({
    colors: Highcharts.map(Highcharts.getOptions().colors || [], function (color: string) {
        return {
            radialGradient: {
                cx: 0.5,
                cy: 0.3,
                r: 0.7
            },
            stops: [
                [0, color],
                [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
            ]
        };
    })
  }); 
}

export const HighChartsTheme = (options?: Highcharts.Options): Highcharts.Options => {
  const theme = (Highcharts as any).theme;

  if (!options) {
    return theme
  }
  
  return { ...theme, ...options }
}

export const HighchartsLightTheme = {
  colors: ['#45CB92', '#B4D8BF', '#FC1368', '#F2F2C0', '#FEC5FE', '#24CBE5', '#2B7D9D', '#FD7577', '#78D295'], 
  chart: {
      backgroundColor: 'transparent',
      borderWidth: 0,
      plotBackgroundColor: 'rgba(255, 255, 255, .1)',
      plotShadow: true,
      plotBorderWidth: 1,
      plotBorderColor: 'transparent'
  },
  title: {
      style: {
          color: 'transparent',
          fontSize: '12px',
          font: ' 12px "Montserrat", "Trebuchet MS", Verdana, sans-serif'
      }
  },
  subtitle: {
      style: {
          color: '#ffffff',
          fontWeight: 'normal',
          font: ' 12px "Montserrat", "Trebuchet MS", Verdana, sans-serif'
      }
  },
  xAxis: {
      gridLineWidth: 1,
      gridLineColor: '#ffffff20',
      lineColor: '#000',
      tickColor: '#000',
      alternateGridColor: 'transparent',
      labels: {
          style: {
              color: 'white',
              fontWeight: 'normal',
              font: ' 11px "Montserrat", Trebuchet MS, Verdana, sans-serif'
          }
      },
      title: {
          style: {
              color: 'white',
              fontWeight: 'normal',
              fontSize: '12px',
              fontFamily: '"Montserrat", Trebuchet MS, Verdana, sans-serif'
          }
      }
  },
  yAxis: {
      minorTickInterval: 'auto',
      gridLineColor: '#ffffff20',
      minorTickColor: 'green',
      minorGridLineColor: '#ffffff20',
      alternateGridColor: 'transparent',
      lineColor: '#000',
      lineWidth: 1,
      tickWidth: 1,
      tickColor: '#ffffff80',
      labels: {
          style: {
              color: 'white',
              fontWeight: 'normal',
              font: ' 11px "Montserrat", Trebuchet MS, Verdana, sans-serif'
          }
      },
      title: {
          margin: 24,
          style: {
              color: '#ffffff80',
              fontWeight: 'normal',
              fontSize: '12px',
              fontFamily: '"Montserrat", Trebuchet MS, Verdana, sans-serif'
          }
      }
  },
  plotOptions: {  
    series: {
        dataLabels: {
            color: 'white',
            style: {
                fontSize: '13px',
                textOutline: 'none',
                fontWeight: 'normal'
            }
        },
        marker: {
            lineColor: '#333'
        }
    },
    boxplot: {
        fillColor: '#505053'
    },
    candlestick: {
        lineColor: 'white'
    },
    errorbar: {
        color: 'white'
    }
},
  legend: {
      enabled: true,
      backgroundColor: 'transparent',
      itemStyle: {
          font: '500 12px "Montserrat", Trebuchet MS, Verdana, sans-serif',
          color: 'white',
          fontWeight: 'normal'
      },
      itemHoverStyle: {
          color: '#039'
      },
      itemHiddenStyle: {
          color: 'gray'
      }
  },
  navigation: {
      buttonOptions: {
          theme: {
              stroke: '#CCCCCC'
          }
      }
  }
} as Highcharts.Options