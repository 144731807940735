import { Button, Icon, TextArea } from '@blueprintjs/core';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { axios, getQueryVariable } from '../common';
import { Breadcrumbs } from '../components/breadcrumb';
import { SimpleLayout } from '../layouts/simple';

export interface Props extends WithTranslation {
}

export interface State {
  reason: string;
  isSaved: boolean;
}

export class RejectPage extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props)
    this.state = {
      isSaved: false,
      reason: ''
    }
  }

  private onReasonChange = (evt: any) => {
    this.setState({ reason: evt.currentTarget.value })
  }

  private save = async () => {
    const { reason } = this.state;
    await axios.put(window.location.pathname, { reason });
    this.setState({
      isSaved: true
    })
  }

  public render() {
    const { t } = this.props;
    const code = getQueryVariable('code')
    
    return (
      <SimpleLayout title="Purchase Invoice Confirmation">
        <Breadcrumbs path={`Store / Verkkokauppa`} title={t('purchase_invoices.confirmation')}/>
        <div className="content-full d-f f-c ai-c jc-c" style={{ height: 'calc(100vh - 150px)' }}>
          <div style={{ 
            height: 360, 
            width: 360, 
            backgroundColor: 'rgba(0, 0, 0, 0.3)', 
            boxShadow: '0 5px 15px 5px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(0, 0, 0, 0.6)',
            borderRadius: 12,
            padding: 24,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center'
          }}>
            <Icon size={48} icon="cross" color='#f00' style={{ margin: '0 auto 24px auto' }}/>
            <h1 style={{ color: 'white', marginTop: 0, marginBottom: 24, padding: 0}}>{t('purchase_invoices.one')}<br/>{code}</h1>
            { !this.state.isSaved && <>
              <p style={{ color: 'white', fontSize: '14px' }}>{t('purchase_invoices.enter_reject_reason')}</p>
              <TextArea 
                value={this.state.reason}
                onChange={this.onReasonChange}
                style={{ color: 'white', border: '1px solid rgba(255, 255, 255, 0.3'}}
              />
              <Button
                text={t('purchase_invoices.reject')}
                intent="danger"
                style={{ marginTop: 12 }}
                onClick={this.save}
              />
            </> }
            { this.state.isSaved && <>
              <p style={{ color: 'white', fontSize: '14px' }}>{t('purchase_invoices.reject_success')}</p>
            </>}
          </div>
        </div>
      </SimpleLayout>
    );
  }
}

export default withTranslation()(RejectPage)
