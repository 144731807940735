import { Eventable } from "@patterns/eventable";
import { Notifier } from "@patterns/ui";
import { ExtraCost, ExtraCostItem } from "./models/extra_cost";
import { ExtraService } from "./models/extra_service";
import { Product } from "./models/product";

const STORAGE_KEY = `leasex:shopping-cart`;

export enum ShoppingCartEvents {
  UPDATE = 'shopping-cart:update'
}

export type CountedProduct = {
  count: number
  total: number
  item: Product
  extraCosts: { [id: string]: { cost: ExtraCost, items: ExtraCostItem[] } | undefined  }
  extraServices: { [id: string]: ExtraService | undefined }
}

export type ProductWithServices = {
  extraServices: ExtraService[]
  item: Product
}

export class ShoppingCart extends Eventable {
  items: CountedProduct[] = []

  constructor() {
    super();
    this.restore()
  }

  public add = (item: Product) => {
    this.items.push({ count: 1, item, total: item.price, extraServices: {}, extraCosts: {} });
    this.save();
    Notifier.success('Added to cart')
  }

  public clear = () => {
    this.items = [];
    this.save()
  }

  public removeItemAtIndex = (item: CountedProduct, index: number) => {
    this.items.splice(index, 1);
    this.save()
  }

  public hasExtraCost = (index: number, extraCost: ExtraCost, extraItem: ExtraCostItem) => {
    const item = this.items[index];
    if (!item.extraCosts || !item.extraCosts[extraCost.id]) {
      return false
    }

    const idx = item.extraCosts[extraCost.id]!.items.findIndex(i => i.label_en === extraItem.label_en);
    return idx >= 0;
  }

  public hasExtraService = (index: number, extraService: ExtraService) => {
    const item = this.items[index];
    return typeof item.extraServices[extraService.id] !== 'undefined'
  }

  public toggleExtraCost = (index: number, extraCost: ExtraCost, extraItem: ExtraCostItem) => {
    const item = this.items[index];
    if (!item.extraCosts) {
      item.extraCosts = {} as any
    }
    if (item.extraCosts[extraCost.id]) {
      const items = item.extraCosts[extraCost.id]!.items;
      const idx = items.findIndex(i => i.label_en === extraItem.label_en);
      if (idx >= 0) {
        items.splice(idx, 1)

        if (items.length === 0) {
          item.extraCosts[extraCost.id] = undefined
        }
      } else { 
        item.extraCosts[extraCost.id]!.items.push(extraItem)
      }
    } else {
      item.extraCosts[extraCost.id] = { 
        cost: extraCost, 
        items: [ extraItem ]
      }
    }
    this.save()
  }

  public toggleExtraService = (index: number, extraService: ExtraService) => {
    const item = this.items[index];
    if (item.extraServices[extraService.id]) {
      item.extraServices[extraService.id] = undefined
    } else {
      item.extraServices[extraService.id] = extraService
    }
    this.save()
  }

  get count() {
    return this.items.reduce((total, item) => total += item.count, 0);
  }

  get total() {
    return this.items.reduce((total, item) => {
      total += parseFloat(item.total as any);

      if (item.extraCosts) {
        Object.keys(item.extraCosts).forEach(key => {
          if (item.extraCosts[key]) {
            const sum = item.extraCosts[key]!.items.reduce((accu, item) => accu += item.price, 0);
            total += sum
          }
        });
      }

      if (item.extraServices) {
        Object.keys(item.extraServices).forEach(key => {
          if (item.extraServices[key]) {
            total += parseFloat(item.extraServices[key]?.price ?? 0 as any)
          }
        });
      }
      
      return total
    }, 0)
  }

  get json() {
    return {
      count: this.count,
      items: this.items,
      total: this.total
    }
  }

  private save = () => {
    const cart = JSON.stringify(this.json);
    localStorage.setItem(STORAGE_KEY, cart);
    this.notify(ShoppingCartEvents.UPDATE);
  }

  private restore = () => {
    const cart = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');
    if (cart.items) {
      this.items = cart.items.map((item: any) => ({ count: item.count, item: new Product(item.item), total: item.total, extraServices: item.extraServices || [] }))
    }
    this.notify(ShoppingCartEvents.UPDATE);
  }
}