import { Contact, ContactType } from './contact';
import { deserializeString, Resource } from '@patterns/core';
import { SiemensSignatory } from '../components/contracts/calculation.form';

@Resource('lessees')
export class Lessee extends Contact {
  signatories: SiemensSignatory[]
  emailRecipient: string;
  storeRecipient: string;

  verkkokauppaIdentity: string;
  verkkokauppaSecret: string;

  constructor(data: any = {}) {
    super(data);
    this.type = ContactType.LESSEE;
    this.signatories = data.signatories || [];
    this.storeRecipient = deserializeString(data.storeRecipient);
    this.emailRecipient = deserializeString(data.emailRecipient);
    this.verkkokauppaIdentity = deserializeString(data.verkkokauppaIdentity);
    this.verkkokauppaSecret = deserializeString(data.verkkokauppaSecret);
  }
}
