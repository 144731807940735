import { Icon } from '@blueprintjs/core';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getQueryVariable } from '../common';
import { Breadcrumbs } from '../components/breadcrumb';
import { SimpleLayout } from '../layouts/simple';

export interface Props extends WithTranslation {
}

export class AcceptPage extends React.Component<Props> {
  public render() {
    const { t } = this.props;
    const code = getQueryVariable('code')

    return (
      <SimpleLayout title={t('purchase_invoices.confirmation')}>
        <Breadcrumbs path={`Store / Purchase Invoice Confirmation`} title={t('purchase_invoices.confirmation')}/>
        <div className="content-full d-f f-c ai-c jc-c" style={{ height: 'calc(100vh - 150px)' }}>
          <div style={{ 
            height: 360, 
            width: 360, 
            backgroundColor: 'rgba(0, 0, 0, 0.3)', 
            boxShadow: '0 5px 15px 5px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(0, 0, 0, 0.6)',
            borderRadius: 12,
            padding: 24,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center'
          }}>
            <Icon size={48} icon="tick-circle" color='#0f0' style={{ margin: '0 auto 24px auto' }}/>
            <h1 style={{ color: 'white', marginTop: 0, marginBottom: 24, padding: 0}}>{t('purchase_invoices.one')}<br/>{ code }</h1>
            <p style={{ color: 'white', fontSize: '14px' }}>{t('purchase_invoices.accept_success')}</p>
          </div>
        </div>
      </SimpleLayout>
    );
  }
}

export default withTranslation()(AcceptPage)
