import { Button } from '@blueprintjs/core';
import { FlexRow } from '@patterns/ui';
import { Document, Page } from 'react-pdf';
import * as React from 'react';

export interface Props {
  onClose: () => void
  url: string
  name: string
  actions?: React.ReactElement
}

export interface State {
  page: number
  pages: number
}

export class PDFPreview extends React.Component<Props, State> {
  state = {
    page: 1,
    pages: 1
  }

  private onDocumentLoadSuccess = () => {

  }

  public render() {
    return (
      <div className="pdf-preview">
        <FlexRow style={{height: '100vh', maxHeight: '100vh'}}>
          <div className="f-5 d-f ai-c jc-c" onClick={(evt: any) => { 
            evt.stopPropagation();
            evt.preventDefault();
            this.props.onClose();
            // setShow(false) 
          }}>
            <Button large className="m-r-12" disabled={this.state.page === 1} icon="chevron-left" minimal onClick={(evt: any) => {
              evt.stopPropagation();
              evt.preventDefault();
              this.setState({ page: this.state.page - 1 });
            }}/>
            {/* <object className="pdf-iframe" type="application/pdf" data={`/api/purchase_invoice_attachments/${this.state.attachment.id}?token=${session.token}`}>
              PDF Preview
            </object> */}
            <Document
              file={this.props.url}
              onLoadSuccess={this.onDocumentLoadSuccess}
              renderMode="svg">
              <div className="f-1 ta-c m-b-24">
                Page { this.state.page } / { this.state.pages }
                { this.props.name }<br/>
              </div>
              <Page 
                pageNumber={this.state.page} 
                width={(window.innerWidth * 0.75)}
                height={400}
              />
            </Document>
            <Button large className="m-l-12" disabled={this.state.page === this.state.pages} icon="chevron-right" minimal onClick={(evt: any) => {
              if (this.state.page === this.state.pages) {
                return
              }

              evt.stopPropagation();
              evt.preventDefault();
              this.setState({ page: this.state.page + 1})
            }}/>
          </div>
        </FlexRow>

        <div className="preview-floating-controls">
          { this.props.actions && this.props.actions }
        </div>
      </div>
    );
  }
}
