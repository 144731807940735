import { Button, Checkbox, InputGroup } from '@blueprintjs/core';
import { FlexRow, Notifier } from '@patterns/ui';
import * as React from 'react';
import { PurchaseInvoice } from '../../models/purchase_invoice';
import { PurchaseInvoiceRepository } from '../../repository';
import SiemensManagerInstance, { SiemensAsset, SiemensAssetSubType, SiemensAssetType } from '../../siemens.manager';
import produce from 'immer';
import { priceFormat } from '../../common';
import DataStore from '../../data_store';
import CreateProposalDialog from '../contracts/create_proposal.dialog';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SiemensProposal } from '../contracts/calculation.form';
import ProposalDialog from '../contracts/proposal.dialog';
import SiemensSubTypeSuggest from '../siemens_subtype_suggest';
import SiemensTypeSuggest from '../siemens_type_suggest';
import { formatDate } from '@patterns/core';

// we need to get unresolved purchase invoices and their assets
// order in types => subtypes => purchase invoice => asset rows
//

// const TypeSuggest = Suggest.ofType<SiemensAssetType>();
// const SubTypeSuggest = Suggest.ofType<SiemensAssetSubType>()

type InvoiceAssetsMap = { [id:string]: SiemensAsset[] }
type SubTypeInvoicesMap = { [id:string]: InvoiceAssetsMap }
type DataMap = { [id:string]: SubTypeInvoicesMap }
type ActiveMap = { [id: string]: boolean }

export interface Props extends WithTranslation {
  isOpen: boolean
  onClose: () => void
  onSubmit: (assets: SiemensAsset[], residualValue: number) => void
  residualValue: number
}

export interface State {
  activeMap: ActiveMap
  dataMap: DataMap
  assets: SiemensAsset[]
  invoices: PurchaseInvoice[]
  residualValue: number
  type_id: number;
  typeQuery: string;
  subtype_id: number
  subTypeQuery: string;
  invoiceQuery: string;
  showCreateDialog: boolean;
  showDialog: boolean;
  selected: SiemensProposal;
}

export class Pool extends React.Component<Props, State> {
  state = {
    activeMap: {} as ActiveMap,
    assets: [] as SiemensAsset[],
    dataMap: {},
    invoices: [],
    residualValue: 0,
    type_id: 0,
    typeQuery: '',
    subtype_id: 0,
    subTypeQuery: '',
    invoiceQuery: '',
    showCreateDialog: false,
    showDialog: false,
    selected: {} as SiemensProposal
  } as State

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.fetch();
      
      this.setState({
        activeMap: {} as ActiveMap,
      });
      
      if (this.props.residualValue !== prevProps.residualValue || this.props.residualValue !== this.state.residualValue) {
        this.setState({
          residualValue: this.props.residualValue || 0
        })
      }
    }
  }
  
  private fetch = async () => {
    const { items } = await PurchaseInvoiceRepository.index(1, 500, 'code', 'ASC');
    const assets = [] as SiemensAsset[];
    const dataMap = {} as DataMap;
    const assetFilter = this.state.residualValue ? 
      (a: SiemensAsset) => (typeof a.proposalId === 'undefined' || a.proposalId === 0) && this.getResidualValue(a) === this.state.residualValue : 
      (a: SiemensAsset) =>  typeof a.proposalId === 'undefined' || a.proposalId === 0;

    const accepted = items.filter(i => i.isAccepted);
    const rejected = items.filter(i => i.isRejected);
    const pending = items.filter(i => !i.isAccepted  && !i.isRejected);

    const process = (invoices: PurchaseInvoice[]) => {
      invoices.forEach(invoice => {
        console.log('processing invoice', invoice.id, 'assets', invoice.assets.filter(assetFilter));
        invoice.assets.filter(assetFilter).forEach((asset, index) => {
          if (!dataMap[asset.assetTypeId]) {
            dataMap[asset.assetTypeId]= {} as SubTypeInvoicesMap
          }

          if (!dataMap[asset.assetTypeId][asset.assetSubTypeId]) {
            dataMap[asset.assetTypeId][asset.assetSubTypeId] = {} as InvoiceAssetsMap
          }

          if (!dataMap[asset.assetTypeId][asset.assetSubTypeId][invoice.code]) {
            dataMap[asset.assetTypeId][asset.assetSubTypeId][invoice.code] = [] as SiemensAsset[]
          }


          dataMap[asset.assetTypeId][asset.assetSubTypeId][invoice.code].push(asset);
          
          asset.note = asset.location;

          asset.invoiceId = invoice.id;
          asset.invoiceCode = invoice.code;
          asset.invoiceStatus = 'pending';
          
          if (invoice.isRejected) {
            asset.invoiceStatus = 'rejected'
          }

          if (invoice.isAccepted) {
            asset.invoiceStatus = 'accepted'
          }

          asset.id = index;
          console.log('adding asset', asset);
          assets.push(asset)
        })
      })
    }

    process(accepted);
    process(rejected);
    process(pending);

    this.setState({ assets, invoices: items, dataMap })
  }

  private toggle = (asset: SiemensAsset, residualValue: number = 0) => {
    const id = `${asset.invoiceId}-${asset.id}`;
    const activeMap = produce(this.state.activeMap, map => {
      if (typeof map[id] === 'undefined') {
        map[id] = false
      }
      map[id] = !map[id];
    });

    let active = false;
    // in case all selects are inactive, disable residual value filter
    Object.keys(activeMap).forEach(key => {
      if (activeMap[key]) {
        active = true
      }
    });

    const residual = active ? residualValue : (this.state.assets.length === 0 ? 0 : this.props.residualValue);
    this.setState({ activeMap, residualValue: residual })
  }

  private onTypeChange = (type: SiemensAssetType) => {
    this.setState({ type_id: type.id })
  }

  private onSubTypeChange = (subtype: SiemensAssetSubType) => {
    this.setState({ subtype_id: subtype.id })
  }

  private onInvoiceQueryChange =(evt: React.ChangeEvent<HTMLInputElement>) => this.setState({
    invoiceQuery: evt.currentTarget.value
  })

  private getInvoiceFilter = () => {
    return this.state.invoiceQuery.length === 0 ?
      (asset: SiemensAsset) => asset :
      (asset: SiemensAsset) => (asset.invoiceCode || '').toLowerCase().indexOf(this.state.invoiceQuery.toLowerCase()) >= 0;
  }
  
  private getResidualFilter = () =>  {
    return this.state.assets.length === 0 ? (asset: SiemensAsset) => asset : (this.state.residualValue === 0 ?
      (asset: SiemensAsset) => asset :
      (asset: SiemensAsset) => DataStore.categoryBySubtype(asset.assetSubTypeId)?.residualValue === this.state.residualValue);
  }

  private getTypeFilter = () => {
    return this.state.type_id === 0 ? 
      (asset: SiemensAsset) => asset :
      (asset: SiemensAsset) => asset.assetTypeId === this.state.type_id
  }

  private getTSubypeFilter = () => {
    return this.state.subtype_id === 0 ?
      (asset: SiemensAsset) => asset :
      (asset: SiemensAsset) => asset.assetSubTypeId === this.state.subtype_id
  }

  private getFilteredAssets = () => this.state.assets
      .filter(this.getResidualFilter())
      .filter(this.getInvoiceFilter())
      .filter(this.getTypeFilter())
      .filter(this.getTSubypeFilter());

  private getSelectedAssets = () => this.state.assets.filter(asset => this.state.activeMap[`${asset.invoiceId}-${asset.id}`] === true)
  
  private submit = () => this.props.onSubmit(this.getSelectedAssets(), this.state.residualValue)

  private hasActiveAssets = () => {
    let active = 0;
    Object.keys(this.state.activeMap).forEach(key => {
      if (this.state.activeMap[key]) {
        active += 1;
      }
    });
    return active > 0;
  }

  private close = (reload?: boolean) => this.setState({ showDialog: false }, () => {
    if (reload) {
      this.fetch()
    }
  })

  private copyItems = () => {
    const keys = Object.keys(this.state.activeMap);
    const assets = this.state.assets.filter(a => keys.includes(`${a.invoiceId}-${a.id}`));
    assets.forEach(asset => {
      asset.valuePerAsset = asset.totalValue; 
    })
    localStorage.setItem('purchase-assets-selection', JSON.stringify(assets));
    Notifier.success('Items were copied');
  }

  private createProposal = () => {
    this.copyItems();
    this.setState({ showCreateDialog: true })
  }
  
  private onProposalDialogClose = (proposal?: SiemensProposal) => {
    // this.setState({ showProposalDialog: false });
    if (proposal) {
      this.setState({ showCreateDialog: false, showDialog: true, selected: proposal })
    } else {
      this.setState({ showCreateDialog: false })
    }
  }

  private getResidualValue = (asset: SiemensAsset): number => {
    return DataStore.categoryBySubtype(asset.assetSubTypeId)?.residualValue || 0
  }

  private renderItems = () => {
    return this.getFilteredAssets().map((asset, index) => {
      const id = `${asset.invoiceId}-${asset.id}`;
      const residualValue = this.getResidualValue(asset);

      let className = '';
      if (asset.invoiceStatus === 'accepted') {
        className = 'accepted';
      }
      if (asset.invoiceStatus === 'rejected') {
        className = 'rejected';
      }

      return <FlexRow key={`purchase-pool-asset-${index}`} className={`jc-c ai-c d-f row-${className}`}>
        <div className="d-f report-table-cell w-50 p-l-12">
          { asset.invoiceStatus === 'accepted' && <Checkbox
            checked={this.state.activeMap[id] || false}
            onChange={() => this.toggle(asset, residualValue)}
          /> }
        </div>
        <div className="d-f report-table-cell f-1 p-l-12">
          { asset.invoiceCode }
        </div>
        <div className="d-f report-table-cell f-2 p-l-12">
          { SiemensManagerInstance.getType(asset.assetTypeId)?.name }
        </div>
        <div className="d-f report-table-cell f-2 p-l-12">
          { SiemensManagerInstance.getSubType(asset.assetSubTypeId)?.name }
        </div>
        <div className="d-f report-table-cell f-2 p-l-12">{ asset.description }</div>
        <div className="d-f report-table-cell f-2 p-l-12">{ asset.note }</div>
        <div className="d-f report-table-cell f-2 p-l-12">{ asset.serialNumber }</div>
        <div className="d-f report-table-cell f-2 p-l-12">{ asset.user }</div>
        <div className="d-f report-table-cell f-2 p-l-12">{ asset.costCenter }</div>
        <div className="d-f report-table-cell f-2">{ formatDate(asset.deliveryDate) }</div>
        {/* <div className="d-f report-table-cell f-1 ta-r jc-e">{ asset.quantity }</div> */}
        <div className="d-f report-table-cell f-1 ta-r jc-e">{ priceFormat(asset.totalValue) }</div>
        {/* <div className="d-f report-table-cell f-1 ta-r jc-e">{ priceFormat(asset.totalValue) }</div> */}
        <div className="d-f report-table-cell w-110 ta-r jc-e p-r-12">{ residualValue } %</div>
      </FlexRow>
    })
  }

  public render() {
    const { t } = this.props;
    return (
    <div className="">
      <div>
          <div className="table patterns-data-table">
            <FlexRow className="header">
              <div className="d-f f-1 report-table-cell header" style={{ paddingLeft: 0, marginLeft: 0}}>
                <InputGroup placeholder={t('search')} value={this.state.invoiceQuery} onChange={this.onInvoiceQueryChange} style={{ paddingLeft: 0, marginLeft: 0, width: 110 }}/>
              </div>
              <div className="d-f f-2 report-table-cell header p-l-12">
                <SiemensTypeSuggest
                  activeItem={SiemensManagerInstance.getType(this.state.type_id) || { id: 0, name: '' } as SiemensAssetType}
                  onSelect={this.onTypeChange}
                  disabled={false}
                />
                <Button minimal disabled={this.state.type_id === 0} icon="cross" onClick={() => this.setState({ type_id: 0 })}/>
              </div>
              <div className="d-f f-2 report-table-cell header p-l-12">
                <SiemensSubTypeSuggest 
                  activeItem={SiemensManagerInstance.getSubType(this.state.subtype_id) || {} as SiemensAssetSubType}
                  onSelect={this.onSubTypeChange}
                  disabled={false}
                />
                <Button minimal disabled={this.state.subtype_id === 0} icon="cross" onClick={() => this.setState({ subtype_id: 0 })}/>
              </div>
              <div className="d-f f-2 report-table-cell header p-l-12" style={{ paddingLeft: 0, marginLeft: 0}}>
                <InputGroup placeholder={t('search')} style={{ paddingLeft: 0, marginLeft: 0}}/>
              </div>
              <div className="d-f f-2 report-table-cell header ta-r jc-e"></div>
              <div className="d-f f-2 report-table-cell header ta-r jc-e"></div>
              <div className="d-f f-2 report-table-cell header ta-r jc-e"></div>
              <div className="d-f f-1 report-table-cell header ta-r jc-e"></div>
              <div className="d-f f-1 report-table-cell header ta-r jc-e"></div>
              <div className="d-f f-1 report-table-cell header ta-r jc-e"></div>
              <div className="d-f f-1 report-table-cell header ta-r jc-e"></div>
            </FlexRow>
            <FlexRow className="header">
              <div className="d-f w-50 report-table-cell header"></div>
              <div className="d-f f-1 report-table-cell header p-l-12">{t('invoice')}</div>
              <div className="d-f f-2 report-table-cell header p-l-12">{t('type')}</div>
              <div className="d-f f-2 report-table-cell header p-l-12">{t('subtype')}</div>
              <div className="d-f f-2 report-table-cell header p-l-12">{t('assets.make_and_model')}</div>
              <div className="d-f f-2 report-table-cell header p-l-12">{t('assets.additional_details')}</div>
              <div className="d-f f-2 report-table-cell header p-l-12">{t('assets.serial_number')}</div>
              <div className="d-f f-2 report-table-cell header p-l-12">{t('user')}</div>
              <div className="d-f f-2 report-table-cell header p-l-12">{t('cost_center')}</div>
              {/* <div className="d-f f-1 report-table-cell header ta-r jc-e">Quantity</div> */}
              <div className="d-f f-2 report-table-cell header">{t('delivery_date')}</div>
              <div className="d-f f-1 report-table-cell header ta-r jc-e">{t('patterns.value')}</div>
              {/* <div className="d-f f-1 report-table-cell header ta-r jc-e">Total</div> */}
              <div className="d-f w-110 report-table-cell header ta-r jc-e">{t('residual_value')}</div>
            </FlexRow>
            { this.renderItems() }
            { this.hasActiveAssets() && <FlexRow className="m-t-24">
              <Button intent="primary" text={t('copy_items')}  onClick={this.copyItems}/>
              <Button className="m-l-24" intent="success" text={t('copy_and_create_proposal')} onClick={this.createProposal}/>
            </FlexRow> }
            {/* 
            <FlexRow className="m-t-24">
              Residual Value Filter: <span className="bold m-l-12">{ this.state.residualValue.toFixed(2) } %</span>
            </FlexRow> */}
          </div>
        </div>

        <CreateProposalDialog 
          isOpen={this.state.showCreateDialog}
          onClose={this.onProposalDialogClose}
        />

        <ProposalDialog 
          isOpen={this.state.showDialog}
          proposal={this.state.selected}
          onClose={this.close}
        />
        {/* <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Close" onClick={this.close}/>
            <Button intent="primary" text="Add Assets" onClick={this.submit}/>
          </div>
        </div> */}
      </div>
    );
  }
}

export default withTranslation()(Pool);