import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PiecePieWidget } from '../piece_pie_widget';

export interface Props extends WithTranslation {
  height: number
  width: number
  legend?: boolean
  params?: string
  onPointClick?: (item: any) => void
}

export function AssetCostCenterChart({ height, legend, width, params, onPointClick, t }: Props) {
  return (
    <PiecePieWidget 
      title={t('charts.assets_per_cost_center')}
      url={`/assets/cost_centers_chart?${params}`}
      legend={legend}
      width={width}
      height={height}
      onPointClick={onPointClick}
    />
  );
}

export default withTranslation()(AssetCostCenterChart)