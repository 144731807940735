import { Button, FormGroup, Icon, MenuItem, NonIdealState } from '@blueprintjs/core';
import { capitalize, formatDate, formatPrice } from '@patterns/core';
import { Form, FormField, FormFieldType } from '@patterns/form';
import { FlexColumn, FlexRow } from '@patterns/ui';
import * as React from 'react';
import { Asset, ExtraCostItemMap } from '..//models/asset';
import { CustomFormField } from '../models/custom_form_field';
import { ExtraCost, ExtraCostItem } from '../models/extra_cost';
import { AssetRepository, DeviceRepository } from '../repository';
import { GaugeWidget } from '../components/gauge.widget';
import { MapView } from '../components/map';
import { MainLayout } from '../layouts/main';
import { Breadcrumbs } from '../components/breadcrumb';
import { RouteComponentProps } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { Device } from '../models/device';
import { bytesToGB } from '../common';
import { KeyValueView } from '../components/key_value_view';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';

const AssetForm = Form.ofType<Asset>();

export interface Props extends WithTranslation, RouteComponentProps {
  id: string
}

export interface State {
  asset: Asset
  device: Device
  extraCostItemMap: ExtraCostItemMap
  isLoading: boolean
}

export class AssetPage extends React.Component<Props, State> {
  state = {
    asset: new Asset(),
    device: new Device(),
    extraCostItemMap: {},
    isLoading: true
  }

  componentDidMount() {
    this.fetch()
  }
  
  private getBasicInfoFields = () => {
    const { t, i18n: { language } } = this.props;
    return [
      {
        id: 'name',
        type: FormFieldType.Custom,
        label: t('name'),
        hint: "Eg Laserjet CP1025nw",
        customElement: (item, onChange) => <span className="fake-field">{ item.name }</span>
      }, {
        id: 'category',
        type: FormFieldType.Custom,
        label: t('category'),
        customElement: (item, onChange) => <span className="fake-field">{ language === 'fi' ? item.category.name_fi : item.category.name_en }</span>
      }, {
        id: 'office',
        type: FormFieldType.Custom,
        label: t('office'),
        customElement: (item, onChange) => <span className="fake-field">{ item.office.name }</span>
      }, {
        id: 'lifecycle',
        type: FormFieldType.Custom,
        label: t('assets.lifecycle'),
        customElement: (item, onChange) => <span className="fake-field">{ item.lifecycle }</span>
      }, {
        id: 'warrantyEnd',
        type: FormFieldType.Custom,
        label: t('assets.warranty_end'),
        customElement: (item, onChange) => <span className="fake-field">{ formatDate(item.warrantyEnd) }</span>
      }, {
        id: 'employee',
        type: FormFieldType.Custom,
        label: t('employee'),
        customElement: (item, onChange) => <span className="fake-field">{ item.employee.name }</span>
      }, {
        id: 'note',
        type: FormFieldType.Custom,
        label: t('note'),
        customElement: (item, onChange) => <span className="fake-field">{ item.note }</span>
      }
    ] as FormField<Asset>[]
  }

  private getFinancialFields = () => {
    const { t } = this.props;

    return [
      {
        id: 'Lessee',
        type: FormFieldType.Custom,
        label: t('lessee'),
        customElement: (item, onChange) => <span className="fake-field">{ item.contract?.lessee?.name ?? '' }</span>
      },
      {
        id: 'contract_number',
        type: FormFieldType.Custom,
        label: t('contract_number'),
        customElement: (item, onChange) => <span className="fake-field">{ item.contract?.isOwned ? t('contract_ended') : item.contract?.number}</span>
      },
      {
        id: 'cost_center',
        type: FormFieldType.Custom,
        label: t('cost_center'),
        customElement: (item, onChange) => <span className="fake-field">{ item.costCenter.name }</span>
      },
      {
        id: 'purchase_price',
        type: FormFieldType.Custom,
        label: t('assets.purchase_price'),
        customElement: (item, onChange) => <span className="fake-field">{ formatPrice(item.purchasePrice) }</span>
      },
      {
        id: 'purchase_date',
        type: FormFieldType.Custom,
        label: t('assets.purchase_date'),
        customElement: (item, onChange) => <span className="fake-field">{ formatDate(item.purchaseDate) }</span>
      },
      {
        id: 'start_date',
        type: FormFieldType.Custom,
        label: t('contracts.start_date'),
        customElement: (item, onChange) => <span className="fake-field">{ formatDate(item.contract.startDate) }</span>
      },
      {
        id: 'end_date',
        type: FormFieldType.Custom,
        label: t('contracts.end_date'),
        customElement: (item, onChange) => <span className="fake-field">
          { formatDate(moment(item.contract.startDate).add(item.lifecycle, 'months').toDate()) }
        </span>
      },
      {
        id: 'leasing_company',
        type: FormFieldType.Custom,
        label: t('leasing_company'),
        customElement: (item, onChange) => <span className="fake-field">{ item.contract.leasingCompany.name }</span>
      }
    ] as FormField<Asset>[]
  }

  private fetch = async () => {
    const asset = await AssetRepository.get((this.props.match.params as any).id);
    const device = await DeviceRepository.get(`by_serial_number/${asset.serialNumber}`);
    this.setState({ asset, device, isLoading: false })
  }

  private onChange = (asset: Asset) => {
    this.setState({ asset })
  }

  private renderCategoryField(field: CustomFormField) {
    const valueField = this.state.asset.fields[field.key];
    switch (field.type) {
      case 'text':
        return <span className="fake-field">{valueField}</span>
      case 'textarea':
        return <span className="fake-field">{valueField}</span>
      case 'number':
        return <span className="fake-field">{valueField}</span>
      case 'switch':
        return <span className="fake-field">
          <Icon icon="tick" className="m-r-12"/> {valueField}
        </span>
      case 'checkbox':
        return <span className="fake-field">
          <Icon icon="tick" className="m-r-12"/> {valueField}
        </span>
      case 'date':
        return <span className="fake-field">{formatDate(valueField as string)}</span>
    }
  }

  private renderCategoryFields = () => {
    return this.state.asset.category.fields.map(field => <FormGroup
      key={`category-field-${field.key}`}
      label={this.props.i18n.language === 'fi' ? field.label_fi : field.label_en}
      inline={true}
    >{ this.renderCategoryField(field) }
    </FormGroup>);
  }

  private extraCostItemRenderer = (item: ExtraCostItem, options: any) => <MenuItem
    key={`extra-cost-item-${item.label_en.replace(/ /g, '_')}`}
    text={item.label_en}
    label={formatPrice(item.price)}
    onClick={options.handleClick}
  />


  private renderCategoryExtraCosts = () => {
    const types = {} as { [id:string]: ExtraCost[]};
    const extraCosts = this.state.asset.category.categoryExtraCosts.map(c => c.extraCost);
    extraCosts.forEach(extraCost => {
      if (!types[extraCost.type]) {
        types[extraCost.type] = []
      }
      types[extraCost.type].push(extraCost)
    });

    return Object.keys(types).map((type, index) => {
      return <React.Fragment key={`category-extra-cost-item-${index}`}>
        <FormGroup
          inline={true}
          label={type}
          className="patterns-form-field-group patterns-form-field-group-fill long-label">
        </FormGroup>

        { types[type].map(extraCost => {
          const label = this.state.asset.extraCostItems[extraCost.id] ? (this.props.i18n.language === 'fi' ? this.state.asset.extraCostItems[extraCost.id].label_fi : this.state.asset.extraCostItems[extraCost.id].label_en) : '-';
          return <FormGroup
            key={`category-extra-costs-field-${extraCost.id}`}
            label={extraCost.title}
            inline={true}>
            <span className="fake-field">{ label }</span>
          </FormGroup> 
        })}
      </React.Fragment>
    })
  }

  private back = () => {
    this.props.history.push('/assets')
  }

  public render() {
    const { t } = this.props;
    return <MainLayout title={t('asset')} gradient={true}>
      { this.state.isLoading && <NonIdealState title={t('patterns.please_wait')} description={t('assets.loading_asset') as string}/> }
      { !this.state.isLoading && <React.Fragment>
        <Breadcrumbs path={`${t('assets.title')} / ${this.state.asset.name}`} title={this.state.asset.name || ''}/>
        <Button className="m-l-24" icon="arrow-left" onClick={this.back}>{t('assets.back')}</Button>

        <div style={{ overflowY: 'auto' }}>
          <FlexRow className="m-l-24 m-r-24">
            <div className="f-1 m-r-6">
              <h2 className="white">{t('assets.general_info')}</h2>
              <AssetForm
                className="f-1 asset-form non-bold backdrop-blur no-bg"
                fields={this.getBasicInfoFields()}
                item={this.state.asset}
                onChange={this.onChange}
                inline={true}
                fill={true}
              />
            </div>
            <div className="f-1 m-l-6 m-r-6">
              <h2 className="white">{t('assets.financial_info')}</h2>
              <AssetForm
                className="f-1 asset-form non-bold backdrop-blur no-bg"
                fields={this.getFinancialFields()}
                item={this.state.asset}
                onChange={this.onChange}
                inline={true}
                fill={true}
              />
            </div>
            <div className="f-1 m-l-6 m-r-6">
              <h2 className="white">{t('assets.category_specific_fields')}</h2>
              <FlexColumn className="patterns-form wider asset-form backdrop-blur non-bold no-bg" flex={1}>
                { this.renderCategoryFields() }
                { this.renderCategoryExtraCosts() }
              </FlexColumn>
            </div>
            <FlexColumn className="patterns-form f-1 m-l-6" flex={1}>
              <h2 className="white">{capitalize(t('assets.lifecycle'))}</h2>
              <AutoSizer>{({ height, width }) => (
                <GaugeWidget title={capitalize(t('assets.lifecycle'))} width={width} height={400} value={this.state.asset.age}/>
              )}</AutoSizer>
            </FlexColumn>
          </FlexRow>
          { this.state.device.exists && <div className="m-t-24">
            <FlexRow>
              <FlexColumn className="m-l-24">
                <div>
                  <h2 className="white">System</h2>
                  <div className="asset-form" style={{ minHeight: 180 }}>
                    <KeyValueView 
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.manufacturer')}
                      value={this.state.device.data.system.manufacturer}
                    />
                    <KeyValueView 
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.model')}
                      value={this.state.device.data.system.model}
                    />
                    <KeyValueView 
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.serial_number')}
                      value={this.state.device.data.system.serial}
                    />
                    <KeyValueView 
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.sku')}
                      value={this.state.device.data.system.sku}
                    />
                  </div>
                </div>
              
                <div style={{ minHeight: 200 }}>
                  <h2 className="white" style={{ marginBottom: 25 }}>{t('fleet.graphics')}</h2>
                  <div className="asset-form" style={{ minHeight: 180 }}>
                    <KeyValueView
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.vendor')}
                      value={this.state.device.data.graphics.controllers[0].vendor}
                    />
                    <KeyValueView
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.model')}
                      value={this.state.device.data.graphics.controllers[0].model}
                    />
                  </div>
                </div>

              </FlexColumn>

              <FlexColumn className="m-l-24 m-r-24">
                <div style={{ minHeight: 200 }}>
                  <h2 className="white">CPU</h2>
                  <div className="asset-form" style={{ minHeight: 180 }}>
                    <KeyValueView 
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.manufacturer')}
                      value={this.state.device.data.cpu.manufacturer}
                    />
                    <KeyValueView 
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.brand')}
                      value={this.state.device.data.cpu.brand}
                    />
                    <KeyValueView 
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.cores')}
                      value={this.state.device.data.cpu.cores}
                    />
                    <KeyValueView 
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.physical_cores')}
                      value={this.state.device.data.cpu.physicalCores}
                    />
                    <KeyValueView 
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.processors')}
                      value={this.state.device.data.cpu.processors}
                    />
                  </div>
                </div>

                <div style={{ minHeight: 200 }}>
                  <h2 className="white" style={{ marginBottom: 25 }}>{t('fleet.memory_modules')}</h2>
                  <div className="asset-form" style={{ minHeight: 180 }}>
                    { (this.state.device.data.memLayout || []).map((mem: any, index: number) => <KeyValueView
                        key={`device-mem-${index}`}
                        classKey="f-2"
                        classValue="f-3"
                        label={`${mem.formFactor} ${index}`}
                        value={`${bytesToGB(mem.size)}GB (${mem.manufacturer === 'Undefined' ? 'Unknown' : mem.manufacturer})`}
                      />
                    )}
                  </div>
                </div>
              </FlexColumn>
              <FlexColumn>
                <h2 className="white">${t('fleet.operating_system')}</h2>
                <div style={{ minHeight: 200 }}>
                  {/* <KeyValueView 
                    classKey="f-2 bold"
                    classValue="f-3"
                    label="Operating System"
                    value={<div className="d-f ai-c">
                      { this.state.device.data.os && this.state.device.data.os.platform === 'win32' &&
                        <img src={logoWindows} style={{ height: 24 }} alt="logo"/> }
                      { this.state.device.data.os && this.state.device.data.os.platform === 'darwin' &&
                        <img src={logoOsx} style={{ height: 24 }} alt="logo"/> }
                    </div>}
                    className="header"
                  /> */}
                  <div className="asset-form" style={{ minHeight: 180 }}>
                    <KeyValueView 
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.distribution')}
                      value={this.state.device.data.os.distro}
                    />
                    <KeyValueView 
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.release')}
                      value={this.state.device.data.os.release}
                    />
                    <KeyValueView 
                      classKey="f-2"
                      classValue="f-3"
                      label={t('fleet.serial_number')}
                      value={this.state.device.data.os.serial}
                    />
                  </div>
                </div>

              </FlexColumn>
            </FlexRow>
            
            <h2 className="m-l-24 white">{t('fleet.disk_layout')}</h2>
            <div className="asset-form m-l-24 m-r-24" style={{ minHeight: 180 }}>
              <FlexRow style={{height: 160, marginTop: 12}}>
                { (this.state.device.data.diskLayout || []).map((disk: any, index: number) => <FlexColumn key={`device-disk-${index}`}flex={1} className="ai-c jc-c">
                  <Icon icon="database" iconSize={48}/>
                  <div className="m-t-12">{bytesToGB(disk.size)}GB</div>
                  <div className="m-12">{disk.name} ({disk.type})</div>
                </FlexColumn> )}
              </FlexRow>
            </div>
          </div> }
          { this.state.asset.hasCoordinates() && <div className="m-24" style={{ width: 'calc(100% - 48px)', height: 356 }}>
            <MapView lat={this.state.asset.office.lat} lng={this.state.asset.office.lng}/> 
          </div> } 
        </div>
      </React.Fragment> }
    </MainLayout>;
  }
}

export default withTranslation()(AssetPage)