import * as React from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { MainLayout } from '../layouts/main';

export interface Props {
}

export class Overview extends React.Component<Props> {
  public render() {
    return (
      <MainLayout title="Overview">
        <NonIdealState title="Not Available"/>
      </MainLayout>
    );
  }
}
