import * as React from 'react';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import ReplacementDevicesDialog from '../components/replacement_devices/replacement_devices.dialog';
import { ReplacementDeviceRepository } from '../repository';
import { ReplacementDevice } from '../models/replacement_device';

import { Notifier, Toolbar } from '@patterns/ui';

import { Button } from '@blueprintjs/core';
import { priceFormat } from '../common';
import { withTranslation, WithTranslation } from 'react-i18next';

const ReplacementDevicesTable = DataTable.ofType<ReplacementDevice>();


export interface Props extends WithTranslation {
}

export interface State {
  selected: ReplacementDevice
  showDialog: boolean
}

export class ReplacementDevices extends React.Component<Props, State> {
  cancelToken: any;

  state = {
    selected: new ReplacementDevice({}),
    showDialog: false
  } as State

  table = React.createRef<any>();

  private getColumns = () => {
    const { i18n: { language } } = this.props;
    return [
      {
        id: 'id',
        title: 'ID',
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        alignment: Alignment.Left,
        width: 80,
        visible: false,
        format: device => <span>{ device.id }</span>
      },
      {
        id: 'name',
        title: 'Name',
        type: ColumnType.Text,
        sortable: false,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ device.name }</span>
      },
      {
        id: 'image',
        title: 'Image',
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        alignment: Alignment.Center,
        width: 82,
        visible: true,
        format: device => <img
          className="table-cell-image"
          src={device.image} 
          alt=""
        />
      },
      {
        id: 'sku',
        title: 'SKU',
        type: ColumnType.Text,
        sortable: false,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ device.sku }</span>
      },
      {
        id: 'ean',
        title: 'Ean Code',
        type: ColumnType.Text,
        sortable: false,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ device.ean }</span>
      },
      {
        id: 'price',
        title: 'Price',
        type: ColumnType.Number,
        sortable: false,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ priceFormat(device.price) }</span>
      },
      {
        id: 'category',
        title: 'Category',
        type: ColumnType.Number,
        sortable: false,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: device => <span>{ language === 'fi' ? device.category.name_fi : device.category.name_en }</span>
      },
      {
        id: 'createdAt',
        title: 'Created At',
        type: ColumnType.DateTime,
        sortable: false,
        filterable: true,
        alignment: Alignment.Left,
        width: 130,
        visible: true
      },
      {
        id: 'updatedAt',
        title: 'Updated At',
        type: ColumnType.DateTime,
        sortable: false,
        filterable: false,
        alignment: Alignment.Left,
        width: 130,
        visible: true
      }
    ] as Column<ReplacementDevice>[]
  }
  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await ReplacementDeviceRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken
    );
    this.cancelToken = cancelToken;
    return { items, total }
  }

  private onSelect = (selected: ReplacementDevice) => this.setState({ 
    selected,
    showDialog: true
  })

  private onDelete = async (device: ReplacementDevice) => {
    await ReplacementDeviceRepository.delete(device.id);
    Notifier.success('Replacement Device was successfully deleted');
    this.setState({
      showDialog: false
    })
    this.table.current?.fetch();
  }

  private create = () => this.setState({
    selected: new ReplacementDevice({}),
    showDialog: true
  })

  private onClose = async (changed: boolean) => { 
    this.setState({ showDialog: false }, () => {
      if (changed) {
        this.table.current?.fetch()
      }
    })
  }

  public render() {
    return (
      <div className="m-l-24 m-r-24">
        <Toolbar>
          <div className="f-1 jc-s ai-c d-f m-l-12">
            <Button intent="primary" text={this.props.t('store_settings.add_replacement_device')} icon="plus" onClick={this.create}/>
          </div>
        </Toolbar>
        
        <ReplacementDevicesTable
          id="replacement_devices-table"
          ref={this.table}
          columns={this.getColumns()}
          fetch={this.fetch}
          sort="id"
          sortDir="asc"
          onItemSelect={this.onSelect}
        />
        
        <ReplacementDevicesDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onDelete={this.onDelete}
          replacementDevice={this.state.selected}
        />
      </div>
    );
  }
}

export default withTranslation()(ReplacementDevices)