import * as React from 'react';
import { FlexRow, Notifier, Toolbar } from '@patterns/ui';
import { CategoriesView } from '../categories/categories_table';
import { Category } from '../../models/category';
import { CategoryRepository } from '../../repository';
import CategoryDialog from './category_dialog';
import { CategoriesTree }  from '../categories/categories_tree';
import { Button, NonIdealState, Spinner } from '@blueprintjs/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import { axios } from '../../common';
import { t } from '@patterns/locale';
import DataStore from '../../data_store';

export interface Props extends WithTranslation {}

export interface State {
  showDialog: boolean
  selected: Category
  categoryFilter?: Category
  isLoading: boolean
}

export class Categories extends React.Component<Props, State> {
  table = React.createRef<CategoriesView>();
  tree = React.createRef<CategoriesTree>();

  state = {
    selected: new Category({}),
    showDialog: false,
    isLoading: false
  } as State

  private async reload() {
    await this.table.current?.tableRef.current?.fetch();
    await this.tree.current?.fetch();
  }

  private onClose = () => {
    this.setState({ showDialog: false });
    this.table.current?.tableRef.current?.fetch()
  }

  private onSave = async (category: Category) => {
    await CategoryRepository.save(category);
    await DataStore.updateCategories();
    this.setState({ showDialog: false });
    this.reload();
    Notifier.success(this.props.t('settings.category_save_success'))
  }

  private onSelect = async (selected: Category) => {
    this.setState({ selected, showDialog: true })
  }

  private onDelete = async (category: Category) => {
    await CategoryRepository.delete(category.id);
    await DataStore.updateCategories();
    this.setState({ showDialog: false })
    this.reload()
  }

  private create = async () => {
    this.setState({ selected: new Category({}), showDialog: true })
  }

  private import = () => {
    this.setState({ isLoading: true }, async () => {
      await axios.post('/categories/import_default', {});
      await this.reload();
      this.setState({ isLoading: false })
    })
  }

  private onNodeSelect = async (category?: Category) => { 
    this.setState({ categoryFilter: category }, () => {
      this.table.current?.tableRef.current?.fetch();
    });
  }

  public render() {
    return <FlexRow flex={1}>
      <div className="m-l-24 m-r-24 f-1">
        <Toolbar>
          <div className="f-1 jc-s ai-c d-f m-l-12">
            <Button intent="primary" text={this.props.t('settings.add_category')} icon="plus" onClick={this.create}/>
            <Button intent="primary" text={this.props.t('settings.import_categories')} icon="plus" onClick={this.import} className="m-l-12"/>
          </div>
        </Toolbar>
        
        { this.state.isLoading && <NonIdealState title={t('patterns.please_wait')}>
          <Spinner />
        </NonIdealState> }
        
        { !this.state.isLoading && <CategoriesView 
          ref={this.table} 
          category={this.state.categoryFilter}
          onSelect={this.onSelect}
          {...this.props}
        /> }
      </div>
      {/* <div className="f-1 content-full d-f">
        <CategoriesTreeTranslated 
          ref={this.tree}
          title="Category Tree Preview" 
          showNewButton={true}
          onCreate={this.create} 
          onSelect={this.onNodeSelect} 
        />
      </div> */}

      <CategoryDialog
        isOpen={this.state.showDialog}
        onClose={this.onClose}
        onSave={this.onSave}
        onDelete={this.onDelete}
        category={this.state.selected}
        {...this.props}
      />

      {/* <CreateButton
        onClick={this.create}
      /> */}
    </FlexRow>
  }
}

export default withTranslation()(Categories)