import * as React from 'react';
import { Button, Checkbox, Tag, Switch } from '@blueprintjs/core';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import { User } from '../../models/user';
import UserDialog from './user_dialog';
import { UserRepository } from '../../repository';
import { AppContext } from '../../app_context';
import { Notifier, Toolbar } from '@patterns/ui';
import { withTranslation, WithTranslation } from 'react-i18next';
import { session } from '../../session';

const UsersTable = DataTable.ofType<User>();

export interface Props extends WithTranslation {
}

export interface State {
  activeOnly: boolean
  selected: User
  showDialog: boolean
}

export class UsersView extends React.Component<Props, State> {
  static contextType = AppContext;

  cancelToken: any;
  
  state = {
    activeOnly: true,
    selected: new User({}),
    showDialog: false
  } as State

  tableRef = React.createRef<any>();

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'name',
        title: t('name'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: user => <span>{ user.name }</span>
      },
      {
        id: 'email',
        title: t('email'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true
      },
      {
        id: 'role',
        title: t('role'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: ({ role }) => {
          let intent: "none" | "danger" | "success" = "none"
          if (role === 'superuser') intent= "success";
          if (role === 'admin') intent = "danger";
          return <Tag intent={intent}>{role}</Tag>
        }
      },
      {
        id: 'is_active',
        title: t('is_active'),
        type: ColumnType.Custom,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        width: 110,
        visible: true,
        format: user => <Checkbox checked={user.is_active}/>
      },
      {
        id: 'createdAt',
        title: t('created_at'),
        type: ColumnType.DateTime,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        width: 140,
        visible: true
      },
      {
        id: 'updatedAt',
        title: t('updated_at'),
        type: ColumnType.DateTime,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        width: 140,
        visible: true
      }
    ] as Column<User>[]
  }
  
  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await UserRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken
    );

    this.cancelToken = cancelToken;

    let filter = (i: User) => i.id === session.user.id;

    if (session.user.isAdmin || session.user.isSuperuser) {
      filter = (i: User) => true
    }

    const currentOrg = session.user.organization.id;
    const activeFilter = this.state.activeOnly ? (item: User) => (item as any).organizations.includes(currentOrg) : (item: User) => item;
    const users = items.filter(filter).filter(activeFilter);
    return { items: users, total }
  }

  private onSelect = (selected: User) => this.setState({ 
    selected,
    showDialog: true 
  })

  private onSave = async (user: User) => {
    if (!user.password || user.password.length === 0) {
      delete user.password
    }

    user.organization = this.context.session.user.organization;
    await UserRepository.save(user);

    this.setState({
      showDialog: false
    });

    this.tableRef.current?.fetch();
    Notifier.success(this.props.t('settings.user_save_success'))
  }

  private create = () => this.setState({
    selected: new User({}),
    showDialog: true
  })

  private onClose = () => this.setState({ showDialog: false })

  public render() {
    return (
      <div className="m-l-24 m-r-24">
        <Toolbar>
          <div className="f-1 jc-s ai-c d-f m-l-12">
            <Button intent="primary" text={this.props.t('settings.add_user')} icon="plus" onClick={this.create}/>
            <Switch
              className='m-l-24 remove-bottom-margin'
              label={this.props.t('active_only')}
              checked={this.state.activeOnly}
              value={this.state.activeOnly.toString()}
              onChange={evt => this.setState({ activeOnly: evt.currentTarget.checked }, () => this.tableRef.current?.fetch())}
            />
          </div>
        </Toolbar>
        <UsersTable
          id="users-table"
          ref={this.tableRef}
          columns={this.getColumns()}
          fetch={this.fetch}
          sort="name"
          sortDir="asc"
          onItemSelect={this.onSelect}
        />

        <UserDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onSave={this.onSave}
          user={this.state.selected}
        />
      </div>
    );
  }
}

export default withTranslation()(UsersView)