import * as React from 'react';
import { Dialog, Classes, Button, FormGroup, NumericInput, MenuItem } from '@blueprintjs/core';
import { SiemensPricelist } from '../../models/siemens_pricelist';
import { deepEqual } from '@patterns/core';
import { SiemensPricelistRepository } from '../../repository';
import produce from 'immer';
import { SaveButton, ConfirmButton, FlexRow, FlexColumn } from '@patterns/ui';
import { AppContext } from '../../app_context';
import { axios } from '../../session';
import SiemensManagerInstance, { SiemensAssetSubType, SiemensAssetType } from '../../siemens.manager';
import SiemensTypeSuggest from '../siemens_type_suggest';
import SiemensSubTypeSuggest from '../siemens_subtype_suggest';

export interface Props {
  isOpen: boolean
  onClose: (changed: boolean) => void
  onDelete: (device: SiemensPricelist) => void
  siemensPricelist: SiemensPricelist
}

export interface State {
  name: string
  type_id: number
  subtype_id: number
  residualValue: number
  types: SiemensAssetType[];
  subTypes: { [id:string] : SiemensAssetSubType[] };
}

export class SiemensPricelistsDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props)
    this.state = {
      name: props.siemensPricelist.name,
      type_id: props.siemensPricelist.type_id,
      subtype_id: props.siemensPricelist.subtype_id,
      residualValue: props.siemensPricelist.residualValue,
      types: [],
      subTypes: {}
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.siemensPricelist, prevProps.siemensPricelist)) {
      const { name, subtype_id, type_id, residualValue } = this.props.siemensPricelist;
      this.setState({ name, subtype_id, type_id, residualValue })
    }
  }

  private delete = async () => this.props.onDelete(this.props.siemensPricelist)

  private fetch = async () => {
    const response = await axios.get('/siemens/asset-config');
    this.setState({
      types: response.data.assetTypes,
      subTypes: response.data.assetSubTypes,
    })
  }

  private onClose = () => this.props.onClose(false)

  private save = async () => {
    const siemensPricelist = produce(this.props.siemensPricelist, siemensPricelist => {
      siemensPricelist.name = this.state.name;
      siemensPricelist.type_id = this.state.type_id;
      siemensPricelist.subtype_id = this.state.subtype_id;
      siemensPricelist.residualValue = this.state.residualValue;
    });
    await SiemensPricelistRepository.save(siemensPricelist);
    this.props.onClose(true)
  }

  private onResidualValueChange = (value: number, valueString: string) => this.setState({  residualValue: value })

  private onNameChange = (name: string) => this.setState({ name })

  private onTypeChange = (type: SiemensAssetType) => this.setState({ type_id: type.id })

  private onSubTypeChange = (subType: SiemensAssetSubType) => this.setState({ subtype_id: subType.id })

  private typeRenderer = (type: SiemensAssetType | SiemensAssetSubType, options: any) => {
    return <MenuItem
      key={`type-${type.id}-item`}
      text={type.name}
      onClick={options.handleClick}
    />
  }

  public render() {
    const type = SiemensManagerInstance.getType(this.state.type_id) || { id: 0, name: 'Select Type' };
    let subType = { id: 0, name: 'Select SubType', assetTypeId: 0 };

    if (type.id !== 0) {
      const existingSub = SiemensManagerInstance.getSubtypes(type.id).find(t => t.id === this.state.subtype_id);
      if (existingSub) { 
        subType = existingSub;
      }
    }

    return (
      <Dialog canOutsideClickClose={false} title="Siemens Pricelist" isOpen={this.props.isOpen} onClose={this.onClose} className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <FlexColumn>
            <FormGroup inline={true} label="Type">
              <SiemensTypeSuggest
                disabled={false}
                activeItem={type} 
                onSelect={this.onTypeChange}/>
            </FormGroup>
            <FormGroup inline={true} label="Subtype">
              <SiemensSubTypeSuggest
                disabled={false}
                activeItem={subType} 
                onSelect={this.onSubTypeChange}/>
            </FormGroup>
            <FormGroup inline={true} label="Residual Value %">
              <NumericInput 
                buttonPosition="none"
                fill={true}
                style={{ textAlign: 'right' }}
                value={parseFloat(this.state.residualValue.toString()).toFixed(2)}
                onValueChange={this.onResidualValueChange}
              />
            </FormGroup>
          </FlexColumn>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <FlexRow flex={1}>
              <ConfirmButton
                title="Delete"
                confirmTitle="Confirm Delete"
                onConfirm={this.delete}
              />
            </FlexRow>
            <Button text="Cancel" onClick={this.onClose}/>
            <SaveButton onClick={this.save}/>
          </div>
        </div>
      </Dialog>
    );
  }
}
