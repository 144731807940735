import { NonIdealState } from '@blueprintjs/core';
import * as React from 'react';
import { MainLayout } from '../../layouts/main';
import DustinService from '../../dustin.service';

export interface Props {
}

export interface State {
  url: string
}

export class Dustin extends React.Component<Props, State> {
  state = {
    url: ''
  } as State

  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const url = await DustinService.login();
    window.location.href = url;
  }

  public render() {
    return (
      <MainLayout title="Dustin">
        <NonIdealState title="Please wait" description="You will be redirected to Dustin.fi site"/>
      </MainLayout>
    );
  }
}
