import { Button, FormGroup, NonIdealState } from '@blueprintjs/core';
import { download, formatDate } from '@patterns/core';
import { FlexRow, Toolbar } from '@patterns/ui';
import moment from 'moment';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppContext } from '../app_context';
import { axios, monthDiff, priceFormat } from '../common';
import { MainLayout } from '../layouts/main';
import { Breadcrumbs } from './breadcrumb';
import logo from '../assets/jitsystems.png';
import { Lessee } from '../models/lessee';
import { session } from '../session';
import MonthSelect from './month_select';

export interface Props extends WithTranslation {

}

export interface State {
  isLoading: boolean;
  items: any[];
  lessee: Lessee;
  date: Date;
}

export class LeaseLiabilities extends React.Component<Props, State> {
  static contextType = AppContext;

  state = {
    date: moment().endOf('month').toDate(),
    isLoading: false,
    lessee: new Lessee({}),
    items: [] as any[],
  } as State


  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const state = { 
      items: [],
      lessee: new Lessee({})
    } as { lessee: Lessee, items: any };

    try {
      const { data: items } = await axios.get('/contracts/liabilities');
      state.items = items;
    } catch (e) {
      console.error('unable to load liabilities', e);
    }

    try {
      const { data: lessee } = await axios.get('/lessees/current');
      state.lessee = lessee;
    } catch (e) {
      console.error('unable to load liabilities', e);
    }

    this.setState(state)
  }

  private print = () => {
    const { language } = this.props.i18n;
    const params = [
      `lang=${language}`,
      `lessee_id=${session.lesseeId}`,
      `date=${this.state.date.toISOString()}`
    ].join('&');

    const url = `/api/contracts/liabilities.pdf?${params}`;
    download(url, session.token)
  }

  private renderHeader = () => {
    const { t } = this.props;
    return <FlexRow className="m-b-12">
      <div className="w-100 header bold">{t('contract')}</div>
      <div className="f-1 header bold">{t('contracts.end_date')}</div>
      <div className="f-2 header bold">{t('contracts.within_one_year')}</div>
      <div className="f-2 header bold">{t('contracts.one_to_five_years')}</div>
      <div className="f-2 header bold">{t('contracts.over_five_years')}</div>
      <div className="f-2 header bold">{t('total')}</div>
    </FlexRow>
  }

  private renderItems = () => {
    const { date } = this.state;

    return this.state.items
      .filter(i => new Date(i.validTill) > date)
      .filter(i => new Date(i.startDate) < date)
      .map((i, itemIdx) => {
        const value = parseFloat(i.monthlySUM);
        const monthsDiff = monthDiff(moment(date).startOf('month').add(1,'month').toDate(), moment(i.validTill).toDate())  + 1;
        const total = monthsDiff * value;
        let within_one_year = 0;
        let one_to_five = 0;
        let over_five = 0;
        if (monthsDiff <= 12) {
          within_one_year = monthsDiff * value;
        } else if (monthsDiff > 60) {
          within_one_year = 12 * value;
          one_to_five = 48 * value;
          over_five = (monthsDiff - 60) * value;
        } else {
          within_one_year = 12 * value;
          one_to_five = (monthsDiff - 12) * value;
        }
        
        console.log('number', i.number, 'total', total, 'monthsDiff', monthsDiff, 'date', date);
        return <FlexRow key={`report-item-${itemIdx}`}>
          <div className="w-100">{i.number}</div>
          <div className="f-1">{formatDate(moment(i.validTill).toDate())}</div>
          <div className="f-2">{priceFormat(within_one_year)}</div>
          <div className="f-2">{priceFormat(one_to_five)}</div>
          <div className="f-2">{priceFormat(over_five)}</div>
          <div className="f-2 bold">{priceFormat(total)}</div>
        </FlexRow>
    });
  }

  private renderTotal = () => {
    const { date } = this.state;

    let within_one_year = 0;
    let one_to_five = 0;
    let over_five = 0;
    let total = 0;

    this.state.items
      .filter(i => new Date(i.validTill) > moment().startOf('month').add(1, 'month').toDate())
      .filter(i => new Date(i.startDate) < date)
      .forEach((i, itemIdx) => {
        const value = isNaN(parseFloat(i.monthlySUM)) ? 0 : parseFloat(i.monthlySUM);
        const monthsDiff = monthDiff(moment(date).startOf('month').add(1,'month').toDate(), moment(i.validTill).toDate())  + 1;
        
        total += monthsDiff * value;

        if (monthsDiff <= 12) {
          within_one_year += monthsDiff * value;
        } else if (monthsDiff > 60) {
          within_one_year += 12 * value;
          one_to_five += 48 * value;
          over_five += (monthsDiff - 60) * value;
        } else {
          within_one_year += 12 * value;
          one_to_five += (monthsDiff - 12) * value;
        }
      });

    return <FlexRow key={`report-total`} className="m-t-12">
      <div className="w-100"></div>
      <div className="f-1 bold">{this.props.t('total')}</div>
      <div className="f-2">{priceFormat(within_one_year)}</div>
      <div className="f-2">{priceFormat(one_to_five)}</div>
      <div className="f-2">{priceFormat(over_five)}</div>
      <div className="f-2">{priceFormat(total)}</div>
    </FlexRow>
  }

  public render() {
    const { t } = this.props;
    return (
      <MainLayout title="Lease Liabilities">
        <Breadcrumbs path={`Contracts / Liabilities`} title={t('contracts.liabilities')}/>

        <div className="content-full">
          { this.state.isLoading && <NonIdealState title={t('patterns.please_wait')} description="Please Wait"/> }
          { !this.state.isLoading && <div className="m-24 verkkokauppa-order-confirmation">
            <Toolbar>
              <FormGroup inline={true} label={t('date')}>
                <MonthSelect isEndDate date={this.state.date} onSelect={(date) => this.setState({ 
                  date: moment(date).endOf('month').subtract(3, 'hours').toDate() 
                })} />

                {/* <DateInput 
                  formatDate={date => capitalize(moment(date).format('DD.MM.YYYY'))}
                  parseDate={parseDate}
                  value={this.state.date}
                  locale="fi"
                  onChange={date => this.setState({ date })}
                  className="white bp3-dark"
                /> */}
              </FormGroup>
              <Button intent='primary' text={t('print')} onClick={this.print} className="m-l-24"/>
            </Toolbar>
            <FlexRow className='jc-e m-t-24 m-b-24'>
              <div className='f-2 d-f f-c ai-e' style={{ marginRight: 270 }}>
                <div className='d-f f-r jc-e f-1 m-b-12'>
                  <img className="m-t-24" src={logo} alt="logo"/>
                </div>
                <div>Tillipojanlenkki 4, 01720</div>
              </div>
              <div className='f-1'></div>
            </FlexRow>
            <FlexRow>
              <div className="f-2" style={{ marginRight: 120 }}>
                <FlexRow>
                  <div className='f-1'>
                    { (this.state.lessee.name.length === 0) && <strong style={{ textTransform: 'uppercase' }}>{t('navbar.select_lessee')}</strong> }
                    <div>{ this.state.lessee.name }</div>
                    <div>{ [this.state.lessee.street, this.state.lessee.street2].filter(f => f).join(', ') }</div>
                    <div>{this.state.lessee.postCode} {this.state.lessee.city} </div>
                  </div>
                  <div className="f-1" style={{ marginRight: 100 }}>
                    <FlexRow>
                      <div className="f-1 ta-r">{t('date')}</div>
                      <div className="f-1 ta-r">{formatDate(new Date())}</div>
                    </FlexRow>
                    <FlexRow className='m-t-24'>
                      <div className="f-1 ta-r">{t('phone')}</div>
                      <div className="f-1 ta-r">+358 (0) 207 738 860</div>
                    </FlexRow>
                    <FlexRow>
                      <div className="f-1 ta-r">{t('email')}</div>
                      <div className="f-1 ta-r">info@jit.fi</div>
                    </FlexRow>
                  </div>
                </FlexRow>
                <FlexRow className='m-t-24 m-b-24'>
                  <span className='bold m-r-12'>{t('contracts.liabilities_per')}</span>
                  <span>{formatDate(this.state.date)}</span>
                </FlexRow>
                <FlexRow className='m-t-24 m-b-24 m-r-24 p-r-24'>
                  <div className="m-r-24">{t('contracts.text_1')}</div>
                </FlexRow>
                <FlexRow className='m-b-24 m-r-24 p-r-24'>
                  <div className="m-r-24">{t('contracts.text_2')}</div>
                </FlexRow>
                { this.renderHeader() }
                { this.renderItems() }
                { this.renderTotal() }
              </div>
              <div className='f-1'></div>
              </FlexRow>
            </div> }
        </div>
      </MainLayout>
    );
  }
}

export default withTranslation()(LeaseLiabilities)