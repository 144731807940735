import { ProgressBar } from '@blueprintjs/core';
import { diffMonths } from '@patterns/core';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { priceFormat } from '../../common';
import { ReportItem } from '../../pages/devices';
import { axios } from '../../session';
import { FlexRow } from '@patterns/ui';

export interface Props extends WithTranslation {
  since: Date
  till: Date
}

export interface State {
  items: ReportItem[]
  months: number
}

export class LeaseShare extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      months: diffMonths(this.props.since, this.props.till)
    } as State
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.since.getTime() !== this.props.since.getTime() || prevProps.till.getTime() !== this.props.till.getTime()) {
      this.setState({
        months: diffMonths(this.props.since, this.props.till)
      }, () => this.fetch())
    }
  }

  public fetch = async () => {
    const params = [
      `since=${this.props.since.toISOString()}`,
      `till=${this.props.till.toISOString()}`,
      `language=${this.props.i18n.language}`
    ].join('&');
    const response = await axios.get(`/contracts/lease_share?${params}`);
    const items = response.data as ReportItem[];
    const hasValues = items.find(i => i.y > 0);

    if (hasValues) {
      const total = items.reduce((total, item) => total += item.y, 0);
      items.forEach(item => {
        item.percentage = item.y / total
      })
      this.setState({ items })
    } else {

    }
  }

  private renderSummary() {
    const total = this.state.items.reduce((total, item) => total += item.y, 0);

    return <FlexRow className="p-l-24 p-r-12 report-table-cell">
      <div className="f-1">{this.props.t('total')}</div>
      <div className="f-1 m-r-12"></div>
      <div style={{ width: 50 }}></div>
      <div style={{ width: 100 }} className="ta-r">{ priceFormat(total) }</div>
    </FlexRow>
  }

  private renderItem(item: ReportItem, key: string) {
    const price = item.y;
    return <FlexRow key={key} className="p-l-24 p-r-12 report-table-cell">
      <div className="f-1">{ item.name }</div>
      <div className="f-1 m-r-12"><ProgressBar intent="success" value={item.percentage} stripes={false} animate={false}/></div>
      <div style={{ width: 50 }}>{ (item.percentage * 100).toFixed(1) }%</div>
      <div style={{ width: 100 }} className="ta-r">{ priceFormat(price) }</div>
    </FlexRow>
  }

  public render() {
    const { t } = this.props;

    return (
      <div className="blue-alpha">
        <FlexRow className="p-l-24 p-r-12 report-table-cell">
          <div className="f-1">{t('category')}</div>
          <div className="f-2 ta-r p-r-10">{t('leases_report.percent_of_total_lease_costs')}</div>
          <div style={{ width: 100 }} className="ta-r">{t('leases_report.lease_per_month')}</div>
        </FlexRow>
        { this.state.items.map((item, idx) => this.renderItem(item, `report-item-${idx}`))}
        { this.renderSummary() }
      </div>
    );
  }
}

export default withTranslation()(LeaseShare)