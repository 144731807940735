import * as React from 'react';
import { Dialog, FileInput, FormGroup, Classes, Button } from '@blueprintjs/core';
import { axios } from '../common';
import { convertFileToBase64 } from '@patterns/core';
import { FlexColumn } from '@patterns/ui';

export interface Props {
  isOpen: boolean
  onClose: () => void
  url: string
}

export interface State {
  selection?: File
}

export class Importer extends React.Component<Props, State> {
  state = {} as State
  
  private import = async () => {
    if (!this.state.selection) {
      return
    }

    const data = await convertFileToBase64(this.state.selection);
    await axios.post(this.props.url, { data });
    this.props.onClose();
  }

  private onFileChange = async (evt: React.FormEvent<HTMLInputElement>) => {
    const selection = evt!.currentTarget!.files![0];
    this.setState({ selection })
  }

  public render() {
    return (
      <Dialog canOutsideClickClose={false} isOpen={this.props.isOpen} onClose={this.props.onClose} title="Data Import">
        <div className={Classes.DIALOG_BODY}>
          <FlexColumn>
            <FormGroup inline={true} label="Select File" className="m-12">
              <FileInput 
                fill={true}
                inputProps={{
                  name: 'import-file'
                }}
                hasSelection={typeof this.state.selection !== 'undefined'}
                buttonText="..."
                text={typeof this.state.selection === 'undefined' ? 'Choose File' : this.state.selection.name}
                onInputChange={this.onFileChange}
              />
            </FormGroup>
          </FlexColumn>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Close" icon="cross" onClick={() => this.props.onClose()}/>
            <Button intent="primary" text="Import" icon="cloud-upload" onClick={this.import}/>
          </div>
        </div>
      </Dialog>
    );
  }
}
