import * as React from 'react';
import { Dialog, Classes, FormGroup, InputGroup, Button } from '@blueprintjs/core';
import { Contact, ContactType } from '../../models/contact';
import { FlexColumn } from '@patterns/ui';
import deepEqual from 'deep-equal';
import produce from 'immer';
import { FlexRow } from '@patterns/ui';
import { ConfirmDelete } from '../confirm_delete';
import { withTranslation, WithTranslation } from 'react-i18next';

// export interface FProps {
//   className?: string
//   style?: React.CSSProperties
//   onClick?: () => void
// }

// export interface FlexProps extends FProps {
//   flex?: number
// }

// export const FlexRow = ({ children, flex, style, className = '', onClick }: React.PropsWithChildren<FlexProps>): React.ReactElement =>
//   React.createElement('div', { className: `patterns-flex-row ${className}`, style: { ...style, flex }, onClick }, children);


export interface Props extends WithTranslation {
  contact: Contact
  isOpen: boolean
  onClose: () => void
  onSave: (contact: Contact) => void
  onDelete: (contact: Contact) => void
}

export interface State {
  name: string
  description: string
  email: string
  street: string
  street2: string
  city: string
  postCode: string
  country: string
  businessID: string
  note: string
  title: string
}

export class ContactDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { 
      name, description, email, street, street2, city, postCode, country ,businessID, note, type
    } = this.props.contact;

    this.state = { 
      name, description, email, street, street2, city, postCode, country ,businessID, note,
      title: this.getTitle(type)
    }
    
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.contact, prevProps.contact)) {
      const { 
        name, description, email, street, street2, city, postCode, country ,businessID, note, type
      } = this.props.contact;
      this.setState({ 
        name, description, email, street, street2, city, postCode, country ,businessID, note,
        title: this.getTitle(type)
      })
    }
  }

  getTitle(type: ContactType) {
    const { t } = this.props;
    switch(type) {
      case ContactType.ORGANIZATION:
        return t('organization');
      case ContactType.SUPPLIER:
        return t('supplier');
      case ContactType.LESSEE:
        return t('lessee');
      case ContactType.LEASING_COMPANY:
        return t('leasing_company');
      case ContactType.ACCEPTANCE_TEMPLATE:
        return t('acceptance_template');
    }
  }

  async save() {
    const contact = produce(this.props.contact, contact => {
      contact.name = this.state.name;
      contact.description = this.state.description;
      contact.email = this.state.email;
      contact.street = this.state.street;
      contact.street2 = this.state.street2;
      contact.city = this.state.city;
      contact.postCode = this.state.postCode;
      contact.country = this.state.country;
      contact.businessID = this.state.businessID;
      contact.note = this.state.note;
    });
    this.props.onSave(contact);
  }

  private update = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const key = evt.currentTarget.name as keyof State;
    const state = {} as State
    state[key] = evt.currentTarget.value;
    this.setState(state)
  }

  public render() {
    const { t } = this.props;
    return (
      <Dialog 
        title={this.state.title}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        canOutsideClickClose={false}
        className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <FlexColumn flex={1}>
            <FormGroup inline label={t('name')}>
              <InputGroup 
                fill
                name="name"
                value={this.state.name}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label={t('description')}>
              <InputGroup 
                fill
                name="description"
                value={this.state.description}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label={t('email')}>
              <InputGroup 
                fill
                name="email"
                value={this.state.email}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label={t('street')}>
              <InputGroup 
                fill
                name="street"
                value={this.state.street}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label={t('street_2')}>
              <InputGroup 
                fill
                name="street2"
                value={this.state.street2}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label={t('post_code')}>
              <InputGroup 
                fill
                name="postCode"
                value={this.state.postCode}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label={t('city')}>
              <InputGroup 
                fill
                name="city"
                value={this.state.city}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label={t('country')}>
              <InputGroup 
                fill
                name="country"
                value={this.state.country}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label={t('business_id')}>
              <InputGroup 
                fill
                name="businessID"
                value={this.state.businessID}
                onChange={this.update}
              />
            </FormGroup>

            <FormGroup inline label={t('note')}>
              <InputGroup 
                fill
                name="note"
                value={this.state.note}
                onChange={this.update}
              />
            </FormGroup>
          </FlexColumn>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <FlexRow className="f-1">
            <FlexRow className="f-1 jc-s">
              <ConfirmDelete
                outlined={true}
                title={t('delete')}
                onConfirm={() => this.props.onDelete(this.props.contact)}
              />
            </FlexRow>
            <FlexRow className="f-2 jc-e">
              <Button 
                outlined 
                icon="cross"
                text={t('close')}
                onClick={this.props.onClose} 
                className="m-r-12"
              />
              <Button 
                outlined 
                icon="tick"
                text={t('save')}
                intent="primary" 
                onClick={() => this.save()}
              />
            </FlexRow>
          </FlexRow>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(ContactDialog)