import { BaseModel, Selectable, Resource, deserializeString, deserializeNumber } from '@patterns/core'

export type ExtraCostItem = {
  label_en: string;
  label_fi: string;
  price: number;
}

@Resource('extra_costs')
export class ExtraCost extends BaseModel implements Selectable {
  title: string
  price: number
  type: string
  items: ExtraCostItem[]

  constructor(data: any = {}) {
    super(data);
    this.title = deserializeString(data.title);
    this.price = deserializeNumber(data.price);
    this.type = deserializeString(data.type);
    this.items = (data.items || []) as ExtraCostItem[];
  }

  getLabel() { return null }
  getTitle() { return this.id }
}
