import { Button, Callout, Divider, FormGroup, InputGroup, NonIdealState } from '@blueprintjs/core';
import { formatPrice } from '@patterns/core';
import { FlexRow } from '@patterns/ui';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { axios } from '../../common';
import { MainLayout } from '../../layouts/main';
import { Office } from '../../models/office';
import { OfficeRepository } from '../../repository';
import { Breadcrumbs } from '../breadcrumb';
import { OfficeSelect } from '../selects';

export interface Props extends WithTranslation {

}

export interface State {
  isLoading: boolean;
  isAccepted: boolean;
  url: string;
  order: any;
  name: string;
  company: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  email: string;
  phone: string;
  office: Office;
  offices: Office[]
}

export class VerkkokauppaConfirm extends React.Component<Props, State> {
  state = {
    isLoading: true,
    isAccepted: false,
    url: '',
    office: new Office({}),
    offices: [] as Office[],
    name: '',
    company: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    email: '',
    phone: '',
  } as State

  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const { items: offices } = await OfficeRepository.index(0, 1000, 'name', 'ASC');
    const response = await axios.get('/verkkokauppa/last_order');
    this.setState({ order: response.data.lastOrder, offices, isLoading: false });
    // const url = await VerkkokauppaService.login();
    // window.location.href = url;
  }

  private confirm = async () => {
    this.setState({ isLoading: true }, async () => {
      const { name, company, street, street2, postalCode, city, state, country, email } = this.state;
      const data = { name, company, street, street2, postalCode, city, state, country, email };

      await axios.post(`/verkkokauppa/${this.state.order.id}/accept?lang=${this.props.i18n.language}`, data);
      this.setState({ isLoading: false, isAccepted: true })
    })
  }

  private onOfficeChange = (office: Office) => {
    this.setState({
      office,
      name: office.contactName,
      email: office.contactEmail,
      company: office.name,
      street: office.street,
      street2: office.street_2,
      city: office.city,
      postalCode: office.postalCode,
      phone: office.contactPhone
    })
  }

  private renderHeader = () => {
    const { t } = this.props;
    return <FlexRow className="m-b-12">
      <div className="f-3 header">{t('patterns.items_one')}</div>
      <div className="f-1 ta-r header">{t('price')}</div>
      <div className="f-1 ta-r header">{t('quantity')}</div>
      <div className="f-1 ta-r header">{t('unit')}</div>
      <div className="f-4 ta-r header">&nbsp;</div>
      <div className="f-3 ta-r header">&nbsp;</div>
    </FlexRow>
  }
  private renderItems = () => {
    if (this.state.order && this.state.order.data.cXML.Message.PunchOutOrderMessage)
    return this.state.order.data.cXML.Message.PunchOutOrderMessage.ItemIn.map((item: any, index: number) => {
      return <FlexRow key={`punchout-item-${index}`}>
        <div className="f-3">{item.ItemDetail.Description["#text"]}</div>
        <div className="f-1 ta-r">{formatPrice(item.ItemDetail.UnitPrice.Money["#text"])}</div>
        <div className="f-1 ta-r">{item["@_quantity"]}</div>
        <div className="f-1 ta-r">{item.ItemDetail.UnitOfMeasure}</div>
        <div className="f-4 ta-r">&nbsp;</div>
        <div className="f-3 ta-r header">&nbsp;</div>
      </FlexRow>
    })
  }
  private renderSummary = () => {
    return <FlexRow>
      <div className="f-3">{this.props.t('total')}</div>
      <div
        className="f-1 ta-r">{formatPrice(this.state.order.data.cXML.Message.PunchOutOrderMessage.PunchOutOrderMessageHeader.Total.Money["#text"])}</div>
      <div className="f-1 ta-r"></div>
      <div className="f-1 ta-r"></div>
      <div className="f-4 ta-r">&nbsp;</div>
      <div className="f-3 ta-r header">&nbsp;</div>
    </FlexRow>
  }

  public render() {
    const { t } = this.props;
    return (
      <MainLayout title="Verkkokauppa">
        <Breadcrumbs path={`Store / Verkkokauppa`} title={t('order_confirmation')}/>

        <div className="content-full">
          { this.state.isLoading && <NonIdealState title={t('patterns.please_wait')} description="Loading Verkkokauppa Order"/> }
          { !this.state.isLoading && <div className="m-24 verkkokauppa-order-confirmation">
            { this.state.order && <h2>{t('patterns.items_few')}</h2> }
            { this.renderHeader() }

            { this.state.order && this.renderItems() }
            { this.state.order && this.renderSummary() }

            { this.state.order && <div className="delivery-form">
              <Divider />
              <h2>{t('delivery_address')}</h2>

              <FormGroup inline={true} label={t('office')}>
                <OfficeSelect
                  activeItem={this.state.office}
                  onSelect={this.onOfficeChange}
                  hint={t('select_office')}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('company')}>
                <InputGroup
                  value={this.state.company}
                  onChange={evt => this.setState({ company: evt.currentTarget.value })}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('street')}>
                <InputGroup
                  value={this.state.street}
                  onChange={evt => this.setState({ street: evt.currentTarget.value })}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('street_2')}>
                <InputGroup
                  value={this.state.street2}
                  onChange={evt => this.setState({ street2: evt.currentTarget.value })}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('city')}>
                <InputGroup
                  value={this.state.city}
                  onChange={evt => this.setState({ city: evt.currentTarget.value })}
                />
              </FormGroup>
{/*
              <FormGroup inline={true} label={t('state')}>
                <InputGroup
                  value={this.state.state}
                  onChange={evt => this.setState({ state: evt.currentTarget.value })}
                />
              </FormGroup> */}

              <FormGroup inline={true} label={t('post_code')}>
                <InputGroup
                  value={this.state.postalCode}
                  onChange={evt => this.setState({ postalCode: evt.currentTarget.value })}
                />
              </FormGroup>

              {/* <FormGroup inline={true} label="Country">
                <InputGroup
                  value={this.state.country}
                  onChange={evt => this.setState({ country: evt.currentTarget.value })}
                />
              </FormGroup> */}

              <FormGroup inline={true} label={t('contact_name')}>
                <InputGroup
                  value={this.state.name}
                  onChange={evt => this.setState({ name: evt.currentTarget.value })}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('email')}>
                <InputGroup
                  value={this.state.email}
                  onChange={evt => this.setState({ email: evt.currentTarget.value })}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('phone')}>
                <InputGroup
                  value={this.state.phone}
                  onChange={evt => this.setState({ phone: evt.currentTarget.value })}
                />
              </FormGroup>
            </div> }

            { (!this.state.isAccepted && this.state.order) && <FlexRow className="m-t-24">
              <Button text={t('confirm')} intent="success" icon="tick" onClick={this.confirm}/>
            </FlexRow> }

            { this.state.isAccepted && <FlexRow className="m-t-24">
              <Callout intent="success">
                {t('order_create_success')}
              </Callout>
            </FlexRow> }
          </div> }
        </div>
      </MainLayout>
    );
  }
}

export default withTranslation()(VerkkokauppaConfirm)
