import { BaseModel, Selectable, Resource, deserializeNumber } from '@patterns/core'
import { Category } from './category';

@Resource('price_lists')
export class PriceList extends BaseModel implements Selectable {
  category: Category
  extendedLeasingPrice: number
  reclaimPrice: number

  constructor(data: any = {}) {
    super(data);
    this.category = new Category(data.category || {});
    this.extendedLeasingPrice = deserializeNumber(data.extendedLeasingPrice);
    this.reclaimPrice = deserializeNumber(data.reclaimPrice);
  }

  getLabel() { return null }
  getTitle() { return this.id }
}
