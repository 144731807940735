import { BaseModel, Selectable, Resource, deserializeNumber } from '@patterns/core'
import { deserializeString, deserializeDate } from '@patterns/core'
import { Category } from './category';
import { CostCenter } from './cost_center';
import { Contract } from './contract';
import { Fields } from '../models/fields';
import { diffDays, monthDiff } from '../common';
import { AssetReplacement } from './asset_replacement';
import { ExtraCost, ExtraCostItem } from './extra_cost';
import { Office } from './office';
import { Employee } from './employee';
import { PurchaseInvoice } from './purchase_invoice';

export type ExtraCostItemMap = { [id: string]: ExtraCostItem }
export type AssetBinding = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  version: number;
  extension: number;
  year: number;
  extendedAt: string;
  extensionTill: string;
  manual: boolean;
  assetId: string;
  contractId: string;
}

@Resource('assets')
export class Asset extends BaseModel implements Selectable {
  name: string;
  category: Category;
  contract: Contract;
  lifecycle: number;
  purchasePrice: number;
  reclaimPrice: number;
  purchaseDate: Date;
  decisionPrice: number;
  decisionDate?: Date;
  warrantyEnd: Date;
  fields: Fields;
  employee: Employee;
  state: string;
  costCenter: CostCenter;
  note: string;
  flatCosts: ExtraCost[];
  technicalInfo: string;
  serialNumber: string;
  office: Office;
  replacement: AssetReplacement;
  extraCostItems: ExtraCostItemMap;
  isPickedUp: boolean;
  isReplaced: boolean;
  monthlyPMT: number;
  monthlyExtraCosts: number;
  monthlyTCO: number;
  isUsed: boolean;
  hasReport: boolean;
  isDummy: boolean;
  comments: string;
  pickupPlace?: Office;
  purchaseInvoice: PurchaseInvoice;
  bindings: AssetBinding[];

  constructor(data: any = {}) {
    super(data);
    this.name = data.name;
    this.category = new Category(data.category || {});
    this.contract = new Contract(data.contract || {});
    this.lifecycle = data.lifecycle || 0;
    this.purchasePrice = data.purchasePrice || 0;
    this.decisionPrice = data.decisionPrice || 0;
    this.reclaimPrice = data.reclaimPrice || 0;
    this.decisionDate = data.decisionDate;

    if (data.purchaseDate) {
      this.purchaseDate = deserializeDate(data.purchaseDate);
    } else {
      this.purchaseDate = new Date(0);
    }

    if (data.warrantyEnd) {
      this.warrantyEnd = deserializeDate(data.warrantyEnd);
    } else {
      this.warrantyEnd = new Date(0);
    }

    this.employee = new Employee(data.employee || {});
    this.fields = data.fields as Fields || {};
    this.costCenter = new CostCenter(data.costCenter || {});
    this.note = deserializeString(data.note);
    this.state = deserializeString(data.state);
    this.serialNumber = deserializeString(data.serialNumber);
    this.technicalInfo = data.technicalInfo;
    this.replacement = new AssetReplacement(data.replacement || {});
    this.office = new Office(data.office || {});
    this.pickupPlace = new Office(data.pickupPlace || {});
    this.extraCostItems = data.extraCostItems || {};
    this.flatCosts = (data.flatCosts || []).map((i: any) => new ExtraCost(i));
    this.isPickedUp = typeof data.isPickedUp === 'undefined' ? false : data.isPickedUp;
    this.isReplaced = typeof data.isReplaced === 'undefined' ? false : data.isReplaced;
    this.monthlyPMT = deserializeNumber(data.monthlyPMT);
    this.monthlyExtraCosts = deserializeNumber(data.monthlyExtraCosts);
    this.monthlyTCO = deserializeNumber(data.monthlyTCO);
    this.isUsed = typeof data.isUsed === 'undefined' ? false : data.isUsed;
    this.hasReport = typeof data.hasReport === 'undefined' ? false : data.hasReport;
    this.isDummy = data.isDummy || false;
    this.comments = deserializeString(data.comments);
    this.purchaseInvoice = new PurchaseInvoice(data.purchaseInvoice || {});
    this.bindings = data.assetBindings || [];
  }

  periodPMT(since: Date, till: Date) {
    if (!this.contract) {
      return 0
    }

    const daysTotal = diffDays(this.contract.startDate, this.contract.validTill);
    const daysPeriod = diffDays(since, till);
    return parseFloat(((daysPeriod / daysTotal) * (parseFloat(this.monthlyPMT as unknown as string))).toFixed(2));
  }

  get age() {
    return monthDiff(this.purchaseDate, new Date()) - 1;
  }

  get remaining() {
    const remaining = monthDiff(new Date(), this.warrantyEnd);
    if (remaining < 0) {
      return remaining * 1;
    }

    return remaining;
  }

  get extendState() {
    if (this.isPickedUp) {
      return 'Picked Up'
    } else if (this.isReplaced) {
      return 'Replaced'
    } else {
      return 'Pending'
    }
  }

  hasCoordinates() {
    return this.office.exists && this.office.lat !== 0 && this.office.lng !== 0
  }

  getLabel() { return '' }
  getTitle() { return this.name }
}
