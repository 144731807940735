/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon, ProgressBar, Tag } from '@blueprintjs/core';
import { t } from '@patterns/locale';
import { FlexRow } from '@patterns/ui';
import produce from 'immer';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AutoSizer } from 'react-virtualized';
import { priceFormat } from '../../common';
import { Asset } from '../../models/asset';
import { ExtraCost } from '../../models/extra_cost';
import { ExtraCostRepository } from '../../repository';
import { axios } from '../../session';
import { TCOBarChart } from '../extra_costs/tco_bar_chart';
import { ExpanseMap } from './budget';
import { ExtraCostReportItem } from './extra_costs';

export type TCOReportItem = {
  categoryName: string
  categoryIcon: string
  categoryId: string
  lifecycle: number
  lifecyclePrice: number
  cnt: number
  classification: string
  monthPrice: number
  monthlyExtraCosts: string
  monthlyTCO: string
  visible: boolean
}

export type ExtraCostTitleMap = { [id: string]: ExtraCostReportItem[] }
export type ExtraCostTypeMap = { [id: string]: ExtraCostTitleMap }

export type DataMap = { [id: string]: Asset[] }

export interface Props extends WithTranslation {
  months: number
  showInvisible: boolean
}

export interface State {
  assetExpanseMap: ExpanseMap
  data: TCOReportItem[]
  dataMap: DataMap  
  expanseMap: ExpanseMap
  extraCosts: ExtraCost[]
  items: ExtraCostReportItem[]
  typeMap: ExtraCostTypeMap
}

export class TCOReportRow extends React.Component<Props> {
  state = {
    assetExpanseMap: {},
    data: [] as TCOReportItem[],
    dataMap: {} as DataMap,
    expanseMap: {} as ExpanseMap,
    extraCosts: [] as ExtraCost[],
    items: [] as ExtraCostReportItem[],
    typeMap: {} as ExtraCostTypeMap
  } as State

  componentDidMount() {
    this.fetch()
  }

  private fetch = async () => {
    const { items } = await ExtraCostRepository.index(0, 10000, 'title', 'ASC');
    const report = await axios.get(`/assets/tco_report?months=${this.props.months}`);
    const data = report.data.leasingResults as TCOReportItem[];
    const assetData = await axios.get('/extra_costs/asset_costs_chart');
    this.setItems(assetData.data as ExtraCostReportItem[]);
    this.setState({ 
      data, 
      extraCosts: items,
    })
  }

  private fetchAssets = async (categoryId: string, classification: string, index: number) => {
    const key = `${categoryId}-${index}`;
    const response = await axios.get(`/assets/fields?categoryId=${categoryId}&classification=${classification}`);
    const dataMap = produce(this.state.dataMap, dataMap => {
      dataMap[key] = response.data.assets
    });
    this.setState({ dataMap })
  }

  private toggleAssetRow = (asset: Asset, index: number) => {
    const key = `${asset.id}-${index}`;
    const assetExpanseMap = produce(this.state.assetExpanseMap, expanseMap => {
      if (typeof expanseMap[key] === 'undefined') {
        expanseMap[key] = true;
      } else {
        expanseMap[key] = !expanseMap[key]
      }
    });
    this.setState({ assetExpanseMap })
  }

  private toggleRow = (categoryId: string, classification: string, index: number) => {
    const key = `${categoryId}-${index}`;
    const expanseMap = produce(this.state.expanseMap, expanseMap => {
      if (typeof expanseMap[key] === 'undefined') {
        expanseMap[key] = true;
        this.fetchAssets(categoryId, classification, index)
      } else {
        expanseMap[key] = !expanseMap[key]
      }
    });
    this.setState({ expanseMap })
  }

  private setItems = (items: ExtraCostReportItem[]) => {
    const typeMap = {} as ExtraCostTypeMap;

    items.forEach(item => {
      if (!typeMap[item.type]) {
        typeMap[item.type] = {} as ExtraCostTitleMap
      }

      if (!typeMap[item.type][item.title]) {
        typeMap[item.type][item.title] = []
      }

      typeMap[item.type][item.title].push(item)
    });

    this.setState({
      items,
      typeMap
    })
  }

  private renderProgressItem = (item: ExtraCostReportItem, sum: number, key: string) => {
    const percentage = (item as any).monthPrice / sum;
    return <FlexRow key={key} className="p-l-24 p-r-24 report-table-cell blue-alpha">
      <div className="f-2">{ item.name }</div>
      {/* <div className="f-1 m-r-12"><ProgressBar intent="success" value={percentage} stripes={false} animate={false}/></div> */}
      {/* <div style={{ width: 50 }}>{ (percentage * 100).toFixed(1) }%</div> */}
      <div className="w-150 ta-r">{ item.y }</div>
      <div className="w-150 ta-r">{ priceFormat((item as any).monthPrice || 0) }</div>
      <div className="w-150 ta-r">{ priceFormat(((item as any).monthPrice * item.y) || 0) }</div>
      <div className="w-150 ta-r">{ priceFormat(((item as any).monthPrice * item.y * (this.props.months || 1)) || 0) }</div>
    </FlexRow>
  }

  // private renderBreakdown = (categoryIdx: number) => {
  //   const types = Object.keys(this.state.typeMap); 

  //   const children = types.map((type, typeIdx) => {
  //     let typeSum = 0;
  //     Object.keys(this.state.typeMap[type]).forEach((title, titleIdx) => {
  //       const items = this.state.typeMap[type][title];
  //       const sum = items.reduce((total, item) => total += (item.y * (item as any).price), 0);
  //       typeSum += sum;
  //     });

  //     return Object.keys(this.state.typeMap[type]).map((title, titleIdx) => {
  //       const items = this.state.typeMap[type][title];
  //       const sum = items.reduce((total, item) => total += (item.y * (item as any).price), 0);
  //       const children = this.state.typeMap[type][title].map((item, itemIdx) => this.renderProgressItem(item, sum, `summary-item-${categoryIdx}-${typeIdx}-${titleIdx}-${itemIdx}`))
        
  //       return <div>
  //         <FlexRow className="report-table-header p-l-24 p-r-24" style={{ width: '100%', alignItems: 'center', height: 32 }}>
  //           <div className="f-2 header">{ title }</div>
  //           <div className="f-1 header m-r-12"></div>
  //           <div style={{ width: 50 }}></div>
  //           <div style={{ width: 50 }} className="ta-r header"></div>
  //           <div className="w-100 ta-r header"></div>
  //           <div className="w-100 ta-r header"><Tag minimal intent="success">{ priceFormat(sum) }</Tag></div>
  //         </FlexRow>
  //         { children }
  //       </div>
  //     });
  //   })

  //   return <div key={`category-breakdown-${categoryIdx}`}>
  //     <FlexRow className="report-table-header p-l-24 p-r-24" style={{ width: '100%', alignItems: 'center', height: 32 }}>
  //       <div className="f-2 header">Extra Cost</div>
  //       <div className="f-1 header m-r-12">Share</div>
  //       <div style={{ width: 50 }}></div>
  //       <div style={{ width: 50 }} className="ta-r header">Quantity</div>
  //       <div className="w-100 ta-r header">Unit Price</div>
  //       <div className="w-100 ta-r header">Total</div>
  //     </FlexRow>
  //     { children }
  //   </div>
  // }

  private renderAsset = (asset: Asset, index: number) => {
    const key = `${asset.id}-${index}`;
    const children = [] as React.ReactElement[];
    if (this.state.assetExpanseMap[key]) {
      asset.flatCosts.forEach((extraCost, index) => {
        const extra = this.state.extraCosts.find(extra => extra.id === (extraCost as any).extraCostId);
        children.push(
          <FlexRow key={`asset-${asset.id}-extra-cost-${index}`} className="report-table-cell p-l-24 p-r-24" style={{ width: '100%', alignItems: 'center', height: 32 }}>
            <div className="w-50 ta-r"></div>
            <div className="f-2">{ extra?.type } { (extraCost as any).label_en }</div>
            <div className="f-1">{ extra?.title }</div>
            <div className="f-1 ta-r"></div>
            <div className="f-1 ta-r">{ priceFormat(extraCost.price) }</div>
            <div className="f-1 ta-r"></div>
            <div className="f-1 ta-r">{ priceFormat((asset.lifecycle || asset.category.lifecycle) * extraCost.price) }</div>
            <div className="f-1 ta-r">{ priceFormat(((extraCost.price || 0) * (this.props.months || 1))) }</div>
          </FlexRow>  
        )
      })
    }
    
    return <React.Fragment key={`report-asset-asset-${asset.id}`}>
      <FlexRow
        className={`p-r-24 p-l-24 report-table-cell hoverable clickable darker`}
        onClick={() => this.toggleAssetRow(asset, index)}>
        <div className="w-50 ai-c jc-c d-f">
          { asset.flatCosts.length > 0 && <Icon icon={this.state.assetExpanseMap[key] ? 'double-chevron-down' : 'double-chevron-right'}/> }
        </div>
        <div className="f-2">{ asset.name }</div>
        <div className="f-1">{ asset.employee ? asset.employee.name : '' }</div>
        <div className="f-1 ta-r">{ priceFormat(asset.monthlyPMT) }</div>
        <div className="f-1 ta-r">{ priceFormat(asset.monthlyExtraCosts) }</div>
        <div className="f-1 ta-r">{ priceFormat(asset.monthlyTCO) }</div>
        {/* <div className="f-1 ta-r">{ priceFormat((asset.lifecycle || asset.category.lifecycle) * asset.monthlyPMT) }</div> */}
        <div className="f-1 ta-r">{ priceFormat(asset.monthlyTCO * (this.props.months || 1))}</div>
      </FlexRow>
      { this.state.assetExpanseMap[key] && children }
    </React.Fragment>
  }

  private renderItems = () => {
    const items = this.props.showInvisible ? this.state.data : this.state.data.filter(i => i.visible);
    return items.filter(i => i.monthPrice > 0).map((item, index) => { 
      const key = `${item.categoryId}-${index}`;
      let items = [] as React.ReactElement[];
      const expanded = this.state.expanseMap[key];

      if (this.state.dataMap[key] && this.state.dataMap[key].length > 0) {
        const asset = this.state.dataMap[key][0];
        const costsMap = {} as { [id:string]: number };
        let total = 0;

        (asset as any).flatCosts.forEach((flatCost: any) => {
          if (!costsMap[flatCost.extraCostId]) {
            costsMap[flatCost.extraCostId] = 0;
          }
          costsMap[flatCost.extraCostId] += parseFloat(flatCost.price);
          total += parseFloat(flatCost.price);
        });

        this.state.dataMap[key].forEach((asset, index) => {
          items.push(this.renderAsset(asset, index));
        })
      }

      return <React.Fragment key={`report-item-${index}`}>
        <FlexRow
          key={`report-asset-item-${index}`}
          className={`p-r-24 p-l-24 report-table-cell hoverable clickable`}
          onClick={() => this.toggleRow(item.categoryId, item.classification, index)}>
          <div className="w-50 d-f jc-s ai-s">
            <Icon icon={this.state.expanseMap[`${item.categoryId}-${index}`] ? 'chevron-down' : 'chevron-right'}/>
          </div>
          <div className="f-2">
            { item.categoryIcon && item.categoryIcon.length > 0 && <i className={`fas ${item.categoryIcon} m-r-12`}  style={{ color: 'white' }}></i> }
            { item.categoryName }
          </div>
          <div className="f-1">{ item.classification === 'No classification' ? t('no_classification') : item.classification }</div>
          <div className="f-1 ta-r">{ priceFormat(item.monthPrice) }</div>
          <div className="f-1 ta-r">{ priceFormat(parseFloat(item.monthlyExtraCosts)) }</div>
          <div className="f-1 ta-r">{ priceFormat(parseFloat(item.monthlyTCO)) }</div>
          <div className="f-1 ta-r">{ priceFormat(item.monthPrice * item.lifecycle) }</div>
          <div className="f-1 ta-r">{ priceFormat(parseFloat(item.monthlyTCO) * (this.props.months || 1)) }</div>
        </FlexRow>
        { expanded && items.length > 0 && items }
      </React.Fragment>
    })
  }

  private renderSummary() {
    const { t } =this.props;
    const types = Object.keys(this.state.typeMap); // maintenance costs, service costs
    let allTypeSum = 0;
    types.forEach((type, idx) => {
      Object.keys(this.state.typeMap[type]).forEach((title) => {
        const items = this.state.typeMap[type][title];
        const sum = items.reduce((total, item) => total += (item.y * (item as any).price), 0);
        allTypeSum += sum;
      });
    })

    const supportChildren = types.map((type, typeIdx) => {
      let typeSum = 0;
      const items = Object.keys(this.state.typeMap[type]).map((title, titleIdx) => {
        const items = this.state.typeMap[type][title];
        const sum = items.reduce((total, item) => total += (item.y * (item as any).price), 0);
        typeSum += sum * items.length;
        return {
          y: items.length,
          name: title,
          monthPrice: sum,
          type,
          title
        };
      });

      const children = items.map((item: any, idx: number) => this.renderProgressItem(item, allTypeSum, `summary-item-${typeIdx}-${idx}`))

      return <div key={`summary-main-item-${typeIdx}`}>
        <FlexRow className="report-table-header p-l-24 p-r-24" style={{ width: '100%', alignItems: 'center', height: 32 }}>
          <div className="f-2 header">{ type }</div>
          {/* <div className="f-1 header m-r-12"></div> */}
          <div style={{ width: 50 }}></div>
          <div className="w-150 ta-r header"></div>
          <div className="w-150 ta-r header"><Tag minimal intent="success">{ priceFormat(typeSum || 0) }</Tag></div>
          <div className="w-150 ta-r header"><Tag minimal intent="success">{ priceFormat((typeSum || 0) * (this.props.months || 1)) }</Tag></div>
        </FlexRow>
        { children }
      </div>
    });

    return <div className="chart-container f-1" style={{backgroundColor: 'transparent'}}>
      <div className="chart-inner-title">
        {t('tco_reports.extra_cost_summary')}
      </div>
      <FlexRow>
        <div className="f-1">
          <FlexRow className="report-table-header p-l-24 p-r-24" style={{ width: '100%', alignItems: 'center', height: 32 }}>
            <div className="f-2 header"></div>
            {/* <div className="f-1 header m-r-12">{t('percentage')}</div> */}
            <div style={{ width: 50 }}></div>
            <div className="w-150 ta-r header">{t('quantity')}</div>
            <div className="w-150 ta-r header">{t('tco_reports.price_per_month')}</div>
            <div className="w-150 ta-r header">{t('tco_reports.sum_per_month')}</div>
            <div className="w-150 ta-r header">{t('tco_reports.sum_per_period')}</div>
          </FlexRow>
          { supportChildren }
        </div>
        {/* <div className="f-1">{ maintenanceChildren }</div> */}
      </FlexRow>
    </div>
  }

  public render() {
    const { t } = this.props;
    return (
      <div className="m-b-24 w-100p">
        <div className="d-f f-r">
          <FlexRow>
            <div className="f-2 chart-container m-t-2" style={{ minHeight: 480 }}>
              <div className="chart-inner-title">
                {t('tco_reports.extra_cost_chart')}
              </div>
              <AutoSizer>{({ height, width }) => (
                <div className="m-r-24">
                  <TCOBarChart
                    height={height - 56}
                    width={width}
                  />
                </div>
              )}</AutoSizer>
            </div>

            <div className="f-1 p-t-2 d-f m-l-12">
              { this.renderSummary() }
            </div>
          </FlexRow>
        </div>
        <div className="d-f f-r m-t-24">
          <div className="f-1 report-table-container m-t-24">
            <FlexRow className="p-l-24 p-r-24 m-t-12 report-table-cell">
              <div className="w-50">&nbsp;</div>
              <div className="f-2 header">{t('category')}</div>
              <div className="f-1 header">{t('classification')}</div>
              <div className="f-1 ta-r header">{t('tco_reports.leasing_costs_1mo')}</div>
              <div className="f-1 ta-r header">{t('tco_reports.extra_costs_1mo')}</div>
              <div className="f-1 ta-r header">{t('tco_reports.tco_1mo')}</div>
              <div className="f-1 ta-r header">{t('tco_reports.tco_selected_period')}</div>
            </FlexRow>
            { this.renderItems() }
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(TCOReportRow)