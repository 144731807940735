import { Button, Tag } from '@blueprintjs/core';
import { Alignment, Column, ColumnType, DataTable, FilterStates } from '@patterns/datatable';
import { Notifier, Toolbar } from '@patterns/ui';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { priceFormat } from '../../common';
import { ExtraCost } from '../../models/extra_cost';
import { ExtraCostRepository } from '../../repository';
import { ExtraCostsDialog } from '../extra_costs/extra_cost.dialog';

const ExtraCostsTable = DataTable.ofType<ExtraCost>();

export interface Props extends WithTranslation {}

export interface State {
  selected: ExtraCost
  showDialog: boolean
}

export class ExtraCosts extends React.Component<Props, State> {
  cancelToken: any;

  state = {
    selected: new ExtraCost({}),
    showDialog: false
  } as State

  tableRef = React.createRef<any>();

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'title',
        title: t('title'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: extraCost => <span>{ extraCost.title }</span>
      },
      {
        id: 'type',
        title: t('type'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: extraCost => <span>{ extraCost.type }</span>
      },
      {
        id: 'tags',
        title: t('tags'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 3,
        visible: true,
        format: extraCost => <div>
          { extraCost.items.map((item, index) => <Tag 
            key={`extra-cost-${extraCost.id}-item-${index}`}
            minimal={true}
            className="m-r-12"
            rightIcon={<span>{ priceFormat(item.price) }</span>}>
            { item.label_en  }
          </Tag>)}
        </div>
      }
    ] as Column<ExtraCost>[]
  }
  private create = async () => {
    this.setState({
      selected: new ExtraCost({}),
      showDialog: true
    })
  }

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await ExtraCostRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken
    );
    this.cancelToken = cancelToken;
    return { items, total }
  }

  private onSelect = (selected: ExtraCost) => this.setState({ 
    selected,
    showDialog: true 
  })

  private onDelete = async (extraCost: ExtraCost) => {
    await ExtraCostRepository.delete(extraCost.id);
    Notifier.success(this.props.t('settings.extra_cost_remove_success'));
    this.setState({
      showDialog: false
    })
    this.tableRef.current?.fetch();
  }

  private onClose = () => {
    this.setState({ showDialog: false });
    this.tableRef.current?.fetch();
  }

  public render() {
    return (
      <div className="m-l-24 m-r-24">
        <Toolbar>
          <div className="f-1 jc-s ai-c d-f m-l-12">
            <Button intent="primary" text={this.props.t('settings.add_extra_cost')} icon="plus" onClick={this.create}/>
          </div>
        </Toolbar>

        <ExtraCostsTable
          id="extra-costs-table"
          ref={this.tableRef}
          columns={this.getColumns()}
          fetch={this.fetch}
          sort="id"
          sortDir="asc"
          onItemSelect={this.onSelect}
        />

        <ExtraCostsDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onDelete={this.onDelete}
          extraCost={this.state.selected}
        />
      </div>
    );
  }
}

export default withTranslation()(ExtraCosts)