import { Button, Icon, InputGroup, NumericInput } from '@blueprintjs/core';
import { deepEqual } from '@patterns/core';
import { FlexRow } from '@patterns/ui';
import produce from 'immer';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { priceFormat } from '../../common';
import { axios } from '../../session';
import { CategoryAssetsMap, ExpanseMap, ReportAsset, ReportCategory } from './budget';

export interface Props extends WithTranslation {
  data: QuarterData
  onChange: (data: QuarterData) => void
  editable?: boolean
  showInvisible: boolean
  hideEmpty: boolean
}

export interface State {
  category?: ReportCategory
  categories: ReportCategory[]
  categoryAssetsMap: CategoryAssetsMap
  expanseMap: ExpanseMap
}

export type QuarterData = {
  quarter: number
  year: number
  data: CategoryAssetsMap  
}

export class BudgetQuarter extends React.Component<Props, State> {
  state = {
    categories: [],
    categoryAssetsMap: {},
    expanseMap: {}
  } as State

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(prevProps.data, this.props.data)) {
      this.fetch()
    }
  }

  private fetch = async () => {
    const categories = (await axios.get('/assets/budget_categories')).data as ReportCategory[];
    const expanseMap = categories.reduce((expanseMap, category) => {
      expanseMap[category.id] = false;
      return expanseMap
    }, {} as ExpanseMap);
    
    const categoryAssetsMap = categories.reduce((categoryAssetsMap, category) => {
      categoryAssetsMap[category.id] = [];
      return categoryAssetsMap;
    }, {} as CategoryAssetsMap);
    
    this.setState({
      categories,
      categoryAssetsMap,
      expanseMap
    })
  }

  private fetchCategoryAssets = async (category: ReportCategory) => {
    const assets = (await axios.get(`/assets/budget_assets/${category.id}`)).data as ReportAsset[];
    const categoryAssetsMap = produce(this.state.categoryAssetsMap, categoryAssetsMap => {
      categoryAssetsMap[category.id] = assets;
    })
    this.setState({
      categoryAssetsMap
    })
  }

  private getQuarterTitle = (quarter: number) => {
    const { t } = this.props;

    const titles = [
      '',
      t('budgeting_reports.quarter_1'),
      t('budgeting_reports.quarter_2'),
      t('budgeting_reports.quarter_3'),
      t('budgeting_reports.quarter_4'),
    ]

    return `Q${quarter}   ${titles[quarter]}`
  }

  private toggle = (category: ReportCategory) => { 
    const expanseMap = produce(this.state.expanseMap, expanseMap => {
      expanseMap[category.id] = !expanseMap[category.id]
    });

    this.setState({ category, expanseMap }, () => {
      if (this.state.categoryAssetsMap[category.id].length === 0) {
        this.fetchCategoryAssets(category)
      }
    })
  }

  private hasNewAssets = (category: ReportCategory) => {
    return this.state.categoryAssetsMap[category.id].findIndex(i => i.isNew) > -1
  }

  private recalculateCategory = (category: ReportCategory, categoryIdx: number) => {
    const assets = this.state.categoryAssetsMap[category.id];

    const price = assets.reduce((total, asset) => total += parseFloat(asset.assetPrice), 0);
    const leasePerMonth = assets.reduce((total, asset) => total += parseFloat(asset.leasePerMonth), 0);
    const extraCostsPrice = assets.reduce((total, asset) => total += parseFloat(asset.extraCostsPrice), 0);
    
    const categories = produce(this.state.categories, categories => {
      const category = categories[categoryIdx];
      category.assetPrice = price.toFixed(2);
      category.extraCostsPrice = extraCostsPrice.toFixed(2);
      category.leasePerMonth = leasePerMonth.toFixed(2);
      category.y = assets.length;
    });

    this.setState({ categories })
  }

  private plus = (category: ReportCategory, event: React.MouseEvent<HTMLButtonElement, MouseEvent>, categoryIdx: number) => {
    event.preventDefault();
    event.stopPropagation();

    const assets = this.state.categoryAssetsMap[category.id];

    const avgPrice = (assets.reduce((total, asset) => total += parseFloat(asset.assetPrice), 0) / assets.length).toFixed(2);
    const avgPurchasePrice = (assets.reduce((total, asset) => total += parseFloat(asset.purchasePrice), 0) / assets.length).toFixed(2);
    const avgLeasePerMonth = (assets.reduce((total, asset) => total += parseFloat(asset.leasePerMonth), 0) / assets.length).toFixed(2);
    const avgMonthlyPMT = (assets.reduce((total, asset) => total += parseFloat(asset.monthlyPMT), 0) / assets.length).toFixed(2);
    const avgExtraCostsPrice = (assets.reduce((total, asset) => total += parseFloat(asset.extraCostsPrice), 0) / assets.length).toFixed(2);

    const asset = {
      name: `New Asset`,
      monthlyPMT: avgMonthlyPMT,
      leasePerMonth: avgLeasePerMonth,
      purchasePrice: avgPurchasePrice,
      assetPrice: avgPrice,
      extraCostsPrice: avgExtraCostsPrice,
      categoryId: category.id,
      categoryName: category.name,
      isNew: true
    } as ReportAsset

    const categoryAssetsMap = produce(this.state.categoryAssetsMap, categoryAssetsMap => {
      categoryAssetsMap[category.id].splice(0, 0, asset);
    });

    this.setState({ categoryAssetsMap }, () => this.recalculateCategory(category, categoryIdx))
  }

  private minus = (category: ReportCategory, event: React.MouseEvent<HTMLButtonElement, MouseEvent>, categoryIdx: number) => {
    event.preventDefault();
    event.stopPropagation();

    const categoryAssetsMap = produce(this.state.categoryAssetsMap, categoryAssetsMap => {
      const idx = categoryAssetsMap[category.id].findIndex(i => i.name === 'New Asset');
      if (idx >= 0) {
        categoryAssetsMap[category.id].splice(idx, 1);
        this.setState({
          categoryAssetsMap
        })
      }
    });

    this.setState({ categoryAssetsMap }, () => this.recalculateCategory(category, categoryIdx))
  }

  private updateCategoryAssetName = (category: ReportCategory, item: ReportAsset, index: number, value: string) => {
    const categoryAssetsMap = produce(this.state.categoryAssetsMap, categoryAssetsMap => {
      categoryAssetsMap[category.id][index].name = value;
    });
    this.setState({ categoryAssetsMap })
  }

  private updateCategoryLeasePerMonth = (category: ReportCategory, item: ReportAsset, index: number, value: number, categoryIdx: number) => {
    const categoryAssetsMap = produce(this.state.categoryAssetsMap, categoryAssetsMap => {
      categoryAssetsMap[category.id][index].leasePerMonth = value.toString();
    });
    this.setState({ categoryAssetsMap }, () => this.recalculateCategory(category, categoryIdx))
  }

  private updateCategoryAssetPrice = (category: ReportCategory, item: ReportAsset, index: number, value: number, categoryIdx: number) => {
    const categoryAssetsMap = produce(this.state.categoryAssetsMap, categoryAssetsMap => {
      categoryAssetsMap[category.id][index].assetPrice = value.toString();
    });
    this.setState({ categoryAssetsMap }, () => this.recalculateCategory(category, categoryIdx))
  }

  private getLeasePerMoTotal = () => this.state.categories
      .filter(item => item.leasePerMonth !== 'NaN')
      .reduce((total, item) => total += (parseFloat(item.leasePerMonth || '0')), 0);

  private getAssetPriceTotal = () => {
    const total = this.state.categories
      .filter(item => item.assetPrice !== 'NaN')
      .reduce((total, item) => total += (parseFloat(item.assetPrice)), 0);
    return total
  }

  private getTotal = () => {
    const total = this.state.categories
      .filter(item => item.extraCostsPrice !== 'NaN')
      .reduce((total, item) => total += parseFloat(item.extraCostsPrice), 0);
    return total
  }

  private renderAssets = (category: ReportCategory, categoryIdx: number) => {
    const padding = 36;
    return this.state.categoryAssetsMap[category.id].map((item, index) => {
      return <FlexRow key={`report-asset-item-${index}`} style={{ paddingLeft: padding }} 
        className={`p-r-24 report-table-cell hoverable clickable ${item.name === 'New Asset' ? 'green' : ''}`}>
      <div className="w-50"></div>
      <div className="f-2 p-l-4">
        { !item.isNew && <span>{ item.name }</span> }
        { item.isNew && <InputGroup 
          value={item.name}
          onChange={(evt: any) => this.updateCategoryAssetName(category, item, index, evt.currentTarget.value)}
          style={{ fontSize: 12, height: 28, margin: 0 }}
        /> }
      </div>
      <div className="w-50 ta-r"></div>
      <div className="w-130 ta-r d-f f-r">
        { !item.isNew && <span className="w-100p ta-r">{ priceFormat(item.leasePerMonth || 0) }</span>}
        { item.isNew && <NumericInput 
          className="m-l-24 ai-c"
          buttonPosition="none"
          style={{ width: 68, fontSize: 12, textAlign: 'right', height: 28, margin: 0 }}
          value={item.leasePerMonth}
          onValueChange={(value: number) => this.updateCategoryLeasePerMonth(category, item, index, value, categoryIdx)}
        /> }
        { item.isNew && <div style={{ lineHeight: '28px' }}>€</div> }
      </div>
      <div className="w-100 ta-r d-f f-r">
        { !item.isNew && <span className="w-100p ta-r">{ priceFormat(item.assetPrice || 0) }</span>}
        { item.isNew && <NumericInput 
          className="m-l-24 ai-c"
          buttonPosition="none"
          style={{ width: 68, fontSize: 12, textAlign: 'right', height: 28, margin: 0 }}
          value={item.assetPrice}
          onValueChange={(value: number) => this.updateCategoryAssetPrice(category, item, index, value, categoryIdx)}
        /> }
        { item.isNew && <div style={{ lineHeight: '28px' }}>€</div> }
      </div>
      <div className="w-100 ta-r">{ priceFormat(item.extraCostsPrice || 0) }</div>
      { this.props.editable && <div className="w-72"></div> }
    </FlexRow>
    })
  }

  private renderCategories = () => {
    let categories = this.props.showInvisible ? this.state.categories : this.state.categories.filter(c => c.visible);
    if (this.props.hideEmpty) {
      categories = categories.filter(c => parseFloat(c.leasePerMonth) > 0)
    }
    
    return categories.map((category, index) => {
      const key = `report-category-${this.props.data.quarter}-${category.id}`;
      const isActive = this.state.expanseMap[category.id]; 
      const hasNewAssets = this.hasNewAssets(category);
      const element = <FlexRow className="p-l-24 report-table-cell hoverable clickable" onClick={() => this.toggle(category)}>
        <div className="w-50">
          { <Icon intent="primary" icon={ isActive ? 'chevron-down' : 'chevron-right' }/> }
        </div>
        <div className="f-2">
          <i className={`fas ${category.icon} m-r-12`}  style={{ color: 'white' }}></i>
          { category.name }
        </div>
        <div className="w-50 ta-r">{ category.y }</div>
        <div className="w-130 ta-r">{ priceFormat(category.leasePerMonth) }</div>
        <div className="w-130 ta-r">{ priceFormat(category.assetPrice) }</div>
        <div className="w-150 ta-r">{ priceFormat(category.extraCostsPrice) }</div>
        { this.props.editable && <div className="w-72">
          <Button 
            small
            icon="plus" 
            minimal 
            className="m-l-12" 
            intent="success"
            onClick={(e: any) => this.plus(category, e, index)}
          />
          <Button 
            small 
            icon="minus" 
            minimal 
            className="m-l-12" 
            intent={hasNewAssets ? 'success' : 'none'}
            disabled={!hasNewAssets}
            onClick={(e: any) => this.minus(category, e, index)}
          />
        </div> }
      </FlexRow>

      if (isActive) {
        return <div key={key}>
          { element }
          <div>
            { this.renderAssets(category, index) }
          </div>
        </div>
      } else {
        return <div key={key}>
          { element }
        </div>
      }
    })
  }

  public render() {
    const { t } = this.props;

    return (
      <div style={{ margin: 12, flex: 1, paddingTop: 12, paddingRight: 12 }} className="report-table-container">
        <h4 className="chart">
          {this.getQuarterTitle(this.props.data.quarter)} {this.props.data.year} 
          {/* {this.props.editable ? 
            <span className="m-l-12" style={{ fontSize: 18, color: Colors.GOLD5 }}>{t('budgeting_reports.forecast')}</span> : 
            <span className="m-l-12" style={{ fontSize: 18, color: Colors.GREEN5 }}>{t('budgeting_reports.actual')}</span> } */}
        </h4>
        <FlexRow className="report-table-cell clickable ">
          <div className="w-50"></div>
          <div className="f-2 header">{t('category')}</div>
          <div className="w-50 ta-r header">{t('qty')}</div>
          <div className="w-130 ta-r header">{t('budgeting_reports.lease_per_mo')}</div>
          <div className="w-130 ta-r header">{t('budgeting_reports.asset_price')}</div>
          <div className="w-150 ta-r header">{t('settings.extra_costs')}</div>
          { this.props.editable && <div className="w-72 ta-r header"></div> }
        </FlexRow>
        { this.renderCategories() }
        <FlexRow className="report-table-cell clickable">
          <div className="w-50"></div>
          <div className="f-2 header"><span className='m-l-24'>TOTAL</span></div>
          <div className="w-50 ta-r header"></div>
          <div className="w-130 ta-r" style={{ fontSize: 14 }}>{ priceFormat(this.getLeasePerMoTotal()) }</div>
          <div className="w-130 ta-r" style={{ fontSize: 14 }}>{ priceFormat(this.getAssetPriceTotal()) }</div>
          <div className="w-150 ta-r" style={{ fontSize: 14 }}>{ priceFormat(this.getTotal()) }</div>
          { this.props.editable && <div className="w-72 ta-r header"></div> }
        </FlexRow>
      </div>
    );
  }
}

export default withTranslation()(BudgetQuarter);