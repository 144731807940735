import { Resource, BaseModel, deserializeString, deserializeNumber } from "@patterns/core";
import { Category } from "./category";
import { ExtraService } from "./extra_service";

@Resource('asset_replacements')
export class AssetReplacement extends BaseModel {
  name: string;
  sku: string;
  ean: string;
  image: string;
  price: number;
  total: number;
  replacementDeviceId: string;
  // asset: Asset
  category: Category;
  extraServices: ExtraService[];

  constructor(data: any = {}) {
    super(data);
    this.name =  deserializeString(data.name);
    this.sku =  deserializeString(data.sku);
    this.ean =  deserializeString(data.ean);
    this.image =  deserializeString(data.image);
    this.price =  deserializeNumber(data.price);
    this.total =  deserializeNumber(data.total);
    this.replacementDeviceId = deserializeString(data.replacementDeviceId);
    // this.asset =  new Asset(data.asset || {});
    this.category =  new Category(data.category || {});
    this.extraServices = (data.extraServices || []).map((i: any) => new ExtraService(i));
  }
}
