import { Contact, ContactType } from './contact';
import { Resource } from '@patterns/core';

@Resource('leasing_companies')
export class LeasingCompany extends Contact {
  constructor(data: any = {}) {
    super(data);
    this.type = ContactType.LEASING_COMPANY;
  }
}
