import * as React from 'react';
import { CostCenter } from '../models/cost_center';
import { Contract } from '../models/contract';
import { LeasingCompany } from '../models/leasing_company';
import { Asset } from '../models/asset';
import { axios, monthDiff, priceFormat } from '../common';
import { FlexRow, FlexColumn } from '@patterns/ui';
import { Icon, HTMLTable, Button, ButtonGroup, Tag, InputGroup } from '@blueprintjs/core';
import { AssetSelect, CostCenterSelect, ContractSelect, LeasingCompanySelect } from '../components/selects';
import { Lessee } from '../models/lessee';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DataStore from '../data_store';
import { withTranslation, WithTranslation } from 'react-i18next';
import AssetPreviewDialog from './assets/asset_preview.dialog';
import { AssetRepository } from '../repository';

type CostCenterMap = { [id: string]: Asset[] }
type CostCenterExpandMap = { [id: string]: boolean }
type CostCenterPageMap = { [id: string]: number }

export type ReportItem = {
  costCenter: CostCenter,
  name: string,
  fields: any,
  contract: Contract,
  leasingCompany: LeasingCompany,
  moPayment: number,
  periodPayment: number
}

export type GroupedReportItem = {
  contractCount: number
  assetCount: number
  id: string
  lesseeName: string
  monthlyPMT: number
}

export interface Props extends WithTranslation, RouteComponentProps {
  since: Date
  till: Date
  months: number
}

export interface State {
  selected: ReportItem
  asset: Asset
  costCenter: CostCenter
  costCenterMap: CostCenterMap
  costCenterExpandMap: CostCenterExpandMap
  costCenterPageMap: CostCenterPageMap
  contract: Contract
  items: Asset[]
  leasingCompany: LeasingCompany
  lessee: Lessee
  monthlyPMT?: number
  periodPMT?: number
  serialNumber: string
  total: number
  showItems: number
  monthlySummary: number
  periodSummary: number
  showInvisible: boolean
  showAssetDialog: boolean
  selectedAsset: Asset
}

export class LeasesReport extends React.Component<Props, State> {
  cancelToken: any;

  state = {
    asset: new Asset({}),
    costCenter: new CostCenter({}),
    costCenterMap: {} as CostCenterMap,
    costCenterExpandMap: {} as CostCenterExpandMap,
    costCenterPageMap: {} as CostCenterPageMap,
    contract: new Contract({}),
    items: [] as Asset[],
    leasingCompany: new LeasingCompany({}),
    lessee: new Lessee({}),
    monthlySummary: 0,
    periodSummary: 0,
    total: 0,
    selected: {},
    serialNumber: '',
    showItems: 25,
    showInvisible: false,
    showAssetDialog: false,
    selectedAsset: new Asset({})
  } as State

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.months !== this.props.months) {
      const items = this.state.items;
      // const monthlySummary = items.reduce((sum, item) => sum += parseFloat(item.monthlyPMT as unknown as string), 0);
      const periodSummary = items.reduce((sum, item) => {
        const monthsToContractTill = monthDiff(this.props.since, item.contract.validTill);
        let monthsToTill = monthDiff(this.props.since, this.props.till) + 1;

        if (monthsToTill === 0) {
          monthsToTill = 1
        }

        let months = monthsToTill > monthsToContractTill ? monthsToContractTill : monthsToTill;
        return sum + (parseFloat(item.monthlyPMT as unknown as string)) * months
      }, 0);

      this.setState({ periodSummary })
    }

    if (prevProps.since !== this.props.since || prevProps.till !== this.props.till) {
      this.fetch()
    }
  }

  private fetch = async (page: number = 1) => {
    if (this.cancelToken) {
      this.cancelToken()
    }

    const params = [
      `page=${page}`,
      `pageSize=${25}`,
      `sort=name`,
      `sortDir=ASC`,
      `since=${this.props.since.toISOString()}`,
      `till=${this.props.till.toISOString()}`
    ];

    if (this.state.asset.exists) {
      params.push(`asset_name=${this.state.asset.name}`)
    }

    if (this.state.costCenter.exists) {
      params.push(`cost_center_id=${this.state.costCenter.id}`)
    }

    if (this.state.contract.exists) {
      params.push(`contract_id=${this.state.contract.id}`)
    }

    if (this.state.leasingCompany.exists) {
      params.push(`leasing_company_id=${this.state.leasingCompany.id}`)
    }

    if (this.state.lessee.exists) {
      params.push(`lessee_id=${this.state.lessee.id}`)
    }

    if (this.state.serialNumber.length > 0) {
      params.push(`serial_number=${this.state.serialNumber}`)
    }

    if (this.state.monthlyPMT && this.state.monthlyPMT > 0) {
      params.push(`monthly_pmt=${this.state.monthlyPMT}`)
    }

    if (this.state.periodPMT && this.state.periodPMT > 0) {
      params.push(`period_pmt=${this.state.periodPMT}`)
    }

    const response = await axios.get(`/assets/report?${params.join('&')}`, {
      cancelToken: new (axios as any).CancelToken((token: any) => {
        this.cancelToken = token
      })
    });

    const items = response.data.assets.map((a: any) => new Asset(a)) as Asset[];
    const total = response.data.count;

    const monthlySummary = items.filter(i => (i.monthlyPMT as unknown as string) !== 'NaN').reduce((sum, item) => sum += parseFloat(item.monthlyPMT as unknown as string), 0);

    const periodSummary = items.reduce((sum, item) => {
      const monthsToContractTill = monthDiff(this.props.since, item.contract.validTill);
      let monthsToTill = monthDiff(this.props.since, this.props.till) + 1;

      if (monthsToTill === 0) {
        monthsToTill = 1
      }

      let months = monthsToTill > monthsToContractTill ? monthsToContractTill : monthsToTill;

        return sum + (parseFloat(item.monthlyPMT as unknown as string)) * months
      }, 0);

    this.setState({ periodSummary });

    const costCenterMap = {} as CostCenterMap;
    const costCenterExpandMap = Object.assign({}, this.state.costCenterExpandMap);
    const costCenterPageMap = Object.assign({}, this.state.costCenterPageMap);

    DataStore.costCenters.forEach((costCenter, index) => {
      costCenterMap[costCenter.id] = items.filter(asset => asset.costCenter && asset.costCenter.id === costCenter.id);
      costCenterPageMap[costCenter.id || 'undefined'] = 1;
    });

    costCenterMap['undefined'] = items.filter(asset => !asset.costCenter || !asset.costCenter.exists);
    costCenterPageMap['undefined'] = 1;

    this.setState({
      monthlySummary,
      periodSummary,
      items,
      total,
      costCenterMap,
      costCenterExpandMap,
      costCenterPageMap
    });
  }

  private onAssetSelect = (asset?: Asset) => this.setState({
    asset: asset || new Asset({})
  }, () => this.fetch());

  private onContractSelect = (contract?: Contract) => this.setState({
    contract: contract || new Contract({})
  }, () => this.fetch());

  private onCostCenterSelect = (costCenter?: CostCenter) => this.setState({
    costCenter: costCenter || new CostCenter({})
  }, () => this.fetch());

  private onLeasingCompanySelect = (leasingCompany?: LeasingCompany) => this.setState({
    leasingCompany: leasingCompany || new LeasingCompany({})
  }, () => this.fetch());

  private onSerialNumberChange=  (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
    serialNumber: event.currentTarget.value
  }, () => this.fetch());

  private getItems = (costCenterId: string = 'undefined') => {
    return this.state.items
      .filter(item => item.costCenter.exists ? item.costCenter.id === costCenterId : costCenterId === 'undefined')
  }

  private toggleCollapse = (costCenterId: string) => {
    const map = Object.assign({}, this.state.costCenterExpandMap);
    if (typeof map[costCenterId] === 'undefined') {
      map[costCenterId] = false;
    }
    map[costCenterId] = !map[costCenterId];
    this.setState({ costCenterExpandMap: map })
  }

  private select = async (selected: Asset) => {
    const asset = await AssetRepository.get(selected.id);
    this.setState({
      showAssetDialog: true,
      selectedAsset: asset
    });
}

  private closeAssetDialog = () => this.setState({ showAssetDialog: false })

  private renderItems = () => {
    const { t } = this.props;

    if (Object.keys(this.state.costCenterMap).length === 0) {
      return <tbody/>
    }

    const costCenters = [...DataStore.costCenters];
    const dummy = costCenters.findIndex(c => c.name === 'Unassigned');
    const newDummy = new CostCenter({ name: 'Unassigned', id: 'undefined' });;
    costCenters.splice(dummy, 1, newDummy);

    const rows = costCenters.filter(c => this.state.costCenterMap[c.id].length > 0).map((costCenter, costCenterIdx) => {
      const items = this.getItems(costCenter.id || 'undefined');
      const rows = items
        .filter(asset => this.state.costCenterExpandMap[asset.costCenter.exists ? asset.costCenter.id : 'undefined'])
        .slice(0, this.state.costCenterPageMap[costCenter.id] * 20)
        .map((asset, index) => {
          const monthsToContractTill = monthDiff(this.props.since, asset.contract.validTill);
          let monthsToTill = monthDiff(this.props.since, this.props.till) + 1;

          if (monthsToTill === 0) {
            monthsToTill = 1
          }

          let months = monthsToTill > monthsToContractTill ? monthsToContractTill : monthsToTill;
          let price = parseFloat((asset.monthlyPMT as unknown as string)) * months;

          return <tr key={`leases-reports-asset-${index}`} style={{ cursor: 'pointer' }}>
            <td>
              <span className="m-l-24" onClick={e => this.select(asset)}>
                { asset.name || '' }
              </span>
            </td>
            <td>
              <span>{ asset.fields['serial'] || '' }</span>
            </td>
            <td>
              <span className="" onClick={() => { this.props.history.push(`/contracts/${asset.contract.number}`)}}>
                { asset.contract.number || '' }
              </span>
            </td>
            <td>
              <span>{ asset.contract.leasingCompany.name || '' }</span>
            </td>
            <td>
              <span>{ asset.costCenter.name }</span>
            </td>
            {/* <td className="ta-r">
              <span>{ priceFormat(asset.monthlyPMT) }</span>
            </td> */}
            <td className="ta-r">
              <span>{ priceFormat(price) }</span>
            </td>
          </tr>
        });

      // const monthlyPMTTotal = items.reduce((total, item) =>
      //   total += parseFloat(item.monthlyPMT as unknown as string), 0);

      const priceTotal = items.reduce((total, item) => {
        const monthsToContractTill = monthDiff(this.props.since, item.contract.validTill);
        let monthsToTill = monthDiff(this.props.since, this.props.till) + 1;

        if (monthsToTill === 0) {
          monthsToTill = 1
        }

        let months = monthsToTill > monthsToContractTill ? monthsToContractTill : monthsToTill;
        return total + parseFloat((item.monthlyPMT as unknown as string)) * months;
      }, 0);

      return [
        <tr
          key={`leases-reports-cost-center-row-${costCenterIdx}`}
          style={{ cursor: 'pointer' }}
          className="cost-center-row-expandable"
          onClick={() => this.toggleCollapse(costCenter.id)}>
          <td>
            <FlexRow className="ai-c">
              <div className="d-f f-r jc-s" style={{ width: 74 }}>
                <Tag intent="success" className="m-l-12 m-r-12"round>{this.state.costCenterMap[costCenter.id || 'undefined'].length}</Tag>
              </div>
              {costCenter.name || t('unassigned_cost_center')}
            </FlexRow>
          </td>
          <td>
            <FlexRow className="">
              <Icon icon={this.state.costCenterExpandMap[costCenter.id || 'undefined'] ? 'chevron-down' : 'chevron-right'}/>
            </FlexRow>
          </td>
          <td></td>
          <td></td>
          <td>
          </td>
          {/* <td className="ta-r bold">{priceFormat(monthlyPMTTotal)}</td> */}
          <td className="ta-r bold">{priceFormat(priceTotal)}</td>
        </tr>,
        rows,
        this.state.costCenterExpandMap[costCenter.id || 'undefined'] && <tr key={`leases-report-cost-center-footer-${costCenterIdx}`}>
          <th colSpan={7} className="ta-c" style={{ textAlign: 'center', paddingBottom: 48 }}>
            <ButtonGroup>
              <Button text={t('show_less')} icon="chevron-up" disabled={this.state.costCenterPageMap[costCenter.id || 'undefined'] === 1} className="icon-fix btn-nonbold" onClick={() => {
                const costCenterPageMap = Object.assign({}, this.state.costCenterPageMap);
                if (costCenterPageMap[costCenter.id || 'undefined'] > 1) {
                  costCenterPageMap[costCenter.id || 'undefined']--;
                }
                this.setState({ costCenterPageMap })
              }}/>
              <Button text={t('show_more')} disabled={this.state.showItems === this.getItems().length } rightIcon="chevron-down" className="icon-fix  btn-nonbold" onClick={() => {
                const costCenterPageMap = Object.assign({}, this.state.costCenterPageMap);
                costCenterPageMap[costCenter.id || 'undefined']++;
                this.setState({ costCenterPageMap })
              }}/>
            </ButtonGroup>
          </th>
        </tr>
      ]
    });


    return <tbody>
      { rows }
    </tbody>
  }

  private renderHeader = () => {
    const { t } = this.props;
    return <thead>
      <tr>
        <th style={{ verticalAlign: 'middle' }}>{t('asset')}</th>
        <th style={{ verticalAlign: 'middle' }}>{t('assets.serial_number')}</th>
        <th style={{ verticalAlign: 'middle' }}>{t('contracts.contract_no')}</th>
        <th style={{ verticalAlign: 'middle' }}>{t('leasing_companies.title')}</th>
        <th style={{ verticalAlign: 'middle' }}>{t('cost_center')}</th>
        {/* <th style={{ verticalAlign: 'middle', textAlign: 'right' }}>{t('monthly_pmt')}</th> */}
        <th style={{ verticalAlign: 'middle', textAlign: 'right' }}>{t('lease')}</th>
      </tr>
      <tr className="thin">
        <th>
          <FlexRow flex={1}>
            <AssetSelect
              activeItem={this.state.asset}
              cancelable={true}
              fill={true}
              hint={t('select_asset')}
              outlined={true}
              intent="primary"
              onSelect={this.onAssetSelect}
            />
          </FlexRow>
        </th>
        <th>
          <FlexRow flex={1}>
            <InputGroup
              value={this.state.serialNumber}
              onChange={this.onSerialNumberChange}
              placeholder={t('assets.enter_serial_number')}
            />
          </FlexRow>
        </th>
        <th>
          <FlexRow flex={1}>
            <ContractSelect
              activeItem={this.state.contract}
              cancelable={true}
              fill={true}
              hint={t('select_contract')}
              outlined={true}
              intent="primary"
              onSelect={this.onContractSelect}
            />
          </FlexRow>
        </th>
        <th>
          <FlexRow flex={1}>
            <LeasingCompanySelect
              activeItem={this.state.leasingCompany}
              cancelable={true}
              fill={true}
              hint={t('select_leasing_company')}
              outlined={true}
              intent="primary"
              onSelect={this.onLeasingCompanySelect}
            />
          </FlexRow>
        </th>
        <th>
          <FlexRow flex={1}>
            <CostCenterSelect
              activeItem={this.state.costCenter}
              cancelable={true}
              fill={true}
              hint={t('select_cost_center')}
              outlined={true}
              intent="primary"
              onSelect={this.onCostCenterSelect}
              fetch={async (query: string) => {
                const { data } = await axios.get('/cost_centers?filter_by_leases=true');
                const centers = data.cost_centers.map((i: any) => new CostCenter(i));
                return centers;
              }}
            />
          </FlexRow>
        </th>
        <th></th>
      </tr>
      {/* <tr className="border-top" style={{ height: 48, backgroundColor: 'rgba(24, 55, 81, 0.5)' }}>
        <th colSpan={4} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.5)' }}></th>
        <th colSpan={1} className="ta-r" style={{ verticalAlign: 'middle', height: 48, color: 'white', textAlign: 'right' }}>TOTAL</th>
        <th colSpan={1} className="ta-r" style={{ fontSize: 14, backgroundColor: '#1C4571', verticalAlign: 'middle', textAlign: 'right', height: 48, color: 'white' }}>
          { priceFormat(this.state.monthlySummary) }
        </th>
        <th colSpan={1} className="ta-r" style={{ fontSize: 14, backgroundColor: '#1C4571', verticalAlign: 'middle', textAlign: 'right', height: 48, color: 'white' }}>
          { priceFormat(this.state.periodSummary) }
        </th>
      </tr> */}
    </thead>
  }

  private renderSummary() {
    return <tfoot>
      <tr className="border-top">
        <th colSpan={4}></th>
        <th colSpan={1} className="ta-r" style={{ color: 'white' }}>{this.props.t('total')}</th>
        {/* <th colSpan={1} className="ta-r" style={{ backgroundColor: '#1C4571', color: 'white', fontSize: 14 }}>
          { priceFormat(this.state.monthlySummary) }
        </th> */}
        <th colSpan={1} className="ta-r"  style={{ backgroundColor: '#1C4571', color: 'white', fontSize: 14 }}>
          { priceFormat(this.state.periodSummary) }
        </th>
      </tr>
    </tfoot>
  }


  public render() {
    return (
      <FlexColumn className="f-1 d-f">
        <HTMLTable condensed striped className="leases-table m-b-24">
          { this.renderHeader() }
          { this.renderItems() }
          { this.renderSummary() }
        </HTMLTable>
        <AssetPreviewDialog
          asset={this.state.selectedAsset}
          isOpen={this.state.showAssetDialog}
          onClose={this.closeAssetDialog}
        />
      </FlexColumn>
    );
  }
}

export default withRouter(withTranslation()(LeasesReport))
