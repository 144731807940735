import * as React from 'react';
import { MainLayout } from '../layouts/main';

export interface Props {
}

export default class FinanceReports extends React.Component<Props> {
  public render() {
    return (
      <MainLayout title="Finance Reports">
        
      </MainLayout>
    );
  }
}
