import * as React from 'react';
import { DataTable, Column, ColumnType, FilterStates, Alignment } from '@patterns/datatable';
import { Asset } from '../../models/asset';
import { Toolbar, Notifier } from '@patterns/ui';
import { formatDate } from '@patterns/core';
import { Tag, Button, Intent, Alert } from '@blueprintjs/core';
import { axios } from '../../session';

import ReturnReplacement from './replacement';
import CategoryCell from '../category.cell';
import { priceFormat } from '../../common';
import { withTranslation, WithTranslation } from 'react-i18next';
import EOLStateSelect from '../eol_state_select';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import AssetPreviewDialog from '../assets/asset_preview.dialog';
import { AssetRepository } from '../../repository';

const AssetTable = DataTable.ofType<Asset>();

export interface Props extends RouteComponentProps, WithTranslation {
  reload: () => void
}

export interface State {
  extendAlertOpen: boolean
  reclaimAlertOpen: boolean
  selection: Asset[]
  returnOpen: boolean
  state: string
  showAssetDialog: boolean;
  selectedAsset: Asset;
}

export class EOLManagement extends React.Component<Props, State> {
  state = {
    extendAlertOpen: false,
    reclaimAlertOpen: false,
    selection: [],
    returnOpen: false,
    state: 'extend',
    showAssetDialog: false,
    selectedAsset: new Asset({})
  } as State

  table = React.createRef<DataTable<Asset>>();

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'tag',
        title: t('state'),
        type: ColumnType.Text,
        width: 200,
        visible: true,
        format: asset => {
          const firstQuarterDay = moment().startOf('quarter').toISOString();
          const nextQuarterDay = moment().endOf('quarter').toISOString();
          const nextNextQuarterDay = moment().add(1, 'quarter').endOf('quarter').toISOString();

          asset.bindings.forEach(binding => {
            // console.log('bindings for', asset.name, 'till', binding.extensionTill)
          });

          const hasBinding = asset.bindings.find(b => moment(b.createdAt).startOf('quarter').toISOString() === firstQuarterDay);
          const hasNextBinding = asset.bindings.find(b => {
            const endOfExtension = moment(b.extensionTill).endOf('quarter').toISOString();
            return endOfExtension === nextQuarterDay || endOfExtension === nextNextQuarterDay
          });

          // console.log(asset.name, 'state', asset.state, 'hasbinding', hasBinding, 'hasNextBinding', hasNextBinding, 'firstQuarterDay', firstQuarterDay, 'nextQuarterDay', nextQuarterDay);

          if (asset.state === 'extended' && typeof hasNextBinding !== 'undefined') {
            return  <Tag intent="success" className='m-r-12'>{t('to_be_extended')}</Tag>
          }

          if (asset.state === 'reclaimed') {
            return  <Tag intent="primary" className='m-r-12'>{t('to_be_reclaimed')}</Tag>
          }

          if (asset.state === 'free' || (asset.state === 'extended' && typeof hasNextBinding === 'undefined')) {
            return  <Tag intent="danger" className='m-r-12'>{t('waiting')}</Tag>
          }

          if (asset.state === 'returned') {
            return  <Tag intent="warning" className='m-r-12'>{t('to_be_returned')}</Tag>
          }

          return <span>{ asset.state }</span>
        }
      },
      {
        id: 'name',
        title: t('name'),
        type: ColumnType.Text,
        flex: 2,
        visible: true,
        format: asset => <span>{ asset.name }</span>
      },
      {
        id: 'category',
        title: t('category'),
        type: ColumnType.Text,
        flex: 1,
        visible: true,
        format: asset => <CategoryCell category={asset.category}/>
      },
      {
        id: 'serial',
        title: t('assets.serial_number'),
        type: ColumnType.Text,
        flex: 1,
        visible: true,
        format: asset => <span>{ asset.serialNumber }</span>
      },
      {
        id: 'employee',
        title: t('user'),
        type: ColumnType.Text,
        flex: 1,
        visible: true,
        format: asset => <span>{ asset.employee.name }</span>
      },
      {
        id: 'pickupPlace',
        title: t('asset_returns.pick_up_place'),
        type: ColumnType.Text,
        flex: 1,
        visible: true,
        format: asset => <span>{ asset.pickupPlace?.name }</span>
      },
      // {
      //   id: 'lessee',
      //   title: t('lessee'),
      //   type: ColumnType.Text,
      //   width: 150,
      //   visible: true,
      //   format: asset => <span>{ asset.contract.lessee.name }</span>
      // },
      {
        id: 'end_date',
        title: t('contracts.end_date'),
        type: ColumnType.Text,
        width: 130,
        visible: true,
        format: asset => {
          // if (asset.contract.validTill.getFullYear() === 1990) {
          //   return <span/>
          // }

          return <span>{ asset.contract.exists ? formatDate(moment().endOf('quarter').toDate()) : t('ending_leases.no_contract') }</span>
        }
      },
      // {
      //   id: 'contract_eol',
      //   title: t('contract_eol'),
      //   type: ColumnType.Text,
      //   width: 130,
      //   visible: true,
      //   format: asset => <Tag intent="danger" minimal>{t('not_selected')}</Tag>
      // },
      // {
      //   id: 'price',
      //   title: t('price'),
      //   type: ColumnType.Text,
      //   alignment: Alignment.Right,
      //   width: 130,
      //   // visible: this.state.state === 'extend',
      //   visible: false,
      //   headerClass: 'p-r-12 ta-r',
      //   format: asset => {
      //     let price = 0;
      //     let suffix = '';

      //     if (asset.category) {
      //       if (this.state.state === 'reclaim') {
      //         price = asset.reclaimPrice
      //       }

      //       if (this.state.state === 'extend') {
      //         price = (asset.category.extendedLeasingPrice / 100) * asset.purchasePrice;
      //         suffix = this.props.t('per_month');
      //       }

      //       if (this.state.state === 'return') {
      //         price = (asset.category.residualValue / 100) * asset.purchasePrice
      //       }
      //     }

      //     return <span>{ priceFormat(price) } {suffix}</span>
      //   }
      // },
      {
        id: 'purchase_price',
        title: t('assets.purchase_price'),
        type: ColumnType.Text,
        alignment: Alignment.Right,
        width: 150,
        visible: true,
        headerClass: 'p-r-12 ta-r',
        format: asset => {
          let price = 0;
          let suffix = '';

          if (asset.category) {
            price = (asset.category.reclaimPrice / 100) * asset.purchasePrice
          }

          return <span>{ priceFormat(price) } {suffix}</span>
        }
      },
      {
        id: 'extension_price',
        title: t('assets.extension_price'),
        type: ColumnType.Text,
        alignment: Alignment.Right,
        width: 180,
        visible: true,
        headerClass: 'p-r-12 ta-r',
        format: asset => {
          let price = 0;
          let suffix = '';

          if (asset.category) {
            price = (asset.category.extendedLeasingPrice / 100) * asset.monthlyPMT;
          }

          return <span>{ priceFormat(price) } {suffix}</span>
        }
      },
      // {
      //   id: 'state',
      //   title: t('state'),
      //   type: ColumnType.Text,
      //   flex: 1,
      //   visible: false,
      //   format: asset => <Tag minimal>{ asset.state }</Tag>
      // },
      {
        id: 'time_left',
        title: t('time_to_take_action'),
        type: ColumnType.Text,
        width: 130,
        visible: true,
        format: asset => {
          const a = moment().startOf('day');
          const b = moment().endOf('quarter').subtract(1, 'month');
          const daysLeft = a.diff(b, 'days') * -1;
          const intent = daysLeft < 30 ? 'danger' : (daysLeft > 60 ? 'success' : 'warning');
          const text = daysLeft < 0 ? 'TIME-SLOT FOR ACTIONS EXCEEDED' : daysLeft;

          // const months = asset.contract.remainingMonths;
          // let intent = 'success' as Intent;
          // if (months < 2) {
          //   intent = 'danger'
          // } else if (months >= 2 && months <= 3) {
          //   intent = "warning"
          // }
          // const now = new Date();

          // const actionDate = asset.contract.validTill > now ?
          //   moment(asset.contract.validTill).subtract(1, 'month').endOf('month').toDate() :
          //   moment(asset.contract.validTill).add(3, 'months').subtract(1, 'month').endOf('month').toDate();

          // const daysLeft = diffDays(actionDate, new Date());
          // console.log('contract validtill', asset.contract.validTill, 'actionDate', actionDate, 'days left', daysLeft)
          return <Tag intent={intent} minimal>{ text } { t('days') }</Tag>
        }
      }
    ] as Column<Asset>[]
  }

  private fetch = async (page: number = 0, pageSize: number = 50, sort: string, sortDir: string, filters: FilterStates) => {
    let params = [
      `page=${page}`,
      `pageSize=${pageSize}`,
      `sort=${sort}`,
      `sortDir=${sortDir.toUpperCase()}`,
    ].join('&')

    const response = await axios.get(`/assets/eol?${params}`);
    const assets = response.data.assets.map((a: any) => new Asset(a));
    return { items: assets, total: response.data.count }
  }

  private onSelectionChange = (selection: Asset[]) => this.setState({ selection })

  private action = () => {
    const limitDate = moment().endOf('quarter').subtract(1, 'month').toDate().getTime();
    const now = new Date().getTime();

    if (now < limitDate) {
      if (this.state.state === 'extend') {
        this.extend()
      } else if (this.state.state === 'return') {
        this.return()
      } else if (this.state.state === 'reclaim') {
        this.reclaim()
      }
    } else {
      alert("Selection can only be made up to 1 month before the end of quarter");
    }
  }

  private extend = () => this.setState({ extendAlertOpen: true }, () => this.props.reload())

  private reclaim = () => this.setState({ reclaimAlertOpen: true }, () => this.props.reload())

  private return = () => this.setState({ returnOpen: true }, () => this.props.reload())

  private close = () => this.setState({ returnOpen: false }, () => {
    this.props.reload();
    this.table.current?.fetch();
  })

  private onExtendAlertCancel = () => this.setState({ extendAlertOpen: false })

  private onReclaimlertCancel = () => this.setState({ reclaimAlertOpen: false })

  private confirmExtend = async () => {
    try {
      await axios.post(`/assets/extend`, this.state.selection.map(a => a.id));
      this.setState({ extendAlertOpen: false });
      Notifier.success(this.props.t('ending_leases.extend_success'));
      this.props.reload()
      this.table.current?.fetch();
    } catch (e) {
      Notifier.failure(this.props.t('ending_leases.extend_failure'))
    }
  }

  private confirmReclaim = async () => {
    try {
      await axios.post(`/assets/reclaim`, this.state.selection.map(a => a.id));
      this.setState({ reclaimAlertOpen: false });
      Notifier.success(this.props.t('ending_leases.reclaim_success'));
      this.props.reload();
      this.table.current?.fetch();
    } catch (e) {
      Notifier.failure(this.props.t('ending_leases.reclaim_failure'));
    }
  }

  private onStateChange = (state: string) => {
    this.setState({ state })
  }

  private select = async (selected: Asset) => {
    const asset = await AssetRepository.get(selected.id);
    this.setState({
      showAssetDialog: true,
      selectedAsset: asset
    });
  }

  private closeAssetDialog = () => this.setState({ showAssetDialog: false });

  public render() {
    const { t } = this.props;
    const selection = this.state.selection.length;

    return (
      <div className="m-24">
        <Toolbar className="table-toolbar">
          {/* <div className="f-2">
            <span className="bold">{t('ending_leases.title')}</span>
          </div> */}

          { selection === 0 && <span className="m-l-12 m-r-24 bold">{t('ending_leases.selection_none')}</span> }
          { selection > 0 && <span className="m-l-12 m-r-24 bold">{t('ending_leases.selected')} { selection } {t('assets.title')}</span> }

          <EOLStateSelect
            option={this.state.state}
            onSelect={this.onStateChange}
          />

          <Button
            minimal disabled={selection === 0}
            text={t('apply')}
            icon="double-chevron-right"
            intent="success"
            className="m-r-12"
            onClick={this.action}
          />

          {/* <Button
            minimal
            disabled={selection === 0}
            text={t('ending_leases.reclaim')}
            icon="refresh"
            intent="warning"
            className="m-r-12"
            onClick={this.reclaim}
          />
          <Button
            minimal
            disabled={selection === 0}
            text={t('ending_leases.return')}
            icon="double-chevron-left"
            intent="danger"
            onClick={this.return}
          /> */}
        </Toolbar>

        <AssetTable
          id="eol-assets-table"
          ref={this.table}
          columns={this.getColumns()}
          fetch={this.fetch}
          onItemSelect={this.select}
          onSelectionChange={this.onSelectionChange}
          multiple={true}
          sort="name"
          sortDir="asc"
          showSelection={false}
        />

        <ReturnReplacement
          selection={this.state.selection}
          isOpen={this.state.returnOpen}
          onClose={this.close}
        />

        <Alert
          cancelButtonText={t('cancel')}
          confirmButtonText={t('ending_leases.extend')}
          icon="tick"
          intent={Intent.SUCCESS}
          isOpen={this.state.extendAlertOpen}
          onCancel={this.onExtendAlertCancel}
          onConfirm={this.confirmExtend}>
          <p>{t('ending_leases.extend_confirm')}</p>
        </Alert>

        <Alert
          cancelButtonText={t('cancel')}
          confirmButtonText={t('ending_leases.reclaim')}
          icon="tick"
          intent={Intent.SUCCESS}
          isOpen={this.state.reclaimAlertOpen}
          onCancel={this.onReclaimlertCancel}
          onConfirm={this.confirmReclaim}>
          <p>{t('ending_leases.reclaim_confirm')}</p>
        </Alert>

        <AssetPreviewDialog
          asset={this.state.selectedAsset}
          isOpen={this.state.showAssetDialog}
          onClose={this.closeAssetDialog}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation()(EOLManagement))
