import * as React from 'react';
import { FlexRow } from '@patterns/ui';

export interface Props {
  classKey: string
  className?: string
  classValue: string
  flex?: number
  label: string | React.ReactElement
  value: string | React.ReactElement
}

export function KeyValueView({ label, className, value, flex, classKey, classValue }: Props) {
  const _className = [ 'key-value-view', className, flex  ].filter(f => f).join(' ');

  return (
    <FlexRow className={_className}>
      <div className={classKey}>
        { label }
      </div>
      <div className={`${classValue} value`}>
        { value }
      </div>
    </FlexRow>
  );
}
