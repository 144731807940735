import * as React from 'react';
import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@patterns/datatable/build/data-table.css';
import '@patterns/ui/build/main.css';
import '@patterns/ui/build/mini.css';
import '@patterns/form/build/form.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import './App.scss';

import { DataTableGlobal } from '@patterns/datatable';
import { parseDate, formatDate } from '@patterns/core';

import Login from './pages/login';
import { Main } from './pages/main';
import { LoadingBar } from './components/loading_bar';
import { AppContextProvider } from './app_context';
import { User } from './models/user';
import { Session, session } from './session';
import { I18nextProvider } from "react-i18next";
import i18n from './i18n';
import { Locale, LocaleManager } from '@patterns/locale';

import en from './i18n/en.json'
import fi from './i18n/fi.json'
import { getQueryVariable } from './common';

DataTableGlobal.parseDate = parseDate;
DataTableGlobal.formatDate = formatDate;

const lang = window.localStorage.getItem('i18nextLng') || 'en';
LocaleManager.instance.setLocale('en', (en as unknown as any).patterns as Locale);
LocaleManager.instance.setLocale('fi', (fi as unknown as any).patterns as Locale);
if (['en', 'fi'].includes(lang)) {
  LocaleManager.instance.setLocale(lang);
} else {
  LocaleManager.instance.setLocale('fi');
}

(window as any).localeManager= LocaleManager.instance;

export interface Props {

}

export interface State {
  loggedIn: boolean
  session: Session<User>
}

export default class App extends React.Component<Props, State> {
  state = {
    loggedIn: session.loggedIn,
    session
  } as State

  componentDidMount() {
    (window as any).session = session;
    const paramLang = getQueryVariable('lang');
    if (paramLang) {
      console.log('paramLang changing lang to', paramLang);
      i18n.changeLanguage(paramLang)
    }
    this.restore()
  }

  componentWillUnmount() {
    session.cancelAll(this.onSessionChange)
  }

  private onSessionChange = () => {
    this.setState({ session, loggedIn: session.loggedIn })
  }

  private restore = async () => {
    session.observeAll(this.onSessionChange);
    await session.restore();
    this.setState({ loggedIn: session.loggedIn });
  }

  private onLogin = () => this.setState({ loggedIn: true })
  private onLogout = () => this.setState({ loggedIn: false })

  public render() {
    return (
      <div className='app-container'>
        <I18nextProvider i18n={i18n}>
          <AppContextProvider>
            { !this.state.loggedIn && <Login onLogin={this.onLogin} /> }
            { this.state.loggedIn && <Main onLogout={this.onLogout}/> }
          </AppContextProvider>
          <LoadingBar />
        </I18nextProvider>
      </div>
    );
  }
}
