import { BaseModel, Selectable, Resource, deserializeString } from '@patterns/core'

@Resource('employees')
export class Employee extends BaseModel implements Selectable {
  name: string
  classification: string

  constructor(data: any = {}) {
    super(data);
    this.name = deserializeString(data.name);
    this.classification = deserializeString(data.classification);
  }

  getLabel() { return '' }
  getTitle() { return this.name }
}