import { ApiRepository } from './api_repository'
import { User } from './models/user'
import { AcceptanceTemplate } from './models/acceptance_template'
import { Asset } from './models/asset'
import { Contact } from './models/contact'
import { Category } from './models/category'
import { CostCenter } from './models/cost_center'
import { Contract } from './models/contract'
import { Employee } from './models/employee'
import { Organization } from './models/organization'
import { Supplier } from './models/supplier'
import { LeasingCompany } from './models/leasing_company'
import { Lessee } from './models/lessee'
import { Product } from './models/product'
import { PurchaseInvoice } from './models/purchase_invoice'
import { PurchaseInvoiceAttachment } from './models/purchase_invoice_attachment'
import { Device } from './models/device'
import { ReplacementDevice } from './models/replacement_device'
import { ExtraCost } from './models/extra_cost'
import { ContractExtraCost } from './models/contract_extra_cost'
import { PriceList } from './models/price_list'
import { ExtraService } from './models/extra_service'
import { Office } from './models/office'
import { Also } from './models/also'
import { Proposal } from './models/proposal'
import { SiemensPricelist } from './models/siemens_pricelist'
import { PickupPlace } from './models/pickup_place'

export const AcceptanceTemplateRepository = new ApiRepository<AcceptanceTemplate>(AcceptanceTemplate);
export const AlsoRepository = new ApiRepository<Also>(Also);
export const AssetRepository = new ApiRepository<Asset>(Asset);
export const CategoryRepository = new ApiRepository<Category>(Category);
export const ContactRepository = new ApiRepository<Contact>(Contact);
export const ContractRepository = new ApiRepository<Contract>(Contract);
export const ContractExtraCostRepository = new ApiRepository<ContractExtraCost>(ContractExtraCost);
export const CostCenterRepository = new ApiRepository<CostCenter>(CostCenter);
export const DeviceRepository = new ApiRepository<Device>(Device);
export const EmployeeRepository = new ApiRepository<Employee>(Employee);
export const ExtraCostRepository = new ApiRepository<ExtraCost>(ExtraCost);
export const ExtraServiceRepository = new ApiRepository<ExtraService>(ExtraService);
export const LeasingCompanyRepository = new ApiRepository<LeasingCompany>(LeasingCompany);
export const LesseeRepository = new ApiRepository<Lessee>(Lessee);
export const OfficeRepository = new ApiRepository<Office>(Office);
export const PickupPlaceRepository = new ApiRepository<PickupPlace>(PickupPlace);
export const PriceListRepository = new ApiRepository<PriceList>(PriceList);
export const ProductRepository = new ApiRepository<Product>(Product);
export const ProposalRepository = new ApiRepository<Proposal>(Proposal);
export const PurchaseInvoiceRepository = new ApiRepository<PurchaseInvoice>(PurchaseInvoice);
export const PurchaseInvoiceAttachmentRepository = new ApiRepository<PurchaseInvoice>(PurchaseInvoiceAttachment);
export const ReplacementDeviceRepository = new ApiRepository<ReplacementDevice>(ReplacementDevice);
export const SupplierRepository = new ApiRepository<Supplier>(Supplier);
export const SiemensPricelistRepository = new ApiRepository<SiemensPricelist>(SiemensPricelist);
export const OrganizationRepository = new ApiRepository<Organization>(Organization);
export const UserRepository = new ApiRepository<User>(User);
