import * as React from 'react';
import { Suggest } from '@blueprintjs/select'
import { Selectable, Constructable } from '@patterns/core'
import { MenuItem, IconName, Intent } from '@blueprintjs/core';

export interface RemoteSuggestProps<T> {
  activeItem: T
  axios?: any
  disabled?: boolean
  cancelable?: boolean
  emptyTitle?: string
  fetch?: (query: string) => Promise<T[]>
  fill?: boolean
  hint?: string
  icon?: IconName
  klass?: Constructable
  minimal?: boolean
  outlined?: boolean
  intent?: Intent
  onSelect: (item: T) => void
}

export interface State<T> {
  activeItem: T
  items: T[]
  query: string
}

export class RemoteSuggest<T extends Selectable> extends React.Component<RemoteSuggestProps<T>, State<T>> {
  private Suggest = Suggest.ofType<T>();
  private cancelToken: any
  
  state = {
    activeItem: new this.props.klass!({}) as T,
    items: [],
    query: ''
  }

  componentDidMount() {
    this.setState({ 
      activeItem: this.props.activeItem,
      query: this.props.activeItem.getTitle()
    }, this.fetch)
  }

  componentDidUpdate(prevProps: RemoteSuggestProps<T>) {
    const { activeItem } = this.props;
    if (activeItem.id !== this.state.activeItem.id) {
      this.setState({ activeItem, query: activeItem.getTitle() }, () => {
        // console.log('remote suggest did update active item', activeItem);
      })
    }
  }

  public static ofType<T extends Selectable>() {
    return RemoteSuggest as new (props: RemoteSuggestProps<T>) => RemoteSuggest<T>
  }
  
  private propFetch = async (query: string) => {
    if (!this.props.fetch) {
      return
    }

    const items = await this.props.fetch(query);
    this.setState({ items })
  }

  private localFetch = async (_query: string) => {
    if (!this.props.klass) {
      console.error('Patterns RemoteSuggest => unable to perform localFetch, klass prop not specified');
      return
    }

    if (!this.props.axios) {
      console.error('Patterns RemoteSuggest => unable to perform localFetch, axios prop not specified');
      return
    }
    
    const Klass = this.props.klass;
    const resource = Klass.resource;

    if (!resource || resource.length === 0) {
      console.error('Patterns RemoteSuggest => unable to perform localFetch, resource is not set');
      return
    }
    
    const query = _query.trim().toLowerCase();

    if (this.cancelToken) {
      this.cancelToken();
    }

    const CancelToken = this.props.axios.CancelToken;

    try {
      const response = await this.props.axios.get(`/${resource}/search?query=${query}`, {
        cancelToken: new CancelToken((token: any) => {
          this.cancelToken = token
        })
      });

      const items = response.data[resource].map((i: any) => new Klass(i)) as T[];
      this.setState({ items })
    } catch (e) {
      if (!this.props.axios.isCancel(e)) {
        throw e
      }
    }
  }

  private fetch = () => {
    if (this.props.fetch) {
      this.propFetch(this.state.query);
      return
    }

    if (this.props.klass) {
      this.localFetch(this.state.query);
      return
    }
  }
  private onQueryChange = (query: string) => {
    this.setState({ query }, () => {
      this.fetch()
    });
  }

  private cancel = () => {
    if (!this.props.klass) {
      return
    }

    const Klass = this.props.klass;
    this.props.onSelect(new Klass({}) as T);
  }

  private inputValueRenderer = (item: T) => item.getTitle()

  private onActiveItemChange = (item: T | null) => {
    // console.log('on active item change', item);
    if (item && item.id !== this.state.activeItem.id) {
      this.setState({ activeItem: item })
    }
  }

  private itemRenderer = (item: T, options: any) => <MenuItem
    key={`remote-select-item-${item.id}`}
    text={item.getTitle()}
    label={item.getLabel() || ''}
    onClick={options.handleClick}
    active={item.id === this.state.activeItem.id}
  />

  public render() {
    // const query = this.state.query.length > 0 ? this.state.query : (activeItem.exists ? activeItem.getTitle() : '');
    // const items = [] as T[];
    
    // if (this.props.klass) {
    //   const Klass = this.props.klass;
    //   items.push(new Klass({}) as T)
    // }

    // items.push(...this.state.items);

    // console.log('selected item / activeItem', this.state.activeItem);
    // const items = this.props.emptyTitle ? [new this.props.klass!({ name: this.props.emptyTitle}), ...this.state.items] : this.state.items;

    return (
      <this.Suggest
        disabled={this.props.disabled}
        selectedItem={this.state.activeItem}
        items={this.state.items as T[]}
        itemRenderer={this.itemRenderer}
        inputValueRenderer={this.inputValueRenderer}
        onItemSelect={this.props.onSelect}
        onActiveItemChange={this.onActiveItemChange}
        onQueryChange={this.onQueryChange}>
        {/* <Button
          outlined={this.props.outlined}
          minimal={this.props.minimal}
          fill={this.props.fill}
          icon={this.props.cancelable && this.state.activeItem.exists ? 'chevron-down' : this.props.icon }
          // rightIcon="chevron-down"
          rightIcon={(this.props.cancelable && this.props.activeItem.exists) ?
            <Icon
              icon="cross"
              intent="warning"
              onClick={this.cancel}
            /> :
            <Icon
              icon="chevron-down"
              intent={this.props.intent || (this.state.activeItem.exists ? 'primary' : 'none')}
              onClick={this.cancel}
            /> 
          }
          text={text}
          intent={this.state.activeItem.exists ? 'success' : (this.props.intent || 'none')}
        /> */}
      </this.Suggest>
    );
  }
}
