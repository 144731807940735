import * as React from 'react';
import { Dialog, Classes, Button, MenuItem, FormGroup, InputGroup, NumericInput, ControlGroup } from '@blueprintjs/core';
import { Form, FormField, FormFieldType } from '@patterns/form';
import { ExtraCost, ExtraCostItem } from '../../models/extra_cost';
import { deepEqual } from '@patterns/core';
import { ExtraCostRepository } from '../../repository';
import produce from 'immer';
import { SaveButton, ConfirmButton, FlexRow, Notifier } from '@patterns/ui';
import { Suggest } from '@blueprintjs/select';
import { axios } from '../../session';

const TypeSuggest = Suggest.ofType<string>();

const ExtraCostForm = Form.ofType<ExtraCost>();

const fields = [
  {
    id: 'title',
    label: 'Title',
    type: FormFieldType.Text
  }
  // {
  //   id: 'price',
  //   label: 'Price',
  //   type: FormFieldType.Number
  // }
] as FormField<ExtraCost>[]

export interface Props {
  isOpen: boolean
  onClose: (changed: boolean) => void
  onDelete: (device: ExtraCost) => void
  extraCost: ExtraCost
}

export interface State {
  attachment: string
  isUploading: boolean
  extraCost: ExtraCost
  query: string
  types: string[]
  items: ExtraCostItem[]
}

export class ExtraCostsDialog extends React.Component<Props, State> {
  cancelToken: any

  constructor(props: Props) {
    super(props)
    this.state = {
      attachment: '',
      isUploading: false,
      extraCost: props.extraCost,
      query: props.extraCost.type,
      types: [ props.extraCost.type ],
      items: props.extraCost.items
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.extraCost, prevProps.extraCost)) {
      this.setState({
        attachment: '',
        extraCost: this.props.extraCost,
        query: this.props.extraCost.type,
        types: [ this.props.extraCost.type ],
        items: this.props.extraCost.items
      })
    }
  }

  private delete = async () => this.props.onDelete(this.state.extraCost)

  private onChange = (device: ExtraCost) => this.setState(produce(this.state, state => {
    state.extraCost = device
  }))

  private onClose = () => this.props.onClose(false)

  private save = async () => {
    const extraCost = produce(this.state.extraCost, extraCost => {
      extraCost.type = this.state.query;
      extraCost.items = this.state.items;
    })
    await ExtraCostRepository.save(extraCost);
    Notifier.success('Extra Cost was successfully saved')
    this.props.onClose(true)
  }

  private onQueryChange = (query: string) => this.setState({ query }, async () => {
    const response = await axios.get(`/extra_costs/search_types?query=${query}`);
    const { types } = response.data;
    this.setState({ types })
  })

  private onTypeSelect = (item: string) => this.setState({ query: item })

  private inputValueRenderer = (value: string) => value

  private renderer = (item: string, options: any) => <MenuItem
    key={`type-suggest-item-${item}`}
    text={item}
    onClick={options.handleClick}
  />

  private newItemRenderer = (options: any) => <MenuItem
    intent="success"
    text={<span>Create: <strong>{this.state.query}</strong></span>} 
    onClick={options.handleClick}
  />

  private createFromQuery = (query: string) => query

  private addItem = () => {
    const items = produce(this.state.items, items => {
      items.push({
        label_en: '',
        label_fi: '',
        price: 0
      })
    })
    this.setState({ items })
  }

  private renderItems = () => {
    return this.state.items.map((item, index) => <FlexRow key={`extra-cost-item-${index}`}>
      <FormGroup inline={true} className="f-1">
        <InputGroup value={item.label_en} placeholder="Label EN" onChange={(evt: any) => {
          const items = produce(this.state.items, items => {
            const item = items[index];
            item.label_en = evt.currentTarget.value;
          })
          this.setState({ items })
        }}/>
      </FormGroup>
      <FormGroup inline={true} className="f-1 m-l-6 m-r-6">
        <InputGroup value={item.label_fi} placeholder="Label FI" onChange={(evt: any) => {
          const items = produce(this.state.items, items => {
            const item = items[index];
            item.label_fi = evt.currentTarget.value;
          })
          this.setState({ items })
        }}/>
      </FormGroup>
      <ControlGroup className="f-1 d-f">
        <FormGroup inline={true} className="f-1">
          <NumericInput 
            style={{ textAlign: 'right' }} 
            rightElement={
              <div className="d-f jc-c ai-c p-t-6 p-r-2 m-l-6">€ / month</div>
            } 
            fill={true} 
            placeholder="Price" 
            buttonPosition="none" 
            value={item.price} 
            onValueChange={value => {
              const items = produce(this.state.items, items => {
                const item = items[index];
                item.price = value;
              })
              this.setState({ items })
            }}
          />
        </FormGroup>
      </ControlGroup>

      <div className="jc-c ai-c m-l-12">
        <Button icon="trash" minimal intent="danger" onClick={() => {
          const items = produce(this.state.items, items => {
            items.splice(index, 1);
          })
          this.setState({ items })
        }}/>
      </div>
    </FlexRow>)
  }

  public render() {
    return (
      <Dialog canOutsideClickClose={false} title="Extra Cost" isOpen={this.props.isOpen} onClose={this.onClose} style={{ width: 657 }} className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <ExtraCostForm
            inline={true}
            fill={true}
            item={this.state.extraCost}
            fields={fields}
            onChange={this.onChange}
          />
          <div className="patterns-form">
            <FormGroup inline={true} label="Type">
              <TypeSuggest
                fill={true}
                query={this.state.query}
                onItemSelect={this.onTypeSelect}
                onQueryChange={this.onQueryChange}
                items={this.state.types}
                itemRenderer={this.renderer}
                inputValueRenderer={this.inputValueRenderer}
                createNewItemRenderer={this.newItemRenderer}
                createNewItemFromQuery={this.createFromQuery}
              />
            </FormGroup>
          </div>
          <div className="d-f f-c">
            { this.renderItems( )}
            <FlexRow>
              <Button minimal intent="primary" icon="plus" text="Add Row" onClick={this.addItem} className="m-l-6"/>
            </FlexRow>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <FlexRow flex={1}>
              <ConfirmButton
                title="Delete"
                confirmTitle="Confirm Delete"
                onConfirm={this.delete}
              />
            </FlexRow>
            <Button text="Cancel" onClick={this.onClose}/>
            <SaveButton onClick={this.save}/>
          </div>
        </div>
      </Dialog>
    );
  }
}
