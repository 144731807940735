import * as React from 'react';
import { FlexRow } from '@patterns/ui';
import { Navbar, Alignment } from '@blueprintjs/core';
import logoBlack from '../assets/leasex_logo_cut_black.png'

export interface Props {
  title: string
  gradient?: boolean
}

export function SimpleLayout({ children, title, gradient }: React.PropsWithChildren<Props>) {
  const [ className, setClassName ] = React.useState(gradient ? 'content-full content-full-gradient f-1' : 'content-full f-1');

  React.useEffect(() => {
    if (gradient) {
      setClassName('content-full content-full-gradient f-1')
    }
  }, [gradient])

  return (
    <React.Fragment>
      <Navbar
        className="navbar"
        style={{ display: 'flex' }}>
        <Navbar.Group align={Alignment.LEFT} style={{ flex: 1 }}>
          <Navbar.Heading style={{ width: 260 }}>
            <div className="navbar-logo"><img alt="logo" src={logoBlack}/></div>
          </Navbar.Heading>
        </Navbar.Group>
      </Navbar>

      <FlexRow>
        <div className={className}>
          { children }
        </div>
      </FlexRow>
    </React.Fragment>
  )
}
