import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FinancePieWidget } from '../finance_pie.chart';

export interface Props extends WithTranslation {
  height: number
  width: number
  legend: boolean
  params?: string
}

export function ContractCostCentersChart({ height, width, legend, t, params  }: Props) {
  let url = "/contracts/cost_centers_chart_future";

  if (params) {
    url += "?" + params;
  }

  return <FinancePieWidget
    width={width}
    height={height}
    url={url}
    title={t('charts.monthly_leasing_per_cost_center')}
    legend={legend}
  />
}

export default withTranslation()(ContractCostCentersChart)
