import { HTMLTable } from '@blueprintjs/core';
import produce from 'immer';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { priceFormat } from '../../common';
import { Asset } from '../../models/asset';
import { Contract } from '../../models/contract';
import { AssetRepository, ContractRepository } from '../../repository';
import AssetDialog from '../assets/asset_dialog';
import { PurchaseInvoice } from '../../models/purchase_invoice';
import pdfIcon from '../../assets/icon-pdf.svg';
import { session } from '../../session';
import { download } from '@patterns/core';
import { FlexRow } from '@patterns/ui';

export interface Props extends WithTranslation {
  contract: Contract
  onChange: () => void
  onInvoiceSelect: (invoice: PurchaseInvoice) => void
}

export interface State {
  contract: Contract
  selected: Asset
  showDialog: boolean
}

export class ContractAssets extends React.Component<Props, State> {
  state = {
    contract: new Contract({}),
    selected: new Asset({}),
    showDialog: false
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.contract.id !== this.props.contract.id) {
      this.fetch()
    }
  }

  private fetch = async () => {
    if (!this.props.contract.exists) {
      return
    }

    const contract = await ContractRepository.get(this.props.contract.id);
    this.setState({ contract })
  }

  private addAsset = () => {
    const asset = new Asset({ contract: this.state.contract })
    const contract = produce(this.state.contract, contract => {
      contract.assets.splice(0, 0, asset);
    });
    this.setState({ contract, selected: asset, showDialog: true })
  }

  private select = (selected: Asset) => {
    this.setState({ selected, showDialog: true })
  }

  private onClose = () => {
    this.setState({ showDialog: false }, () => this.fetch())
  }

  private onDelete = async (asset: Asset) => {
    await AssetRepository.delete(asset.id);
    this.fetch();
    this.setState({showDialog: false});
  }

  private renderAsset = (asset: Asset) => <tr key={`contract-asset-${asset.id}`} onClick={() => this.select(asset)}>
    <td className="bold">{ asset.name }</td>
    <td>{ this.props.i18n.language === 'fi' ? asset.category?.name_fi :  asset.category?.name_en }</td>
    <td>{ asset.employee?.name }</td>
    <td>{ asset.serialNumber }</td>
    <td className="ta-r">{ priceFormat(asset.purchasePrice) }</td>
    <td>
      <FlexRow className='ai-c'>
        <span className='m-r-12'>{asset.purchaseInvoice.code }</span>
        { asset.purchaseInvoice.exists && asset.purchaseInvoice.attachments.length > 0 && <img 
          src={pdfIcon} 
          alt="pdf" 
          style={{ height: 28 }}
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            window.open(`${window.location.origin}/api/purchase_invoice_attachments/${asset.purchaseInvoice.attachments[0].id}?&token=${session.token}`, '_blank')
          }}
        /> }
      </FlexRow>
    </td>
  </tr>
  
  public render() {
    const { t } = this.props;
    return (
      <div className="contract-assets-list">
        <HTMLTable condensed className="contract-assets-table">
          <thead>
            <tr>
              <th>{t('name')}</th>
              <th>{t('category')}</th>
              <th>{t('user')}</th>
              <th>{t('assets.serial_number')}</th>
              <th className="ta-r">{t('assets.purchase_price')}</th>
              <th className="ta-r">{t('purchase_invoices.one')}</th>
            </tr>
          </thead>
          <tbody>
            { this.state.contract.assets.map(asset => this.renderAsset(asset)) }
          </tbody>
        </HTMLTable>
        {/* <Button text={t('assets.add_asset')} icon="plus" intent="primary" className="m-12" onClick={this.addAsset}/> */}

        <AssetDialog 
          asset={this.state.selected}
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onDelete={this.onDelete}
        />
      </div>
    );
  }
}

export default withTranslation()(ContractAssets)