import { Contact, ContactType } from './contact';
import { Resource } from '@patterns/core';

@Resource('suppliers')
export class Supplier extends Contact {
  constructor(data: any = {}) {
    super(data);
    this.type = ContactType.SUPPLIER;
  }
}
