import { BaseModel, deserializeString, Resource, Selectable, deserializeDate } from "@patterns/core";
import { User } from "./user";
import { Contract } from "./contract";
import { PurchaseInvoiceAttachment } from "./purchase_invoice_attachment";
import { SiemensAsset } from "../siemens.manager";
import { Supplier } from "./supplier";

export type PurchaseInvoiceType = 'waiting' | 'leased';

@Resource('purchase_invoices')
export class PurchaseInvoice extends BaseModel implements Selectable {
  createdBy: User
  contract: Contract
  supplier: Supplier
  state: PurchaseInvoiceType
  title: string
  code: string
  date: Date | null
  acceptedAt: Date | null
  isAccepted: boolean
  isRejected: boolean
  rejectionReason: string
  total: number
  assets: SiemensAsset[]
  attachments: PurchaseInvoiceAttachment[]

  constructor(data: any = {}) {
    super(data);
    this.createdBy = data.createdBy ? new User(data.createdBy) : new User({});
    this.contract = data.contract ? new Contract(data.contract) : new Contract({});
    this.supplier = data.supplier ? new Supplier(data.supplier) : new Supplier({});;
    this.state = data.state || 'waiting';
    this.title = deserializeString(data.title);
    this.code = deserializeString(data.code);
    this.date = data.date ? deserializeDate(data.date) : null;
    this.acceptedAt = data.acceptedAt ? deserializeDate(data.acceptedAt) : null;
    this.isAccepted = data.isAccepted || false;
    this.isRejected = typeof data.isRejected !== undefined ? data.isRejected : false;
    this.rejectionReason = data.rejectionReason || '';
    this.total = parseFloat(data.total || 0);
    this.assets = data.assets || [];
    this.attachments = data.attachments ? 
      data.attachments.map((attachment: any) => new PurchaseInvoiceAttachment(attachment)) : 
      [];
  }

  getTitle() {
    return this.title
  }

  getLabel() {
    return this.code
  }
}