import * as React from 'react';
import { Button, FileInput, NonIdealState, Spinner, Dialog, NumericInput, MenuItem, FormGroup } from '@blueprintjs/core';
import { FlexColumn, FlexRow, Notifier } from '@patterns/ui';
import { Form, FormField, FormFieldType } from '@patterns/form';
import { deepEqual, convertFileToBase64 } from '@patterns/core';
import { axios, precisionRound } from '../../common';
import { PurchaseInvoiceRepository, PurchaseInvoiceAttachmentRepository } from '../../repository';
import { PurchaseInvoice } from '../../models/purchase_invoice';
import { PurchaseInvoiceAttachment } from '../../models/purchase_invoice_attachment';
import { PurchaseInvoiceAttachmentView } from './attachment';
import AssetListBuilder from '../contracts/asset_list_builder';
import { SiemensAsset } from '../../siemens.manager';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ConfirmButton } from '../confirm_button';
import { Select } from '@blueprintjs/select';
import { session } from '../../session';
import { SupplierSelect } from '../selects';
const StateSelect = Select.ofType<string>();
const InvoiceForm = Form.ofType<PurchaseInvoice>();

const StateItems = [ 'accepted', 'rejected', 'waiting'];

export interface Props extends WithTranslation {
  isOpen: boolean
  invoice: PurchaseInvoice
  onClose: () => void
}

export interface State {
  attachment?: string
  assets: SiemensAsset[]
  invoice: PurchaseInvoice
  isUploading: boolean
  isLoading: boolean
  changed: boolean
  total: string
  file?: any
  data?: string
}

export class PurchaseOrderDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      invoice: props.invoice,
      assets: props.invoice.assets,
      isUploading: false,
      isLoading: true,
      changed: false,
      total: props.invoice.total.toString()
    }

    console.log('invoice total set to', props.invoice.total.toString());
  }

  componentDidUpdate(prevProps: Props) {
    // console.log('invoice', this.props.invoice);
    if (!deepEqual(this.props.invoice, prevProps.invoice)) {
      if (this.props.invoice.exists) {
        this.setState({
          assets: [],
          file: undefined,
          data: undefined,
          invoice: new PurchaseInvoice({}),
          isLoading: false,
          total: this.props.invoice.total.toString()
        }, () => {
          console.log('invoice total updated to', this.props.invoice.total.toString());
          this.fetch()
        })
      } else {
        this.setState({
          assets: [],
          file: undefined,
          data: undefined,
          invoice: new PurchaseInvoice({}),
          isLoading: false,
          total: '0'
        });
        console.log('invoice total updated to 0, invoice does not exist');
      }
    }
  }

  private reset = () => this.setState({
    assets: [],
    file: undefined,
    data: undefined,
    invoice: new PurchaseInvoice({}),
    isLoading: false
  })

  private getFields = () => {
    const { t } = this.props;
    const fields = [
      // {
      //   id: 'title',
      //   label: t('supplier'),
      //   type: FormFieldType.Text,
      //   visible: true
      // },
      {
        id: 'title',
        label: t('supplier'),
        type: FormFieldType.Custom,
        visible: true,
        customElement: (item, onChange) => {
          return <SupplierSelect
            activeItem={item.supplier}
            onSelect={supplier => {
              const invoice = new PurchaseInvoice(item);
              invoice.supplier = supplier;
              onChange(invoice);
            }}
          />
        }
      },
      {
        id: 'code',
        label: t('invoice_number'),
        type: FormFieldType.Text,
        visible: true
      },
      {
        id: 'date',
        label: t('purchase_invoices.invoice_date'),
        type: FormFieldType.Date,
        visible: true
      },
      {
        id: 'total',
        label: t('total'),
        type: FormFieldType.Custom,
        visible: true,
        customElement: (invoice, onChange) => {
          const diff = precisionRound(this.state.invoice.total - this.getAssetsValue(), 2);
          return <FlexRow>
            <NumericInput
              value={(this.state.total || '').toString().replace('.', ',')}
              buttonPosition="none"
              locale="fi"
              style={{ width: 100, textAlign: 'right' }}
              onValueChange={(number, string) => {
                this.setState({
                  total: string
                }, () => {
                  const updated = new PurchaseInvoice(invoice);
                  updated.total = parseFloat(this.state.total.replace(',', '.'));
                  onChange(updated);;
                })
              }}
              selectAllOnFocus={true}
            />
            <FormGroup inline={true} label={t('difference')}>
              <NumericInput
                fill={true}
                className='m-l-12'
                value={diff}
                locale="fi"
                style={{ width: 100, textAlign: 'right' }}
                readOnly={true}
                contentEditable={false}
                buttonPosition='none'
              />
            </FormGroup>

          </FlexRow>
        }
      }
    ] as FormField<PurchaseInvoice>[]

    if (session.user.isAdmin) {
      fields.push({
        id: 'state',
        label: t('purchase_invoices.state'),
        type: FormFieldType.Custom,
        visible: true,
        customElement: (item, onChange) => {
          return <StateSelect
            items={StateItems}
            activeItem={item.state}
            itemRenderer={(item, options) => <MenuItem
              text={item}
              onClick={options.handleClick}
            />}
            onItemSelect={() => {

            }}
          >
            <Button
              key={`menu-item-${item}`}
              text={item.state}
              rightIcon="chevron-down"
            />
          </StateSelect>
        }
      })
    }

    return fields
  }

  private onChange = (invoice: PurchaseInvoice) => this.setState({ invoice, changed: true })

  private fetch = async () => this.setState({ isLoading: true }, async () => {
    const invoice = await PurchaseInvoiceRepository.get(this.props.invoice.id);
    this.setState({
      assets: invoice.assets,
      invoice,
      isLoading: false,
      total: invoice.total.toString()
    })
  })

  private upload = async (data: string, fileName: string, size: number, mimeType: string, lastModified: number) =>
    this.setState({ isUploading: true }, async () => {
      await axios.put(`/purchase_invoice_attachments/${this.state.invoice.id}`, {
        data,
        fileName,
        lastModified,
        size,
        mimeType,
        invoice: this.state.invoice
      });

      const invoice = await PurchaseInvoiceRepository.get(this.state.invoice.id);
      this.setState({ isUploading: false, invoice })
    })

  private save = async () => {
    this.state.invoice.assets = this.state.assets;
    const data = await PurchaseInvoiceRepository.save(new PurchaseInvoice(this.state.invoice));

    const _invoice = new PurchaseInvoice(data);
    const invoice = await PurchaseInvoiceRepository.get(_invoice.id)
    this.setState({ invoice, changed: false }, async () => {
      if (this.state.file) {
        await this.upload(this.state.data!, this.state.file.name, this.state.file.size, this.state.file.type, this.state.file.lastModified);
      }
      this.props.onClose();
      Notifier.success('Purchase Invoice was successfully saved');
    })
  }

  private delete = async () => {
    if (this.state.invoice.attachments.length > 0) {
      await PurchaseInvoiceAttachmentRepository.delete(this.state.invoice.attachments[0].id)
    }

    await PurchaseInvoiceRepository.delete(this.state.invoice.id);
    this.props.onClose();
    Notifier.success('Purchase Invoice was successfully deleted');
  }

  private deleteAttachment = async (attachment: PurchaseInvoiceAttachment) => {
    this.setState({ isUploading: true }, async () => {
      await PurchaseInvoiceAttachmentRepository.delete(attachment.id);
      const invoice = await PurchaseInvoiceRepository.get(this.state.invoice.id)
      this.setState({ isUploading: false, invoice })
    })
  }

  private getAssetsValue = () => {
    return precisionRound(this.state.assets.reduce((total, item) => total += precisionRound(parseFloat(item.totalValue), 2), 0), 2);
  }

  private onAssetListChange = async (assets: SiemensAsset[]) => {
    if (!deepEqual(this.state.assets, assets)) {
      this.setState({ assets, changed: true })
    }
  }

  private isValid = () => {
    const assetsValue = this.getAssetsValue();
    const valueMatch = assetsValue === precisionRound(this.state.invoice.total, 2);

    // console.log('assetsValue', assetsValue,  'invoice total', this.state.invoice.total, 'state total', this.state.total, 'valueMatch', valueMatch);

    let validAssets = true;

    this.state.assets.forEach(asset => {
      if (asset.description.length === 0) {
        validAssets = false;
      }
      if (typeof asset.assetTypeId === 'undefined' || asset.assetTypeId === 0) {
        validAssets = false;
      }
      if (typeof asset.assetSubTypeId === 'undefined' || asset.assetSubTypeId === 0) {
        validAssets = false;
      }
      if (typeof asset.valuePerAsset === 'undefined' || asset.valuePerAsset === '' || asset.valuePerAsset === '0') {
        validAssets = false;
      }
    });

    const datePresent = this.state.invoice.date !== null;

    const hasAttachment = this.props.invoice.exists || (this.state.data && this.state.file);
    console.log('valueMatch && this.state.changed && validAssets && hasAttachment');
    console.log(valueMatch, this.state.changed, validAssets, hasAttachment);
    return valueMatch && this.state.changed && validAssets && hasAttachment && datePresent;
  }

  private renderAttachments = () => this.state.invoice.attachments.map((attachment, index) =>
    <PurchaseInvoiceAttachmentView
      key={`attachment-row-${index}`}
      attachment={attachment}
      onDelete={this.deleteAttachment}
    />)

  public render() {
    const { t } = this.props;
    const assetsValue = precisionRound(this.getAssetsValue(), 2);

    let attachmentText = t('purchase_invoices.add_attachment');

    if (this.state.invoice.attachments.length === 1) {
      attachmentText = this.state.invoice.attachments[0].name;
    }

    if (this.state.file) {
      attachmentText = this.state.file.name
    }


    return <Dialog
      className="bp3-dark"
      style={{ width: 1600 }}
      isOpen={this.props.isOpen}
      onClose={this.props.onClose}
      canOutsideClickClose={false}
      title={`${t('purchase_invoices.one')} ${this.state.invoice.code}`}>

      { this.state.isLoading && <NonIdealState description={t('purchase_invoices.loading') as string}>
        <Spinner size={48} intent="success" />
      </NonIdealState> }

      { !this.state.isLoading && <FlexColumn className="p-24">
        <div style={{ height: 242 }}>
          <InvoiceForm
            fields={this.getFields()}
            item={this.state.invoice}
            onChange={this.onChange}
            inline={true}
            fill={true}
            className="purchase-invoice-form"
          />
        </div>

        <AssetListBuilder assets={this.state.assets} onChange={this.onAssetListChange} simple={true} showPurchasePool={false} invoiceTotal={this.state.invoice.total}/>

        { assetsValue > this.state.invoice.total && <div className="m-24 bold red">
          {t('purchase_invoices.higher_asset_value')}
        </div> }

        { assetsValue < this.state.invoice.total && <div className="m-24 bold red">
          {t('purchase_invoices.lower_asset_value')}
        </div> }

        { assetsValue === this.state.invoice.total && <div className="m-24 bold green">
          {t('purchase_invoices.matching_asset_value')}
        </div> }

        {/*
       { !this.state.invoice.exists && <NonIdealState
          description={t('purchase_invoices.attachments_availability') as string}
        />} */}

        { (this.state.invoice.attachments.length !== 1) && <FileInput
          className="attachment-input m-b-12"
          text={attachmentText}
          inputProps={{
            accept: ".pdf",
            multiple: false
          }}
          hasSelection={this.state.file && this.state.file.name}
          onInputChange={async value => {
            if (value.currentTarget.files) {
              const file = value.currentTarget.files[0];
              const data = await convertFileToBase64(file);
              this.setState({ file, data, changed: true })
            }
          }}
        /> }

        { this.state.isUploading && <NonIdealState title={t('patterns.please_wait')} description={t('uploading_attachment') as string}>
          <Spinner />
        </NonIdealState>}

        <FlexColumn className="attachments-container">
          { !this.state.isUploading && this.renderAttachments() }
        </FlexColumn>

        { this.state.invoice.exists && <div className="w-130 m-t-24 m-b-24">
          <ConfirmButton
            title={t('delete')}
            confirmTitle={t('confirm')}
            onConfirm={this.delete}
            icon="trash"
          />
        </div> }

        <Button
          disabled={!this.isValid()}
          intent={ this.isValid() ? 'success' : 'none' }
          text={t('purchase_invoices.done')}
          onClick={this.save}
          className="m-b-24"
        />
      </FlexColumn>  }
    </Dialog>
  }
}

export default withTranslation()(PurchaseOrderDetail);
