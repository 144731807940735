import { ColumnType } from "@patterns/datatable";

export type CustomFormFieldType = 'text' | 'textarea' | 'number' | 'date' | 'checkbox' | 'switch';

export type CustomFormField = {
  inline: boolean
  label_en: string
  label_fi: string
  key: string
  type: CustomFormFieldType
  list: boolean
}

export const CustomFormFieldTypeMap = {
  text: ColumnType.Text,
  textarea: ColumnType.Text,
  number: ColumnType.Number,
  date: ColumnType.Date,
  checkbox: ColumnType.Custom,
  switch: ColumnType.Custom
} as { [id: string]: ColumnType}
