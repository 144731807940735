import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PieWidget } from '../pie_widget';

export interface Props extends WithTranslation {
  height: number
  width: number
  legend?: boolean
}

export function OperatingSystemsChart({ height, legend, width, t }: Props) {
  return (
    <PieWidget 
      title={t('charts.devices_per_operating_system')}
      url="/devices/operating_system_chart"
      legend={legend}
      width={width}
      height={height}
    />
  );
}

export default withTranslation()(OperatingSystemsChart)
