import { BaseModel, Selectable, Resource, deserializeString, deserializeNumber } from '@patterns/core'

@Resource('contract_extra_costs')
export class ContractExtraCost extends BaseModel implements Selectable {
  title: string
  price: number

  constructor(data: any = {}) {
    super(data);
    this.title = deserializeString(data.title);
    this.price = deserializeNumber(data.price);
  }

  getLabel() { return null }
  getTitle() { return this.id }
}