import { Button, FormGroup, InputGroup, NonIdealState, Spinner } from '@blueprintjs/core';
import { deepEqual } from '@patterns/core';
import { FlexRow } from '@patterns/ui';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Lessee } from '../../models/lessee';
import { axios } from '../../session';
import { SiemensProposal } from './calculation.form';

export type SiemensCustomer = {
  name: string;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  countryCode: string;
  businessPartner?: {
    registrationNumber: string;
    companyType: string;
    name?: string;
    officialAddress?: any;
  },
  businessPartnerRef?: {
    registrationNumber: string;
    companyType: string;
  },
  invoiceContact: {
    name?: string;
    email?: string;
    phone?: string;
  },
  invoiceAddress: {
    address1?: string;
    address2?: string;
    city?: string;
    phoneNumber?: string;
    postalCode?: string
  }
}

export interface Props extends WithTranslation {
  lessee: Lessee
  proposal: SiemensProposal;
  onChange: (customer: SiemensCustomer) => void;
}

export interface State {
  city: string;
  contactName: string;
  contactEmail: string;
  customerIdx: number;
  customers: SiemensCustomer[];
  isSearching: boolean
  name: string;
  postCode: string;
  registrationNumber: string;
  selected?: SiemensCustomer;
  telephone: string
}

export class SiemensCustomerForm extends React.Component<Props, State> {
  state = {
    city: '',
    contactEmail: '',
    contactName: '',
    customerIdx: -1,
    customers: [] as SiemensCustomer[],
    isSearching: false,
    name: '',
    postCode: '',
    registrationNumber: '',
    telephone: ''
  } as State

  componentDidMount() {
    this.mapState({} as Props)
    this.setState({
      registrationNumber: this.props.lessee.businessID
    }, () => {
      this.search()
    })
  }

  componentDidUpdate(prevProps: Props) {
    this.mapState(prevProps)
  }

  getRegistrationNumber = (customer: SiemensCustomer) => {
    return customer.businessPartnerRef ? customer.businessPartnerRef.registrationNumber : (customer.businessPartner?.registrationNumber || '')
  }

  private mapState = (prevProps: Props) => {
    if (this.props.proposal && !deepEqual(prevProps.proposal, this.props.proposal) && this.props.proposal.customer) {
      const customer = this.props.proposal.customer as any;
      this.setState({
        city: customer.businessPartner?.officialAddress.city || '',
        contactEmail: customer.invoiceContact?.email || '',
        contactName: customer.invoiceContact?.name || '',
        name: customer.businessPartner.name || '',
        postCode: customer.businessPartner.officialAddress.postalCode || '',
        registrationNumber: this.getRegistrationNumber(customer) || '',
        telephone: customer.businessPartner.officialAddress.phoneNumber || '',
      })
    }
  }

  private search = async () => {
    this.setState({ isSearching: true }, async () => {
      const params = [
        `registrationNumber=${this.state.registrationNumber}`,
        `name=${this.state.name}`,
        `city=${this.state.city}`,
        `postCode=${this.state.postCode}`,
        `telephone=${this.state.telephone}`,
      ].join('&');
      
      const results = await axios.get(`/siemens/customer-search?${params}`, {
        timeout: 30000
      });
  
      this.setState({
        customers: results.data.customers as SiemensCustomer[] || [],
        isSearching: false
      })
    })
  }

  private select = (customer: SiemensCustomer, idx: number) => {
    this.setState({ 
      customerIdx: idx, 
      selected: customer,
      city: customer.city,
      name: customer.name,
      postCode: customer.postalCode,
      registrationNumber: this.getRegistrationNumber(customer)
    });
    this.props.onChange(customer);
  }

  private onRegistrationNumberChange = (evt: React.FormEvent<HTMLInputElement>) => this.setState({ 
    registrationNumber: evt.currentTarget.value 
  })

  private onNameChange = (evt: React.FormEvent<HTMLInputElement>) => this.setState({ 
    name: evt.currentTarget.value
  })

  private onCityChange = (evt: React.FormEvent<HTMLInputElement>) => this.setState({ 
    city: evt.currentTarget.value
  })

  private onPostCodeChange = (evt: React.FormEvent<HTMLInputElement>) => this.setState({ 
    postCode: evt.currentTarget.value
  })

  private onTelephoneChange = (evt: React.FormEvent<HTMLInputElement>) => this.setState({ 
    telephone: evt.currentTarget.value
  })

  private onContactNameChange = (evt: React.FormEvent<HTMLInputElement>) => this.setState({ 
    contactName: evt.currentTarget.value
  })

  private onContactEmailChange = (evt: React.FormEvent<HTMLInputElement>) => this.setState({ 
    contactEmail: evt.currentTarget.value
  })

  private renderCustomers = () => {
    const { t } = this.props;
    if (this.state.isSearching) {
      return <NonIdealState>
        <Spinner />
      </NonIdealState>
    }

    if (this.state.customers.length === 0) {
      return <NonIdealState description={t('no_results') as string}/>
    }

    const children = this.state.customers.map((customer, idx) => <FlexRow
      key={`customer-search-${idx}`}
      className={this.state.customerIdx === idx ? 'customer-row active' : 'customer-row'}
      onClick={() => this.select(customer, idx)}>
      <div className="f-1">{ this.getRegistrationNumber(customer) }</div>
      <div className="f-1">{ customer.name }</div>
      <div className="f-1">{ [ customer.address1, customer.address2].filter(f => f && f.length > 0).join(', ') }</div>
      <div className="f-1">{ customer.postalCode }</div>
      <div className="f-1">{ customer.city }</div>
    </FlexRow>);

    return <div className="customer-table">
      <h3>{t('select_customer')}</h3>
      <FlexRow className="customer-row">
        <div className="f-1 header">{t('registration_number')}</div>
        <div className="f-1 header">{t('name')}</div>
        <div className="f-1 header">{t('address')}</div>
        <div className="f-1 header">{t('post_code')}</div>
        <div className="f-1 header">{t('city')}</div>
      </FlexRow>
      { children }
    </div>
  }

  public render() {
    const { t } = this.props;
    return (
      <div>
        <h2>{t('customer')}</h2>
        <FlexRow>
          <div className="f-1">
            <FormGroup inline={true} label={t('registration_number')}>
              <InputGroup value={this.state.registrationNumber} onChange={this.onRegistrationNumberChange}/>
            </FormGroup>
          </div>
          <div className="f-1">
            <FormGroup inline={true} label={t('customer_name')}>
              <InputGroup value={this.state.name} onChange={this.onNameChange}/>
            </FormGroup>
          </div>
        </FlexRow>
        <FlexRow>
          <div className="f-1">
            <FormGroup inline={true} label={t('city')}>
              <InputGroup value={this.state.city} onChange={this.onCityChange}/>
            </FormGroup>
          </div>
          <div className="f-1">
            <FormGroup inline={true} label={t('postCode')}>
              <InputGroup value={this.state.postCode} onChange={this.onPostCodeChange}/>
            </FormGroup>
          </div>
        </FlexRow>
        <FlexRow className="ai-c jc-e">
          <div className="f-1">
            <FormGroup inline={true} label={t('phone')}>
              <InputGroup value={this.state.telephone} onChange={this.onTelephoneChange}/>
            </FormGroup>
          </div>
          <div className="f-1"></div>
        </FlexRow>
        <FlexRow className="separator-bottom">
          <div className="f-1">
            <FormGroup inline={true} label={t('contact_name')}>
              <InputGroup value={this.state.contactName} onChange={this.onContactNameChange}/>
            </FormGroup>
          </div>
          <div className="f-1">
            <FormGroup inline={true} label={t('contact_email')}>
              <InputGroup value={this.state.contactEmail} onChange={this.onContactEmailChange}/>
            </FormGroup>
          </div>
        </FlexRow>

        { !this.props.proposal.customer && <React.Fragment>
          <FlexRow className="separator-bottom">
            <Button
              disabled={this.state.name.length < 3 && this.state.registrationNumber.length < 3}
              intent="primary"
              text={t('search_customers')}
              icon="search"
              className="m-0"
              onClick={this.search}
            />
          </FlexRow>

          <div className="results separator-bottom">
            { this.renderCustomers() }
          </div>
        </React.Fragment> }
      </div>
    );
  }
}

export default withTranslation()(SiemensCustomerForm)