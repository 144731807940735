import * as React from 'react';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import { SiemensPricelistsDialog } from './siemens_pricelist.dialog';
import { SiemensPricelistRepository } from '../../repository';
import { SiemensPricelist } from '../../models/siemens_pricelist';

import { Notifier, Toolbar } from '@patterns/ui';
import { Button } from '@blueprintjs/core';
import SiemensManagerInstance from '../../siemens.manager';

const SiemensPricelistsTable = DataTable.ofType<SiemensPricelist>();

const columns = [
  {
    id: 'type_id',
    title: 'Type',
    type: ColumnType.Text,
    sortable: true,
    filterable: true,
    alignment: Alignment.Left,
    width: 80,
    visible: false,
    format: siemensPricelist => {
      const type = SiemensManagerInstance.getType(siemensPricelist.type_id)
      if (type) {
        return <span>{ type.name }</span>
      } else {
        return <span/>
      }
    }  },
  {
    id: 'name',
    title: 'Subtype',
    type: ColumnType.Text,
    alignment: Alignment.Left,
    flex: 1,
    visible: true,
    format: siemensPricelist => {
      const subtype = SiemensManagerInstance.getSubType(siemensPricelist.subtype_id)
      if (subtype) {
        return <span>{ subtype.name }</span>
      } else {
        return <span/>
      }
    }
  },
  {
    id: 'residualValue',
    title: 'Residual Value %',
    type: ColumnType.Text,
    alignment: Alignment.Left,
    width: 150,
    visible: true,
    format: siemensPricelist => <span>{ parseFloat(siemensPricelist.residualValue.toString()).toFixed(2) }%</span>
  }
] as Column<SiemensPricelist>[]

export interface Props {
}

export interface State {
  selected: SiemensPricelist
  showDialog: boolean
}

export class SiemensPricelists extends React.Component<Props, State> {
  cancelToken: any;

  state = {
    selected: new SiemensPricelist({}),
    showDialog: false
  } as State

  tableRef = React.createRef<any>();

  private create = async () => {
    this.setState({
      selected: new SiemensPricelist({}),
      showDialog: true
    })
  }

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await SiemensPricelistRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken
    );
    this.cancelToken = cancelToken;
    return { items, total }
  }

  private onSelect = (selected: SiemensPricelist) => this.setState({ 
    selected,
    showDialog: true 
  })

  private onDelete = async (siemensPricelist: SiemensPricelist) => {
    await SiemensPricelistRepository.delete(siemensPricelist.id);
    Notifier.success('SiemensPricelist was successfully removed');
    this.setState({ showDialog: false });
    this.tableRef.current?.fetch();
  }

  private onClose = () => {
    this.setState({ showDialog: false });
    this.tableRef.current?.fetch();
  }

  public render() {
    return (
      <div className="m-l-24 m-r-24">
        <Toolbar>
          <div className="f-1">
            <span className="bold m-r-24 p-l-24">Siemens Pricelist</span>
          </div>
          <div className="f-1 jc-e ai-c d-f">
            <Button intent="primary" text="Add Item" icon="plus" onClick={this.create}/>
          </div>
        </Toolbar>
        
        <SiemensPricelistsTable
          id="siemenes-pricelist-table"
          ref={this.tableRef}
          columns={columns}
          fetch={this.fetch}
          sort="id"
          sortDir="asc"
          onItemSelect={this.onSelect}
        />

        <SiemensPricelistsDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onDelete={this.onDelete}
          siemensPricelist={this.state.selected}
        />
      </div>
    );
  }
}
