import React from 'react';
import { Icon, Navbar, MenuItem, Alignment, Button } from '@blueprintjs/core';
import { FlexColumn, FlexRow } from '@patterns/ui';
import "moment/locale/sk";

import { AppContext } from '../app_context';
import { User } from '../models/user';
import { LesseeSelect, OrganizationSelect } from './selects';
import { Organization } from '../models/organization';
import logoBlack from '../assets/leasex_logo_cut_black.png'
import { Lessee } from '../models/lessee';
import { session } from '../session';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  title: string
  titleElement?: React.ReactElement
}

interface State {
  lessee: Lessee
}

export class NavBar extends React.Component<Props, State> {
  state = {
    lessee: new Lessee()
  }

  static contextType = AppContext;

  componentDidMount() {
    session.observeAll(this.onSessionEvent);
  }

  componentWillUnmount() {
    session.cancelAll(this.onSessionEvent);
  }

  onSessionEvent = () => {
    const lessee = new Lessee({
      id: session.lesseeId,
      name: session.lesseeName
    });
    this.setState({ lessee })
  }

  onLesseeSelect = (lessee: Lessee) => {
    session.setLessee(lessee.id, lessee.name);
    window.location.reload();
  }

  userRenderer(user: User, options: any) {
    return <MenuItem
      key={user.id || 'new-user'}
      id={user.id}
      text={user.name}
      icon={<Icon icon="user"/>}
      onClick={options.handleClick}
    />
  }

  setEn = () => {
    this.props.i18n.changeLanguage('en');
    (window as any).localeManager.setLocale('en');
  }

  setFi = () => {
    this.props.i18n.changeLanguage('fi');
    (window as any).localeManager.setLocale('fi');
  }

  render() {
    if (!session.loggedIn) {
      return null
    }
    const { t } = this.props;

    return <Navbar
      className="navbar"
      style={{ display: 'flex' }}>
      <Navbar.Group align={Alignment.LEFT} style={{ flex: 3 }}>
        <Navbar.Heading style={{ width: 260 }}>
          <div className="navbar-logo"><img alt="logo" src={logoBlack}/></div>
        </Navbar.Heading>
        <div className="d-f f-1 ai-c">
          {/* <FormGroup inline={true} className="m-b-0 m-r-24">
            <InputGroup
              placeholder={t('search_placeholder')}
              defaultValue=""
              rightElement={<Icon icon="search" color={Colors.GRAY5}/>}
            />
          </FormGroup> */}
          {/* <Button intent="success" icon="small-plus" text={t('navbar.add_new')} minimal/> */}
        </div>
      </Navbar.Group>
      
      <Navbar.Group align={Alignment.RIGHT}  style={{ flex: 1, justifyContent: 'flex-end' }}>
        <FlexRow className="jc-e ai-c">

          <FlexColumn className="m-t-12">
            <span style={{ lineHeight: 0.5, marginLeft: 10 }}>{ t('lessee') }</span>
            <LesseeSelect
              activeItem={this.state.lessee}
              cancelable={true}
              hint={t('navbar.select_lessee')}
              minimal={true}
              onSelect={this.onLesseeSelect}
            />
          </FlexColumn>

          <FlexColumn className="m-t-12 p-l-12 p-r-12">
            <span style={{ lineHeight: 0.5, }}>{ t('corporation') }</span>
            { session.user.isAdmin && <OrganizationSelect
              activeItem={session.user.organization || new Organization({})}
              cancelable={false}
              hint={t('navbar.select_organization')}
              minimal={true}
              onSelect={async organization => {
                await session.control(organization.id);
                window.location.reload();
              }}
            /> }
            { !session.user.isAdmin && session.user.organization.name }
          </FlexColumn>
          <Button
            minimal
            disabled={this.props.i18n.language === 'en'}
            text="en"
            onClick={this.setEn}
          />
          <Button 
            minimal
            disabled={this.props.i18n.language === 'fi'}
            text="fi"
            onClick={this.setFi}
          />
        </FlexRow>
      </Navbar.Group>
    </Navbar>
  }
}

export default withTranslation()(NavBar)