import * as React from 'react';
import { FlexRow, Notifier, Toolbar } from '@patterns/ui';
import { CostCentersView } from '../cost_centers/cost_centers_table';
import { CostCentersTree } from '../cost_centers/cost_centers_tree';
import { CostCenter } from '../../models/cost_center';
import { CostCenterDialog } from '../cost_centers/cost_center_dialog';
import { CostCenterRepository } from '../../repository';
import { Button } from '@blueprintjs/core';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {}

export interface State {
  costCenterFilter?: CostCenter
  selected: CostCenter
  showDialog: boolean
}

export class CostCenters extends React.Component<Props, State> {
  state = {
    selected: new CostCenter({}),
    showDialog: false
  } as State

  table = React.createRef<CostCentersView>();
  tree = React.createRef<CostCentersTree>();

  private reload = () => {
    this.table.current?.tableRef.current?.fetch();
    this.tree.current?.fetch();
  }

  private create = async () => {
    this.setState({ selected: new CostCenter({}), showDialog: true })
  }

  private onClose = () => this.setState({ showDialog: false })

  private onSave = async (costCenter: CostCenter) => {
    await CostCenterRepository.save(costCenter);
    this.setState({
      showDialog: false
    });
    this.reload();
    Notifier.success(this.props.t('settings.cost_center_save_success'))
  }

  private onDelete = async (costCenter: CostCenter) => {
    await CostCenterRepository.delete(costCenter.id);
    this.setState({ showDialog: false })
    this.reload()
  }

  private onSelect = async (selected: CostCenter) => {
    this.setState({ selected, showDialog: true })
  }

  public render() {
    return <FlexRow flex={1}>
      <div className="f-4 m-l-24 m-r-24">
        <Toolbar>
          <div className="f-1 jc-s ai-c d-f m-l-12">
            <Button intent="primary" text={this.props.t('settings.add_cost_center')} icon="plus" onClick={this.create}/>
          </div>
        </Toolbar>

        <CostCentersView 
          ref={this.table}
          onSelect={this.onSelect}
        />
      </div>

      <CostCenterDialog
        isOpen={this.state.showDialog}
        onClose={this.onClose}
        onSave={this.onSave}
        onDelete={this.onDelete}
        costCenter={this.state.selected}
      />
    </FlexRow>
  }
}

export default withTranslation()(CostCenters)