import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Breadcrumbs } from '../components/breadcrumb';
import ReturnedContractAssets from '../components/eol/returned';
import { MainLayout } from '../layouts/main';

export interface Props extends WithTranslation {};

export function ReturnedAssets(props: Props) {
  const t = props.t;
  
  return (
    <MainLayout title={t('asset_returns.title')}>
      <Breadcrumbs path="End of Lease / Asset Returns" title={t('asset_returns.title')}/>
      <ReturnedContractAssets />
    </MainLayout>
  )
}

export default withTranslation()(ReturnedAssets)