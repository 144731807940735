import { BaseModel, deserializeString, Selectable, Resource, deserializeDate, deserializeNumber } from '@patterns/core'
import { Asset } from './asset';
import { CustomFormField } from './custom_form_field';
import { ExtraCost } from './extra_cost';
import { CategoryExtraCost } from './category_extra_cost';

@Resource('categories')
export class Category extends BaseModel implements Selectable {
  name_en: string;
  name_fi: string;
  type: string;
  lifecycle: number;
  extendedLeasingPrice: number;
  reclaimPrice: number;
  residualValue: number;
  icon: string;
  warrantyEnd: Date;
  parent?: Category;
  children: Category[];
  assets: Asset[];
  fields: CustomFormField[];
  extraCosts: ExtraCost[];
  categoryExtraCosts: CategoryExtraCost[];
  typeId: number;
  subTypeId: number;
  categoryType: string;
  visible: boolean;
  multiplier: number;

  constructor(data: any = {}) {
    super(data);
    this.name_en = deserializeString(data.name_en);
    this.name_fi = deserializeString(data.name_fi);
    this.type = deserializeString(data.type);
    this.lifecycle = data.lifecycle || 36;
    this.warrantyEnd = deserializeDate(data.warrantyEnd);

    this.multiplier = deserializeNumber(data.multiplier);
    this.residualValue = deserializeNumber(data.residualValue);
    this.extendedLeasingPrice = deserializeNumber(data.extendedLeasingPrice);
    this.reclaimPrice = deserializeNumber(data.reclaimPrice);
    this.icon = deserializeString(data.icon || 'fa-question-circle');
    
    if (data.parent) {
      this.parent = new Category(data.parent);      
    }

    if (data.children) {
      this.children = data.children.map((child: any) => new Category(child))
    } else {
      this.children = []
    }

    if (data.assets) {
      this.assets = data.assets.map((asset: any) => new Asset(asset))
    } else {
      this.assets = []
    }

    if (data.fields) {
      this.fields = data.fields as CustomFormField[]
    } else {
      this.fields = []
    }

    if (data.categoryExtraCosts) {
      this.categoryExtraCosts = data.categoryExtraCosts.map((i: any) => new CategoryExtraCost(i))
    } else {
      this.categoryExtraCosts= []
    }

    this.extraCosts = this.categoryExtraCosts.map(categoryExtraCost => categoryExtraCost.extraCost);
    this.typeId = parseInt(data.typeId || 0);
    this.subTypeId = parseInt(data.subTypeId || 0);
    this.categoryType = data.categoryType ?? '';
    this.visible = data.visible === true;
  }

  get hasParent() {
    return typeof this.parent !== 'undefined'
  }

  getLabel() { return null }
  getTitle() { return this.name_en }
}