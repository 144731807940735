import { Contact, ContactType } from './contact';
import { Resource, deserializeString } from '@patterns/core';

@Resource('organizations')
export class Organization extends Contact {
  desktopToken: string;
  keepSessions: boolean = false;

  constructor(data: any = {}) {
    super(data);
    this.type = ContactType.ORGANIZATION;
    this.desktopToken = deserializeString(data.desktopToken);
    this.keepSessions = typeof data.keepSessions !== 'undefined' ? data.keepSessions : true;
  }
}
