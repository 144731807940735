import * as React from 'react';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import { CostCenter } from '../models/cost_center';
import { Contract } from '../models/contract';
import { LeasingCompany } from '../models/leasing_company';
import { Asset } from '../models/asset';
import { axios, priceFormat } from '../common';
import { FlexRow, Toolbar, FlexColumn } from '@patterns/ui';
import { FormGroup, InputGroup, NumericInput, Icon } from '@blueprintjs/core';
import { AssetSelect, CostCenterSelect, ContractSelect, LeasingCompanySelect } from '../components/selects';
import { Lessee } from '../models/lessee';
import { RouteComponentProps } from 'react-router-dom';
import { session } from '../session';
import { WithTranslation, withTranslation } from 'react-i18next';

export type ReportItem = {
  costCenter: CostCenter,
  name: string,
  fields: any,
  contract: Contract,
  leasingCompany: LeasingCompany,
  moPayment: number,
  periodPayment: number
}

const ReportsTable = DataTable.ofType<Asset>();

export type GroupedReportItem = {
  contractCount: number
  assetCount: number
  id: string
  lesseeName: string
  monthlyPMT: number
}

export interface Props extends WithTranslation, RouteComponentProps {
  since: Date
  till: Date
}

export interface State {
  selected: ReportItem
  asset: Asset
  costCenter: CostCenter
  contract: Contract
  leasingCompany: LeasingCompany
  lessee: Lessee
  monthlyPMT?: number
  periodPMT?: number
  serialNumber: string
  monthlySummary: number
  periodSummary: number
}

export class Report extends React.Component<Props, State> {
  cancelToken: any;

  state = {
    asset: new Asset({}),
    costCenter: new CostCenter({}),
    contract: new Contract({}),
    leasingCompany: new LeasingCompany({}),
    lessee: new Lessee({}),
    monthlySummary: 0,
    periodSummary: 0,
    selected: {},
    serialNumber: ''
  } as State

  tableRef = React.createRef<any>();

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'asset',
        title: t('assets'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: item => <span className="bold" onClick={() => {
          this.props.history.push(`/assets/${item.name}`)
        }}>{ item.name || '' }
        <Icon icon="chevron-right"/>
        </span>
      },
      {
        id: 'serialNumber',
        title: t('assets.serial_number'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: item => <span>{ item.fields['serial'] || '' }</span>
      },
      {
        id: 'contract',
        title: t('contracts.contract_no'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: item => <span className="bold" onClick={() => {
          this.props.history.push(`/contracts/${item.contract.number}`)
        }}>{ item.contract.isOwned ? t('contract_ended') : item.contract.number  }
        <Icon icon="chevron-right"/>
        </span>
      },
      {
        id: 'leasingCompany',
        title: t('leasing_company'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: item => <span>{ item.contract.leasingCompany.name || '' }</span>
      },
      {
        id: 'costCenter',
        title: t('cost_center'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: item => <span>{ item.costCenter.name }</span>
      },
      {
        id: 'monthlyPayment',
        title: t('monthly_pmt'),
        type: ColumnType.Number,
        sortable: true,
        filterable: false,
        alignment: Alignment.Right,
        width: 130,
        visible: true,
        format: item => <span>{ priceFormat(item.monthlyPMT) }</span>
      },
      {
        id: 'selectedPeriodPMT',
        title: t('monthly_pmt'),
        type: ColumnType.Number,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        width: 130,
        visible: true,
        format: item => {
          let price = (this.props.since && this.props.till) ? item.periodPMT(this.props.since, this.props.till) : 0
          return <span>{ priceFormat(price) }</span>
        }
      }
    ] as Column<Asset>[]
  }

  private create = async () => {
    this.setState({
      selected: {} as ReportItem
    })
  }

  private fetchGrouped = async () => {
    const response = await axios.get('/assets/grouped_by_lessee');
    const items = response.data.items as GroupedReportItem[];
    const total = response.data.count;
    return { items, total }
  }

  private wrappedFetch = (lesseeId?: string) => async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    if (this.cancelToken) {
      this.cancelToken()
    }

    const params = [
      `page=${page}`,
      `pageSize=${10000}`,
      `sort=${sort}`,
      `sortDir=${sortDir}`,
      `lessee_id=${lesseeId}`,
      `since=${this.props.since.toISOString()}`,
      `till=${this.props.till.toISOString()}`
    ];

    if (this.state.asset.exists) {
      params.push(`asset_name=${this.state.asset.name}`)
    }

    if (this.state.costCenter.exists) {
      params.push(`cost_center_id=${this.state.costCenter.id}`)
    }

    if (this.state.contract.exists) {
      params.push(`contract_id=${this.state.contract.id}`)
    }

    if (this.state.leasingCompany.exists) {
      params.push(`leasing_company_id=${this.state.leasingCompany.id}`)
    }

    if (this.state.lessee.exists) {
      params.push(`lessee_id=${this.state.lessee.id}`)
    }

    if (this.state.serialNumber.length > 0) {
      params.push(`serial_number=${this.state.serialNumber}`)
    }

    if (this.state.monthlyPMT && this.state.monthlyPMT > 0) {
      params.push(`monthly_pmt=${this.state.monthlyPMT}`)
    }

    if (this.state.periodPMT && this.state.periodPMT > 0) {
      params.push(`period_pmt=${this.state.periodPMT}`)
    }

    const response = await axios.get(`/assets/report?${params.join('&')}`, {
      cancelToken: new (axios as any).CancelToken((token: any) => {
        this.cancelToken = token
      })
    });

    const items = response.data.assets.map((a: any) => new Asset(a)) as Asset[];
    const total = response.data.count;

    const monthlySummary = items.reduce((sum, item) => sum += item.monthlyPMT, 0);

    const periodSummary = (this.props.since && this.props.till) ? 
      items.reduce((sum, item) => sum += item.periodPMT(this.props.since!, this.props.till!), 0) : 
      0;

    this.setState({ monthlySummary, periodSummary });
    return { items, total }
  }

  private onAssetSelect = (asset?: Asset) => this.setState({
    asset: asset || new Asset({})
  }, () => this.tableRef.current?.fetch());
  
  private onContractSelect = (contract?: Contract) => this.setState({
    contract: contract || new Contract({})
  }, () => this.tableRef.current?.fetch());

  private onCostCenterSelect = (costCenter?: CostCenter) => this.setState({
    costCenter: costCenter || new CostCenter({})
  }, () => this.tableRef.current?.fetch());

  private onLeasingCompanySelect = (leasingCompany?: LeasingCompany) => this.setState({
    leasingCompany: leasingCompany || new LeasingCompany({})
  }, () => this.tableRef.current?.fetch());

  private onSerialNumberChange=  (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
    serialNumber: event.currentTarget.value
  }, () => this.tableRef.current?.fetch());

  private onMonthlyPMTChange = (value: number) => {
    this.setState({ monthlyPMT: value })
  }

  private onPeriodPMTChange = (value: number) => {
    this.setState({ periodPMT: value })
  }

  public render() {
    // if (!session.lesseeId) {
    //   return <div style={{ height: 200, width: '100%' }}>
    //     <NonIdealState title="Lessee not selected" description="Select Lessee to list assets"/>
    //   </div>
    // }
    const { t } = this.props;
    return (
      <FlexColumn className="f-1 d-f full-height p-t-24">
        <Toolbar className="popover-fix p-2">
          <FlexRow flex={1}>
            <AssetSelect 
              activeItem={this.state.asset}
              cancelable={true}
              fill={true}
              hint={t('select_asset')}
              outlined={true}
              intent="primary"
              onSelect={this.onAssetSelect}
            />
          </FlexRow>
          <FlexRow flex={1}>
            <FormGroup style={{ flex: 1 }}>
              <InputGroup
                fill={true}
                intent="primary"
                style={{ fontSize: 13, flex: 1 }}
                value={this.state.serialNumber}
                onChange={this.onSerialNumberChange}
                placeholder={t('enter_serial_number')}
              />
            </FormGroup>
          </FlexRow>
          <FlexRow flex={1}>
            <ContractSelect 
              activeItem={this.state.contract}
              cancelable={true}
              fill={true}
              hint={t('select_contract')}
              outlined={true}
              intent="primary"
              onSelect={this.onContractSelect}
            />
          </FlexRow>
          <FlexRow flex={1}>
            <LeasingCompanySelect
              activeItem={this.state.leasingCompany}
              cancelable={true}
              fill={true}
              hint={t('select_leasing_company')}
              outlined={true}
              intent="primary"
              onSelect={this.onLeasingCompanySelect}
            />
          </FlexRow>
          <FlexRow flex={1}>
            <CostCenterSelect 
              activeItem={this.state.costCenter}
              cancelable={true}
              fill={true}
              hint={t('select_cost_center')}
              outlined={true}
              intent="primary"
              onSelect={this.onCostCenterSelect}
            />
          </FlexRow>
          <div style={{ width: 130 }}>
            <FormGroup className="p-r-2">
              <NumericInput
                buttonPosition="none"
                fill={true}
                intent="primary"
                style={{ fontSize: 13, textAlign: 'center' }}
                value={this.state.monthlyPMT}
                onValueChange={this.onMonthlyPMTChange}
                placeholder="Minimum monthly payment"
              />
            </FormGroup>
          </div>
          <div style={{ width: 130 }}> 
            <FormGroup>
              <NumericInput
                buttonPosition="none"
                fill={true}
                intent="primary"
                style={{ fontSize: 13 }}
                value={this.state.periodPMT}
                onValueChange={this.onPeriodPMTChange}
                placeholder="Minimum period payment"
              />
            </FormGroup>
          </div>
        </Toolbar>

        <ReportsTable
          id="reports-table"
          columns={this.getColumns()}
          fetch={this.wrappedFetch(session.lesseeId)}
          sort="name"
          sortDir="asc"
          onItemSelect={() => {}}
          showPagination={false}
          showFooter={false}
        />
      </FlexColumn>
    );
  }
}

export default withTranslation()(Report)