import * as React from 'react';
import { Select } from '@blueprintjs/select'
import { FormGroup, Button, MenuItem, ButtonGroup } from '@blueprintjs/core';
import {axios} from "../session";

export type PriceList = {
  id: number,
  name: string
}

const BlueprintSelect = Select.ofType<PriceList>();

export interface Props {
  inline?: boolean
  label?: string
  priceList: PriceList
  onChange: (priceList?: PriceList) => void
}

export function PricelistSelect({ onChange, priceList, label, inline = false }: Props) {
  const [priceLists, setPriceLists] = React.useState([] as PriceList[]);
  const [query, setQuery] = React.useState('');

  React.useEffect(() => {
    const fetch = async () => {
      const { data } = await axios.get('/siemens/pricelists');
      setPriceLists(data)
    };

    void fetch()
  }, [query, fetch]);

  const cancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setQuery('');
    onChange(undefined);
  }

  return (
    <FormGroup inline={inline} label={label}>
      <BlueprintSelect
        filterable={true}
        items={priceLists}
        activeItem={priceList}
        itemRenderer={(item, options) => <MenuItem
          key={item.id}
          text={item.name}
          onClick={options.handleClick}
        />}
        query={query}
        onQueryChange={setQuery}
        onItemSelect={contact => {
          onChange(contact)}
        }
      >
        <ButtonGroup>
          <Button text={priceList.name} rightIcon="chevron-down" minimal fill/>
          <Button icon="cross" minimal onClick={cancel}/>
        </ButtonGroup>
      </BlueprintSelect>
    </FormGroup>
  );
}
