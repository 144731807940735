import * as React from 'react';
import { Dialog, Classes, Button, Icon } from '@blueprintjs/core';
import { Device } from '../../models/device';
import { FlexRow, FlexColumn, ConfirmButton } from '@patterns/ui';
import { KeyValueView } from '../key_value_view';

import logoFujitsu from '../../assets/fujitsu-symbol.png';
import logoWindows from '../../assets/logo_windows.png';
import logoApple from '../../assets/apple-gray.png'
import logoOsx from '../../assets/osx.png'

import { bytesToGB } from '../../common';
import { MapView } from '../map';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface Props extends WithTranslation {
  device: Device
  isOpen: boolean
  onClose: (changed: boolean) => void
  onDelete: (device: Device) => void
}

export class DeviceDialog extends React.Component<Props> {
  private close = () => this.props.onClose(false)

  private delete = () => {
    this.props.onDelete(this.props.device);
  }

  public render() {
    const { t } = this.props;
    const device = this.props.device;

    return (
      <Dialog canOutsideClickClose={false} title={t('fleet.device_info')} isOpen={this.props.isOpen} onClose={this.close} style={{ width: 888 }} className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <FlexRow>
            <FlexColumn>
              <div style={{ height: 204 }}>
                <KeyValueView 
                  classKey="f-2 bold"
                  classValue="f-3"
                  label={t('fleet.system')}
                  value={<div className="d-f ai-c">
                    { device.data.os && device.data.os.platform === 'win32' &&
                      <img src={logoFujitsu} style={{ height: 24 }} alt="logo"/> }
                    { device.data.os && device.data.os.platform === 'darwin' &&
                      <img src={logoApple} style={{ height: 24 }} alt="logo"/> }
                  </div>}
                  className="header"
                />
                <KeyValueView 
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.manufacturer')}
                  value={device.data.system.manufacturer}
                />
                <KeyValueView 
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.model')}
                  value={device.data.system.model}
                />
                <KeyValueView 
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.serial_number')}
                  value={device.data.system.serial}
                />
                <KeyValueView 
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.sku')}
                  value={device.data.system.sku}
                />
              </div>
              <div style={{ height: 156 }}>
                <KeyValueView 
                  classKey="f-2 bold"
                  classValue="f-3"
                  label={t('fleet.operating_system')}
                  value={<div className="d-f ai-c">
                    { device.data.os && device.data.os.platform === 'win32' &&
                      <img src={logoWindows} style={{ height: 24 }} alt="logo"/> }
                    { device.data.os && device.data.os.platform === 'darwin' &&
                      <img src={logoOsx} style={{ height: 24 }} alt="logo"/> }
                  </div>}
                  className="header"
                />
                <KeyValueView 
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.distribution')}
                  value={device.data.os.distro}
                />
                <KeyValueView 
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.release')}
                  value={device.data.os.release}
                />
                <KeyValueView 
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.serial')}
                  value={device.data.os.serial}
                />
              </div>

              <div style={{ height: 156 }}>
                <h4 style={{ marginBottom: 25 }}>{t('fleet.network_interfaces')}</h4>
                { (device.data.net || []).filter((net: any) => net.ip4 && net.ip4.length > 0).map((net: any, index: number) => <KeyValueView
                    key={`device-iface-${index}`}
                    classKey="f-2"
                    classValue="f-3"
                    label={net.ip4}
                    value={net.iface}
                  />
                )}
              </div>
            </FlexColumn>

            <FlexColumn>
              <div style={{ height: 204 }}>
                <h4 style={{ marginBottom: 25 }}>{t('fleet.cpus')}</h4>
                <KeyValueView 
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.manufacturer')}
                  value={device.data.cpu.manufacturer}
                />
                <KeyValueView 
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.brand')}
                  value={device.data.cpu.brand}
                />
                <KeyValueView 
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.cores')}
                  value={device.data.cpu.cores}
                />
                <KeyValueView 
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.physical_cores')}
                  value={device.data.cpu.physicalCores}
                />
                <KeyValueView 
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.processors')}
                  value={device.data.cpu.processors}
                />
              </div>

              <div style={{ height: 156 }}>
                <h4 style={{ marginBottom: 25 }}>{t('fleet.graphics')}</h4>
                <KeyValueView
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.vendor')}
                  value={device.data.graphics.controllers[0].vendor}
                />
                <KeyValueView
                  classKey="f-2"
                  classValue="f-3"
                  label={t('fleet.model')}
                  value={device.data.graphics.controllers[0].model}
                />
              </div>

              <div style={{ height: 156 }}>
                <h4 style={{ marginBottom: 25 }}>{t('fleet.memory_modules')}</h4>
                { (device.data.memLayout || []).map((mem: any, index: number) => <KeyValueView
                    key={`device-mem-${index}`}
                    classKey="f-2"
                    classValue="f-3"
                    label={`${mem.formFactor} ${index}`}
                    value={`${bytesToGB(mem.size)}GB (${mem.manufacturer === 'Undefined' ? t('unknown') : mem.manufacturer})`}
                  />
                )}
              </div>
            </FlexColumn>
          </FlexRow>
          <h4>{t('fleet.disk_layout')}</h4>
          <FlexRow style={{height: 148, marginTop: 12}}>
            { (device.data.diskLayout || []).map((disk: any, index: number) => <FlexColumn key={`device-disk-${index}`}flex={1} className="ai-c jc-c">
              <Icon icon="database" iconSize={48}/>
              <div className="m-t-12">{bytesToGB(disk.size)}GB</div>
              <div className="m-12">{disk.name} ({disk.type})</div>
            </FlexColumn> )}
          </FlexRow>
          <h4>{t('fleet.location')}</h4>
          <div style={{ width: '100%', height: 356 }}>
            <MapView lat={47.15} lng={18.43}/>
          </div>
        </div>
        <div className={`${Classes.DIALOG_FOOTER} m-t-24`}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <div className="f-1">
              <ConfirmButton
                title={t('fleet.delete_device')}
                confirmTitle={t('confirm_delete')}
                onConfirm={this.delete}
              />
            </div>
            <div className="f-1 d-f jc-e">
              <Button
                text={t('close')}
                icon="cross"
                onClick={this.close}
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(DeviceDialog);