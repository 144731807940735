import * as React from 'react';
import { Dialog, Classes, Button, FileInput, FormGroup, NumericInput } from '@blueprintjs/core';
import { Form, FormField, FormFieldType } from '@patterns/form';
import { ReplacementDevice } from '../../models/replacement_device';
import { deepEqual, convertFileToBase64 } from '@patterns/core';
import { ReplacementDeviceRepository } from '../../repository';
import CategorySelect from '../category_select';
import produce from 'immer';
import { FlexColumn, SaveButton, ConfirmButton, FlexRow } from '@patterns/ui';
import { session } from '../../session'
import { withTranslation, WithTranslation } from 'react-i18next';

const ReplacementDeviceForm = Form.ofType<ReplacementDevice>();

export interface Props extends WithTranslation{
  isOpen: boolean  
  onClose: (changed: boolean) => void
  onDelete: (device: ReplacementDevice) => void
  replacementDevice: ReplacementDevice
}

export interface State {
  attachment: string
  isUploading: boolean
  replacementDevice: ReplacementDevice
  stringValue: string
}

export class ReplacementDevicesDialog extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      attachment: '',
      isUploading: false,
      replacementDevice: props.replacementDevice,
      stringValue: props.replacementDevice.price.toFixed(2).replace('.', ',')
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.replacementDevice, prevProps.replacementDevice)) {
      this.setState({
        attachment: '',
        replacementDevice: this.props.replacementDevice,
        stringValue: this.props.replacementDevice.price.toFixed(2).replace('.', ',')
      })
    }
  }

  private getFields = () => {
    const { t } = this.props;
    return [
      {
        id: 'name',
        label: t('name'),
        type: FormFieldType.Text
      },
      {
        id: 'sku',
        label: t('fleet.sku'),
        type: FormFieldType.Text
      },
      {
        id: 'ean',
        label: t('ean_code'),
        type: FormFieldType.Text
      },
      {
        id: 'price',
        label: t('price'),
        type: FormFieldType.Custom,
        customElement: (product, onChange) => <NumericInput 
          value={this.state.stringValue}
          locale="fi"
          fill
          buttonPosition="none"
          onValueChange={(value, stringValue) => {
            this.setState({
              stringValue
            }, () => {
              product.price = value;
              onChange(product)
            })
          }}
        />
      },
      {
        id: 'category',
        label: t('category'),
        type: FormFieldType.Custom,
        customElement: (device, onChange) => {
          return <CategorySelect 
            category={device.category}
            onChange={(category) => {
              const newDevice = produce(device, newDevice => {
                if (category) {
                  newDevice.category = category;
                }
              });
              onChange(newDevice)
            }}
          />
        }
      }
    ] as FormField<ReplacementDevice>[]
  }

  private delete = async () => this.props.onDelete(this.state.replacementDevice)

  private onChange = (device: ReplacementDevice) => this.setState(produce(this.state, state => {
    state.replacementDevice = device
  }))

  private onClose = () => this.props.onClose(false)

  private save = async () => {
    await ReplacementDeviceRepository.save(this.state.replacementDevice);
    this.props.onClose(true)
  }

  public render() {
    const { t } = this.props;
    return (
      <Dialog canOutsideClickClose={false} title={t('store_settings.replacement_device')} isOpen={this.props.isOpen} onClose={this.onClose} className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <ReplacementDeviceForm 
            inline={true}
            fill={true}
            item={this.state.replacementDevice}
            fields={this.getFields()}
            onChange={this.onChange}
          />

          <FlexColumn className="patterns-form">
            <FormGroup 
              label={t('image')}
              inline={true}
              className="patterns-form-field-group patterns-form-field-group-fill">
              
              <FileInput
                fill={true}
                className="attachment-input m-b-12"
                buttonText={t('browse')}
                text={this.state.replacementDevice.image ? t('image') : t('add_image')}
                inputProps={{
                  accept: '.jpg,.jpeg,.png',
                  multiple: false
                }}
                hasSelection={this.state.replacementDevice.image.length > 0}
                onInputChange={async value => {
                  if (value.currentTarget.files) {
                    const file = value.currentTarget.files[0];
                    const data = await convertFileToBase64(file);
                    const newDevice = produce(this.state.replacementDevice, newDevice => {
                      newDevice.image = data;
                    })
                    this.setState({ replacementDevice: newDevice })
                  }
                }}
              />
            </FormGroup>

            { this.state.replacementDevice.exists && <FormGroup 
              label={t('image')} 
              inline={true}
              className="patterns-form-field-group patterns-form-field-group-fill">
              
              <img 
                className="form-image-preview" 
                src={`/api/replacement_devices/${this.state.replacementDevice.id}/image?token=${session.token}`} 
                alt=""
              />
            </FormGroup> }
          </FlexColumn>

        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <FlexRow flex={1}>
              <ConfirmButton
                title={t('fleet.delete_device')}
                confirmTitle={t('confirm_delete')}
                onConfirm={this.delete}
              />
            </FlexRow>
            <Button
              text={t('cancel')} 
              onClick={this.onClose}
            />
            <SaveButton 
              onClick={this.save}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(ReplacementDevicesDialog);