import { Button, Checkbox, Classes, Dialog, InputGroup, MenuItem } from '@blueprintjs/core';
import { FlexRow } from '@patterns/ui';
import * as React from 'react';
import { PurchaseInvoice } from '../models/purchase_invoice';
import { PurchaseInvoiceRepository } from '../repository';
import SiemensManagerInstance, { SiemensAsset, SiemensAssetSubType, SiemensAssetType } from '../siemens.manager';
import produce from 'immer';
import { priceFormat } from '../common';
import DataStore from '../data_store';
import SiemensTypeSuggest from './siemens_type_suggest';
import SiemensSubTypeSuggest from './siemens_subtype_suggest';
import { withTranslation, WithTranslation } from 'react-i18next';
import { formatDate } from '@patterns/core';

// we need to get unresolved purchase invoices and their assets
// order in types => subtypes => purchase invoice => asset rows
//

type InvoiceAssetsMap = { [id:string]: SiemensAsset[] }
type SubTypeInvoicesMap = { [id:string]: InvoiceAssetsMap }
type DataMap = { [id:string]: SubTypeInvoicesMap }
type ActiveMap = { [id: string]: boolean }

export interface Props extends WithTranslation {
  isOpen: boolean
  onClose: () => void
  onSubmit: (assets: SiemensAsset[], residualValue: number) => void
  residualValue: number
  inProposal?: boolean
}

export interface State {
  activeMap: ActiveMap
  dataMap: DataMap
  assets: SiemensAsset[]
  invoices: PurchaseInvoice[]
  residualValue: number
  type_id: number;
  subtype_id: number
  invoiceQuery: string;
}

export class PurchasePool extends React.Component<Props, State> {
  state = {
    activeMap: {} as ActiveMap,
    assets: [] as SiemensAsset[],
    dataMap: {},
    invoices: [],
    residualValue: 0,
    type_id: 0,
    subtype_id: 0,
    invoiceQuery: ''
  } 

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.fetch();
      
      this.setState({
        activeMap: {} as ActiveMap,
      });
      
      if (this.props.residualValue !== prevProps.residualValue || this.props.residualValue !== this.state.residualValue) {
        this.setState({
          residualValue: this.props.residualValue || 0
        })
      }
    }
  }
  
  private close = () => this.props.onClose()

  private fetch = async () => {
    const { items } = await PurchaseInvoiceRepository.index(1, 100, 'code', 'ASC');
    const assets = [] as SiemensAsset[];
    const dataMap = {} as DataMap;

    const assetFilter = this.state.residualValue ? 
      (a: SiemensAsset) => (this.props.inProposal ? (typeof a.proposalId === 'undefined' || a.proposalId === 0) : a) : 
      (a: SiemensAsset) => this.props.inProposal ? (
        a.residualValue === this.state.residualValue && (typeof a.proposalId === 'undefined' || a.proposalId === 0)) : 
        a.residualValue === this.state.residualValue;

    items.forEach(invoice => {
      invoice.assets.filter(assetFilter).forEach((asset, index) => {
        if (!dataMap[asset.assetTypeId]) {
          dataMap[asset.assetTypeId]= {} as SubTypeInvoicesMap;
        }

        if (!dataMap[asset.assetTypeId][asset.assetSubTypeId]) {
          dataMap[asset.assetTypeId][asset.assetSubTypeId] = {} as InvoiceAssetsMap;
        }

        if (!dataMap[asset.assetTypeId][asset.assetSubTypeId][invoice.code]) {
          dataMap[asset.assetTypeId][asset.assetSubTypeId][invoice.code] = [] as SiemensAsset[];
        }

        dataMap[asset.assetTypeId][asset.assetSubTypeId][invoice.code].push(asset);

        asset.invoiceId = invoice.id;
        asset.invoiceCode = invoice.code;
        asset.id = index;

        if (!(typeof asset.proposalId === 'number' && asset.proposalId > 0)) {
          // ignore assets with proposalId
          assets.push(asset)
        }
      })
    })

    this.setState({ assets, invoices: items, dataMap })
  }

  private toggle = (asset: SiemensAsset, residualValue: number = 0) => {
    const id = `${asset.invoiceId}-${asset.id}`;
    const activeMap = produce(this.state.activeMap, map => {
      if (typeof map[id] === 'undefined') {
        map[id] = false
      }
      map[id] = !map[id]
    });

    let active = false;
    // in case all selects are inactive, disable residual value filter
    Object.keys(activeMap).forEach(key => {
      if (activeMap[key]) {
        active = true
      }
    });

    this.setState({ 
      activeMap, 
      residualValue: active ? residualValue : (this.state.assets.length === 0 ? 0 : this.props.residualValue) })
  }

  private paste = async () => {
    const itemsJSON = localStorage.getItem('purchase-assets-selection');
    if (itemsJSON) {
      const activeMap = {} as ActiveMap;
      const assets = JSON.parse(itemsJSON) as SiemensAsset[];

      assets.forEach(asset => {
        const id = `${asset.invoiceId}-${asset.id}`;
        activeMap[id!] = true;
      });

      let residualValue = this.state.residualValue;

      if (assets.length > 0) {
        const asset = assets[0];
        residualValue = DataStore.categoryBySubtype(asset.assetSubTypeId)?.residualValue || residualValue;
      }

      console.log('pasting assets', assets, 'residualValue', residualValue);
      this.setState({
        activeMap,
        assets,
        residualValue
      });
    }
  }

  private hasPoolSelection = () => {
    return typeof localStorage.getItem('purchase-assets-selection') !== 'undefined'
  }

  private onTypeChange = (type: SiemensAssetType) => {
    this.setState({ type_id: type.id })
  }

  private onSubTypeChange = (subtype: SiemensAssetSubType) => {
    this.setState({ subtype_id: subtype.id })
  }

  private onInvoiceQueryChange =(evt: React.ChangeEvent<HTMLInputElement>) => this.setState({
    invoiceQuery: evt.currentTarget.value
  })

  private getInvoiceFilter = () => {
    return this.state.invoiceQuery.length === 0 ?
      (asset: SiemensAsset) => asset :
      (asset: SiemensAsset) => (asset.invoiceCode || '').toLowerCase().indexOf(this.state.invoiceQuery.toLowerCase()) >= 0;
  }
  
  private getResidualFilter = () =>  {
    return this.state.assets.length === 0 ? (asset: SiemensAsset) => asset : (this.state.residualValue === 0 ?
      (asset: SiemensAsset) => asset :
      (asset: SiemensAsset) => DataStore.categoryBySubtype(asset.assetSubTypeId)?.residualValue === this.state.residualValue);
  }

  private getTypeFilter = () => {
    return this.state.type_id === 0 ? 
      (asset: SiemensAsset) => asset :
      (asset: SiemensAsset) => asset.assetTypeId === this.state.type_id
  }

  private getTSubypeFilter = () => {
    return this.state.subtype_id === 0 ? 
      (asset: SiemensAsset) => asset :
      (asset: SiemensAsset) => asset.assetSubTypeId === this.state.subtype_id
  }

  private getFilteredAssets = () => this.state.assets
    .filter(this.getResidualFilter())
    .filter(this.getInvoiceFilter())
    .filter(this.getTypeFilter())
    .filter(this.getTSubypeFilter())

  private getSelectedAssets = () => this.state.assets.filter(asset => this.state.activeMap[`${asset.invoiceId}-${asset.id}`] === true)
  
  private submit = () => this.props.onSubmit(this.getSelectedAssets(), this.state.residualValue)

  private typeRenderer = (type: SiemensAssetType | SiemensAssetSubType, options: any) => {
    return <MenuItem
      key={`type-${type.id}-item`}
      text={type.name}
      onClick={options.handleClick}
    />
  }

  private renderItems = () => this.getFilteredAssets().map((asset, index) => {
    const id = `${asset.invoiceId}-${asset.id}`;
    // const residualValue = SiemensManagerInstance.getSubTypePricelist(asset.assetSubTypeId)?.residualValue;
    const residualValue = DataStore.categoryBySubtype(asset.assetSubTypeId)?.residualValue;
    console.log('rendering asset', asset);
    return <FlexRow key={`purchase-pool-asset-${index}`} className="jc-c ai-c d-f">
      <div className="d-f report-table-cell w-50"><Checkbox checked={this.state.activeMap[id] || false} onChange={() => this.toggle(asset, residualValue)} /></div>
      <div className="d-f report-table-cell f-1">{ asset.invoiceCode }</div>
      <div className="d-f report-table-cell f-2">
        { SiemensManagerInstance.getType(asset.assetTypeId)?.name }
      </div>
      <div className="d-f report-table-cell f-2">
        { SiemensManagerInstance.getSubType(asset.assetSubTypeId)?.name }
      </div>
      <div className="d-f report-table-cell f-2">{ asset.description }</div>
      <div className="d-f report-table-cell f-2">{ asset.serialNumber }</div>
      <div className="d-f report-table-cell f-2">{ asset.location || asset.note }n</div>
      <div className="d-f report-table-cell f-2">{ asset.user }</div>
      <div className="d-f report-table-cell f-2">{ asset.costCenter }</div>
      {/* <div className="d-f report-table-cell f-1 ta-r jc-e">{ asset.quantity }</div> */}
      <div className="d-f report-table-cell f-1">{ formatDate(asset.deliveryDate) }</div>
      <div className="d-f report-table-cell f-1 ta-r jc-e">{ priceFormat(asset.totalValue) }</div>
      {/* <div className="d-f report-table-cell f-1 ta-r jc-e">{ priceFormat(asset.totalValue) }</div> */}
      <div className="d-f report-table-cell f-1 ta-r jc-e">{ residualValue } %</div>
    </FlexRow>
  })

  public render() {
    const { t } = this.props;
    return (
      <Dialog canOutsideClickClose={false} title="Purchase Pool" isOpen={this.props.isOpen} onClose={this.props.onClose} className="bp3-dark" style={{ width: 1400 }}>
        <div className={Classes.DIALOG_BODY}>
          <div className="table">
            <FlexRow className="header">
              <div className="d-f w-50 report-table-cell header"></div>
              <div className="d-f f-1 report-table-cell header" style={{ paddingLeft: 0, marginLeft: 0}}>
                <InputGroup placeholder={t('search')} value={this.state.invoiceQuery} onChange={this.onInvoiceQueryChange} style={{ paddingLeft: 0, marginLeft: 0, width: 80 }}/>
              </div>
              <div className="d-f f-2 report-table-cell header">
                <SiemensTypeSuggest 
                  activeItem={SiemensManagerInstance.getType(this.state.type_id) || {} as SiemensAssetType}
                  onSelect={this.onTypeChange}
                  disabled={false}
                />
                {/* <TypeSuggest
                  items={SiemensManagerInstance.types}
                  activeItem={SiemensManagerInstance.getType(this.state.type_id)}
                  itemRenderer={this.typeRenderer}
                  inputValueRenderer={item => item.name}
                  onActiveItemChange={item => {
                    if (item && item.id !== this.state.type_id) {
                      this.setState({ type_id: item.id })
                    }
                  }}
                  onItemSelect={(type) => this.onTypeChange(type)}>
                  <Button outlined text={this.state.type_id ? SiemensManagerInstance.types.find(t => t.id === this.state.type_id)!.name : 'Select Type'} rightIcon="chevron-down"/>
                  <Button minimal disabled={this.state.type_id === 0} icon="cross" onClick={() => this.setState({ type_id: 0 })}/>
                </TypeSuggest> */}
              </div>
              <div className="d-f f-2 report-table-cell header">
                <SiemensSubTypeSuggest 
                  activeItem={SiemensManagerInstance.getSubType(this.state.subtype_id) || {} as SiemensAssetSubType}
                  onSelect={this.onSubTypeChange}
                  disabled={false}
                />
                {/* <SubTypeSuggest 
                  items={this.state.type_id === 0 ? SiemensManagerInstance.flatSubTypes : SiemensManagerInstance.getSubtypes(this.state.type_id)} 
                  activeItem={SiemensManagerInstance.getSubType(this.state.subtype_id)} 
                  itemRenderer={this.typeRenderer} 
                  inputValueRenderer={item => item.name}
                  onActiveItemChange={item => {
                    if (item && item.id !== this.state.subtype_id) {
                      this.setState({ subtype_id: item.id })
                    }
                  }}
                  onItemSelect={(type) => this.onSubTypeChange(type)}>
                  <Button outlined text={this.state.subtype_id !== 0 ? SiemensManagerInstance.getSubType(this.state.subtype_id)!.name : 'Select SubType'}  rightIcon="chevron-down"/>
                  <Button minimal disabled={this.state.subtype_id === 0} icon="cross" onClick={() => this.setState({ subtype_id: 0 })}/>
                </SubTypeSuggest> */}
              </div>
              <div className="d-f f-2 report-table-cell header" style={{ paddingLeft: 0, marginLeft: 0}}>
                <InputGroup placeholder={t('search')} style={{ paddingLeft: 0, marginLeft: 0}}/>
              </div>
              <div className="d-f f-1 report-table-cell header ta-r jc-e"></div>
              <div className="d-f f-1 report-table-cell header ta-r jc-e"></div>
              <div className="d-f f-1 report-table-cell header ta-r jc-e"></div>
              <div className="d-f f-1 report-table-cell header ta-r jc-e"></div>
            </FlexRow>
            <FlexRow className="header">
              <div className="d-f w-50 report-table-cell header"></div>
              <div className="d-f f-1 report-table-cell header">{t('invoice')}</div>
              <div className="d-f f-2 report-table-cell header">{t('type')}</div>
              <div className="d-f f-2 report-table-cell header">{t('subtype')}</div>
              <div className="d-f f-2 report-table-cell header">{t('assets.make_and_model')}</div>
              <div className="d-f f-2 report-table-cell header p-l-12">{t('assets.serial_number')}</div>
              <div className="d-f f-2 report-table-cell header p-l-12">{t('assets.additional_details')}</div>
              <div className="d-f f-2 report-table-cell header p-l-12">{t('user')}</div>
              <div className="d-f f-2 report-table-cell header p-l-12">{t('cost_center')}</div>
              {/* <div className="d-f f-1 report-table-cell header ta-r jc-e">Quantity</div> */}
              <div className="d-f f-1 report-table-cell header">{t('delivery_date')}</div>
              <div className="d-f f-1 report-table-cell header ta-r jc-e">{t('patterns.value')}</div>
              {/* <div className="d-f f-1 report-table-cell header ta-r jc-e">Total</div> */}
              <div className="d-f f-1 report-table-cell header ta-r jc-e">{t('residual_value')}</div>
            </FlexRow>
            { this.renderItems() }

            { this.hasPoolSelection() && <FlexRow className="m-t-24">
              <Button intent="success" text="Paste Selection from Pool" onClick={this.paste}/>
            </FlexRow> }
            
            <FlexRow className="m-t-24">
              Residual Value Filter: <span className="bold m-l-12">{ parseFloat(this.state.residualValue as unknown as string).toFixed(2) } %</span>
            </FlexRow>
          </div>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Close" onClick={this.close}/>
            <Button intent="primary" text="Add Assets" onClick={this.submit}/>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(PurchasePool)