export class BaseModel {
  id: string
  createdAt: Date
  updatedAt: Date
  deletedAt?: Date
  version: number

  constructor(data: any = {}) {
    this.id = data.id
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.version = data.version || 0;

    if (data.deletedAt) {
      this.deletedAt = new Date(data.deletedAt);
    }
  }

  get exists() {
    return typeof this.id === 'string' && this.id.length > 0
  }
}