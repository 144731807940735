import { Button, Menu, MenuItem, NonIdealState, PopoverInteractionKind } from '@blueprintjs/core';
import { FlexRow } from '@patterns/ui';
import * as React from 'react';
import { AppContext, AppContextType } from '../../app_context';
import { MainLayout } from '../../layouts/main';
import { Category } from '../../models/category';
import { Product } from '../../models/product';
import { ProductRepository } from '../../repository';
import { Breadcrumbs } from '../breadcrumb';
import ShoppingCartView from './shopping_cart';
import DataStore from '../../data_store';
import StoreProductDialog from '../store_product.dialog';
import { Popover2 } from '@blueprintjs/popover2';
import { ImageLoader } from '../image-loader';
import { priceFormat } from '../../common';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export interface Props extends WithTranslation, RouteComponentProps {}

export interface State {
  category: Category
  categories: Category[]
  cart: Product[]
  items: Product[]
  selected: Product
  showCart: boolean
  showDialog: boolean
  tag: number
}

export class Products extends React.Component<Props, State, AppContextType> {
  static contextType = AppContext;

  state = {
    category: new Category({}),
    categories: [] as Category[],
    cart: [],
    items: [] as Product[],
    selected: new Product({}),
    showCart: false,
    showDialog: false,
    tag: 1
  } as State;

  componentDidMount() {
    this.fetch();
    this.context.shoppingCart.observeAll(this.onShoppingCartUpdate);
  }

  componentWillUnmount() {
    this.context.shoppingCart.cancelAll(this.onShoppingCartUpdate);
  }

  private onShoppingCartUpdate = () => this.setState({ tag: this.state.tag + 1 })

  private fetch = async () => {
    // const categories = await CategoryRepository.index(0, 1000, 'name_en', 'ASC');
    const extraParams = this.state.category.exists ? `category_id=${this.state.category.id}` : ''
    const { items } = await ProductRepository.index(0, 1000, 'name', 'ASC', {}, null, extraParams);
    this.setState({ items, categories: DataStore.categories })
  }

  private onSelect = (identifier?: string) => {
    if (!identifier) return;

    const product = this.state.items.find(item => item.id === identifier);
    if (product) {
      this.setState({ selected: product, showDialog: true })
    }
  }

  private addToCart = (item: Product) => {
    this.context.shoppingCart.add(item);
    this.setState({ showCart: true })
  }

  private onDialogClose = () => this.setState({ showDialog: false })

  private renderCategoriesMenu = () => {
    const { i18n: { language } } = this.props;
    const subcategories = this.state.categories;
    const items = subcategories.map(category => {
      // const subcategories = this.state.categories.filter(c => c.parent && c.parent.id === category.id);
      // const children = subcategories.map(subCategory => {
      //   return <MenuItem icon={<i className={`fas ${category.icon}`}></i>} text={subCategory.name_en} />
      // });

      return <MenuItem
        popoverProps={{
          interactionKind: PopoverInteractionKind.HOVER,
          popoverClassName: "tall-menu"
        }}
        key={`category-${category.id}`}
        onClick={() => this.setState({ category }, this.fetch)}
        text={language === 'fi' ? category.name_fi : category.name_en}>
      </MenuItem>
    })

    return <Menu className="tall-menu">
      { items}
    </Menu>
  }

  public render() {
    const { t, i18n: { language } } = this.props;


    return (
      <MainLayout title={t('products')}>
        <Breadcrumbs path={`${t('store.partner_products')} / ${t('store.products_title')}`} title={`${t('sidebar.partner_products')} / ${this.state.category.exists ? (language === 'fi' ? this.state.category.name_fi : this.state.category.name_en) : t('store.all_products')}`}/>
        <div className="store">
          <FlexRow className="m-l-24">
            <Popover2 content={this.renderCategoriesMenu()} placement="right-end">
              <Button icon="share" text={t('store.select_category')} />
            </Popover2>
            <Button className='m-l-24' text={t('store_settings.products')} onClick={() => this.props.history.push('/store/settings/products')} intent="primary"  icon="cog"/>
            <Button className='m-l-12' text={t('store_settings.extra_services')}  onClick={() => this.props.history.push('/store/settings/extra_services')} intent="primary" icon="cog"/>
            {/* <Button className='m-l-12' text={t('store_settings.replacement_devices')}  onClick={() => this.props.history.push('/store/settings/replacement_devices')} intent="primary" icon="cog" /> */}

          </FlexRow>
          <FlexRow>
            <div className="ecommerce-products m-t-12">
              { this.state.items.map((item, index) => <div className="shop-product" key={`shop-product-${index}`}>
                {/* <div
                  className="icon"
                  style={{ backgroundImage: `url("${item.image}")` }}
                  onClick={() => this.onSelect(item)}
                /> */}
                <ImageLoader 
                  alt={item.name}
                  url={item.image}
                  identifier={item.id} 
                  onClick={this.onSelect}
                />
                <div className="filename" onClick={() => this.onSelect(item.id)}>
                  { item.name.substring(0, 63) }
                </div>
                <div className="price" onClick={() => this.onSelect(item.id)}>
                  { priceFormat(item.price) }
                </div>

                <div className="buttons">
                  <Button
                    text={t('store.add_to_cart')}
                    intent="success"
                    icon="shopping-cart"
                    onClick={() => this.addToCart(item)}
                  />
                </div>
              </div> )}
            </div>

            { <div className="f-1 m-t-12" style={{ height: 'calc(100vh - 180px)', overflowY: 'auto'}}>
              { (this.context as AppContextType).shoppingCart.items.length === 0 && <NonIdealState icon="shopping-cart" description={t('store.no_items') as string}/> }
              { (this.context as AppContextType).shoppingCart.items.length > 0 && <ShoppingCartView /> }
            </div> }
          </FlexRow>
          <div className="clearfix"></div>
        </div>

        <StoreProductDialog
          isOpen={this.state.showDialog}
          product={this.state.selected}
          onClose={this.onDialogClose}
        />
      </MainLayout>
    );
  }
}

export default withRouter(withTranslation()(Products))