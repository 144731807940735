import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BarWidget } from '../bar_widget';

export interface Props extends WithTranslation {
  height: number
  width: number
  legend?: boolean
}

export function OnlineDevicesChart({ height, legend, width, t }: Props) {
  return (
    <BarWidget
      title={t('charts.online_devices')}
      url="/devices/online_chart"
      legend={legend}
      width={width}
      height={height}
    />
  );
}

export default withTranslation()(OnlineDevicesChart)