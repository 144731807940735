import { BaseModel } from './base_model';
import { ExtraCost } from './extra_cost';
import { Category } from './category';
import { deserializeString } from '@patterns/core';

export class CategoryExtraCost extends BaseModel {
  categoryId: string
  extraCostId: string
  category: Category
  extraCost: ExtraCost

  constructor(data: any = {}) {
    super(data);

    this.categoryId = deserializeString(data.categoryId);
    this.extraCostId = deserializeString(data.extraCostId);
    this.category = new Category(data.category);
    this.extraCost = new ExtraCost(data.extraCost);
  }
}