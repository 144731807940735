import * as React from 'react';
import { Asset } from '../../models/asset';
import { Dialog, Classes, Button, FormGroup } from '@blueprintjs/core';
import { FlexColumn, FlexRow, Notifier } from '@patterns/ui';
import { axios } from '../../session'
import { withTranslation, WithTranslation } from 'react-i18next';
import { PickupPlaceSelect } from '../selects';
import { PickupPlace } from '../../models/pickup_place';

type ErasureMap = { [id:string]: boolean }

export interface Props extends WithTranslation {
  isOpen: boolean
  onClose: (changed: boolean) => void
  selection: Asset[]
}

export interface State {
  erasures: ErasureMap
  pickupPlace: PickupPlace;
}

export class ReturnReplacement extends React.Component<Props, State> {
  cancelToken: any

  state = {
    erasures: {} as ErasureMap,
    pickupPlace: new PickupPlace({})
  }

  private accept = async () => {
    try {
      const ids = this.props.selection.map(asset => asset.id);
      const data = {
        ids,
        pickupPlaceId: this.state.pickupPlace.id
      }
      await axios.post(`/assets/return`, data);
      this.props.onClose(true);
      Notifier.success('Assets were successfuly extended');
    } catch (e) {
      Notifier.failure('There was an error during asset extending')
    }
  }

  private close = () => this.props.onClose(false)

  private onPickupPlaceSelect = (pickupPlace: PickupPlace) => this.setState({ pickupPlace })

  private renderItem = (asset: Asset) => {
    const { i18n: { language }} = this.props;
    return <FlexRow key={`asset-item-${asset.id}`} className="p-t-6">
      <div className="f-1 d-f ai-c">
        { language === 'fi' ? asset.category.name_fi : asset.category.name_en }
      </div>
      <div className="f-2 d-f ai-c">
        { asset.name }
      </div>
    </FlexRow>
  }

  private renderItems = () => {
    return this.props.selection.map(this.renderItem)
  }

  public render() {
    const { t } = this.props;
    return (
      <Dialog isOpen={this.props.isOpen} onClose={this.close} title={t('replacement_process')} style={{ width: 640 }} className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <FlexRow>
            <FormGroup 
              inline={true} 
              label={t('asset_returns.pick_up_place')} 
              className="label-left">
              <PickupPlaceSelect
                minimal
                activeItem={this.state.pickupPlace}
                onSelect={this.onPickupPlaceSelect}
              />
            </FormGroup>
          </FlexRow>
          <FlexColumn>
            <FlexRow>
              <div className="f-1 d-f ai-c table-header">
                {t('category')}
              </div>
              <div className="f-2 d-f ai-c table-header">
                {t('asset')}
              </div>
            </FlexRow>
            { this.renderItems() }
          </FlexColumn>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text={t('close')}
              icon="cross" 
              onClick={this.close}
            />
            <Button
              disabled={!this.state.pickupPlace.exists}
              text={t('store_settings.accept')} 
              icon="tick" 
              onClick={this.accept} 
              intent="primary"
            />
          </div>
        </div>
      </Dialog>
    )
  }
}

export default withTranslation()(ReturnReplacement)
