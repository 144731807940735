import { BaseModel, Selectable, Resource, deserializeString, deserializeNumber } from '@patterns/core'
import { Category } from './category';

@Resource('also')
export class Also extends BaseModel implements Selectable {
  remote_id: string;
  code: string;
  name: string;
  manufacturer: string;
  manufacturerPartNumber: string;
  description: string;
  remoteCategoryId: string;
  categoryText1: string;
  categoryText2: string;
  categoryText3: string;
  availableQuantity: number;
  netPrice: number;
  marketingText: string;
  datasheetUrl: string;
  image: string;
  price: number;
  useInProducts: boolean;
  useInReplacementDevices: boolean;

  category: Category;
  
  constructor(data: any = {}) {
    super(data);
    this.remote_id = deserializeString(data.remote_id);
    this.code = deserializeString(data.code);
    this.name = deserializeString(data.name);
    this.manufacturer = deserializeString(data.manufacturer);
    this.manufacturerPartNumber = deserializeString(data.manufacturerPartNumber);
    this.description = deserializeString(data.description);
    this.remoteCategoryId = deserializeString(data.remoteCategoryId);
    this.categoryText1 = deserializeString(data.categoryText1);
    this.categoryText2 = deserializeString(data.categoryText2);
    this.categoryText3 = deserializeString(data.categoryText3);
    this.availableQuantity = deserializeNumber(data.availableQuantity);
    this.netPrice = deserializeNumber(data.netPrice);
    this.marketingText = deserializeString(data.marketingText);
    this.datasheetUrl = deserializeString(data.datasheetUrl);
    this.price = deserializeNumber(data.price);
    this.image = deserializeString(data.image);
    this.useInProducts = data.useInProducts || false;
    this.useInReplacementDevices = data.useInReplacementDevices || false;
    this.category = data.category ? new Category(data.category) : new Category({})
  }

  getLabel() { return null }
  getTitle() { return this.id }

  get categories() {
    return [this.categoryText1, this.categoryText2, this.categoryText3].filter(f => f).join(' > ')
  }
}
