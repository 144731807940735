import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Category } from '../models/category';

export interface Props extends WithTranslation {
  category: Category
}

export function CategoryCell({ category, i18n }: Props) {
  return (
    <span>
      {/* <i className={`fas ${category.icon}`}  style={{ color: 'white' }}></i>
      &nbsp;&nbsp;&nbsp; */}
      { i18n.language === 'fi' ? category.name_fi : category.name_en }
    </span>
  );
}

export function CategoryFormatter(category: Category) {
  return <TranslatedCategoryCell category={category}/>
}

const TranslatedCategoryCell = withTranslation()(CategoryCell);

export default TranslatedCategoryCell
