import { Button, InputGroup } from '@blueprintjs/core';
import { FlexRow } from '@patterns/ui';
import * as React from 'react';

export interface Props {
  value: string;
  onSave: (value: string) => void;
}

export function InlineEditor({ value: _value, onSave }: Props) {
  const [ value, setValue ] = React.useState(_value);
  const [ changed, setChanged ] = React.useState(false);

  const onChange = React.useCallback((evt: any | undefined) => {
    setValue(evt.currentTarget.value);
    setChanged(true);
  }, []);

  const save = React.useCallback(() => {
    onSave(value);
    setChanged(false);
  }, [onSave, value]);

    const className = `inline-editor ${changed ? 'active' : 'inactive'}`;

  return (
    <FlexRow className={className}>
      <InputGroup
        value={value}
        onChange={onChange}
      />
      <Button 
        text="Save"
        onClick={save}
        intent="success"
        className="m-l-6"
      />
    </FlexRow>
  );
}
