import { BaseModel, Selectable, Resource, deserializeString, deserializeNumber } from '@patterns/core'

@Resource('extra_services')
export class ExtraService extends BaseModel implements Selectable {
  name: string;
  price: number;
  
  constructor(data: any = {}) {
    super(data);
    this.name = deserializeString(data.name);
    this.price = deserializeNumber(parseFloat(data.price));
  }

  getLabel() { return null }
  getTitle() { return this.id }
}