import { BaseModel, Selectable, Resource } from '@patterns/core'
import { deserializeString } from '@patterns/core'
import { Contract } from './contract';
import { Asset } from './asset';

@Resource('cost_centers')
export class CostCenter extends BaseModel implements Selectable {
  name: string;
  type: string;
  parent?: CostCenter
  children: CostCenter[]
  contracts: Contract[]
  assets: Asset[]

  constructor(data: any = {}) {
    super(data);
    this.name = deserializeString(data.name);
    this.type = data.type || 0;

    if (data.parent) {
      this.parent = new CostCenter(data.parent);
    }

    if (data.children) {
      this.children = data.children.map((child: any) => new CostCenter(child));
    } else {
      this.children = []
    }

    if (data.contracts) {
      this.contracts = data.contracts.map((contract: any) => new Contract(contract));
    } else {
      this.contracts = []
    }

    if (data.assets) {
      this.assets = data.assets.map((asset: any) => new Asset(asset));
    } else {
      this.assets = []
    }
  }

  get hasParent() {
    return typeof this.parent !== 'undefined'
  }

  getLabel() { return null }
  getTitle() { return this.name }
}