import * as React from 'react';
import { Dialog, Classes, Button } from '@blueprintjs/core';
import { Form, FormField, FormFieldType } from '@patterns/form';
import { ExtraService } from '../../models/extra_service';
import { deepEqual } from '@patterns/core';
import { ExtraServiceRepository } from '../../repository';
import produce from 'immer';
import { ConfirmButton, FlexRow, SaveButton } from '@patterns/ui';
import { withTranslation, WithTranslation } from 'react-i18next';

const ExtraServiceForm = Form.ofType<ExtraService>();

export interface Props extends WithTranslation {
  isOpen: boolean  
  onClose: (changed: boolean) => void
  onDelete: (extraService: ExtraService) => void
  extraService: ExtraService
}

export interface State {
  attachment: string
  isUploading: boolean
  extraService: ExtraService
}

export class ExtraServiceDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      attachment: '',
      isUploading: false,
      extraService: props.extraService
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.extraService, prevProps.extraService)) {
      this.setState({
        attachment: '',
        extraService: this.props.extraService
      })
    }
  }

  private getFields = () => {
    const { t } = this.props;
    return [
      {
        id: 'name',
        label: t('name'),
        type: FormFieldType.Text
      },
      {
        id: 'price',
        label: t('price'),
        type: FormFieldType.Number
      }
    ] as FormField<ExtraService>[]
  }
  private delete = async () => this.props.onDelete(this.state.extraService)

  private onChange = (extraService: ExtraService) => this.setState(produce(this.state, state => {
    state.extraService = extraService
  }))

  private onClose = () => this.props.onClose(false)

  private save = async () => {
    await ExtraServiceRepository.save(this.state.extraService);
    this.props.onClose(true)
  }

  public render() {
    const { t } = this.props;
    return (
      <Dialog canOutsideClickClose={false} title={t('extra_service')} isOpen={this.props.isOpen} onClose={this.onClose} className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <ExtraServiceForm 
            inline={true}
            fill={true}
            item={this.state.extraService}
            fields={this.getFields()}
            onChange={this.onChange}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <FlexRow flex={1}>
              <ConfirmButton
                title={t('delete')}
                confirmTitle={t('confirm')}
                onConfirm={this.delete}
              />
            </FlexRow>
            <Button
              text={t('cancel')}
              onClick={this.onClose}
            />
            <SaveButton 
              onClick={this.save}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(ExtraServiceDialog)