import { Button, Classes, Dialog, FormGroup, InputGroup, MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { deepEqual } from '@patterns/core';
import { ConfirmButton, FlexColumn, FlexRow, Notifier } from '@patterns/ui';
import produce from 'immer';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppContext } from '../../app_context';
import { Employee } from '../../models/employee';
import { EmployeeRepository } from '../../repository';
import { axios } from '../../session';

const ClassificationSuggest = Suggest.ofType<string>();

export interface Props extends WithTranslation {
  isOpen: boolean
  onClose: (changed: boolean) => void
  onDelete: (device: Employee) => void
  employee: Employee
}

export interface State {
  classification: string
  classifications: string[]
  name: string
  query: string
}

export class EmployeesDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props)
    this.state = {
      classification: props.employee.classification,
      classifications: [ props.employee.classification ],
      name: props.employee.name,
      query: props.employee.name
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.employee, prevProps.employee)) {
      const { name, classification  } = this.props.employee;
      this.setState({ name, classification })
    }
  }

  private delete = async () => this.props.onDelete(this.props.employee)

  private onClose = () => this.props.onClose(false)

  private save = async () => {
    const employee = produce(this.props.employee, employee => {
      employee.classification = this.state.classification;
      employee.name = this.state.name;
    });
    await EmployeeRepository.save(employee);
    Notifier.success(this.props.t('settings.employee_save_success'))
    this.props.onClose(true)
  }

  private onNameChange = (name: string) => this.setState({ name })
  
  private onClassificationChange = (classification: string) => this.setState({ classification })
  
  private onQueryChange = (query: string) => this.setState({ classification: query, query }, async () => {
    const response = await axios.get(`/employees/search_classifications?query=${query}`);
    const { classifications } = response.data;
    this.setState({ classifications })
  })

  private inputValueRenderer = (value: string) => value

  private renderer = (item: string, options: any) => <MenuItem
    key={`classification-suggest-item-${item}`}
    text={item}
    onClick={options.handleClick}
  />

  private newItemRenderer = (options: any) => <MenuItem
    intent="success"
    text={<span>Create: <strong>{this.state.query}</strong></span>} 
    onClick={options.handleClick}
  />

  private createFromQuery = (query: string) => query

  public render() {
    const { t } = this.props;
    return (
      <Dialog canOutsideClickClose={false} title={t('employee')} isOpen={this.props.isOpen} onClose={this.onClose} className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <FlexColumn>
            <FormGroup inline={true} label="Name">
              <InputGroup 
                value={this.state.name}
                onChange={(evt: any) => this.onNameChange(evt.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup inline={true} label={t('classification')}>
              <ClassificationSuggest
                fill={true}
                query={this.state.query}
                onItemSelect={this.onClassificationChange}
                onQueryChange={this.onQueryChange}
                items={this.state.classifications}
                itemRenderer={this.renderer}
                inputValueRenderer={this.inputValueRenderer}
                createNewItemRenderer={this.newItemRenderer}
                createNewItemFromQuery={this.createFromQuery}
              />
            </FormGroup>
          </FlexColumn>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <FlexRow flex={1}>
              <ConfirmButton
                title={t('delete')}
                confirmTitle={t('confirm_delete')}
                onConfirm={this.delete}
              />
            </FlexRow>
            <Button text={t('cancel')} onClick={this.onClose}/>
            <Button text={t('save')} onClick={this.save} intent="success"/>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(EmployeesDialog)