import * as React from 'react';
import { Form, FormField, FormFieldType } from '@patterns/form';
import { Contract } from '../models/contract';
import { Classes, Button, Tabs, Tab, NonIdealState, Spinner, Dialog, InputGroup } from '@blueprintjs/core';
import { capitalize, deepEqual, formatDate, parseDate } from '@patterns/core';
import produce from 'immer';
import { LeasingCompanySelect, PurchaseInvoiceSelect, SupplierSelect } from './selects';
import { ContractRepository, LeasingCompanyRepository, PurchaseInvoiceRepository } from '../repository';
import { FlexColumn, FlexRow, SaveButton, Notifier } from '@patterns/ui';
import { Column, ColumnType } from '@patterns/datatable';
import { PurchaseInvoice } from '../models/purchase_invoice';
import { PurchaseInvoiceAttachmentView } from './purchase_invoices/attachment';
import PurchaseOrderDetail from './purchase_invoices/detail';
import ContractAssets from './contracts/assets';
import { withTranslation, WithTranslation } from 'react-i18next';
import DurationSelect from './duration_select';
import moment from 'moment';
import { session } from '../session';
import { Lessee } from '../models/lessee';
import { monthDiff } from '../common';
import { LeasingCompany } from '../models/leasing_company';
import PurchaseInvoiceList from './purchase_invoices/list';
import { DateInput } from '@blueprintjs/datetime';

const ContractForm = Form.ofType<Contract>();

export interface Props extends WithTranslation {
  contract: Contract
  isOpen: boolean
  onClose: () => void
}

export interface State {
  contract: Contract
  invoice: PurchaseInvoice
  invoiceToAdd: PurchaseInvoice
  showInvoice: boolean
  tab: string
  changed: boolean
  isLoading: boolean
  siemens: LeasingCompany
}

export class ContractDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      contract: props.contract,
      invoice: new PurchaseInvoice({}),
      invoiceToAdd: new PurchaseInvoice({}),
      showInvoice: false,
      siemens: new LeasingCompany({}),
      tab: 'contract',
      changed: false,
      isLoading: true
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.contract, prevProps.contract)) {
      this.setState({ contract: this.props.contract }, () => this.fetch())
    }
  }

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'date',
        title: t('purchase_invoices.invoice_date'),
        type: ColumnType.Date,
        visible: true,
        width: 130,
        sortable: true
      },
      {
        id: 'code',
        title: t('purchase_invoices.invoice_code'),
        type: ColumnType.Text,
        visible: true,
        width: 130,
        sortable: true
      },
      {
        id: 'createdBy',
        title: t('created_by'),
        type: ColumnType.Custom,
        visible: true,
        flex: 1,
        sortable: true,
        format: invoice => invoice.createdBy.name
      },
      {
        id: 'title',
        title: t('title'),
        type: ColumnType.Custom,
        visible: true,
        flex: 2,
        sortable: true
      }
    ] as Column<PurchaseInvoice>[];
  }

  private getFields = () => {
    const { t } = this.props;
    return [
      {
        id: 'number',
        type: FormFieldType.Text,
        label: t('contract_number'),
        disabled: session.user.role !== 'admin'
      },
      {
        id: 'startDate',
        type: FormFieldType.Custom,
        label: t('contracts.start_date'),
        disabled: session.user.role !== 'admin',
        customElement: (contract, onChange) => {
          return <DateInput
            disabled={session.user.role !== 'admin'}
            parseDate={parseDate}
            formatDate={formatDate}
            value={contract.startDate}
            canClearSelection={false}
            onChange={date => {
              const months = monthDiff(contract.startDate, contract.validTill);
              const c = new Contract(contract);
              c.startDate = date;
              c.validTill = moment(contract.startDate).add(months, 'months').endOf('quarter').subtract(1, 'hour').toDate();;
              onChange(c);
            }}
          />
        }
      },
      {
        id: 'validTill',
        type: FormFieldType.Custom,
        label: t('proposals.term'),
        disabled: session.user.role !== 'admin',
        customElement: (contract, onChange) => {
          if (!contract.startDate) {
            return <span></span>
          }

          return <DurationSelect
            term={contract.validity}
            disabled={session.user.role !== 'admin'}
            onSelect={months => {
              const date = moment(contract.startDate).add(months, 'months').endOf('quarter').subtract(1, 'hour').toDate();;
              const newContract = produce(contract, newContract => {
                newContract.validTill = date;
                newContract.validity = months;
              });
              onChange(newContract)
            }}
          />
        }
      },
      {
        id: 'leaseMonetaryMonth',
        type: FormFieldType.Custom,
        label: t('contracts.rental_per_month'),
        disabled: session.user.role !== 'admin',
        customElement: (contract, onChange) => {
          return <InputGroup
            value={(contract.leaseMonetaryMonth || '').toString().replace('.', ',')}
            disabled={session.user.role !== 'admin'}
            onChange={evt => {
              const newContract = produce(contract, newContract => {
                newContract.leaseMonetaryMonth = parseFloat(evt.currentTarget.value.replace(',', '.'))
              });
              onChange(newContract)
            }}
          />
        }
      },
      // {
      //   id: 'extendedLeasingPrice',
      //   type: FormFieldType.Text,
      //   label: t('extended_leasing_price'),
      //   customElement: (contract, onChange) => {
      //     return <InputGroup
      //       value={contract.extendedLeasingPrice.toString()}
      //       onChange={evt => {
      //         const newContract = produce(contract, newContract => {
      //           newContract.extendedLeasingPrice = parseFloat(evt.currentTarget.value.replace(',', '.'))
      //         });
      //         onChange(newContract)
      //       }}
      //     />
      //   }
      // },
      // {
      //   id: 'reclaimPrice',
      //   type: FormFieldType.Text,
      //   label: t('reclaim_price'),
      //   customElement: (contract, onChange) => {
      //     return <InputGroup
      //       value={contract.reclaimPrice.toString()}
      //       onChange={evt => {
      //         const newContract = produce(contract, newContract => {
      //           newContract.reclaimPrice = parseFloat(evt.currentTarget.value.replace(',', '.'))
      //         });
      //         onChange(newContract)
      //       }}
      //     />
      //   }
      // },
      {
        id: 'leasingCompany',
        type: FormFieldType.Custom,
        label: t('leasing_company'),
        disabled: session.user.role !== 'admin',
        customElement: (contract, onChange) => <LeasingCompanySelect
          minimal={true}
          disabled={session.user.role !== 'admin'}
          hint={t('select')}
          activeItem={contract.leasingCompany.exists ? contract.leasingCompany : this.state.siemens}
          onSelect={(leasingCompany) => {
            const newContract = produce(contract, newContract => {
              newContract.leasingCompany = leasingCompany
            });
            onChange(newContract)
          }}
        />
      },
      {
        id: 'supplier',
        type: FormFieldType.Custom,
        label: t('supplier'),
        disabled: session.user.role !== 'admin',
        customElement: (contract, onChange) => <SupplierSelect
          minimal={true}
          hint={t('select')}
          disabled={session.user.role !== 'admin'}
          activeItem={contract.supplier}
          onSelect={(supplier) => {
            const newContract = produce(contract, newContract => {
              newContract.supplier = supplier
            });
            onChange(newContract)
          }}
        />
      }
      // {
      //   id: 'lessee',
      //   type: FormFieldType.Custom,
      //   label: t('lessee'),
      //   customElement: (contract, onChange) => <LesseeSelect
      //     minimal={true}
      //     activeItem={contract.lessee}
      //     onSelect={(lessee) => {
      //       const newContract = produce(contract, newContract => {
      //         newContract.lessee = lessee
      //       });
      //       onChange(newContract)
      //     }}
      //   />
      // }
    ] as FormField<Contract>[];
  }

  private fetch = () => this.setState({ isLoading: true }, async () => {
    const leasingCompanies = await LeasingCompanyRepository.index();
    const siemens = leasingCompanies.items.find(i => i.name === 'Siemens') || new LeasingCompany({});

    if (!this.props.contract.exists) {
      this.setState({ isLoading: false, siemens })
      return
    }

    const contract = await ContractRepository.get(this.props.contract.id);
    this.setState({
      contract,
      siemens: siemens,
      isLoading: false
    })
  })

  private onChange = (_contract: Contract, field: string, prevItem?: Contract) => {
    let contract = _contract;
    if (field === 'startDate') {
      contract = produce(contract, newContract => {
        if (prevItem) {
          const prevDiff = monthDiff(prevItem?.startDate, prevItem?.validTill);
          newContract.validTill = moment(_contract.startDate).add(prevDiff, 'months').toDate();
        }
      });
    }
    this.setState({ contract, changed: true })
  }

  private onTabChange = (tab: string) => this.setState({ tab })

  private onInvoiceSelect = (invoice: PurchaseInvoice) => this.setState({
    invoice,
    showInvoice: true
  })

  private onInvoiceSelectToAdd = (invoiceToAdd: PurchaseInvoice) => this.setState({
    invoiceToAdd
  })

  private onInvoiceClose = () => this.setState({
    invoice: new PurchaseInvoice({}),
    showInvoice: false
  })

  private save = async () => {
    try {
      const contract = new Contract(this.state.contract);
      const lessee = new Lessee({
        id: session.lesseeId,
        name: session.lesseeName
      });
      contract.lessee = lessee;
      contract.leasingCompany = contract.leasingCompany.exists ? contract.leasingCompany : this.state.siemens;
      await ContractRepository.save(contract);
      this.setState({ changed: false })
      this.props.onClose();
    } catch (e) {
      Notifier.failure(`Error while saving Contract: ${JSON.stringify((e as any).response.data)}`)
    }
  }

  private attachPurchaseInvoice = () => this.setState({ isLoading: true }, () => {
    const newInvoice = produce(this.state.invoiceToAdd, newInvoice => {
      newInvoice.contract = this.state.contract;
    });
    PurchaseInvoiceRepository.save(newInvoice).then(this.fetch).then(() => this.setState({
      invoiceToAdd: new PurchaseInvoice({})
    }));
  })

  private renderPurchaseInvoicePicker = () => {
    return <FlexRow className="purchase-invoice-picker">
      <PurchaseInvoiceSelect
        fill={true}
        activeItem={this.state.invoiceToAdd}
        onSelect={this.onInvoiceSelectToAdd}
        cancelable={true}
        hint="Add Purchase Invoice"
      />
      <Button
        icon="tick"
        intent="success"
        minimal
        onClick={this.attachPurchaseInvoice}
      />
    </FlexRow>
  }

  private renderAttachments() {
    const invoices = this.state.contract.purchaseInvoices.filter(i => i.attachments.length > 0);
    return invoices.map(invoice => <div key={`invoice-attachments-${invoice.id}`}>
      <FlexRow className="invoice-attachments-title">
        <div style={{ width: 100 }} className="d-f ai-c">
          { invoice.date ? formatDate(invoice.date) : '' }
        </div>
        <div className="f-1 bold d-f ai-c">
          <Button
            small
            minimal
            intent="primary"
            rightIcon="chevron-right"
            text={ invoice.code }
            onClick={() => this.onInvoiceSelect(invoice)}
          />
        </div>
        <div className="f-2 d-f ai-c">
          { invoice.title }
        </div>
      </FlexRow>
      <FlexColumn className="attachments-container">
        { invoice.attachments.map(attachment => <PurchaseInvoiceAttachmentView
          key={`invoice-attachments-row-${attachment.id}`}
          attachment={attachment}
        />)}
      </FlexColumn>
    </div>)
  }

  public render() {
    const { t } = this.props;
    return (
      <Dialog
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        style={{ width: 960 }}
        className="bp3-dark"
        title={`${capitalize(t('contract'))}  ${this.props.contract.number}`}>
        <div>
          { !this.state.isLoading && <Tabs selectedTabId={this.state.tab} onChange={this.onTabChange} className="f-1 contract-tabs m-24">
            <Tab id="contract" title={t('contract')} panel={
              <div className="p-12">
                <ContractForm
                  fill={true}
                  fields={this.getFields()}
                  item={this.state.contract}
                  inline={true}
                  onChange={this.onChange}
                />
              </div>
            }/>
            {/* <Tab id="attachments" title={t('attachments')} panel={
              <FlexColumn flex={1}>
                { this.renderPurchaseInvoicePicker() }
                { this.renderAttachments( ) }
              </FlexColumn>
            }/> */}
            <Tab id="assets" title={t('assets.title')} panel={
              <ContractAssets
                contract={this.props.contract}
                onChange={() => {}}
                onInvoiceSelect={this.onInvoiceSelect}
              />
            }/>

          </Tabs> }
        </div>
        <div className={`${Classes.DIALOG_BODY} contract-detail`}>
          { this.state.isLoading && <NonIdealState description={t('contracts.loading_contract') as string}>
            <Spinner size={48}/>
          </NonIdealState> }
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <SaveButton
            disabled={!this.state.changed && !(session.user.isAdmin || session.user.isSuperuser)}
            fill={true}
            onClick={this.save}
          />
        </div>

        <PurchaseOrderDetail
          invoice={this.state.invoice}
          isOpen={this.state.showInvoice}
          onClose={this.onInvoiceClose}
        />
      </Dialog>
    );
  }
}

export default withTranslation()(ContractDetail)
