import { Button, Callout, Icon, NonIdealState, Spinner } from '@blueprintjs/core';
import { FlexRow } from '@patterns/ui';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { axios } from '../../session';
import { SiemensProposal } from './calculation.form';

export interface SiemensProposalDocument {
  id: {
    value: number;
    type: 'DOCUMENT_ID' | 'DOCUMENT_TYPE';
  };
  documentType: {
    id: number;
    code: string;
    name: string;
  };
  downloadAllowed: boolean;
  downloadSignedAllowed: boolean;
  sendByEmailAllowed: boolean;
  required: boolean;
  received: boolean;
  receivedDate?: string;
  valid?: string;
  validationDate?: string;
  reason?: string;
  signingEnabled: boolean;
  sendSigningRequestAllowed: boolean;
  signingRequest?: {
    status: string;
  };
  alias?: string;
  fileName?: string;
}

export interface Props extends WithTranslation {
  onChange: () => void;
  proposal: SiemensProposal;
}

export interface State {
  isLoading: boolean;
  documents: SiemensProposalDocument[];
}

export class Documents extends React.Component<Props, State> {
  state = {
    isLoading: false,
    documents: [] as SiemensProposalDocument[]
  }

  componentDidMount = async () => {
    this.fetch()
  }

  private fetch = async () => {
    this.setState({ isLoading: true }, async () => {
      const { data } = await axios.get(`/siemens/proposals/${this.props.proposal.id}/documents`);
      const documents = (data.documents as SiemensProposalDocument[]).filter(doc => doc.sendSigningRequestAllowed)
      this.setState({ documents, isLoading: false });
    })
  }

  private sign = async () => {
    this.setState({ isLoading: true }, async () => {
      const signatoryIds = this.props.proposal.signatories.map(signatory => signatory.id);
      const documentIds = this.state.documents.map(doc => doc.id);
      await Promise.all(documentIds.map(id => {
        return axios.post(`/siemens/proposals/${this.props.proposal.id}/documents/sign`, {
          documentIds: [ id ],
          signatoryIds
        })
      }))
      
      this.props.onChange();
      this.fetch()
    })
  }

  private renderItems = () => {
    return this.state.documents.map((document, index) => {
      return <FlexRow key={`document-${index}`} className="ai-c" style={{ minHeight: 36 }}>
        <div className="f-1"><Icon icon="document" className="m-r-6"/> { document.documentType.name }</div>
        <div className="f-1">{ document.signingRequest?.status ?? ''}</div>
      </FlexRow>
    })
  }

  public render() {
    const { t } = this.props;
    if (!this.props.proposal.decision) {
      return <div>
        <h2>{t('proposals.documents_to_sign')}</h2>
        <Callout intent="none">{t('proposals.request_decision_first')}</Callout>
      </div>
    }

    if (this.props.proposal.signatories.length === 0) {
      return <div>
        <h2>{t('proposals.documents_to_sign')}</h2>
        <Callout intent="none">{t('proposals.add_signatories_first')}</Callout>
      </div>
    }

    return (
      <div>
        <h2>{t('proposals.documents_to_sign')}</h2>
        <div className="dark-box">
          { this.renderItems() }

          { !this.state.isLoading && this.state.documents.length > 0 && <div className="f-1 m-t-24">
            <Button text={t('proposals.send_documents')} icon="send-message" onClick={() => this.sign()} intent="primary"/>
          </div> }
          
          { !this.state.isLoading && this.state.documents.length === 0 && <div className="f-1">
            <Callout intent="success">{t('proposals.all_sent_for_signing')}</Callout>
          </div> }

          { this.state.isLoading && <NonIdealState><Spinner size={36}/></NonIdealState> }
        </div>
      </div>
    );
  }
}

export default withTranslation()(Documents)
