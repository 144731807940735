import { BaseModel, Selectable, Resource, deserializeString, deserializeDate } from '@patterns/core'
import { SiemensOffice, SiemensProposalAsset, SiemensProposalAssetSummary, SiemensProposalCalculation, SiemensProposalCurrentCalculation, SiemensProposalStatus, SiemensSeller } from '../siemens.manager'

@Resource('proposals')
export class Proposal extends BaseModel implements Selectable {
  proposalNumber: string = '';
  customer: any;
  office?: SiemensOffice;
  salesContact?: SiemensSeller;
  salesContactId: number = 0;
  label: string = '';
  customerBpRef?: {
    registrationNumber: number;
    companyType: null;
  };
  assets?: SiemensProposalAsset[];
  calculation?: SiemensProposalCalculation;
  status?: SiemensProposalStatus;
  data: any;
  assetSummary?: SiemensProposalAssetSummary;
  currentCalculation?: SiemensProposalCurrentCalculation;
  statusLabel?: string;
  customerName?: string;
  assetCount?: number;
  assetValue?: number;
  monthlyPayment?: number;

  constructor(data: any = { data: {} }) {
    super(data);
    this.data = data;

    if (data.data) {
      this.createdAt = deserializeDate(data.data.created);
      this.proposalNumber = deserializeString(data.data.proposalNumber);
      this.office = data.data.office;
      this.customer = data.data.customer;
      this.salesContact = data.data.salesContact;
      this.label = deserializeString(data.data.label);
      this.customerBpRef = data.data.customerBpRef;
      this.assets = data.data.assets || [];
      this.calculation = data.data.calculation;
      this.status = data.data.status;
      this.assetSummary = data.data.assetSummary;
      this.currentCalculation = data.data.currentCalculation;
      this.statusLabel = this.status?.status;
      this.customerName = this.customer.businessPartner.name;
      this.assetCount = this.assetSummary?.numberOfAssets;
      this.assetValue = this.assetSummary?.totalAssetValue;
      this.monthlyPayment = this.currentCalculation?.payment.paymentPerMonth;
    }

  }

  getLabel() { return null }
  getTitle() { return this.label }
}
