/* eslint-disable react/style-prop-object */
import mapboxgl from 'mapbox-gl';
import * as React from 'react';
import ReactMapboxGl, { Feature, Layer } from 'react-mapbox-gl';
import { marker } from './icons';
import { AppContext } from '../app_context';
import { axios } from '../common';

export const MapboxToken = 'pk.eyJ1IjoidG10aWV0b295IiwiYSI6ImNpdHBoNHJxMzAwMDQyeXFvZnhid2F0MDEifQ.zc5cpODp4-9Xrk1yppJU0g';

const styleURL = 'mapbox://styles/tmtietooy/ckk7a25dg073717p12yg4igp9';

const Map = ReactMapboxGl({
  accessToken: MapboxToken
});


export interface Props {
  lat: number
  lng: number
  zoom?: number
}

export interface State {
  features: any[];
  syncKey: number;
}

export class MapView extends React.Component<Props, State> {
  static contextType = AppContext;

  state = { syncKey: new Date().getTime(), features: [] }

  map?: mapboxgl.Map

  componentDidMount() {
    (window as any).koko = this;
  }

  private fetch = async () => {
    const { data: assets } = await axios.get('/assets/map');
    
    const features = assets.map((asset: any) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [ parseFloat(asset.office.lng), parseFloat(asset.office.lat) ]
      },
      properties: {
        id: asset.id,
        title: asset.name
      }
    }));

    this.setState({ features }, () => {
      features.forEach((feature: any) => {
        const icon = <img alt="marker" className='toolbar-icon' src={marker}/>
        const _marker = new mapboxgl.Marker(icon as any)
          .setLngLat(feature.geometry.coordinates) as any
        _marker.addTo(this.map)
      });
      if (features && features.length > 0) {
        this.map?.setCenter(features[0].geometry.coordinates)
      }
    })
  }

  public render() {
    const zoom = this.props.zoom || 15;

    return (<Map
      style={styleURL}
      containerStyle={{
        width: '100%',
        height: '100%'
      }}
      onStyleLoad={(map) => {
        this.map = map;
        
        this.fetch();
        // this.map.current?.addImage('marker', marker);
        // const icon = <img alt="marker" className='toolbar-icon' src={marker}/>
        // const _marker = new mapboxgl.Marker(icon as any)
        //   .setLngLat([this.props.lng, this.props.lat]) as any
        // _marker.addTo(this.map)
      }}
      center={[this.props.lng, this.props.lat]}
      zoom={[zoom]}>
      
      <Layer
        type="symbol" 
        id="marker" 
        layout={{ 'icon-image': 'marker' }}>
        <Feature coordinates={[ this.props.lng, this.props.lat ]} />
      </Layer>
    </Map>);
  }
}
