import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PieWidget } from '../pie_widget';

export interface Props extends WithTranslation {
  height: number
  width: number
  legend?: boolean
}

export function DeviceManufacturerChart({ height, legend, width, t }: Props) {
  return (
    <PieWidget 
      title={t('charts.devices_per_manufacturer')}
      url="/devices/manufacturer_chart"
      legend={legend}
      width={width}
      height={height}
    />
  );
}

export default withTranslation()(DeviceManufacturerChart)
