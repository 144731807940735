import * as React from 'react';
import { ProgressBar } from '@blueprintjs/core';
import EventBus, { Events } from '../event_bus'


export interface Props {
}

export function LoadingBar(props: Props) {
  const [show, setShow] = React.useState(false)
  React.useEffect(() => {
    const observer = (event?: string, data?: string) => {
      if (event === Events.LOADING_START) {
        setShow(true)
        return
      }

      if (event === Events.LOADING_STOP) {
        setShow(false)
      }
    }
    
    EventBus.observe(observer)
    return () => {
      EventBus.cancel(observer)
    }
  }, [])

  return (
    <div className="loading-bar">
      { show && <ProgressBar intent="primary"/> }
    </div>
  );
}
