import * as React from 'react';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import { CostCenterRepository } from '../../repository';
import { CostCenter } from '../../models/cost_center';

const CostCentersTable = DataTable.ofType<CostCenter>();

const columns = [
  {
    id: 'name',
    title: 'Name',
    type: ColumnType.Text,
    sortable: true,
    filterable: true,
    alignment: Alignment.Left,
    flex: 1,
    visible: true
  },
  {
    id: 'type',
    title: 'Type',
    type: ColumnType.Text,
    sortable: false,
    filterable: false,
    alignment: Alignment.Left,
    flex: 1,
    visible: true,
    format: (costcenter) => <span>
      {`${costcenter.parent ? 'Sub' : 'Parent'} Cost Center`}
    </span>
  },
  {
    id: 'parent',
    title: 'Parent CostCenter',
    type: ColumnType.Custom,
    sortable: true,
    filterable: false,
    alignment: Alignment.Left,
    flex: 1,
    visible: true,
    format: costcenter => <span>{ costcenter.parent?.name ?? '' }</span>
  },
  {
    id: 'updatedAt',
    title: 'Updated At',
    type: ColumnType.DateTime,
    sortable: true,
    filterable: false,
    alignment: Alignment.Left,
    width: 140,
    visible: true
  }
] as Column<CostCenter>[]

export interface Props {
  onSelect: (selected: CostCenter) => void
}

export interface State {
  selected: CostCenter
  showDialog: boolean
}

export class CostCentersView extends React.Component<Props, State> {
  cancelToken: any;

  state = {
    selected: new CostCenter({}),
    showDialog: false
  } as State

  tableRef = React.createRef<any>();

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await CostCenterRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken
    );
    this.cancelToken = cancelToken
    return { items, total }
  }

  private onSelect = (selected: CostCenter) => this.setState({ 
    selected,
    showDialog: true 
  }, () => {
    this.props.onSelect(selected)
  })

  public render() {
    return (
      <div>
        <CostCentersTable
          id="cost-centers-table"
          ref={this.tableRef}
          columns={columns}
          fetch={this.fetch}
          sort="name"
          sortDir="asc"
          onItemSelect={this.onSelect}
        />
      </div>
    );
  }
}
