import { BaseModel, Selectable, Resource, deserializeString } from '@patterns/core'

@Resource('offices')
export class Office extends BaseModel implements Selectable {
  name: string;
  address: string;
  street: string;
  street_2: string;
  city: string;
  postalCode: string;

  lat: number;
  lng: number;
  contactName: string;
  contactPhone: string;
  contactEmail: string;

  constructor(data: any = {}) {
    super(data);
    this.name = deserializeString(data.name);
    this.address = deserializeString(data.address);
    this.lat = parseFloat(data.lat || '0');
    this.lng = parseFloat(data.lng || '0');
    this.contactName = deserializeString(data.contactName);
    this.contactPhone = deserializeString(data.contactPhone);
    this.contactEmail = deserializeString(data.contactEmail);
    this.street = deserializeString(data.street);
    this.street_2 = deserializeString(data.street_2);
    this.city = deserializeString(data.city);
    this.postalCode = deserializeString(data.postalCode);
  }

  getLabel() { return '' }
  getTitle() { return this.name }
}