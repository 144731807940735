import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs } from '../components/breadcrumb';
import { ExtendedContractAssets } from '../components/eol/extended';
import { MainLayout } from '../layouts/main';

interface Props extends RouteComponentProps, WithTranslation {}

export function ExtendedLeases(props: Props) {
  const { t } = props;
  return (
    <MainLayout title={t('extended_leases.title')}>
      <Breadcrumbs path={`${t('end_of_lease')} / ${t('extended_leases.title')}`} title={t('extended_leases.title')}/>
      <ExtendedContractAssets {...props} />
    </MainLayout>
  )
}

export default withTranslation()(ExtendedLeases)