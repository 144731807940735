import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs } from '../components/breadcrumb';
import EOLManagement from '../components/eol/management';
import { MainLayout } from '../layouts/main';

interface Props extends WithTranslation, RouteComponentProps {}

export function EndingLeases({ t, history }: Props) {
  const reload = () => {}
  return (
    <MainLayout title={t('ending_leases.title')}>
      <Breadcrumbs path={`${t('end_of_lease')} / ${t('ending_leases.title')}`} title={t('ending_leases.title')}/>
      <EOLManagement reload={reload}/>
    </MainLayout>
  )
}

export default withTranslation()(EndingLeases)