import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import produce from 'immer';
import { deepEqual } from '@patterns/core';
import { axios } from '../../common';
import { AppContext, AppContextType } from '../../app_context';
import { Highcharts, HighChartsTheme } from '../../highcharts';

export interface Props {
  height: number
  width: number
  legend?: boolean
}

export interface State {
  options: Highcharts.Options
}

export class TCOBarChart extends React.Component<Props, State> {
  static contextType = AppContext;
  private contextCopy = produce(this.context, () => {});

  private options: Highcharts.Options = {
    chart: {
      animation: false,
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      plotBorderWidth: undefined,
      plotShadow: false,
      type: 'column',
      options3d: {
        enabled: true,
        alpha: 10,
        beta: 0,
        viewDistance: 55
      }
    },
    xAxis: {
      categories: ['Loading'],
      labels: {
        skew3d: true,
        style: {
          fontSize: '14px'
        }
      }
    },
  
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: 'Value',
        skew3d: true
      }
    },
  
    tooltip: {
      headerFormat: '<b>{point.key}</b><br>',
      pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
    },
  
    plotOptions: {
      column: {
        stacking: 'normal',
        depth: 40
      },
      series: {
        animation: false  
      }
    },

    series: [{
      name: 'Month0',
      type: 'column',
      showInLegend: true,
      data: [
        { name: 'Loading...', y: 0 }
      ]
    }]
  }

  constructor(props: Props, context: AppContextType) {
    super(props);
    this.options.title = {...context.chartTheme, text: 'Extra Costs' }

    this.state = {
      options: HighChartsTheme(this.options)
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.contextCopy, this.context)) {
      this.contextCopy = this.context;
      this.setState({
        options: HighChartsTheme(this.state.options)
      })
    }
  }

  componentDidMount() {
    this.fetch()
  }

  public fetch = async () => {
    const response = await axios.get('/extra_costs/asset_costs_chart');
    const items = response.data;
    const data = items.length === 0 ? [{ name: 'None', y: 0 }] : response.data;
    const options = HighChartsTheme(produce(this.state.options, options => {
      options.title!.text = 'Extra Costs';
      const categories = [] as string[];
      const seriesNames = [] as string[];

      data.forEach((i: any) => {
        if (!categories.includes(i.type)) {
          categories.push(i.type)
        }

        if (!seriesNames.includes(i.title)) {
          seriesNames.push(i.title)
        }
      });
        
      const series = [] as any[];
      seriesNames.forEach(serie => {
        const seriesIdx = seriesNames.findIndex(s => s === serie);
        categories.forEach(category => {
          const item = data.find((i: any) => i.type === category && i.title === serie);
          if (!series[seriesIdx]) {
            series[seriesIdx] = {
              name: serie,
              data: [ item ? item.y : 0 ]
            }
          } else {
            series[seriesIdx].data.push( item ? item.y : 0 )
          }
        })
      })
      
      options.xAxis = { categories };
      options.series = series
    }));
    this.setState({ options })
  }

  public render() {
    const { height, width } = this.props;
    
    return (
      <div className="chart-container" style={{ height, width }}>
        <div className="chart-inner-container">
          <HighchartsReact
            highcharts={Highcharts}
            options={this.state.options}
            immutable={true}
          />
        </div>
      </div>
    );
  }
}
