import * as React from 'react';
import { Select } from '@blueprintjs/select'
import { Category } from '../models/category';
import { FormGroup, Button, MenuItem, ButtonGroup, Intent } from '@blueprintjs/core';
import { CategoryRepository } from '../repository';
import { withTranslation, WithTranslation } from 'react-i18next';

const BlueprintSelect = Select.ofType<Category>();

export interface Props extends WithTranslation {
  inline?: boolean
  label?: string
  minimal?: boolean
  disabled?: boolean
  category: Category
  intent?: Intent
  onChange: (category?: Category) => void
}

export function CategorySelect({ onChange, category, disabled, i18n, label, inline = false, minimal = true }: Props) {
  const [categories, setCategories] = React.useState([] as Category[]);
  const [query, setQuery] = React.useState('');
  
  React.useEffect(() => {
    let _cancelToken: () => void = () => {};

    const fetch = async () => {
      const { items, cancelToken } = await CategoryRepository.search(query, '', _cancelToken);
      _cancelToken = cancelToken as any;
      setCategories(items)
    };

    fetch()
  }, [query]);

  const cancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setQuery('');
    setCategories([]);
    onChange(undefined);
  }
  

  return (
    <FormGroup inline={inline} label={label}>
      <BlueprintSelect
        filterable={true}
        disabled={disabled}
        items={categories}
        activeItem={category}
        itemRenderer={(item, options) => <MenuItem 
          key={item.id}
          text={i18n.language === 'fi' ? item.name_fi : item.name_en}
          label={i18n.language === 'fi' ? (item.parent?.name_fi ?? '') : (item.parent?.name_en) ?? ''}
          onClick={options.handleClick}
        />}
        query={query}
        onQueryChange={setQuery}
        onItemSelect={category => {
          onChange(category)}
        }
      >
        <ButtonGroup>
          <Button disabled={disabled} text={category.exists ? (i18n.language === 'fi' ? category.name_fi : category.name_en) : i18n.t('store.select_category')} rightIcon="chevron-down" minimal fill/>
          { category.exists && <Button icon="cross" minimal={minimal} onClick={cancel}/> }
        </ButtonGroup>
      </BlueprintSelect>
    </FormGroup>
  );
}

export default withTranslation()(CategorySelect)