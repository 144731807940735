import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PiecePieWidget } from '../piece_pie_widget';

export interface Props extends WithTranslation {
  height: number
  width: number
  legend?: boolean
  onPointClick?: (data: any) => void
  params?: string
}

export function AssetAgeChart({ height, width, legend, onPointClick, params, t }: Props) {
  return (
    <PiecePieWidget 
      title={t('charts.asset_age_chart')}
      url={`/assets/age_chart?${params}`}
      legend={legend}
      width={width}
      height={height}
      onPointClick={onPointClick}
    />
  );
}

export default withTranslation()(AssetAgeChart)