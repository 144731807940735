import * as React from 'react';

import { Select } from '@blueprintjs/select';
import { MenuItem, Button, ButtonGroup } from '@blueprintjs/core';
import { withTranslation, WithTranslation } from 'react-i18next';

export type Term = { value: number, title: string };

const BlueprintSelect = Select.ofType<Term>()

export interface Props extends WithTranslation {
  disabled?: boolean;
  term: number
  onSelect: (term: number) => void
}

export interface State {
  term: Term
}

export class DurationSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let term = this.getTerms().find(m => m.value === props.term);
    if (!term) {
      term = { value: props.term, title: `${props.term} ${this.props.i18n.t('contracts.months_short')}` }
    }

    this.state = { term }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.term !== this.props.term) {
      let term = this.getTerms().find(m => m.value === this.props.term);
      if (!term) {
        term = { value: this.props.term, title: `${this.props.term} ${this.props.i18n.t('contracts.months_short')}` }
      }

      this.setState({ term })
    }
  }

  private getTerms = () => {
    const values = [ 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 48, 60 ];
    return values.map(value => ({ value, title: `${value} ${this.props.i18n.t('contracts.months_short')}` } as Term));
  }

  private onTermSelect = (term: Term) => this.setState({ term }, () => {
    this.setState({ term });
    this.props.onSelect(term.value)
  })

  private renderer = (item: Term, options: any) => <MenuItem
    key={`item-renderer-${item.value}`}
    active={item.value === this.state.term.value}
    text={item.title}
    onClick={options.handleClick}
  />

  public render() {
    return (
      <ButtonGroup>
        <BlueprintSelect
          activeItem={this.state.term}
          filterable={false}
          disabled={this.props.disabled}
          items={this.getTerms()}
          itemRenderer={this.renderer}
          onItemSelect={this.onTermSelect}>
          <Button text={this.state.term.title} rightIcon="chevron-down" minimal/>
        </BlueprintSelect>
      </ButtonGroup>
    );
  }
}

export default withTranslation()(DurationSelect)