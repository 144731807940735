import * as React from 'react';
import { Dialog, Classes, FormGroup, InputGroup, Button, NumericInput, Colors, MenuItem, Tag, Checkbox } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import { Category } from '../../models/category';
import { FlexRow } from '@patterns/ui';
import deepEqual from 'deep-equal';
import produce from 'immer';
import CategorySelect from '../category_select';
import { ConfirmDelete } from '../confirm_delete';
import FormBuilder from '../form_builder';
import { CustomFormField } from '../../models/custom_form_field';
import { ExtraCost } from '../../models/extra_cost';
import { ExtraCostRepository } from '../../repository';
import { CategoryExtraCost } from '../../models/category_extra_cost';
import SiemensManagerInstance from '../../siemens.manager';
import { withTranslation, WithTranslation } from 'react-i18next';
import TermSelect from '../term_select';
import { MultiplierSelect } from '../multiplier_select';

const ExtraCostSelect = MultiSelect.ofType<ExtraCost>();

export interface Props extends WithTranslation {
  isOpen: boolean
  onClose: () => void
  onSave: (category: Category) => void
  onDelete: (category: Category) => void
  category: Category
}

export interface State {
  icon: string
  name_en: string
  name_fi: string
  lifecycle: number
  multiplier: number
  extendedLeasingPrice: number
  residualValue: number
  reclaimPrice: number
  visible: boolean
  parent?: Category
  fields: CustomFormField[]
  categoryExtraCosts: CategoryExtraCost[]
  extraCosts: ExtraCost[]
}

export class CategoryDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { name_en, name_fi, lifecycle, parent, fields, categoryExtraCosts, residualValue, extendedLeasingPrice, reclaimPrice, visible, multiplier } = this.props.category;
    this.state = { name_en, name_fi, lifecycle, parent, fields, categoryExtraCosts, extraCosts: [], residualValue, extendedLeasingPrice, reclaimPrice, multiplier, icon: '', visible }
  }

  componentDidMount() {
    this.fetchExtraCosts()
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.category, prevProps.category)) {
      const { name_en, name_fi, lifecycle, parent, fields, categoryExtraCosts, residualValue, extendedLeasingPrice, reclaimPrice, visible, multiplier } = this.props.category;
      this.setState({ name_en, name_fi, lifecycle, parent, fields, categoryExtraCosts, extraCosts: [], residualValue, extendedLeasingPrice, reclaimPrice, visible, multiplier }, () => this.fetchExtraCosts())
    }
  }

  private fetchExtraCosts = async () => {
    const { items } = await ExtraCostRepository.index(0, 1000, 'title', 'asc');
    this.setState({ extraCosts: items })
  }

  async save() {
    const category = produce(this.props.category, category => {
      category.name_en = this.state.name_en.trim();
      category.name_fi = this.state.name_fi.trim();
      category.lifecycle = this.state.lifecycle;
      category.parent = this.state.parent;
      category.fields = this.state.fields;
      category.residualValue = this.state.residualValue;
      category.extendedLeasingPrice = this.state.extendedLeasingPrice;
      category.reclaimPrice = this.state.reclaimPrice;
      category.categoryExtraCosts = this.state.categoryExtraCosts;
      category.icon = this.state.icon;
      category.visible = this.state.visible;
      category.multiplier = this.state.multiplier;
    });
    this.props.onSave(category);
  }

  private extraCostRenderer = (extraCost: ExtraCost, options: any) => <MenuItem
    key={`extra-cost-item-${extraCost.id}`}
    text={extraCost.title}
    label={extraCost.type}
    onClick={options.handleClick}
  />

  private extraCostTagRenderer = (extraCost: ExtraCost) => <Tag>{extraCost.title}</Tag>

  private onExtraCostSelect = (extraCost: ExtraCost) => {
    const categoryExtraCosts = produce(this.state.categoryExtraCosts, categoryExtraCosts => {
      categoryExtraCosts.push(new CategoryExtraCost({
        categoryId: this.props.category.id,
        extraCostId: extraCost.id,
        category: this.props.category,
        extraCost: extraCost
      }))
    });

    this.setState({ categoryExtraCosts })
  }

  private handleTagRemove = async (value: any, index: number) => {
    const categoryExtraCosts = produce(this.state.categoryExtraCosts, categoryExtraCosts => {
      categoryExtraCosts.splice(index, 1);
    })
    this.setState({ categoryExtraCosts })
  }

  private onIconChange = (icon: string) => {
    this.setState({ icon })
  }

  private onTermSelect = (term: number) => { this.setState({ lifecycle: term }) }

  // private onMultiplierSelect = (multiplier: number, residualValue: number) => {
  //   this.setState({ multiplier, residualValue })
  // }
  public render() {
    const { t } = this.props;
    return (
      <Dialog 
        className="form-align-left bp3-dark"
        style={{ width: 1024 }}
        title={t('category')}
        isOpen={this.props.isOpen}
        canOutsideClickClose={false}
        onClose={this.props.onClose}>
          <div className={Classes.DIALOG_BODY} style={{ width: '100%' }}>
              <FlexRow flex={1}>
                <FormGroup inline label={t('name_en')} style={{ width: '50%' }}>
                  <InputGroup 
                    fill
                    value={this.state.name_en}
                    onChange={(evt: any) => this.setState({ name_en: evt.currentTarget.value })}
                  />
                </FormGroup>

                <FlexRow className="m-l-24">
                  <CategorySelect
                    label={t('parent_category')}
                    inline={true}
                    category={this.state.parent || new Category({})}
                    onChange={parent => this.setState({ parent })}
                  />
                </FlexRow>
               
              </FlexRow>

              <FlexRow>
                <FormGroup inline label={t('name_fi')} style={{ width: '50%' }}>
                  <InputGroup
                    fill
                    value={this.state.name_fi}
                    onChange={(evt: any) => this.setState({ name_fi: evt.currentTarget.value })}
                  />
                </FormGroup>

                <FormGroup inline label={t('assets.lifecycle')} className="m-l-24">
                  <TermSelect 
                    onSelect={this.onTermSelect}
                    term={this.state.lifecycle}
                  />
                </FormGroup>  
              </FlexRow>
              
              <FlexRow>
                <FormGroup inline label={t('extended_leasing_price')} style={{ width: '50%' }}>
                  <NumericInput
                    buttonPosition="none"
                    fill
                    value={this.state.extendedLeasingPrice}
                    onValueChange={(value: number) => this.setState({ extendedLeasingPrice: value })}
                  />
                </FormGroup>
{/* 
                <FlexRow className="m-l-24">
                  <FormGroup inline label={t('multiplier')}>
                    <MultiplierSelect 
                      term={this.state.lifecycle}
                      multiplier={this.state.multiplier}
                      onSelect={this.onMultiplierSelect}
                    />
                  </FormGroup>
                </FlexRow> */}
              </FlexRow>

              <FlexRow>
                <FormGroup inline label={t('residual_value_percent')} style={{ width: '50%' }}>
                  <NumericInput
                    buttonPosition="none"
                    fill
                    value={parseFloat(this.state.residualValue as unknown as string).toFixed(0)}
                    onValueChange={(value: number) => this.setState({ residualValue: value })}
                  />
                </FormGroup>
                {/* <FlexRow className="m-l-24">
                  <FormGroup label={t('icon')} inline={true}>
                    <FontIconPicker 
                      icons={fontAwesome}
                      renderUsing="class"
                      value={this.state.icon}
                      onChange={this.onIconChange}
                      convertHex={true}
                    />
                  </FormGroup>
                </FlexRow> */}

                <FlexRow className="m-l-24">
                  <FormGroup inline label={t('reclaim_price')}>
                    <NumericInput
                      buttonPosition="none"
                      fill
                      value={this.state.reclaimPrice}
                      onValueChange={(value: number) => this.setState({ reclaimPrice: value })}
                    />
                  </FormGroup>
                </FlexRow>
              </FlexRow>

              <FlexRow>
                <FormGroup inline label={t('visible')} style={{ width: '50%' }}>
                  <Checkbox
                    checked={this.state.visible}
                    onClick={evt => this.setState({ visible: evt.currentTarget.checked })}
                  />
                </FormGroup>

                { this.props.category.categoryType === 'siemens' && <FlexRow className="m-l-24">
                  <FormGroup inline label={t('original_name')}>
                    <InputGroup
                      fill
                      defaultValue={SiemensManagerInstance.getSubType(this.props.category.subTypeId)?.name}
                      readOnly={true}
                    />
                  </FormGroup>
                </FlexRow>}

              </FlexRow>

              <h4>{t('settings.extra_asset_fields')}</h4>
              <FlexRow className="m-r-24">
                <FormBuilder fields={this.state.fields} onChange={fields => this.setState({ fields })}/>
              </FlexRow>
              <h4>{t('settings.extra_cost_fields')}</h4>
              <FlexRow className="m-r-24 p-r-12">
                <ExtraCostSelect
                  fill={true}
                  itemRenderer={this.extraCostRenderer}
                  items={this.state.extraCosts}
                  tagRenderer={this.extraCostTagRenderer}
                  selectedItems={this.state.categoryExtraCosts.map(ce => ce.extraCost)}
                  onItemSelect={this.onExtraCostSelect}
                  tagInputProps={{
                    onRemove: this.handleTagRemove,
                    rightElement: <Button icon="cross" small/>
                  }}
                />
              </FlexRow>
          </div>
          <div className={Classes.DIALOG_FOOTER} style={{ borderTop: `1px solid ${Colors.DARK_GRAY2}`}}>
            <FlexRow className="f-1 m-t-24">
              <FlexRow className="f-1 jc-s">
                <ConfirmDelete
                  outlined={true}
                  title={t('delete')}
                  onConfirm={() => this.props.onDelete(this.props.category)}
                />
              </FlexRow>
              <FlexRow className="f-2 jc-e">
                <Button outlined text="Close" onClick={this.props.onClose} className="m-r-12"/>
                <Button outlined text="Save" intent="primary" onClick={() => this.save()} />
              </FlexRow>
            </FlexRow>
          </div>
      </Dialog>
    );
  }
}

export default withTranslation()(CategoryDialog)