import * as React from 'react';
import DataStore from '../data_store';

import {
  Switch,
  Route
} from "react-router-dom";

import { Logout } from './logout';
import Dashboard from './dashboard';
import Settings from './settings';
import { AcceptanceTemplates } from './acceptance_templates';
import Assets from './assets';
import Contracts from './contracts';
import { Organizations } from './organizations';
import AssetReports from './asset_reports';
import ContractReports from './contract_reports';
import FinanceReports from './finance_reports';
import EOL from './eol';
import PurchaseInvoices from './purchase_invoices';
import Devices from './devices';
import ReplacementDevices from './replacement_devices';
import ExtraCosts from '../components/settings/extra_costs';
import { Overview } from './overview';
import Report from './report';
import Reports from './reports';
import EndingLeases from './ending_leases';
import ExtendedLeases from './extended_leases';
import PurchasedLeases from './purchased_leases';
import ReturnedAssets from './returned_leases';
import { Invoices } from './invoices';
import TCOReports from '../components/reports/tcoreports';
import ExtraServices from '../components/settings/extra_services';
import StoreSettings from './store_settings';
import { Verkkokauppa } from '../components/shop/verkkokauppa';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import AssetPage from './asset';
import Products from '../components/shop/products';
import VerkkokauppaConfirm from '../components/shop/verkkokauppa_confirm';
import { Dustin } from '../components/shop/dustin';
import DustinConfirm from '../components/shop/dustin_confirm';
import StoreConfirm from '../components/shop/confirm';
import LeaseLiabilities from '../components/lease_liabilities';
import AcceptPage from '../pages/accept';
import RejectPage from '../pages/reject';
import { SiemensPricelists } from '../components/settings/siemens_pricelists';

// import Products from '../components/shop/products';

export interface Props {
  onLogout: () => void
}

export interface State {
  isLoading: boolean
}

export class Main extends React.Component<Props, State> {
  state = { isLoading: true }

  componentDidMount() {
    this.fetch()
  }

  async fetch() {
    this.setState({ isLoading: true }, async () => {
      await DataStore.initialize();
      this.setState({ isLoading: false })
    })
  }

  public render() {
    if (this.state.isLoading) {
      return <NonIdealState className="loader-main">
        <div className="container">
          <Spinner size={96} className="m-t-24" intent="success"/>
        </div>
      </NonIdealState>
    }

    return (
      <Switch>
        <Route exact path="/" component={Dashboard}/>
        <Route path="/acceptance_templates" component={AcceptanceTemplates}/>
        <Route exact path="/assets" component={Assets}/>
        <Route exact path="/assets/:id" component={AssetPage}/>
        <Route exact path="/assets/eol" component={EOL}/>
        <Route exact path="/assets/reports" component={AssetReports}/>
        <Route exact path="/extra_costs" component={ExtraCosts}/>
        <Route exact path="/contracts" component={Contracts}/>
        <Route exact path="/expired_contracts" component={Contracts}/>
        <Route exact path="/contracts/reports" component={ContractReports}/>
        <Route exact path="/contracts/overview" component={LeaseLiabilities}/>
        <Route path="/extra_services" component={ExtraServices}/>
        <Route path="/ending_leases" component={EndingLeases}/>
        <Route path="/extended_leases" component={ExtendedLeases}/>
        <Route path="/purchased_leases" component={PurchasedLeases}/>
        <Route path="/asset_returns" component={ReturnedAssets}/>
        <Route path="/contracts/:id" component={Contracts}/>
        <Route path="/report" component={Report}/>
        <Route path="/fleet" component={Devices}/>
        <Route path="/invoices" component={Invoices}/>
        <Route path="/store/products" component={Products}/>
        <Route path="/store/confirm" component={StoreConfirm}/>
        <Route path="/store/verkkokauppa/confirm" component={VerkkokauppaConfirm}/>
        <Route path="/store/verkkokauppa" component={Verkkokauppa}/>
        <Route path="/store/dustin/confirm" component={DustinConfirm} />
        <Route path="/store/dustin" component={Dustin}/>
        <Route path="/store/settings" component={StoreSettings}/>
        <Route path="/store/settings/:tab" component={StoreSettings}/>
        <Route path="/siemens/pricelists" component={SiemensPricelists}/>
        <Route path="/tco" component={TCOReports}/>
        <Route path="/reports/:tab" component={Reports}/>
        <Route exact path="/reports" component={Reports}/>
        <Route exact path="/finance/reports" component={FinanceReports}/>
        <Route path="/settings" component={Settings}/>
        <Route path="/settings/:tab" component={Settings}/>
        <Route path="/overview" component={Overview}/>
        <Route exact path="/purchase_invoices" component={PurchaseInvoices}/>
        <Route path="/purchase_invoices/:id/accept" component={AcceptPage}/>
        <Route path="/purchase_invoices/:id/reject" component={RejectPage}/>
        <Route path="/replacement_devices" component={ReplacementDevices}/>
        <Route path="/organizations" component={Organizations}/>
        <Route path="/logout" component={Logout}/>
      </Switch>
    );
  }
}
