import * as React from 'react';
import { InputGroup, Button, MenuItem, Checkbox, Tooltip, Position } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { deepEqual } from '@patterns/core';
import { FlexColumn, FlexRow } from '@patterns/ui';
import { cloneDeep } from 'lodash';
import { CustomFormField, CustomFormFieldType } from '../models/custom_form_field';
import produce from 'immer';

type LabelProperties = 'label_en' | 'label_fi';
const FieldTypes = ['text', 'textarea', 'number', 'date', 'checkbox', 'switch'] as CustomFormFieldType[];

const TypeSelect = Select.ofType<CustomFormFieldType>();

export interface Props {
  className?: string
  fields: CustomFormField[]
  onChange: (fields: CustomFormField[]) => void
}

export interface State {
  fields: CustomFormField[]
}

export default class FormBuilder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { fields: cloneDeep(props.fields) }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!deepEqual(this.props.fields, prevProps.fields)) {
      this.setState({ fields: cloneDeep(this.props.fields)});
    }
    if (!deepEqual(this.state.fields, prevState.fields)) {
      this.props.onChange(this.state.fields);
    }
  }

  private addField = () => this.setState({
    fields: produce(this.state.fields, fields => {
      fields.push({
        inline: true,
        key: '',
        label_en: '',
        label_fi: '',
        type: 'text',
        list: false
      })
    })
  })
  
  private typeRenderer = (type: CustomFormFieldType, options: any) => <MenuItem
    key={`custom-form-field-type-${type}`}
    text={type}
    onClick={options.handleClick}
  />

  private onKeyChange = (field: CustomFormField, value: string, index: number) => this.setState({
    fields: produce(this.state.fields, fields => {
      const updated = cloneDeep(field);
      updated.key = value;
      fields.splice(index, 1, updated);
    })
  })

  private onLabelChange = (field: CustomFormField, value: string, property: LabelProperties, index: number) => this.setState({
    fields: produce(this.state.fields, fields => {
      const updated = cloneDeep(field);
      updated[property] = value;
      // prioritize english label
      const keyLabel = updated.label_en.length > 0 ? updated.label_en : updated.label_fi;
      updated.key = keyLabel.trim().toLocaleLowerCase().replace(/ /g, '_');
      fields.splice(index, 1, updated);
    })
  })

  private onTypeChange = (field: CustomFormField, value: CustomFormFieldType, index: number) => this.setState({
    fields: produce(this.state.fields, fields => {
      const updated = cloneDeep(field);
      updated.type = value;
      fields.splice(index, 1, updated);
    })
  })
  
  private renderKeyEditor(field: CustomFormField, index: number) {
    return <InputGroup
      placeholder="Key"
      className="f-1"
      value={field.key}
      onChange={(evt: any) => this.onKeyChange(field, evt.currentTarget.value, index)}
    />
  }

  private moveUp = (field: CustomFormField, index: number) => {
    this.setState({
      fields: produce(this.state.fields, fields => {
        const nextIdx = index - 1;
        const nextItem = fields.splice(nextIdx, 1);
        fields.splice(index, 0, nextItem[0]);
      })
    })
  }

  private moveDown = (field: CustomFormField, index: number) => {
    this.setState({
      fields: produce(this.state.fields, fields => {
        const nextIdx = index + 1;
        const nextItem = fields.splice(nextIdx, 1);
        fields.splice(index, 0, nextItem[0]);
      })
    })
  }

  private validateLabel(label: string) {
    return label.length > 0;
    // const match = label.match(/[\w\d\s]*/g);
    // return match && match.join('') === label
  }

  private setList(list: boolean, index: number) {
    this.setState({
      fields: produce(this.state.fields, fields => {
        fields[index].list = list;
      })
    })
  }

  private renderLabelEditor(field: CustomFormField, label: string, property: LabelProperties, index: number) {
    const valid = this.validateLabel(field[property]);
    const intent = valid ? 'success' : 'danger';
    return <InputGroup
      placeholder={label}
      className="f-1 m-r-12"
      value={field[property]}
      intent={intent}
      onChange={(evt: any) => this.onLabelChange(field, evt.currentTarget.value, property, index)}
    />
  }

  private renderTypeSelect(field: CustomFormField, index: number) {
    return <div className="m-l-12 m-r-6" style={{ width: 112 }}>
      <TypeSelect
        filterable={false}
        popoverProps={{ fill: true }}
        activeItem={field.type}
        items={FieldTypes}
        itemRenderer={this.typeRenderer}
        onItemSelect={type => this.onTypeChange(field, type, index)}
      >
        <Button fill minimal text={field.type} rightIcon="chevron-down"/>
      </TypeSelect>
    </div>
  }

  private renderActions(field: CustomFormField, index: number) {
    return <div className="d-f jc-c" style={{ width: 90 }}>
      <Button icon="trash" intent="danger" minimal small className="m-r-12"/>
      <Tooltip content="Show field in technical table" position={Position.TOP}>
        <Checkbox 
          className="m-t-6" 
          checked={field.list} 
          onClick={(evt) => this.setList(evt.currentTarget.checked, index)}
        />
      </Tooltip>
      <Button 
        disabled={index === this.state.fields.length - 1}
        icon="chevron-down" 
        intent="none" 
        minimal 
        small
        onClick={() => this.moveDown(field, index)}
      />
      <Button 
        disabled={index === 0} 
        icon="chevron-up" 
        intent="none" 
        minimal 
        small 
        onClick={() => this.moveUp(field, index)}
      />
    </div>
  }

  private renderFields() {
    return this.state.fields.map((field, index) => <FlexRow
      key={`form-builder-field-${index}`}
      className="custom-form-field m-t-6">
      { this.renderKeyEditor(field, index) }
      { this.renderLabelEditor(field, 'Label EN', 'label_en', index) }
      { this.renderLabelEditor(field, 'Label FI', 'label_fi', index) }
      { this.renderTypeSelect(field, index) }
      { this.renderActions(field, index) }
    </FlexRow>)
  }

  public render() {
    return (
      <FlexColumn className={this.props.className}>
        { this.renderFields() }        
        <div>
          <Button
            icon="plus"
            text="Add Row"
            intent="primary"
            minimal={true}
            onClick={this.addField}
            className="m-t-12"
          />
        </div>
      </FlexColumn>
    );
  }
}
