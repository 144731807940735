import { FormGroup, Slider } from '@blueprintjs/core';
import moment from 'moment';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { axios, endOfQuarter, getQuarter, startOfQuarter } from '../../common';
import { BudgetPlan } from '../../models/budget_plan';
import BudgetQuarter, { QuarterData } from './budget_quarter';

export interface Props extends WithTranslation {
  start: Date
  end: Date
}

export type ExpanseMap = { [id: string]: boolean }

export type ExtraCostReportItem = {
  name: string
  y: number
  type: string
  title: string
  price: number
}

export type ExtraCostTitleMap = { [id: string]: ExtraCostReportItem[] }
export type ExtraCostTypeMap = { [id: string]: ExtraCostTitleMap }

export type ReportCategory = {
  name: string
  icon: string
  id: string
  visible: boolean
  parentId: string
  y: number
  leasePerMonth: string
  assetPrice: string
  extraCostsPrice: string
}

export type ReportAsset = {
  name: string
  monthlyPMT: string
  assetPrice: string
  purchasePrice: string
  extraCostsPrice: string
  leasePerMonth: string
  categoryId: string
  categoryName: string
  isNew: boolean
}
export type CategoryAssetsMap = { [id: string]: ReportAsset[] }

export interface State {
  budgetPlan: BudgetPlan
  categories: ReportCategory[]
  category?: ReportCategory
  categoryAssetsMap: CategoryAssetsMap
  data: QuarterData[]
  expanseMap: ExpanseMap
  quarters: number
  showInvisible: boolean
  hideEmpty: boolean
  since: Date
  till: Date
  typeMap: ExtraCostTypeMap
}

const SampleData = {
  quarter: 3,
  year: 2020,
  data: {
  }
} as QuarterData

export class Budget extends React.Component<Props, State> {
  state = {
    budgetPlan: new BudgetPlan(),
    categories: [] as ReportCategory[],
    categoryAssetsMap: {} as CategoryAssetsMap,
    data: [],
    expanseMap: {} as ExpanseMap,
    quarters: 1,
    showInvisible: false,
    hideEmpty: true,
    since: startOfQuarter(new Date()),
    till: endOfQuarter(new Date()),
    typeMap: {} as ExtraCostTypeMap
  } as State

  componentDidMount() {
    this.fetch()
  }

  
  private fetch = async () => {
    const categories = (await axios.get('/assets/budget_categories')).data as ReportCategory[];
    const expanseMap = categories.reduce((expanseMap, category) => {
      expanseMap[category.id] = false;
      return expanseMap
    }, {} as ExpanseMap);
    
    const categoryAssetsMap = categories.reduce((categoryAssetsMap, category) => {
      categoryAssetsMap[category.id] = [];
      return categoryAssetsMap;
    }, {} as CategoryAssetsMap);
    
    this.setState({
      categories,
      categoryAssetsMap,
      expanseMap,   
    })
  }

  private onSliderChange = (quarters: number) => this.setState({ quarters })

  private renderQuarters = () => {
    const endDate = moment(this.props.start).add(this.state.quarters * 3, 'months').toDate();
    const [ startQuarter, startYear ] = getQuarter(this.props.start);
    const [ endQuarter, endYear ] = getQuarter(endDate);
    const quarters = [] as React.ReactElement[];

    for (let yearIdx = startYear; yearIdx <= endYear; yearIdx++) {
      const startValue = yearIdx === startYear ? startQuarter : 1;
      
      for (let quarterIdx = startValue; yearIdx === endYear ? quarterIdx <= endQuarter : quarterIdx <= 4; quarterIdx++) {
        quarters.push(<BudgetQuarter
          data={{...SampleData, quarter: quarterIdx, year: yearIdx }}
          onChange={() => {}}
          editable={false}
          showInvisible={true}
          hideEmpty={this.state.hideEmpty}
        />)
      }
    }

    return <div className="costs-breakdown" style={{ width: '100%'}}>
      { quarters }
      <div className="clearfix"></div>
    </div>
  }

  public render() {
    return (
      <div className="reports-container">
        <div className="months-slider d-f jc-c ai-c p-24 m-r-24 m-t-24">
          <FormGroup inline={true} className="w-100p" label={this.props.t('budgeting_reports.quarters')}>
            <Slider
              min={1}
              max={16}
              stepSize={1}
              labelRenderer={value => `${value + 1}`}
              value={this.state.quarters}
              labelStepSize={1}
              onChange={this.onSliderChange}
              className="m-l-24"
            />
          </FormGroup>
        </div>
        {/* <div>
          <Switch value={`${this.state.hideEmpty}`} label={this.props.t('budgeting_reports.hide_empty_categories')} className="m-l-24" style={{ color: 'white' }} onChange={(evt) => this.setState({ hideEmpty: !evt.currentTarget.checked })}/>
        </div> */}

        {/* <FlexRow>
          <Switch
            label="Show invisible categories" 
            style={{ color: 'white' }}
            className="m-l-24"
            checked={this.state.showInvisible}
            onChange={evt => this.setState({ showInvisible: evt.currentTarget.checked })}
          />
        </FlexRow> */}

        <div className="m-l-24 m-r-24">
          { this.renderQuarters( )}
        </div>

      </div>
    );
  }
}

export default withTranslation()(Budget)