import { BaseModel, Selectable, Resource, deserializeString, deserializeNumber } from '@patterns/core'

@Resource('siemens_pricelists')
export class SiemensPricelist extends BaseModel implements Selectable {
  name: string
  type_id: number
  subtype_id: number
  residualValue: number

  constructor(data: any = {}) {
    super(data);
    this.name = deserializeString(data.name);
    this.type_id = deserializeNumber(data.type_id);
    this.subtype_id = deserializeNumber(data.subtype_id);
    this.residualValue = parseFloat(data.residualValue || 0);
  }

  getLabel() { return '' }
  getTitle() { return this.name }
}