import { Contact, ContactType } from './contact';
import { Resource } from '@patterns/core';

@Resource('acceptance_templates')
export class AcceptanceTemplate extends Contact {
  constructor(data: any = {}) {
    super(data);
    this.type = ContactType.ACCEPTANCE_TEMPLATE;
  }
}
