import * as React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { FlexColumn, FlexRow, Sidebar, SidebarItem } from '@patterns/ui'
import { Collapse, MenuItem, Icon, Tag, Alert } from '@blueprintjs/core';
import { axios, session } from '../session';
import { withTranslation, WithTranslation } from 'react-i18next';

const ASSETS_PATH = [
  '/assets',
  '/fleet'
];

const STORE_PATH = [
  '/store/products',
  '/store/settings',
  '/store/settings/products',
  '/store/settings/extra_services',
  '/store/settings/replacement_devices',
];

const LEASES_PATH = [
  '/contracts',
  '/contracts/archive',
];

const END_OF_LEASES_PATH = [
  '/ending_leases',
  '/extended_leases',
  '/purchased_leases',
  '/asset_returns',
  '/invoices',
];

const REPORTS_PATH = [
  '/reports/leases',
  '/reports/other_costs',
  '/reports/tco',
  '/reports/budgeting',
  '/contracts/overview'
]

interface Props extends WithTranslation, RouteComponentProps {}

export function SidebarView(props: Props) {
  const currentLocation = props.location.pathname;
  let item = '';
  const [ expandedItem, setExpandedItem ] = React.useState(item);
  const [ assets, setAssets ] = React.useState(0);
  const [ contracts, setContracts ] = React.useState(0);
  const [ eols, setEols ] = React.useState(0);
  const [ eolContracts, setEolContracts ] = React.useState(0);
  const [ showVerkkoAlert, setShowVerkkoAlert] = React.useState(false);
  const [ showDustinAlert, setShowDustinAlert] = React.useState(false);
  const { t } = props;

  React.useEffect(() => {
    const fetch = async () => {
      const response = await axios.get('/dashboard');
      const data = response.data;
      setAssets(data.assets);
      setContracts(data.contracts);
      const eolResponse = await axios.get('/assets/eol?pageSize=1');
      setEols(eolResponse.data.count);
      setEolContracts(data.eolCount)
    }

    fetch();

    if (ASSETS_PATH.includes(props.location.pathname)) setExpandedItem('assets');
    if (REPORTS_PATH.includes(props.location.pathname)) setExpandedItem('reports');
    if (LEASES_PATH.includes(props.location.pathname)) setExpandedItem('leases');
    if (STORE_PATH.includes(props.location.pathname)) setExpandedItem('store');
    if (END_OF_LEASES_PATH.includes(props.location.pathname)) setExpandedItem('eol');

    console.log('user', session.user);
  }, [ props.location.pathname ]);

  const innerStyle = props.i18n.language === 'fi' ? { margin: '6px 0px' } : {}

  return <Sidebar>
    <div style={{ position: 'relative' }}>
      <FlexRow className="m-t-24 m-b-24 m-l-6">
        <div style={{ width: 46, height: 46, borderRadius: 25, marginRight: 12, marginLeft: 12 }}>
          <Icon icon="user" iconSize={38} />
        </div>
        <FlexColumn>
          <span className="bold white">{ session.user.name }</span>
          <span className="green m-t-6 bold"><Icon icon="full-circle" className="m-r-6" />ONLINE</span>
        </FlexColumn>
        <div onClick={() => {
          window.location.pathname = '/logout';
        }} style={{ width:46, height: 46 }} className="d-f ai-c jc-c pointer"><Icon color="#E8567D" icon="power" iconSize={24} /></div>
      </FlexRow>

      <FlexRow className="m-b-24">
        <FlexColumn className="f-1 sidebar-widget-box">
          <div className="sidebar-widget-box-inner" style={innerStyle}>
            <span className="bold">{ assets }</span>
            <span className="subtitle">{t('assets.title')}</span>
          </div>
        </FlexColumn>
        <FlexColumn className="f-1 sidebar-widget-box">
          <div className="sidebar-widget-box-inner" style={innerStyle}>
            <span className="bold">{ contracts <= 0 ? 0 : contracts }</span>
            <span className="subtitle">{t('contracts.title')}</span>
          </div>
        </FlexColumn>
        <FlexColumn className="f-1 sidebar-widget-box">
          <div className="sidebar-widget-box-inner" style={innerStyle}>
            <span className="bold">{ eols <= 0 ? 0 : eols }</span>
            <span className="subtitle">EOLs</span>
          </div>
        </FlexColumn>
      </FlexRow>

      {/* <SidebarItem title="DASHBOARD" icon="dashboard" to="/"/> */}

      <MenuItem
        text={t('dashboard.title')}
        icon={<Icon icon="dashboard" style={{ backgroundColor: '#FA9627', padding: 6, borderRadius: 4, marginRight: 12 }} color="white"/>}
        className="header"
        onClick={() => {
          props.history.push('/')
        }}
      />

      <MenuItem
        text={t('store.title')}
        icon={<Icon icon="home" style={{ backgroundColor: '#167DFA', padding: 6, borderRadius: 4, marginRight: 12 }} color="white"/>}
        labelElement={<Icon icon="circle-arrow-down"/>}
        className="header"
        onClick={() => setExpandedItem('store')}/>
        <Collapse isOpen={expandedItem === 'store'} className="m-l-30">
          <SidebarItem title={t('sidebar.partner_products')} to="/store/products"/>
          { session.user.email !== 'demo@jit.fi' && <SidebarItem title="Verkkokauppa.com" to="/store/verkkokauppa" onClick={() => setShowVerkkoAlert(true)}/> }
          { session.user.email !== 'demo@jit.fi' && <SidebarItem title="Dustin.fi" to="/store/dustin" onClick={() => setShowDustinAlert(true)}/> }
          {/* <SidebarItem title={t('settings.title')} to="/store/settings"/> */}
          <MenuItem
            active={['/store/settings', '/store/settings/products', '/store/settings/extra_services', '/store/settings/replacement_devices'].includes(currentLocation)}
            text={t('settings.title')}
            onClick={() => props.history.push('/settings')}
          />
          {/* <Link
            to="/store/settings"
            className="patterns-sidebar-menu-item"
            component={(props, ref) => }
          /> */}
          {/* '/store/settings/products', */}

        </Collapse>

      <MenuItem
        text={t('assets.title')}
        icon={<Icon icon="mobile-phone" style={{ backgroundColor: '#3BCA5B', padding: 6, borderRadius: 4, marginRight: 12 }} color="white"/>}
        className="header"
        onClick={() => {
          props.history.push('/assets')
        }}
      />

      {/* <MenuItem
        text={t('assets.title')}
        className="header"
        icon={<Icon icon="mobile-phone" style={{ backgroundColor: '#3BCA5B', padding: 6, borderRadius: 4, marginRight: 12 }} color="white"/>}
        labelElement={<Icon icon="circle-arrow-down"/>}
        onClick={() => setExpandedItem('assets')}/>
        <Collapse isOpen={expandedItem === 'assets'} className="m-l-30">
          <SidebarItem title={t('assets.registry')} to="/assets"/>
          {/* <SidebarItem title={t('fleet.title')} to="/fleet"/> */}
        {/* </Collapse> */}

      <MenuItem
        text={t('contracts.leases')}
        className="header"
        icon={<Icon icon="euro" style={{ backgroundColor: '#FA9627', padding: 6, borderRadius: 4, marginRight: 12 }} color="white"/>}
        labelElement={<Icon icon="circle-arrow-down"/>}
        onClick={() => setExpandedItem('leases')}/>
        <Collapse isOpen={expandedItem === 'leases'} className="m-l-30">
          <SidebarItem title={t('sidebar.valid_leases')} to="/contracts"/>
          <SidebarItem title={t('sidebar.expired_leases')} to="/expired_contracts"/>
        </Collapse>

      <MenuItem
        text={<span>{t('end_of_lease')}{ eolContracts > 0 && <Tag className="m-l-8" intent="danger" round>{ eols }</Tag> }</span>}
        icon={<Icon icon="time" style={{ backgroundColor: '#F83059', padding: 6, borderRadius: 4, marginRight: 12 }} color="white"/>}
        className="header"
        labelElement={<Icon icon="circle-arrow-down"/>}
        onClick={() => setExpandedItem('eol')}/>
      <Collapse isOpen={expandedItem === 'eol'} className="m-l-30">
        <SidebarItem title={t('ending_leases.title')} to="/ending_leases"/>
        <SidebarItem title={t('extended_leases.title')} to="/extended_leases"/>
        <SidebarItem title={t('purchased_assets.title')} to="/purchased_leases"/>
        <SidebarItem title={t('asset_returns.title')} to="/asset_returns"/>
        {/* <SidebarItem title={t('sidebar.invoices')} to="/invoices"/> */}
      </Collapse>

      <MenuItem
        text={t('sidebar.reports')}
        icon={<Icon icon="list" style={{ backgroundColor: '#585BD3', padding: 6, borderRadius: 4, marginRight: 12 }} color="white"/>}
        className="header"
        labelElement={<Icon icon="circle-arrow-down"/>}
        onClick={() => setExpandedItem('reports')}/>
      <Collapse isOpen={expandedItem === 'reports'} className="m-l-30">
        <SidebarItem title={t('contracts.liabilities')} to="/contracts/overview"/>
        <SidebarItem title={t('leases_report.title')} to="/reports/leases"/>
        {/* <SidebarItem icon="chart" title="Costs Breakdown" to="/reports/extra_costs"/> */}
        {/* <SidebarItem title={t('tco_reports.title')} to="/reports/tco"/> */}
        {/* <SidebarItem title={t('budgeting_reports.title')} to="/reports/budgeting"/> */}
      </Collapse>

      {/* <MenuDivider /> */}

      {/* <SidebarItem title="Settings" icon="cog" to="/settings"/> */}

      <MenuItem
        text={t('settings.title')}
        icon={<Icon icon="cog" style={{ backgroundColor: '#6a6a6a', padding: 6, borderRadius: 4, marginRight: 12 }} color="white"/>}
        className="header"
        onClick={() => {
          props.history.push('/settings')
        }}
      />

      { session.user.isAdmin && <MenuItem
        text={t('purchase_invoices.title')}
        icon={<Icon icon="euro" style={{ backgroundColor: '#F83059', padding: 6, borderRadius: 4, marginRight: 12 }} color="white"/>}
        className="header"
        onClick={() => {
          props.history.push('/purchase_invoices')
        }}
      /> }

    </div>

    <Alert
      icon="info-sign"
      isOpen={showVerkkoAlert}
      confirmButtonText="OK"
      cancelButtonText={t('cancel')}
      intent='primary'
      onCancel={() => setShowVerkkoAlert(false)}
      onConfirm={() => {
        setShowVerkkoAlert(false);
        props.history.push('/store/verkkokauppa')
      }}>
      <span>{t('redirect')}</span>
    </Alert>

    <Alert
      icon="info-sign"
      isOpen={showDustinAlert}
      confirmButtonText="OK"
      cancelButtonText={t('cancel')}
      intent='primary'
      onCancel={() => setShowDustinAlert(false)}
      onConfirm={() => {
        setShowDustinAlert(false);
        props.history.push('/store/dustin')
      }}>
      <span>{t('redirect')}</span>
    </Alert>
  </Sidebar>

}

export default withRouter(withTranslation()(SidebarView));
