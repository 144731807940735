import * as React from 'react';

import { Select } from '@blueprintjs/select';
import { MenuItem, Button, ButtonGroup } from '@blueprintjs/core';
import { withTranslation, WithTranslation } from 'react-i18next';

export type Term = { value: number, title: string };

const BlueprintSelect = Select.ofType<Term>()

export interface Props extends WithTranslation {
  term: number
  onSelect: (term: number) => void
}

export interface State {
  term: Term
}

export class TermSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      term: (this.getTerms().find(m => m.value === props.term || this.getTerms()[2]))!
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.state.term.value !== this.props.term) {
      this.setState({ 
        term: (this.getTerms().find(m => m.value === this.props.term))!
      })
    }
  }

  private getTerms = () => {
    const { t } = this.props;
    return [
      { value: 12, title: `12 ${t('contracts.months_short')}` },
      { value: 24, title: `24 ${t('contracts.months_short')}` },
      { value: 36, title: `36 ${t('contracts.months_short')}` },
      { value: 48, title: `48 ${t('contracts.months_short')}` },
      { value: 60, title: `60 ${t('contracts.months_short')}` }
    ] as Term[]
  }

  private onTermSelect = (term: Term) => this.setState({ term }, () => {
    this.setState({ term });
    this.props.onSelect(term.value)
  })

  private renderer = (item: Term, options: any) => <MenuItem
    key={`item-renderer-${item.value}`}
    active={item.value === this.state.term.value}
    text={item.title}
    onClick={options.handleClick}
  />

  public render() {
    return (
      <ButtonGroup>
        <BlueprintSelect
          activeItem={this.state.term}
          filterable={false}
          items={this.getTerms()}
          itemRenderer={this.renderer}
          onItemSelect={this.onTermSelect}>
          <Button text={this.state.term.title} rightIcon="chevron-down" outlined intent="primary"/>
        </BlueprintSelect>
      </ButtonGroup>
    );
  }
}

export default withTranslation()(TermSelect);