import { BaseModel, Selectable, Resource, deserializeString, deserializeNumber } from '@patterns/core'
import { session } from '../session';
import { Category } from './category';
import placeholderSmall from '../assets/no-image-available-full-trans-2.png';

@Resource('products')
export class Product extends BaseModel implements Selectable {
  name: string;
  sku: string;
  ean: string;
  price: number;
  image: string;
  description: string;
  alsoId: number;
  category: Category;
  
  constructor(data: any = {}) {
    super(data);
    this.name = deserializeString(data.name);
    this.sku = deserializeString(data.sku);
    this.ean = deserializeString(data.ean);
    this.description = deserializeString(data.description);
    this.price = deserializeNumber(parseFloat(data.price));
    this.alsoId = deserializeNumber(data.alsoId);
 
    if (this.alsoId === 0) {
      this.image = `/api/products/${this.id}/image?token=${session.token}`
    } else {
      if (data.image.length > 0) {
        this.image = data.image;
      } else {
        this.image = placeholderSmall;
      }
    }

    this.category = data.category ? new Category(data.category) : new Category({})
  }

  getLabel() { return null }
  getTitle() { return this.id }
}