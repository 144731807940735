import React, { PropsWithChildren, useEffect, useState } from 'react';
import { session, Session } from './session';
import { User } from './models/user';
import { setHighChartTheme, HighchartsLightTheme } from './highcharts';
import { ShoppingCart } from './shopping_cart';

const KEY_NIGHTMODE = 'leasex:darkmode'

export type AppContextType = {
  nightMode: boolean
  session: Session<User>
  chartTheme: Highcharts.Options
  shoppingCart: ShoppingCart
  lessee_id?: string
};

export const AppContext = React.createContext({
  nightMode: true,
  session,
  chartTheme: HighchartsLightTheme
} as AppContextType);

export const AppContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [nightMode] = useState(localStorage.getItem(KEY_NIGHTMODE) === 'true' || true);
  const [context, setContext] = useState({ nightMode, session } as AppContextType);
  const [shoppingCart] = useState(new ShoppingCart());

  // const observer = React.useCallback((event?: string) => {
  //   if (event === Events.NIGHT_MODE_TOGGLE) {
  //     setNightMode(!nightMode)
  //   }
  // }, [nightMode]);

  const sessionObserver = React.useCallback(() => {
    setContext({ 
      session, 
      shoppingCart,
      nightMode, 
      chartTheme: HighchartsLightTheme 
    });
  }, [nightMode, shoppingCart]);

  useEffect(() => {
    // document.body.className = nightMode ? '' : '';
    localStorage.setItem(KEY_NIGHTMODE, `${nightMode}`);

    setHighChartTheme(nightMode);
    setContext({ session, nightMode, shoppingCart, chartTheme: HighchartsLightTheme });

    // EventBus.observe(observer);
    session.observeAll(sessionObserver);
    
    return () => {
      // EventBus.cancel(observer);
      session.cancelAll(sessionObserver)
    }
  }, [nightMode, sessionObserver, shoppingCart]);

  // useEffect(() => {
    // document.body.className = nightMode ? 'bp3-dark' : ''    
  // });

  return <AppContext.Provider value={context}>
    { children }
  </AppContext.Provider>
}
