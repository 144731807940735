import * as React from 'react';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import { CategoryRepository } from '../../repository';
import { Category } from '../../models/category';
import { Button, Checkbox } from '@blueprintjs/core';
import { FlexRow } from '@patterns/ui';
import { axios } from '../../session';
import { withTranslation, WithTranslation } from 'react-i18next';

const CategoriesTable = DataTable.ofType<Category>();

export interface Props extends WithTranslation {
  category?: Category
  onSelect: (category: Category) => void
}

export interface State {
  selected: Category
  selection: Category[]
  showDialog: boolean
}

export class CategoriesView extends React.Component<Props, State> {
  cancelToken: any;

  state = {
    selected: new Category({}),
    showDialog: false
  } as State

  tableRef = React.createRef<DataTable<Category>>();

  private getColumns = () => {
    const { t, i18n: { language } } = this.props;
    return [
      // {
      //   id: 'icon',
      //   title: t('icon'),
      //   type: ColumnType.Text,
      //   sortable: true,
      //   filterable: true,
      //   alignment: Alignment.Left,
      //   width: 50,
      //   visible: true,
      //   format: category => <i className={`fas ${category.icon}`}></i>
      // },
      {
        id: 'name_en',
        title: t('name_en'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: category => <strong>{ category.name_en }</strong>
      },
      {
        id: 'name_fi',
        title: t('name_fi'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 1,
        visible: true
      },
      {
        id: 'parent',
        title: t('parent_category'),
        type: ColumnType.Custom,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: category => <span>{ category.parent?.name_en ?? '' }</span>
      },
      {
        id: 'visible',
        title: t('visible'),
        type: ColumnType.Custom,
        sortable: false,
        filterable: false,
        alignment: Alignment.Left,
        width: 100,
        visible: true,
        format: category => <span onClick={evt => {
          evt.stopPropagation();
        }}>
          <Checkbox 
            checked={category.visible}
            onClick={evt => {
              evt.stopPropagation();
              evt.preventDefault();
            }}
            onChange={evt => {
              evt.preventDefault();
              evt.stopPropagation();
              this.toggleVisible(category);
            }}
          />
        </span>
      },
      {
        id: 'lifecycle',
        title: t('assets.lifecycle'),
        type: ColumnType.Number,
        sortable: false,
        filterable: false,
        alignment: Alignment.Right,
        width: 130,
        visible: true,
        format: category => <span>{category.lifecycle} months</span>
      },
      {
        id: 'extendedLeasingPrice',
        title: t('extended_leasing_price'),
        type: ColumnType.Number,
        sortable: false,
        filterable: false,
        alignment: Alignment.Right,
        width: 200,
        visible: true,
        format: category => <span>{category.extendedLeasingPrice}%</span>
      },
      {
        id: 'reclaimPrice',
        title: t('reclaim_price'),
        type: ColumnType.Number,
        sortable: false,
        filterable: false,
        alignment: Alignment.Right,
        width: 160,
        visible: true,
        format: category => <span>{category.reclaimPrice}%</span>
      },
      {
        id: 'updatedAt',
        title: t('updated_at'),
        type: ColumnType.DateTime,
        sortable: true,
        filterable: true,
        alignment: Alignment.Right,
        width: 140,
        visible: true
      }
    ] as Column<Category>[]
  }

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const params = [`show_invisible=true`];

    if (this.props.category) {
      params.push(`category_id=${this.props.category.id}`)
    }

    const { items, total, cancelToken } = await CategoryRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken, params.join('&')
    );
    this.cancelToken = cancelToken
    return { items, total }
  }

  private onSelect = (selected: Category) => { 
    this.setState({ 
      selected,
      showDialog: true 
    });

    this.props.onSelect(selected)
  }

  private onSelectionChange = (selection: Category[]) => this.setState({ selection })

  private toggleVisible = async (category: Category) => {
    category.visible = !category.visible;
    await axios.post('/categories/toggle_visibility', {
      ids: [category.id],
      visible: category.visible
    });
    this.tableRef.current?.fetch()   
  }

  private setVisible = async () => {
    const ids = this.state.selection.map(category => category.id);
    await axios.post('/categories/toggle_visibility', {
      ids,
      visible: true
    });
    this.tableRef.current?.fetch()
  }

  private setInvisible = async () => {
    const ids = this.state.selection.map(category => category.id);
    await axios.post('/categories/toggle_visibility', {
      ids,
      visible: false
    });
    this.tableRef.current?.fetch()
  }
  
  public render() {
    const { t } = this.props;
    
    return <CategoriesTable
      id="categories-table"
      ref={this.tableRef}
      columns={this.getColumns()}
      fetch={this.fetch}
      showSelection={true}
      onSelectionChange={this.onSelectionChange}
      sort="name_en"
      sortDir="asc"
      multiple={true}
      onItemSelect={this.onSelect}
      actions={<FlexRow>
        <Button text={t('set_visible')} minimal style={{ width: 120 }} icon="eye-on" onClick={this.setVisible}/>
        <Button text={t('set_invisible')} minimal  style={{ width: 120 }} icon="eye-off" onClick={this.setInvisible}/>

      </FlexRow>}
    />
  }
}

export default withTranslation()(CategoriesView)