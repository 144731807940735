import * as React from 'react';
import { Dialog, Classes, Button, FormGroup } from '@blueprintjs/core';
import { Product } from '../models/product';
import { FlexColumn } from '@patterns/ui';
import { priceFormat } from '../common';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface Props extends WithTranslation {
  isOpen: boolean  
  onClose: (changed: boolean) => void
  product: Product
}

export class StoreProductDialog extends React.Component<Props> {
  private onClose = () => this.props.onClose(false)

  public render() {
    const { t, i18n: { language } } = this.props;

    return (
      <Dialog canOutsideClickClose={false} title={t('store.product_view')} isOpen={this.props.isOpen} onClose={this.onClose} className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <FormGroup inline={false} label={t('category')}>
            <div className="">
              { language === 'fi' ? this.props.product.category.name_fi : this.props.product.category.name_en }
            </div>
          </FormGroup>
          <FormGroup inline={false} label={t('description')}>
            <div className="">
              { this.props.product.name }
            </div>
          </FormGroup>
          
          <FlexColumn className="patterns-form">
            <FormGroup 
              label={t('image')}
              inline={false}
              className="patterns-form-field-group patterns-form-field-group-fill">
              
              <img 
                className="form-image-preview" 
                src={this.props.product.image} 
                alt=""
                style={{ maxHeight: 240, margin: '0 auto' }}
              />
            </FormGroup>

            <FormGroup inline={false} label={t('fleet.sku')}>
              <div className="">
                { this.props.product.sku }
              </div>
            </FormGroup>

            <FormGroup inline={false} label={t('price')}>
              <div className="">
                { priceFormat(this.props.product.price) }
              </div>
            </FormGroup>
          </FlexColumn>

        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text={t('close')}
              onClick={this.onClose}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(StoreProductDialog)