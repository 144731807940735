import { MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import SiemensManagerInstance, { SiemensAssetType } from '../siemens.manager';

const TypeSuggest = Suggest.ofType<SiemensAssetType>();

export interface Props extends WithTranslation {
  disabled: boolean;
  activeItem: SiemensAssetType;
  onSelect: (item: SiemensAssetType) => void;
}

interface State {
  activeItem: SiemensAssetType;
  query: string;
  items: SiemensAssetType[];
}

export class SiemensTypeSuggest extends React.Component<Props, State> {
  state = {
    activeItem: this.props.activeItem,
    query: '',
    items: SiemensManagerInstance.types
  } as State

  componentDidUpdate(prevProps: Props) {
    const { activeItem } = this.props;
    if (activeItem.id !== prevProps.activeItem.id) {
      this.setState({ activeItem })
    }
  }

  private onQueryChange = (query: string) => this.setState({
    query, 
    items: SiemensManagerInstance.searchType(query) 
  })

  private inputValueRenderer = (item: SiemensAssetType) => { 
    return item.name 
  }

  private renderer = (type: SiemensAssetType, options: any) => <MenuItem
    key={`type-${type.id}-item`}
    text={type.name}
    onClick={options.handleClick}
    active={this.state.activeItem.id === type.id}
  />

  private onActiveItemChange = (item: SiemensAssetType | null) => {
    if (item && item.id !== this.state.activeItem.id) {
      this.setState({ activeItem: item })
    }
  }

  public render() {
    return (
      <TypeSuggest
        fill={true}
        disabled={this.props.disabled}
        items={this.state.items}
        selectedItem={this.state.activeItem}
        onQueryChange={this.onQueryChange}
        itemRenderer={this.renderer}
        inputValueRenderer={this.inputValueRenderer}
        onActiveItemChange={this.onActiveItemChange}
        onItemSelect={this.props.onSelect}
        inputProps={{
          placeholder: this.props.t('search')
        }}
      />
    );
  }
}

export default withTranslation()(SiemensTypeSuggest);