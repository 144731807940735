import { NonIdealState } from '@blueprintjs/core';
import * as React from 'react';
import { MainLayout } from '../../layouts/main';
import VerkkokauppaService from '../../verkkokauppa.service';

export interface Props {
}

export interface State {
  url: string
}

export class Verkkokauppa extends React.Component<Props, State> {
  state = {
    url: ''
  } as State

  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const url = await VerkkokauppaService.login();
    window.location.href = url;
  }

  public render() {
    return (
      <MainLayout title="Verkkokauppa">
        <NonIdealState title="Please wait" description="You will be redirected to Vekkokauppa site"/>
      </MainLayout>
    );
  }
}
