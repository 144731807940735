import * as React from 'react';
import { MainLayout } from '../layouts/main';
import { Tabs, Tab } from '@blueprintjs/core';
import { FlexColumn } from '@patterns/ui';
import ExtraServices from '../components/settings/extra_services';
import Products from './products';
import { Breadcrumbs } from '../components/breadcrumb';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

export interface Props extends WithTranslation, RouteComponentProps {}

export interface State {
  tabId: string
}

export class StoreSettings extends React.Component<Props, State> {
  state = {
    showDialog: false,
    tabId: 'products'
  } as State

  componentDidMount() {
    this.setTab()
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setTab()
    }
  }

  private setTab = () => {
    const split = this.props.location.pathname.split('/');

    if (split.length === 4) {
      this.setState({ tabId: split[3] })
    }
  }

  private onTabChange = (tabId: string) => this.setState({ tabId })

  public render() {
    const { t } = this.props;
    const titles = [ t('store_settings.title') ];
    if (this.state.tabId === 'products') {
      titles.push(t('store_settings.products'))
    }
    if (this.state.tabId === 'extra_services') {
      titles.push(t('store_settings.extra_services'))
    }
    // if (this.state.tabId === 'replacement_devices') {
    //   titles.push(t('store_settings.replacement_devices'))
    // }

    const title = titles.join(' / ');

    return (
      <MainLayout
        title={title}>
        <Breadcrumbs path={t('store_settings.path')} title={title}/>
        <FlexColumn flex={1}>
          <Tabs
            className="custom-tabs no-top-margin"
            selectedTabId={this.state.tabId}
            onChange={this.onTabChange}
            renderActiveTabPanelOnly={true}>
            <Tab id="products" title={t('store_settings.products')} panel={<Products />}/>
            {/* <Tab id="also" title={t('store_settings.also')} panel={<Alsos />}/> */}
            <Tab id="extra_services" title={t('store_settings.extra_services')} panel={<ExtraServices />}/>
            {/* <Tab id="replacement_devices" title={t('store_settings.replacement_devices')} panel={<ReplacementDevices />}/> */}
          </Tabs>
        </FlexColumn>
      </MainLayout>
    );
  }
}

export default withTranslation()(StoreSettings)