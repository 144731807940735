import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PiecePieWidget }from '../piece_pie_widget';

export interface Props extends WithTranslation {
  height: number
  width: number
  legend?: boolean
  onPointClick?: (item: any) => void
  params?: string
}

export function AssetCategoryChart({ height, legend, width, onPointClick, params, t, i18n: { language } }: Props) {
  return (
    <PiecePieWidget
      title={t('charts.assets_per_category')}
      url={`/assets/category_chart?${params}&language=${language}`}
      legend={legend}
      width={width}
      height={height}
      onPointClick={onPointClick}
    />
  );
}

export default withTranslation()(AssetCategoryChart)
