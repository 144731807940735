import React from 'react';
import { Button, FormGroup, InputGroup, Callout, Colors } from '@blueprintjs/core';
import { hasStrings } from '@patterns/core';

import { User } from '../models/user';

import logo from '../assets/jit-leasex-logo-by.png';
import sample from '../assets/video2.mp4';

import { motion } from 'framer-motion'
import { FlexColumn } from '@patterns/ui';
import { AppContext } from '../app_context';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FlexRow, Notifier } from '@patterns/ui';

const dimmer = {
  hidden: { backgroundColor: 'rgba(0, 0, 0, 0)' },
  visible: {
    transition: {
      delay: 0,
      when: "beforeChildren",
      staggerChildren: 0.5
    }
  }
};

const container = {
  hidden: { 
    opacity: 0, scale: 0, transform: 'rotate(-180deg)'
  },
  visible: {
    opacity: 1,
    scale: 1,
    border: '1px solid rgba(255, 255, 255, 0.15)',
    boxShadow: '0 5px 48px rgba(0, 0, 0, 0.5)',
    transition: {
      delay: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const logoVariants = {
  hidden: { 
    opacity: 0,
    scale: 3
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.5
    }
  }
}

const errorVariants = {
  hidden: { 
    opacity: 0,
    scale: 0
  },
  visible: {
    opacity: 1,
    scale: 1
  }
}

interface LoginProps extends WithTranslation {
  onLogin: (user: User) => void
}

interface LoginState {
  userName: string;
  password: string;
  error: boolean;
  stage: number;
  code: string;
  codeError: boolean;
}

export class Login extends React.Component<LoginProps, LoginState> {
  static contextType = AppContext;

  constructor(props: LoginProps) {
    super(props)
    this.state = {
      userName: '',
      password: '',
      error: false,
      stage: 0,
      code: '',
      codeError: false
    }
  }

  async login() {
    if (this.state.stage === 0) {
      const userName = this.state.userName;
      const password = this.state.password;
      if (hasStrings([userName, password])) {
        try {
          const { success } = await this.context.session.prelogin(userName, password, this.props.i18n.language);
          if (success) {
            this.setState({ stage: 1, error: false, codeError: false })
          }
        } catch (e) {
          console.error('e', e);
          this.setState({ error: true, codeError: false })
        }
      }
      return
    }

    if (this.state.stage === 1) {
      if (this.state.code.length === 0 || this.state.code.length === 6) {
        try {
          const { user } = await this.context.session.login(this.state.code);
          this.props.onLogin(user);
        } catch (e) {
          this.setState({ codeError: true })
        }
      } else {
        this.setState({ codeError: true })
      }
    }
  }

  update(event: any, field: string) {
    const state : any = {};
    state[field] = event.target.value;
    this.setState(state)
  }

  private resetPassword = async () => {
    try {
      if (this.state.userName.length > 0) {
        await this.context.session.passwordReset(this.state.userName);
      }

      Notifier.success('We have sent you password reset confirmation email');
    } catch (e) {
      console.error(e);
      Notifier.failure('Password reset was unsuccessful');
    }
  }

  private validateReset = () => {
    return this.state.userName.length <= 3
  }

  renderError() {
    if (!this.state.error) {
      return null
    }

    return (<FlexRow className="jc-c login-error">
      { this.props.t('invalid_password') }
    </FlexRow>)
  }

  renderCodeError() {
    if (!this.state.codeError) {
      return null
    }

    return (<FlexRow className="jc-c login-error">
      { this.props.t('invalid_code') }
    </FlexRow>)
  }

  setLang(lang: string) {

  }

  render() {
    const { t } = this.props;

    return (
      <div className="App">
        <div className="background"/>
        <div className="background-video">
          <video className='videoTag' autoPlay loop muted>
            <source src={sample} type='video/mp4' />
          </video>
        </div>
        <motion.div 
          className="foreground"
          variants={dimmer}
          initial="hidden"
          animate="visible">
          <motion.div
            className="login-window"
            variants={container}
            initial="hidden"
            animate="visible"
            onMouseEnter={() => {
              document.getElementsByClassName('background-video')[0].className = "background-video blurred"
              document.getElementsByClassName('foreground')[0].className = "foreground blurred"
            }}
            onMouseLeave={() => {
              document.getElementsByClassName('background-video')[0].className = "background-video"
              document.getElementsByClassName('foreground')[0].className = "foreground"
            }}
          >
          <FlexColumn>
            <FlexRow>
              <motion.img
                className="logo"
                variants={logoVariants}
                initial="hidden"
                animate="visible"
                src={logo}>
              </motion.img>
            </FlexRow>
            { this.state.stage === 0 && <FlexRow>
              <FormGroup label={t('email')} inline>
                <InputGroup
                  placeholder={t('email_placeholder')}
                  className="text-input-plain"
                  value={this.state.userName}
                  onChange={(event: any) => this.update(event, 'userName')}
                />
              </FormGroup>
            </FlexRow> }
            { this.state.stage === 0 && <FlexRow>
              <FormGroup label={t('password')} inline>
                <InputGroup
                  placeholder={t('password_placeholder')}
                  className="text-input-plain"
                  value={this.state.password}
                  onChange={(event: any) => this.update(event, 'password')}
                  type="password"
                />
              </FormGroup>
            </FlexRow> }
            { this.state.stage === 1 && <FlexRow>
              <FormGroup label={t('confirmation_code')} inline>
                <InputGroup 
                  placeholder={t('enter_confirmation_code')}
                  className="text-input-plain"
                  value={this.state.code}
                  onChange={(event: any) => {
                    this.setState({ code: event.currentTarget.value})
                  }}
                  type="password"
                />
              </FormGroup>
            </FlexRow> }

            { this.state.stage === 1 && this.state.codeError ? <FlexRow className='login-error'>{t('invalid_code')}</FlexRow> : null }

            <FlexRow>
              { this.state.error && <motion.div
                style={{ flex: 1 }}
                variants={errorVariants}
                initial="hidden"
                animate="visible">
                <Callout title={t('invalid_password')} intent="danger" style={{ fontWeight: 'normal', fontSize: 14 }}/>
              </motion.div> }
            </FlexRow>

            <FlexRow>
              <FlexRow flex={2} className="jc-s" style={{ marginTop: 22 }}>
                <Button 
                  disabled={this.validateReset()}
                  text={t('login.reset_password')}
                  outlined
                  className='password-reset-btn'
                  onClick={() => {
                    this.resetPassword()
                  }}
                />
              </FlexRow>
              <FlexRow flex={3} className="jc-e ai-c" style={{ marginTop: 22 }}>
                <div className={`lang-select ${this.props.i18n.language === 'en' ? 'active' : '' }`} onClick={() => this.props.i18n.changeLanguage('en')}>en</div>
                <div className='lang-select-spacer'>&nbsp;|&nbsp;</div>
                <div className={`lang-select ${this.props.i18n.language === 'fi' ? 'active' : '' } m-r-24`} onClick={() => this.props.i18n.changeLanguage('fi')}>fi</div>
                <Button
                  style={{ backgroundColor: Colors.VERMILION3 }}
                  text={t('login.log_in')}
                  className="login-btn m-l-24"
                  onClick={() => {
                    this.setState({ error: false });
                    this.login()
                  }}
                />
              </FlexRow>
            </FlexRow>
          </FlexColumn>
          </motion.div>
        </motion.div>
      </div>
    )
  }
}

export default withTranslation()(Login)