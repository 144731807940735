import * as React from 'react';

import { ButtonGroup, Button } from '@blueprintjs/core';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import { download } from '@patterns/core';
import { Organization } from '../models/organization';
import { CreateButton } from '../components/create_button';
import { OrganizationDialog } from '../components/organization_dialog';
import { MainLayout } from '../layouts/main';
import { OrganizationRepository } from '../repository';
import { Notifier } from '@patterns/ui';
import EventBus from '../event_bus';

const columns = [
  {
    id: 'name',
    title: 'Name',
    type: ColumnType.Text,
    sortable: true,
    filterable: true,
    alignment: Alignment.Left,
    flex: 1,
    visible: true,
    format: contact => <strong>{ contact.name }</strong>
  },
  {
    id: 'description',
    title: 'Description',
    type: ColumnType.Text,
    sortable: true,
    filterable: true,
    alignment: Alignment.Left,
    flex: 1,
    visible: true
  },
  {
    id: 'email',
    title: 'Email',
    type: ColumnType.Text,
    sortable: true,
    filterable: true,
    alignment: Alignment.Left,
    flex: 1,
    visible: true
  },
  {
    id: 'city',
    title: 'City',
    type: ColumnType.Text,
    sortable: true,
    filterable: true,
    alignment: Alignment.Left,
    flex: 1,
    visible: true
  },
  {
    id: 'businessID',
    title: 'Business ID',
    type: ColumnType.Text,
    sortable: true,
    filterable: true,
    alignment: Alignment.Left,
    flex: 1,
    visible: true
  },
  {
    id: 'updatedAt',
    title: 'Updated At',
    type: ColumnType.DateTime,
    sortable: true,
    filterable: false,
    alignment: Alignment.Left,
    width: 140,
    visible: true
  }
] as Column<Organization>[]

export interface State {
  selected: Organization
  showDialog: boolean
}

export class Organizations extends React.Component<{}, State> {
  cancelToken: any;
  private OrganizationsTable = DataTable.ofType<Organization>();

  constructor(props: {}) {
    super(props);
    this.state = {
      selected: new OrganizationRepository.klass({}),
      showDialog: false
    } as State
  }

  tableRef = React.createRef<any>();

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await OrganizationRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken
    );
    this.cancelToken = cancelToken
    return { items, total }
  }

  private reload() {
    this.tableRef.current?.fetch();
  }

  private onSelect = (selected: Organization) => this.setState({ 
    selected,
    showDialog: true 
  })

  private create = () => this.setState({
    selected: new OrganizationRepository.klass({}) as Organization,
    showDialog: true
  })

  private onClose = () => this.setState({ showDialog: false })

  private onSave = async (contact: Organization) => {
    await OrganizationRepository.save(contact);
    this.setState({ showDialog: false });
    Notifier.success('Organization was successfuly created');
    EventBus.notify('organizations:change')
    this.reload()
  }

  private onDelete = async (contact: Organization) => {
    await OrganizationRepository.delete(contact.id);
    this.setState({ showDialog: false })
    EventBus.notify('organizations:change')
    this.reload()
  }

  public render() {
    return (<MainLayout title="Organizations">
      <div className="f-1 d-f full-height">
        <this.OrganizationsTable
          id="organizations-table"
          ref={this.tableRef}
          columns={columns}
          fetch={this.fetch}
          sort="name"
          sortDir="asc"
          multiple={true}
          onItemSelect={this.onSelect}
          tableActions={<ButtonGroup style={{ justifySelf: 'center' }}>
            <Button minimal icon="cloud-download" text="Export" onClick={() => download('/api/contacts/export/0', '')}/>
            <Button minimal icon="cloud-upload" text="Import"/>
          </ButtonGroup>}
        />

        <CreateButton onClick={this.create} />

        <OrganizationDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onSave={this.onSave}
          onDelete={this.onDelete}
          organization={this.state.selected}
        />
      </div>
    </MainLayout>);
  }
}


// import * as React from 'react';
// import { MainLayout } from '../layouts/main';
// import { OrganizationsView } from '../components/settings/contacts';
// import { OrganizationType } from '../models/contact';
// import { OrganizationRepository } from '../repository';
// import { Organization } from '../models/organization';

// const OrganizationsView = OrganizationsView.ofType<Organization>();

// export interface Props {
// }


// export default class Organizations extends React.Component<Props> {
//   public render() {
//     return (
//       <MainLayout title="Organizations">
//         <OrganizationsView
//           repository={OrganizationRepository}
//           type={OrganizationType.ORGANIZATION}/>
//       </MainLayout>
//     );
//   }
// }
