import * as React from 'react';
import { FlexRow } from '@patterns/ui';
import { ReportItem } from '../pages/devices';
import { Intent, ProgressBar } from '@blueprintjs/core';
import { axios } from '../session';

export interface Props {
  className?: string
  url: string
  title: string
  intent: Intent
  onLoad?: (hasItems: boolean) => void
}

export interface State {
  items: ReportItem[]
}

export class ProgressChart extends React.Component<Props, State> {
  state = {
    items: []
  }

  componentDidMount() {
    this.fetch()
  }

  private fetch = async () => {
    const items = (await axios.get(this.props.url)).data as ReportItem[];
    console.log('chart items:', items);
    this.calcPercentages(items) ;
    this.setState({ items }, () => {
      if (this.props.onLoad) {
        this.props.onLoad(items.length !== 0 && items[0].name !== 'None')
      }
    })
  }

  private calcPercentages = (items: ReportItem[]) => {
    const total = items.reduce((total, item) => total += item.y, 0);
    items.forEach(item => {
      item.percentage = item.y / total
    })
  }

  public render() {
    const className = this.props.className || '';

    return (
      <div className={`d-f f-c m-t-12 progress-chart ${className}`}>
        <h4>{ this.props.title }</h4>
        { this.state.items.map((item: ReportItem, index: number) => <FlexRow key={`operating-systems-chart-item-${index}`} className="jc-c ai-c m-l-12 m-r-12 m-b-6">
          <div className="f-3 jc-c">{ item.name }</div>
          <div className="">{ (item.percentage * 100).toFixed(2) } %</div>
          <div className="f-2 jc-c m-l-12">
            <ProgressBar value={ item.percentage } animate={false} stripes={false} intent={this.props.intent}/>
          </div>
        </FlexRow> )}
      </div>
    );
  }
}
