import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import produce from 'immer';
import { deepEqual } from '@patterns/core';
import { AppContext, AppContextType } from '../app_context';
import { Highcharts } from '../highcharts';

export interface Props {
  title: string
  value: number
  legend?: boolean
  height: number
  width: number
}

export interface State {
  options: Highcharts.Options
}

export class GaugeWidget extends React.Component<Props, State> {
  static contextType = AppContext;
  private contextCopy = produce(this.context, () => {});

  private options: Highcharts.Options = {
    chart: {
      type: 'gauge',
      plotBorderWidth: 0,
      plotShadow: false,
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent'
    },

    plotOptions: {
      gauge: {
        dial: {
          baseLength: '70%',
          baseWidth: 6,
          backgroundColor: '#ffffff',
          topWidth: 1
        },
        pivot: {
          backgroundColor: 'transparent',
        },
        lineWidth: 1
      }
    },
    
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
      }
    } as any,
  
    // the value axis
    yAxis: {
      min: 0,
      max: 60,
      minorTickInterval: 'auto',
      minorTickWidth: 0,
      minorTickLength: 4,
      minorTickPosition: 'inside',
      minorTickColor: 'transparent',
      tickPixelInterval: 30,
      gridLineWidth: 0,
      maxPadding: 0,
      tickWidth: 0,
      lineWIdth: 1,
      tickPosition: 'inside',
      tickLength: 4,
      tickColor: 'transparent',
      labels: {
        step: 2,
        rotation: 'auto'
      },
      title: {
        text: 'AGE'
      },
      plotBands: [{
        from: 0,
        to: 36,
        color: '#55BF3B' // green
      }, {
        from: 36,
        to: 48,
        color: '#DDDF0D' // yellow
      }, {
        from: 48,
        to: 100,
        color: '#DF5353' // red
      }],
      plotLines: [{
        width: 1
      },{
        width: 1
      },{
        width: 1
      }]
    } as any,
  
    series: [{
      name: 'Age',
      data: [ 0 ],
      tooltip: {
        valueSuffix: ' months'
      }
    }] as any
  }

  constructor(props: Props, context: AppContextType) {
    super(props);
    this.options.title = { ...context.chartTheme, text: '' }

    const options = this.options
    this.state = { options }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.contextCopy, this.context)) {
      this.contextCopy = this.context;
      const options = Object.assign({}, this.options);
      if (!options.legend) {
        (options as any).legend = {}
      }
      (options as any).legend.enabled = this.props.legend === true;
      this.setState({ options }, this.setData)
    }

    if (this.props.value !== prevProps.value) {
      this.setData()
    }
  }

  componentDidMount() {
    this.setData()
  }

  private setData() {
    const options = produce(this.state.options, options => {
      // options.title!.text = this.props.title;
      (options as any).series[0].data[0] = this.props.value;
    });
    this.setState({ options })
  }

  public render() {
    const { height, width } = this.props;
    
    return (
      <div className="chart-container" style={{ height, width, backgroundColor: 'transparent' }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={this.state.options}
          immutable={true}
        />
      </div>
    );
  }
}
