import { Button, Collapse, FormGroup, InputGroup, NonIdealState } from '@blueprintjs/core';
import { FlexColumn, FlexRow, Notifier } from '@patterns/ui';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { axios, session } from '../../session';
import { SiemensProposal } from './calculation.form';

export interface Props extends WithTranslation {
  onChange: () => void;
  proposal: SiemensProposal;
}

export interface State {
  isLoading: boolean;
  firstName: string;
  lastName: string;
  email: string;
  showForm: boolean;
}

export class Signatories extends React.Component<Props, State> {
  state = {
    isLoading: false,
    firstName: '',
    lastName: '',
    email: '',
    showForm: false
  }

  private onAdd = async () => {
    const { firstName, lastName, email } = this.state;
    this.setState({ isLoading: true }, async () => {
      const data = { firstName, lastName, email, phone: '0', type: 'CUSTOMER' };
      await axios.post(`/siemens/proposals/${this.props.proposal.id}/signatories`, data);
      this.props.onChange();
    })
  }

  private onFirstNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => this.setState({ 
    firstName: evt.currentTarget.value
  })

  private onLastNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => this.setState({ 
    lastName: evt.currentTarget.value
  })

  private onEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => this.setState({ 
    email: evt.currentTarget.value
  })

  private onRemove = async (index: number) => {
    const signatory = this.props.proposal.signatories[index];
    if (signatory) {
      await axios.delete(`/siemens/proposals/${this.props.proposal.id}/signatories/${signatory.id}`);
      this.props.onChange()
    }
  }

  private toggleForm = () => this.setState({ showForm: !this.state.showForm })

  private addDefaultSignatories = async () => {
    try {
      await axios.post(`/siemens/proposals/${this.props.proposal.id}/add_default_signatories`);
      this.props.onChange();
      Notifier.success(this.props.t('proposals.signatory_success'));      
    } catch (e) {
      Notifier.failure(this.props.t('proposals.signatory_failure'))
    }
  }

  private renderForm = () => {
    const { t } = this.props;
    const { firstName, lastName, email } = this.state;

    const valid = firstName.length > 0 && lastName.length > 0 && email.length > 0;

    return <div>
      <FlexColumn className="m-24">
        <div className="f-1">
          <FormGroup inline={false} label={t('first_name')}>
            <InputGroup 
              value={firstName}
              onChange={this.onFirstNameChange}
            />
          </FormGroup>
        </div>
        <div className="f-1">
          <FormGroup inline={false} label={t('last_name')}>
            <InputGroup
            fill
              value={lastName}
              onChange={this.onLastNameChange}
            />
          </FormGroup>
        </div>
        <div className="f-1">
          <FormGroup inline={false} label="Email">
            <InputGroup 
              value={email}
              onChange={this.onEmailChange}
            />
          </FormGroup>
        </div>

        <Button 
          text={t('proposals.add_signatory')}
          disabled={!valid}
          icon="add" 
          onClick={this.onAdd} 
          intent="primary"
        />
      </FlexColumn>
      <div className="f-1">
        
      </div>
    </div>
  }

  private renderItems = () => {
    return this.props.proposal.signatories.map((signatory, index) => {
      return <FlexRow key={`signatory-${index}`} className="ai-c">
        <div className="f-1">{ signatory.firstName } { signatory.lastName }</div>
        <div className="f-1">{ signatory.email }</div>
        <div className="f-1"><Button minimal icon="trash" onClick={() => this.onRemove(index)} intent="warning"/></div>
      </FlexRow>
    })
  }

  public render() {
    const { t } = this.props;
    return (
      <div>
        <h2 className="m-t-0">{t('proposals.current_signatories')}</h2>
        <div className="dark-box">
          { this.renderItems() }

          <FlexRow className="m-t-24">
            <Button
              disabled={session.lesseeId ? false : true}
              outlined
              icon="plus"
              text={t('proposals.add_default_signatories')}
              onClick={this.addDefaultSignatories}
              intent="primary"
              className="m-r-24"
            />

            <Button
              outlined
              icon="plus"
              text={t('proposals.add_signatory')}
              onClick={this.toggleForm}
              intent="primary"
            />
          </FlexRow>

          <Collapse isOpen={this.state.showForm}>
            { !this.state.isLoading && this.renderForm() }
          </Collapse>

          { this.state.isLoading && <NonIdealState  title="" /> }
        </div>
      </div>
    );
  }
}

export default withTranslation()(Signatories)