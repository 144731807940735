import * as React from 'react';
import { Button, IconName } from '@blueprintjs/core'

export interface Props {
  title: string
  minimal?: boolean
  outlined?: boolean
  icon?: IconName
  onConfirm: () => void
  style?: React.CSSProperties
}

export function ConfirmDelete({ onConfirm, title, minimal = false, outlined = false, icon, style }: Props) {
  const [ isPressed, setIsPressed ] = React.useState(false) 

  React.useEffect(() => {
    let handle: number = 0; 
    if (isPressed) {
      handle = setTimeout(() => {
        setIsPressed(false)
        handle = 0;
      }, 10000) as unknown as number
    }

    return () => {
      if (handle !== 0) {
        clearTimeout(handle)
      }
    }
  }, [isPressed])

  return (
    <Button 
      style={style}
      minimal={ minimal }
      outlined={ outlined }
      text={ isPressed ? "Confirm" : title }
      icon={ isPressed ? "tick" : ( icon ? icon : "trash" ) }
      intent={ isPressed ? "danger" : "warning" }
      onClick={ isPressed ? () => onConfirm() : () => setIsPressed(true) }
    />
  );
}
