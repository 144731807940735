import { Spinner } from '@blueprintjs/core';
import deepEqual from 'deep-equal';
import * as React from 'react';
import placeholderSmall from '../assets/no-image-available-full-trans-2.png';

export interface Props {
  alt?: string
  className?: string
  height?: number
  identifier?: string
  url: string
  onClick?: (identifier?: string) => void
  width?: number
}

export interface State {
  isLoading: boolean
}

export class ImageLoader extends React.Component<Props, State> {
  image = React.createRef<HTMLImageElement>()

  constructor(props: Props) {
    super(props)

    this.state = {
      isLoading: true
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(prevProps, this.props)) {
      this.setState({ isLoading: true })
    }
  }

  private onClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.identifier)
    }
  }

  private onLoad = () => this.setState({ isLoading: false })

  private getClassName = () => {
    return `image-loader ${this.props.className ?? ''}`
  }

  private getStyle = () => {
    const style = {} as React.CSSProperties;
    
    if (typeof this.props.height !== 'undefined') {
      style.height = this.props.height;
    }

    if (typeof this.props.width !== 'undefined') {
      style.width = this.props.width;
    }

    return style
  }

  private renderImage() {
    const style = this.getStyle();
    const imageStyle = {} as React.CSSProperties;

    if (typeof style.width !== 'undefined') {
      imageStyle.maxWidth = style.width;
    }

    if (typeof style.height !== 'undefined') {
      imageStyle.maxHeight = style.height;
    }

    let image = !this.state.isLoading && this.image.current?.width ? this.props.url : placeholderSmall;
    let className = this.state.isLoading ? 'hidden' : '';

    return <img
      alt={ this.props.alt }
      className={ className }
      src={ this.props.url }
      onClick={ this.onClick }
      onError={ this.onLoad }
      onLoad={ this.onLoad }
      ref={ image }
      style={ imageStyle }
    />
  }

  private renderSpinner() {
    if (this.state.isLoading) 
      return <Spinner intent="primary" size={48}/>;
    return
  }

  public render() {
    return (
      <div className={this.getClassName()} style={this.getStyle()}>
        { this.renderSpinner() }
        { this.renderImage() }
      </div>
    );
  }
}
