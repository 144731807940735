import * as React from 'react';
import { DataTable, Column, ColumnType, FilterStates, Alignment } from '@patterns/datatable';
import { Asset } from '../../models/asset';
import { formatDate } from '@patterns/core';
import { axios, priceFormat } from '../../common';
import CategoryCell from '../category.cell';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import { session } from '../../session';
import AssetPreviewDialog from '../assets/asset_preview.dialog';

const AssetTable = DataTable.ofType<Asset>();

export interface Props extends RouteComponentProps, WithTranslation {}

export interface State {
  selection: Asset[]
  selected: Asset
  showDialog: boolean
}

export class ReclaimedContractAssets extends React.Component<Props, State> {
  state = {
    selection: [],
    showDialog: false,
    selected: new Asset()
  } as State

  table = React.createRef<DataTable<Asset>>();

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'name',
        title: t('name'),
        type: ColumnType.Text,
        flex: 2,
        visible: true,
        format: asset => <span>{ asset.name }</span>
      },
      {
        id: 'category',
        title: t('category'),
        type: ColumnType.Text,
        flex: 1,
        visible: true,
        format: asset => <CategoryCell category={asset.category}/>
      },
      {
        id: 'serial',
        title: t('assets.serial_number'),
        type: ColumnType.Text,
        flex: 1,
        visible: true,
        format: asset => <span>{ asset.serialNumber }</span>
      },
      {
        id: 'employee',
        title: t('user'),
        type: ColumnType.Text,
        flex: 1,
        visible: true,
        format: asset => <span>{ asset.employee.name }</span>
      },
      {
        id: 'cost_center',
        title: t('cost_center'),
        type: ColumnType.Text,
        flex: 1,
        visible: true,
        format: asset => <span>{ asset.costCenter.name }</span>
      },
      // {
      //   id: 'lessee',
      //   title: t('lessee'),
      //   type: ColumnType.Text,
      //   flex: 1,
      //   visible: true,
      //   format: asset => <span>{ asset.contract.lessee.name }</span>
      // },
      {
        id: 'decisionPrice',
        title: t('assets.purchase_price'),
        type: ColumnType.Text,
        alignment: Alignment.Right,
        width: 130,
        visible: true,
        headerClass: 'p-r-12 ta-r',
        format: asset => <span>{ priceFormat(asset.decisionPrice) }</span>
      },
      // {
      //   id: 'reclaim_date',
      //   title: t('assets.purchase_date'),
      //   type: ColumnType.Text,
      //   alignment: Alignment.Left,
      //   width: 130,
      //   visible: true,
      //   headerClass: 'p-r-12 ta-r',
      //   format: asset => <span>{ formatDate(asset.purchaseDate) }</span>
      // },
      // {
      //   id: 'state',
      //   title: t('status'),
      //   type: ColumnType.Text,
      //   width: 130,
      //   visible: true,
      //   format: asset => <span>{ asset.state }</span>
      // },
      // {
      //   id: 'actions',
      //   title: t('actions'),
      //   type: ColumnType.Text,
      //   width: 130,
      //   visible: true,
      //   format: asset => <span></span>
      // },
      {
        id: 'decision_date',
        title: t('decision_date'),
        type: ColumnType.Date,
        alignment: Alignment.Right,
        width: 150,
        visible: true,
        format: asset => <span>{asset.decisionDate ? formatDate(asset.decisionDate) : ''}</span>
      },
      {
        id: 'actions',
        title: t('revert'),
        type: ColumnType.Text,
        alignment: Alignment.Right,
        width: 150,
        visible: true,
        headerClass: 'p-r-12 ta-r',
        format: asset => {
          if (session.user.isSuperuser && asset.contract.validTill.getTime() > new Date().getTime()) {
            return <Button intent="danger" small text={t('revert')} onClick={() => {
              this.revert(asset);
            }}/>
          }

          if ((session.user.isAdmin)) {
            return <Button intent="danger" small text={t('revert')} onClick={() => {
              this.revert(asset);
            }}/>
          }

          return <span/>
        }
      }
    ] as Column<Asset>[]
  }

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    let params = [
      `page=${page}`,
      `pageSize=${pageSize}`,
      `sort=${sort}`,
      `sortDir=${sortDir.toUpperCase()}`,
    ].join('&')

    const response = await axios.get(`/assets/reclaimed?${params}`);
    const assets = response.data.assets.map((a: any) => new Asset(a));
    return { items: assets, total: 0 }
  }

  private onSelectionChange = (selection: Asset[]) => this.setState({ selection })

  private onSelect = (asset: Asset) => {
    this.setState({ 
      selected: asset,
      showDialog: true
    })
  }

  private revert = async (asset: Asset) => {
    await axios.get(`/assets/${asset.id}/revert`);
    this.table.current?.fetch();
  }

  private onDialogClose = () => {
    this.setState({ showDialog: false }, () => this.table.current?.fetch())
  }

  public render() {
    return (
      <div className="m-24">
        {/* <Toolbar className="table-toolbar">
          <div className="f-2">
            <span className="bold">{t('purchased_leases.title')}</span>
          </div>
        </Toolbar> */}
        <AssetTable 
          id="reclaimed-assets-table"
          ref={this.table}
          columns={this.getColumns()}
          fetch={this.fetch}
          onItemSelect={this.onSelect}
          onSelectionChange={this.onSelectionChange}
          multiple={true}
          sort="decisionDate"
          sortDir="desc"
        />

        <AssetPreviewDialog 
          asset={this.state.selected}
          isOpen={this.state.showDialog}
          onClose={this.onDialogClose}
        />
      </div>
      
    );
  }
}

export default withRouter(withTranslation()(ReclaimedContractAssets))