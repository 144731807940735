import * as React from 'react';

import { Select } from '@blueprintjs/select';
import { MenuItem, Button, ButtonGroup } from '@blueprintjs/core';
import { withTranslation, WithTranslation } from 'react-i18next';

export type Option = { value: string, title: string };

const BlueprintSelect = Select.ofType<Option>()

export interface Props extends WithTranslation {
  option: string
  onSelect: (option: string) => void
}

export interface State {
  option: Option
}

export class EOLStateSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      option: (this.getOptions().find(m => m.value === props.option))!
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.option !== this.props.option) {
      this.setState({ 
        option: (this.getOptions().find(m => m.value === this.props.option))!
      })
    }
  }

  private getOptions = () => {
    const { t } = this.props;
    return [
      { value: 'extend', title: t('ending_leases.extend') },
      { value: 'reclaim', title: t('ending_leases.reclaim') },
      { value: 'return', title: t('ending_leases.return') }
    ] as Option[]
}

  private onOptionSelect = (option: Option) => this.setState({ option }, () => {
    this.setState({ option });
    this.props.onSelect(option.value)
  })

  private renderer = (item: Option, options: any) => <MenuItem
    key={`item-renderer-${item.value}`}
    active={item.value === this.state.option.value}
    text={item.title}
    onClick={options.handleClick}
  />

  public render() {
    return (
      <ButtonGroup>
        <BlueprintSelect
          activeItem={this.state.option}
          filterable={false}
          items={this.getOptions()}
          itemRenderer={this.renderer}
          onItemSelect={this.onOptionSelect}>
          <Button text={this.state.option.title} rightIcon="chevron-down" outlined intent="primary"/>
        </BlueprintSelect>
      </ButtonGroup>
    );
  }
}

export default withTranslation()(EOLStateSelect)