import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import produce from 'immer';
import { deepEqual } from '@patterns/core';
import { axios } from '../common';
import { AppContext, AppContextType } from '../app_context';
import { Highcharts, HighChartsTheme } from '../highcharts';

export interface Props {
  title: string
  url: string
  height: number
  width: number
  legend?: boolean
}

export interface State {
  options: Highcharts.Options
}

export class BarWidget extends React.Component<Props, State> {
  static contextType = AppContext;
  private contextCopy = produce(this.context, () => {});

  private options: Highcharts.Options = {
    chart: {
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      plotBorderWidth: undefined,
      plotShadow: false,
      type: 'column',
      animation: false,
      options3d: {
        enabled: false,
        alpha: 10,
        beta: 0,
        viewDistance: 55
      }
    },
    xAxis: {
      categories: ['Loading'],
      labels: {
        // skew3d: true,
        style: {
          fontSize: '14px'
        }
      }
    },
  
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: 'Value',
        // skew3d: true
      }
    },
  
    tooltip: {
      headerFormat: '<b>{point.key}</b><br>',
      pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
    },
  
    plotOptions: {
      column: {
        stacking: 'normal',
        depth: 40,
        borderColor: 'transparent'
      },
      series: {
        animation: false  
      }
    },

    series: [{
      name: 'Month',
      type: 'column',
      showInLegend: true,
      data: [
        { name: 'Loading...', y: 0 }
      ]
    }]
  }

  constructor(props: Props, context: AppContextType) {
    super(props);
    this.options.title = {...context.chartTheme, text: '' }

    const options = HighChartsTheme(this.options);
    if (!options.legend) {
      options.legend = {}
    }
    options.legend!.enabled = props.legend === true;
    this.state = { options }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.contextCopy, this.context)) {
      this.contextCopy = this.context;
      const options = HighChartsTheme(this.options);
      if (!options.legend) {
        options.legend = {}
      }
      options.legend!.enabled = this.props.legend === true;
      this.setState({ options })
    }
    if (prevProps.url !== this.props.url) {
      this.fetch()
    }
  }

  componentDidMount() {
    this.fetch()
  }

  private updateOptions = () => {
    const options = HighChartsTheme(this.state.options);
    
    // options.legend!.enabled = this.props.legend === true;
    options.title = {...this.context.chartTheme, text: this.props.title };
    options.plotOptions!.column!.depth = this.props.width * 0.05;
    
    this.setState({ options })
  }

  public fetch = async () => {
    const response = await axios.get(this.props.url);
    const items = response.data;
    const data = items.length === 0 ? [{ name: 'None', y: 0 }] : response.data;
    const options = HighChartsTheme(produce(this.state.options, options => {
      options.title!.text = '';
      (options.xAxis as any).categories = data.map((i: any) => i.name);
      (options as any).series[0].data = data;
    }));
    this.setState({ options })
  }

  public render() {
    const { title, height, width } = this.props;
    
    return (
      <div className="chart-container" style={{ height, width }}>
        <div className="chart-inner-title">
          { title }
        </div>
        <div className="chart-inner-container">
          <HighchartsReact
            highcharts={Highcharts}
            options={this.state.options}
            immutable={true}
          />
        </div>
      </div>
    );
  }
}
