import * as React from 'react';

import { Select } from '@blueprintjs/select';
import { MenuItem, Button, ButtonGroup } from '@blueprintjs/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import { formatDate } from '@patterns/core';
import moment from 'moment';

export type Month = { value: number, title: string };

export const Months = [
  { value: 0, title: 'jan' },
  { value: 1, title: 'feb' },
  { value: 2, title: 'mar' },
  { value: 3, title: 'apr' },
  { value: 4, title: 'may' },
  { value: 5, title: 'jun' },
  { value: 6, title: 'jul' },
  { value: 7, title: 'aug' },
  { value: 8, title: 'sep' },
  { value: 9, title: 'oct' },
  { value: 10, title: 'nov' },
  { value: 11, title: 'dec' }
] as Month[]

export type Year = { value: number,  title: string };

export const CurrentYear = new Date().getFullYear();
export const Years = [] as Year[];
for (let i = CurrentYear - 10; i < CurrentYear + 20; i++) {
  Years.push({ value: i, title: `${i}` })
}

const BlueprintSelect = Select.ofType<Month>()
const BlueprintYearSelect = Select.ofType<Year>()

export interface Props extends WithTranslation {
  date: Date
  onSelect: (item: Date) => void
  isEndDate?: boolean
}

export interface State {
  month: Month
  year: Year
}

export class MonthSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      month: (Months.find(m => m.value === props.date.getMonth()))!,
      year: this.getYear(props.date)
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.date.getTime() !== this.props.date.getTime()) {
      this.setState({ 
        month: (Months.find(m => m.value === this.props.date.getMonth()))!,
        year: this.getYear(this.props.date)
      })
    }
  }

  private getYear = (date: Date) => {
    const year = Years.find(year => date.getFullYear() === year.value);
    return year || { value: date.getFullYear(), title: `${date.getFullYear()}` }
  }

  private onMonthSelect = (month: Month) => this.setState({ month }, () => {
    const date = new Date(this.state.year.value, this.state.month.value, 1);
    this.props.onSelect(date)
  })

  private onYearSelect = (year: Year) => this.setState({ year }, () => {
    const date = new Date(this.state.year.value, this.state.month.value, 1);
    this.props.onSelect(date)
  })
    
  private renderer = (item: Month, options: any) => { 
    const _date = moment(new Date(this.props.date.getFullYear(), item.value, 1));
    const date = this.props.isEndDate ? _date.endOf('month').toDate() : _date.startOf('month').toDate();
    return <MenuItem
      key={`item-renderer-${item.value}`}
      active={item.value === this.state.year.value || item.value === this.state.month.value}
      text={this.props.t(`months_abbrev.${item.title}`)}
      label={formatDate(date)}
      onClick={options.handleClick}
    />
  }

  private yearRenderer = (item: Year, options: any) => <MenuItem
    key={`item-renderer-${item.value}`}
    active={item.value === this.state.year.value || item.value === this.state.month.value}
    text={item.title}
    onClick={options.handleClick}
  />

  public render() {
    const _date = moment(new Date(this.props.date.getFullYear(), this.state.month.value, 1));
    const date = this.props.isEndDate ? _date.endOf('month').toDate() : _date.startOf('month').toDate();
    const monthTitle = this.props.t(`months_abbrev.${this.state.month.title}`);
    const title = `${monthTitle} (${formatDate(moment(date).toDate())})`

    return (
      <ButtonGroup>
        <BlueprintSelect
          activeItem={this.state.month}
          filterable={false}
          items={Months}
          itemRenderer={this.renderer}
          onItemSelect={this.onMonthSelect}>
          <Button 
            text={title} 
            // rightIcon="chevron-down" 
            outlined intent="primary" 
            style={{ fontSize: 12 }}/>
        </BlueprintSelect>
        <BlueprintYearSelect
          activeItem={this.state.year}
          filterable={false}
          items={Years}
          itemRenderer={this.yearRenderer}
          onItemSelect={this.onYearSelect}>
          <Button text={this.state.year.title} rightIcon="chevron-down" outlined intent="primary" style={{ fontSize: 12 }}/>
        </BlueprintYearSelect>
      </ButtonGroup>
    );
  }
}

export default withTranslation()(MonthSelect);