import { Button, Classes, Dialog, FormGroup, TextArea } from '@blueprintjs/core';
import * as React from 'react';
import { PurchaseInvoice } from '../../models/purchase_invoice';
import { axios } from '../../session';

export interface Props {
  isOpen: boolean
  invoice: PurchaseInvoice
  onClose: () => void
}

export interface State {
  isLoading: boolean
  reason: string
}

export class RejectReasonDialog extends React.Component<Props, State> {
  state = {
    isLoading: false,
    reason: ''
  } as State

  private confirm = () => this.setState({ isLoading: true }, this.save)

  private save = async () => {
    await axios.put(`/purchase_invoices/${this.props.invoice.id}/reject`, {
      reason: this.state.reason
    });
    this.setState({ isLoading: false }, this.props.onClose)
  }

  private onReasonChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({
    reason: evt.currentTarget.value
  })

  public render() {
    return (
      <Dialog canOutsideClickClose={false} isOpen={this.props.isOpen} title="Reject reason" icon="info-sign" onClose={this.props.onClose} className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="Reason" helperText="Please enter your reason for rejection of the purchase invoice">
            <TextArea value={this.state.reason} onChange={this.onReasonChange} className="w-100p"/>
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Close" icon="cross" onClick={this.props.onClose} />
            <Button disabled={this.state.reason.length <= 1} intent="danger" text="Confirm" icon="cross" onClick={this.confirm} />
          </div>
        </div>
      </Dialog>
    );
  }
}
