import { Eventable } from '@patterns/eventable'
import { Constructable, BaseModel, download } from '@patterns/core'
import { AxiosError, AxiosResponse } from 'axios';
import { User } from './models/user';
import { axios, getQueryVariable } from './common';
import { LesseeRepository } from './repository';

export enum SessionEvents {
  Destroyed = 'session:destroyed',
  Expired = 'session:expired',
  LoggedIn = 'session:logged_in',
  Restored = 'session:restored'
}

export class Session<T extends BaseModel> extends Eventable {
  public token?: string;
  private masterToken?: string;
  private klass: Constructable;

  axios?: any;
  user: T;
  lesseeId?: string;
  lesseeName?: string;
  controlled: boolean = false;

  loggedIn: boolean = false;
  intercepted: boolean = false;

  controlURL: string = '/auth/control';
  loginURL: string = '/auth/login';
  confirmURL: string = '/auth/confirm';
  profileURL: string = '/auth/me';

  constructor(klass: Constructable, _axios: any, loginURL?: string, profileURL?: string) {
    super();
    this.klass = klass;
    this.axios = _axios;

    this.user = new this.klass({}) as T;

    if (loginURL) {
      this.loginURL = loginURL
    }

    if (profileURL) {
      this.profileURL = profileURL
    }

    this.destroy = this.destroy.bind(this)
  }

  private intercept() {
    if (!this.intercepted) {
      this.axios.interceptors.response.use((response: AxiosResponse) => response, (error: AxiosError) => {
        const errorCodes = [401, 403];
        if (error.response && errorCodes.includes(error.response.status)) {
          this.destroy()
        }
        return Promise.reject(error);
      });
      this.intercepted = true
    }
  }

  public setLessee = (lesseeId?: string, lesseeName?: string) => {
    this.lesseeId = lesseeId;
    this.lesseeName = lesseeName;
    axios.defaults.headers.common['X-LEASEX-LESSEE'] = lesseeId;

    if (lesseeId) {
      localStorage.setItem('lessee_id', lesseeId);
    } else {
      localStorage.removeItem('lessee_id');
    }

    if (lesseeName) {
      localStorage.setItem('lessee_name', lesseeName);
    } else {
      localStorage.removeItem('lessee_name');
    }
  }

  public destroy() {
    this.axios.defaults.headers.common['Authorization'] = `Bearer`;
    localStorage.removeItem('session');
    localStorage.removeItem('master');
    localStorage.removeItem('user');
    this.user = new this.klass({}) as T;
    this.token = undefined;
    this.loggedIn = false;
    this.notify(SessionEvents.Destroyed)
  }

  public async restore() {
    const path = window.location.pathname;
    let session = localStorage.getItem('session');

    // allow email tokens on purchase invoices
    if (!session && path.includes('purchase_invoices') &&
      (path.includes('accept') || path.includes('reject'))) {
      session = getQueryVariable('token');
    }

    const master = localStorage.getItem('master');
    let userJSON = localStorage.getItem('user');

    if (!userJSON) {
      userJSON = JSON.stringify({ id: 'signing', name: 'signing' })
    }

    const lesseeId = localStorage.getItem('lessee_id');
    const lesseeName = localStorage.getItem('lessee_name');

    if (session && userJSON) {
      this.token = session;
      if (master) {
        this.masterToken = master;
        if (this.masterToken !== this.token) {
          this.controlled = true;
        }
      }
      this.user = new this.klass(JSON.parse(userJSON)) as T;
      this.loggedIn = true;
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
      this.intercept();

      if (lesseeId && lesseeName) {
        this.setLessee(lesseeId, lesseeName)
      }

      try {
        await this.axios.get(this.profileURL);
        this.notify(SessionEvents.Restored)
      } catch (e) {
        this.destroy()
      }
    }
  }

  public async save() {
    const anyUser = this.user as any;

    if (this.token && this.user && anyUser.organization.keepSessions) {
      if (anyUser.organization.keepSessions)
      localStorage.setItem('session', this.token);
      if (this.masterToken) {
        localStorage.setItem('master', this.masterToken);
      }
      localStorage.setItem('user', JSON.stringify(this.user))
    } else {
      throw new Error("Invalid Session Token or User")
    }
  }

  private loadLessee = async () => {
    const lessees = await LesseeRepository.index(1, 100, 'name', 'asc');;

    if (lessees.items.length > 0) {
      const lessee = lessees.items[0];
      this.setLessee(lessee.id, lessee.name);
    } else {
      this.setLessee(undefined, undefined);
    }
  }

  public async login(code: string) {
    try {
      this.masterToken = undefined;
      this.controlled = false;
      const response = await this.axios.post(this.confirmURL, { code });
      if (response.data.token) {
        this.token = response.data.token;
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        const user = (await this.axios.get(this.profileURL)).data;
        this.user = new this.klass(user) as T;

        await this.loadLessee();

        this.loggedIn = true;
        await this.save();
        this.notify(SessionEvents.LoggedIn);
        return { user: this.user }
      } else {
        throw new Error("Invalid Credentials")
      }
    } catch (e) {
      throw new Error("Login Failure")
    }
  }

  public async prelogin(email: string, password: string, language: string) {
    try {
      this.masterToken = undefined;
      this.controlled = false;
      const response = await this.axios.post(this.loginURL, { email, password, language });
      if (response.data.success) {
        return { success: true }
      } else {
        throw new Error("Invalid Credentials")
      }
    } catch (e) {
      throw new Error("Login Failure")
    }
  }

  public async passwordReset(email: string) {
    await this.axios.get(`/auth/password_reset?email=${email}`);
  }

  public async cancelControl() {
    try {
      this.controlled = false;
      this.token = this.masterToken;
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
      const user = (await this.axios.get(this.profileURL)).data;
      this.user = new this.klass(user) as T;
      this.loggedIn = true;
      await this.save();
      this.notify(SessionEvents.LoggedIn);
    } catch (e) {
      console.error(e)
      throw new Error("Login Failure")
    }
  }


  public async control(organization_id: string) {
    try {
      if (!this.masterToken) {
        this.masterToken = this.token;
      }

      const response = await this.axios.post(this.controlURL, { organization_id });
      if (response.data.token) {
        this.token = response.data.token;
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        const user = (await this.axios.get(this.profileURL)).data;
        this.user = new this.klass(user) as T;
        await this.loadLessee();
        this.loggedIn = true;
        this.controlled = true;
        await this.save();
        this.notify(SessionEvents.LoggedIn);
        return { user: this.user }
      } else {
        throw new Error("Invalid Credentials")
      }
    } catch (e) {
      throw new Error("Login Failure")
    }
  }

  public download(url: string) {
    if (!this.token) {
      console.error('Patterns Session => download not started, missing token');
      return
    }

    download(url, this.token)
  }

  static ofType<T extends BaseModel>(klass: Constructable, axios?: any) {
    return new Session<T>(klass, axios)
  }
}

const session = new Session(User, axios) as Session<User>;

export {
  axios,
  session
}
