import { axios } from "./session"
import { SiemensPricelist } from "./models/siemens_pricelist"
// import { Notifier } from "@patterns/ui"

export type AssetTypeMap = { [id:number] : number }

export type SiemensAssetType = {
  id: number;
  name: string;
}

export type SiemensAssetSubType = {
  id: number;
  assetTypeId: number;
  name: string;
}

export type SiemensAsset = {
  id?: number;
  assetTypeId: number;
  invoiceId?: string;
  invoiceCode?: string;
  invoiceStatus?: string;
  proposalId?: number;
  assetSubTypeId: number;
  condition: string;
  description: string;
  deliveryDate: string;
  quantity: number;
  totalValue: string;
  valuePerAsset: string;
  valuePerAssetNum: number;
  residualValue: number;
  serialNumber: string;
  user?: string;
  userId?: string;
  costCenter?: string;
  costCenterId?: string;
  note: string;
  location: string;
}

export type SiemensOffice = {
  id: number;
  name: string;
  postalAddress: {
    id: number;
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    phoneNumber: string;
    email: string;
  };
}

export type SiemensRole = {
  id: string;
  name: string;
}

export type SiemensSeller = {
  id: number;
  firstName: string;
  lastName: string;
  title?: string;
  email: string;
  phone?: string;
  cellPhone?: string;
  fax: null;
  role: SiemensRole;
  blocked: boolean;
  blockedReason: null;
  loginPresent: true;
  officeIds: number[];
}


export type SiemensProposalAssetSummary = {
  numberOfAssets: number;
  totalAssetValue: number;
}

export type SiemensProposalStatus = {
  amendAllowed: boolean;
  assetsReadonly: boolean;
  calculationsReadonly: boolean;
  customerReadonly: boolean;
  equipmentSupplierAllowed: boolean;
  equipmentSupplierReadonly: boolean;
  loanReadonly: boolean;
  officeReadonly: boolean;
  proposalReadonly: boolean;
  salesContactReadonly: boolean;
  signatoryReadonly: boolean;
  status: string;
  upgradeReadonly: boolean;
}

export type SiemensProposalAsset = {
  description: string;
  assetTypeId: number;
  assetSubTypeId: number;
  quantity: number;
  valuePerAsset: string;
  totalValue: string;
  deliveryDate: string;
  condition: string;
}

export type SiemensProposalCalculation = {
  operation: string;
  sheet: {
    marketProductId: number;
    introducerPricelistId: number;
  }
}

export type SiemensNamedObject = {
  id: number;
  name: string;
}

export type SiemensCalculationFee = {
  id: number;
  name: string;
  readonly: boolean;
  code: string;
}

export type SiemensCalculationIncludedFee = {
  amount: number;
  feeIncluded: boolean;
  feeType: SiemensCalculationFee;
  manualSet: false;
}

export type SiemensProposalCurrentCalculation = {
  assetValue: number;
  created: string;
  fees: SiemensCalculationIncludedFee[];
  id: number;
  introducerPricelist: SiemensNamedObject;
  introducerPricelistId: number;
  marketProduct: SiemensNamedObject;
  marketProductId: number;
  payment: { 
    paymentMode: 'IN_ADVANCE';
    paymentPerMonth: number;
  };
  paymentPeriod: {
    id: number;
    label: "Quarter";
    periodsPerYear: number;
    monthsPerPeriod: number;
  };
  postponedStart: {
    month: null;
    affectedParty: null;
  };
  residualValue: {
    amount: number;
    percentage: number;
    usePercentage: boolean;
    manualSet: boolean;
  };
  selloutTariffRate: number;
  specialPayments: any[];
  specialPaymentsSum: number;
  term: number;
  vatReclaim: null;
};

const axiosOptions = {
  timeout: 20000
}

export class SiemensManager {
  flatSubTypes: SiemensAssetSubType[] = [];
  types: SiemensAssetType[] = [];
  subTypes: { [id:string] : SiemensAssetSubType[] } = {};
  priceLists: SiemensPricelist[] = [];
  offices: SiemensOffice[] = [];
  sellers: SiemensSeller[] = [];

  public fetch = async () => {
    try {
      let response = await axios.get('/siemens/asset-config', axiosOptions);
      this.types = response.data.assetTypes;
      this.subTypes = response.data.assetSubTypes;
      this.flatSubTypes = [];
      Object.keys(this.subTypes).forEach(key => {
        this.flatSubTypes.push(...this.subTypes[key])
      })

      response = await axios.get('/siemens_pricelists?pageSize=1000', axiosOptions);
      this.priceLists = response.data.siemens_pricelists.map((item: any) => new SiemensPricelist(item))

      const officesRes = await axios.get('/siemens/offices');
      this.offices = officesRes.data.offices as SiemensOffice[];

      const sellersRes = await axios.get('/siemens/sellers');
      this.sellers = sellersRes.data.sellers as SiemensSeller[];
      console.log('siemens data initialized');
    } catch (e) {
      console.warn('siemens data fetch failed');
      // Notifier.failure('Siemens data retrieval failed, application may behave erratic.')
    }
  }

  public getType = (type_id: number) => {
    return this.types.find(t => t.id === type_id);
  }

  public searchType = (query: string) => {
    if (query.length === 0) {
      return this.types;
    }

    return this.types.filter(t => t.name.toLowerCase().includes(query.toLowerCase()));
  }

  public getSubType = (subtype_id: number) => {
    return this.flatSubTypes.find(t => t.id === subtype_id);
  }

  public getSubtypes = (type_id: number) => {
    return this.subTypes[type_id];
  }

  public searchSubType = (query: string, type_id?: number) => {
    if (query.length === 0) {
      if (type_id) {
        return this.getSubtypes(type_id);
      }
      return this.flatSubTypes;
    }

    if (type_id) {
      return this.getSubtypes(type_id).filter(t => t.name.toLowerCase().includes(query.toLowerCase()));  
    }

    return this.flatSubTypes.filter(t => t.name.toLowerCase().includes(query.toLowerCase()));
  }

  public getSubTypePricelist = (subtype_id: number) => {
    console.log('subtype pricelist', subtype_id, this.priceLists.find(priceList => priceList.subtype_id === subtype_id), this.priceLists);
    return this.priceLists.find(priceList => priceList.subtype_id === subtype_id);
  }

  public requestCreditDecision = async (proposal_id: string, comment: string) => {
    const response = await axios.post(`/siemens/proposals/${proposal_id}/request_credit_decision`, {
      commentToRiskAnalyst: comment
    });
    return response.data
  }
}

const SiemensManagerInstance = new SiemensManager();
export default SiemensManagerInstance;
