import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../layouts/main';
import { Tabs, Tab } from '@blueprintjs/core';
import { FlexColumn } from '@patterns/ui';
import UsersView from '../components/settings/users';
import CostCenters from '../components/settings/cost_centers';
import Categories from '../components/settings/categories';
import { SupplierRepository, LeasingCompanyRepository, LesseeRepository } from '../repository';
import { ContactType } from '../models/contact';
import { ContactsView } from '../components/settings/contacts';
import { Supplier } from '../models/supplier';
import { LeasingCompany } from '../models/leasing_company';
import { Lessee } from '../models/lessee';
import ExtraCosts from '../components/settings/extra_costs';
import Offices from '../components/settings/offices';
import PickupPlaces from '../components/settings/pickup_places';
import Employees from '../components/settings/employees';
import { Breadcrumbs } from '../components/breadcrumb';
import { withTranslation, WithTranslation } from 'react-i18next';
import { session } from '../session';

const LeasingCompaniesView = ContactsView.ofType<LeasingCompany>();
const LesseesView = ContactsView.ofType<Lessee>();
const SuppliersView = ContactsView.ofType<Supplier>();

interface Props extends WithTranslation, RouteComponentProps {}

export interface State {
  tabId: string
}

export class Settings extends React.Component<Props, State> {
  state = {
    showDialog: false,
    tabId: 'user_accounts'
  } as State

  private onTabChange = (tabId: string) => this.setState({ tabId })

  public render() {
    const { t } = this.props;

    console.log('user is admin', session.user.isAdmin);

    return (
      <MainLayout
        title="Settings">
        { this.state.tabId === 'user_accounts' && <Breadcrumbs path="Settings / User Accounts" title={t('settings.user_accounts')}/> }
        { this.state.tabId === 'cost_centers' && <Breadcrumbs path="Settings / Cost Centers" title={t('cost_centers')}/> }
        { this.state.tabId === 'categories' && session.user.isAdmin && <Breadcrumbs path="Settings / Categories" title={t('categories')}/> }
        { this.state.tabId === 'suppliers' && session.user.isAdmin && <Breadcrumbs path="Settings / Suppliers" title={t('suppliers')}/> }
        { this.state.tabId === 'leasing_companies' && session.user.isAdmin && <Breadcrumbs path="Settings / Leasing Companies" title={t('leasing_companies.title')}/> }
        { this.state.tabId === 'lessees' && session.user.isAdmin && <Breadcrumbs path="Settings / Lessees" title={t('lessees')} /> }
        { this.state.tabId === 'extra_costs' && session.user.isAdmin && <Breadcrumbs path="Settings / Extra Costs" title={t('settings.extra_costs')}/> }
        { this.state.tabId === 'employees' && <Breadcrumbs path="Settings / Employees" title={t('employees')}/> }
        { this.state.tabId === 'offices' && <Breadcrumbs path="Settings / Offices" title={t('offices')}/> }
        { this.state.tabId === 'pickup_places' && <Breadcrumbs path="Settings / Pickup Places" title={t('pickup_places')}/> }
        
        <FlexColumn flex={1}>
          <Tabs
            className="custom-tabs"
            selectedTabId={this.state.tabId}
            onChange={this.onTabChange}
            renderActiveTabPanelOnly={true}>
            { (session.user.isAdmin || session.user.isSuperuser) && <Tab id="user_accounts" title={t('settings.user_accounts')} panel={<UsersView />}/> }
            <Tab id="cost_centers" title={t('cost_centers')} panel={<CostCenters />}/>
            { session.user.isAdmin && <Tab id="categories" title={t('categories')} panel={<Categories />}/> }
            { session.user.isAdmin && <Tab id="suppliers" title={t('suppliers')} panel={
              <SuppliersView
                id="suppliers-table"
                title={t('suppliers')}
                repository={SupplierRepository}
                type={ContactType.SUPPLIER}
                button={t('settings.add_supplier')}
                {...this.props}
              />
            }/> }
            { session.user.isAdmin && <Tab id="leasing_companies" title={t('leasing_companies.title')} panel={
              <LeasingCompaniesView
                id="leasing-companies-table"
                title={t('leasing_companies.title')}
                repository={LeasingCompanyRepository}
                type={ContactType.LEASING_COMPANY}
                button={t('settings.add_company')}
                {...this.props}
              />
            }/> }
            {  session.user.isAdmin && <Tab id="lessees" title={t('lessees')} panel={
              <LesseesView
                id="lessees-table"
                title={t('lessees')}
                repository={LesseeRepository}
                type={ContactType.LESSEE}
                button={t('settings.add_lessee')}
                {...this.props}
              />
            }/> }
            {  session.user.isAdmin && <Tab id="extra_costs" title={t('settings.extra_costs')} panel={<ExtraCosts />}/> }
            <Tab id="employees" title={t('employees')} panel={<Employees />}/>
            <Tab id="offices" title={t('offices')} panel={<Offices />}/>
            { session.user.isAdmin &&  <Tab id="pickup_places" title={t('pickup_places')} panel={<PickupPlaces />}/> }
            {/* <Tab id="pricelists" title={t('pricelists')} panel={<SiemensPricelists />}/> */}
          </Tabs>
        </FlexColumn>
      </MainLayout>
    );
  }
}

export default withTranslation()(Settings)