import { Button, MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import SiemensManagerInstance, { SiemensAssetSubType, SiemensAssetType } from '../siemens.manager';

const SubTypeSuggest = Suggest.ofType<SiemensAssetSubType>();

export interface Props extends WithTranslation {
  disabled: boolean;
  activeItem: SiemensAssetSubType;
  onSelect: (item: SiemensAssetSubType) => void;
  type?: SiemensAssetType
}

interface State {
  activeItem: SiemensAssetSubType;
  query: string;
  items: SiemensAssetSubType[];
}

export class SiemensSubTypeSuggest extends React.Component<Props, State> {
  state = {
    activeItem: this.props.activeItem || {} as SiemensAssetSubType,
    query: '',
    items: SiemensManagerInstance.searchSubType('', this.props.type?.id)
  } as State

  componentDidUpdate(prevProps: Props) {
    const { activeItem } = this.props;
    if (activeItem.id !== prevProps.activeItem.id) {
      this.setState({ activeItem })
    }

    if (this.props.type !== prevProps.type) {
      this.setState({ 
        items: SiemensManagerInstance.searchSubType('', this.props.type?.id)
      })
    }
  }

  private onQueryChange = (query: string) => this.setState({ 
    query,
    items: SiemensManagerInstance.searchSubType(query, this.props.type?.id)
  })

  private inputValueRenderer = (item: SiemensAssetSubType) => item.name

  private onActiveItemChange = (item: SiemensAssetSubType | null) => {
    if (item && item.id !== this.state.activeItem.id) {
      this.setState({ activeItem: item })
    }
  }

  private renderer = (type: SiemensAssetType, options: any) => <MenuItem
    key={`type-${type.id}-item`}
    text={type.name}
    onClick={options.handleClick}
    active={this.state.activeItem.id === type.id}
  />

  public render() {
    return (
      <SubTypeSuggest
        disabled={this.props.disabled}
        items={this.state.items}
        selectedItem={this.state.activeItem}
        onQueryChange={this.onQueryChange}
        itemRenderer={this.renderer}
        inputValueRenderer={this.inputValueRenderer}
        onActiveItemChange={this.onActiveItemChange}
        onItemSelect={this.props.onSelect}
        inputProps={{
          placeholder: this.props.t('search')
        }}>
        <Button minimal text={this.state.activeItem.name || this.props.t('select_subtype') }/>
      </SubTypeSuggest>
    );
  }
}

export default withTranslation()(SiemensSubTypeSuggest);