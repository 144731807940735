import * as React from 'react';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import PickupPlacesDialog from './pickup_place.dialog';
import { PickupPlaceRepository } from '../../repository';
import { PickupPlace } from '../../models/pickup_place';

import { Notifier, Toolbar } from '@patterns/ui';
import { Button } from '@blueprintjs/core';
import { WithTranslation, withTranslation } from 'react-i18next';

const PickupPlacesTable = DataTable.ofType<PickupPlace>();

export interface Props extends WithTranslation {}

export interface State {
  selected: PickupPlace
  showDialog: boolean
}

export class PickupPlaces extends React.Component<Props, State> {
  cancelToken: any;

  state = {
    selected: new PickupPlace({}),
    showDialog: false
  } as State

  tableRef = React.createRef<any>();

  private create = async () => {
    this.setState({
      selected: new PickupPlace({}),
      showDialog: true
    })
  }

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'id',
        title: 'ID',
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        width: 80,
        visible: false,
        format: pickupPlace => <span>{ pickupPlace.id }</span>
      },
      {
        id: 'name',
        title: t('name'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: pickupPlace => <span>{ pickupPlace.name }</span>
      },
      {
        id: 'address',
        title: t('address'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: pickupPlace => <div>
          { [
            pickupPlace.street, 
            pickupPlace.street_2, 
            pickupPlace.city, 
            pickupPlace.postalCode
          ].filter(f => f).join(', ') }
        </div>
      },
      {
        id: 'lat',
        title: t('latitude'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        width: 130,
        visible: true,
        format: pickupPlace => <span>{ pickupPlace.lat }</span>
      },
      {
        id: 'lng',
        title: t('longitude'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: pickupPlace => <span>{ pickupPlace.lng }</span>
      },
      {
        id: 'contactName',
        title: t('name'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: pickupPlace => <span>{ pickupPlace.name }</span>
      },
      {
        id: 'contactEmail',
        title: t('email'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: pickupPlace => <span>{ pickupPlace.name }</span>
      },
      {
        id: 'contactPhone',
        title: t('phone'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: pickupPlace => <span>{ pickupPlace.name }</span>
      },
    ] as Column<PickupPlace>[]
  }

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await PickupPlaceRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken
    );
    this.cancelToken = cancelToken;
    return { items, total }
  }

  private onSelect = (selected: PickupPlace) => this.setState({ 
    selected,
    showDialog: true 
  })

  private onDelete = async (pickupPlace: PickupPlace) => {
    await PickupPlaceRepository.delete(pickupPlace.id);
    Notifier.success(this.props.t('settings.pickup_place_remove_success'));
    this.setState({ showDialog: false });
    this.tableRef.current?.fetch();
  }

  private onClose = () => {
    this.setState({ showDialog: false });
    this.tableRef.current?.fetch();
  }

  public render() {
    return (
      <div className="m-l-24 m-r-24">
        <Toolbar>
          <div className="f-1 jc-s ai-c d-f m-l-12">
            <Button intent="primary" text={this.props.t('settings.add_pickup_place')} icon="plus" onClick={this.create}/>
          </div>
        </Toolbar>
        
        <PickupPlacesTable
          id="pickupPlaces-table"
          ref={this.tableRef}
          columns={this.getColumns()}
          fetch={this.fetch}
          sort="id"
          sortDir="asc"
          onItemSelect={this.onSelect}
        />

        <PickupPlacesDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onDelete={this.onDelete}
          pickupPlace={this.state.selected}
        />
      </div>
    );
  }
}

export default withTranslation()(PickupPlaces)