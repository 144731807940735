import * as React from 'react';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import EmployeesDialog from './employee.dialog';
import { EmployeeRepository } from '../../repository';
import { Employee } from '../../models/employee';

import { Notifier, Toolbar } from '@patterns/ui';
import { Button } from '@blueprintjs/core';
import { withTranslation, WithTranslation } from 'react-i18next';

const EmployeesTable = DataTable.ofType<Employee>();

export interface Props extends WithTranslation {}

export interface State {
  selected: Employee
  showDialog: boolean
}

export class Employees extends React.Component<Props, State> {
  cancelToken: any;

  state = {
    selected: new Employee({}),
    showDialog: false
  } as State

  tableRef = React.createRef<any>();

  private create = async () => {
    this.setState({
      selected: new Employee({}),
      showDialog: true
    })
  }

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'id',
        title: 'ID',
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        width: 80,
        visible: false,
        format: employee => <span>{ employee.id }</span>
      },
      {
        id: 'name',
        title: t('name'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: employee => <span>{ employee.name }</span>
      },
      {
        id: 'classification',
        title: t('classification'),
        type: ColumnType.Text,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
        format: employee => <span>{ employee.classification }</span>
      }
    ] as Column<Employee>[]
  }

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    const { items, total, cancelToken } = await EmployeeRepository.index(
      page, pageSize, sort, sortDir, filters, this.cancelToken
    );
    this.cancelToken = cancelToken;
    console.log('has users', items);
    return { items, total }
  }

  private onSelect = (selected: Employee) => this.setState({ 
    selected,
    showDialog: true 
  })

  private onDelete = async (employee: Employee) => {
    await EmployeeRepository.delete(employee.id);
    Notifier.success('Employee was successfully removed');
    this.setState({
      showDialog: false
    })
    this.tableRef.current?.fetch();
  }

  private onClose = () => {
    this.setState({ showDialog: false });
    this.tableRef.current?.fetch();
  }

  public render() {
    return (
      <div className="m-l-24 m-r-24">
        <Toolbar>
          <div className="f-1 jc-s ai-c d-f m-l-12">
            <Button intent="primary" text={this.props.t('settings.add_employee')} icon="plus" onClick={this.create}/>
          </div>
        </Toolbar>
        
        <EmployeesTable
          id="employees-table"
          ref={this.tableRef}
          columns={this.getColumns()}
          fetch={this.fetch}
          sort="id"
          sortDir="asc"
          onItemSelect={this.onSelect}
        />

        <EmployeesDialog
          isOpen={this.state.showDialog}
          onClose={this.onClose}
          onDelete={this.onDelete}
          employee={this.state.selected}
        />
      </div>
    );
  }
}

export default withTranslation()(Employees)