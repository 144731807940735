import { BaseModel, deserializeNumber, Resource } from '@patterns/core'
import { deserializeString } from '@patterns/core'
import { PurchaseInvoice } from './purchase_invoice';

@Resource('purchase_invoice_attachments')
export class PurchaseInvoiceAttachment extends BaseModel {
  name: string;
  type: string;
  path: string;
  size: number;

  invoice?: PurchaseInvoice

  constructor(data: any = {}) {
    super(data);
    this.name = deserializeString(data.name);
    this.type = deserializeString(data.type);
    this.path = deserializeString(data.path);
    this.size = deserializeNumber(data.size);
    
    if (data.invoice) {
      this.invoice = new PurchaseInvoice(data.contract);
    }
  }
}