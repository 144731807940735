import * as React from 'react';
import { MainLayout } from '../layouts/main';
import { ContactsView } from '../components/settings/contacts';
import { ContactType } from '../models/contact';
import { AcceptanceTemplateRepository } from '../repository';
import { AcceptanceTemplate } from '../models/acceptance_template';
import { withTranslation, WithTranslation } from 'react-i18next';

const AcceptanceTemplatesView = ContactsView.ofType<AcceptanceTemplate>();

export interface Props extends WithTranslation {}

export class AcceptanceTemplates extends React.Component<Props> {
  public render() {
    return (
      <MainLayout title={this.props.t('acceptance_templates')}>
        <AcceptanceTemplatesView
          id="acceptance_templates-table"
          title={this.props.t('acceptance_templates')}
          repository={AcceptanceTemplateRepository}
          type={ContactType.ACCEPTANCE_TEMPLATE}
          button="add"
          {...this.props}
        />
      </MainLayout>
    );
  }
}

export default withTranslation()(AcceptanceTemplates)