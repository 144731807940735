import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import produce from 'immer';
import { deepEqual } from '@patterns/core';
import { axios } from '../../common';
import { AppContext, AppContextType } from '../../app_context';
import { Highcharts, HighChartsTheme } from '../../highcharts';
import { WithTranslation } from 'react-i18next';

export interface Props extends WithTranslation {
  height: number
  width: number
  legend?: boolean
}

export interface State {
  options: Highcharts.Options
}

export class AssetLifecycleChart extends React.Component<Props, State> {
  static contextType = AppContext;
  private contextCopy = produce(this.context, () => {});

  private options: Highcharts.Options = {
    chart: {
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      plotBorderWidth: undefined,
      plotShadow: false,
      type: 'column',
      marginTop: 24,
      options3d: {
        enabled: false,
        alpha: 10,
        // beta: 12,
        viewDistance: 55
      }
    },
    xAxis: {
      categories: ['Loading'],
      gridLineColor: 'black',
      labels: {
        skew3d: true,
        style: {
          fontSize: '14px'
        }
      }
    },
  
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: this.props.t('quantity'),
        skew3d: true
      }
    },
  
    tooltip: {
      headerFormat: '<b>{point.key}</b><br>',
      pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
    },
  
    plotOptions: {
      column: {
        stacking: 'normal',
        depth: 40
      }
    },

    series: [{
      name: 'Month0',
      type: 'column',
      showInLegend: true,
      data: [
        { name: 'Loading...', y: 0 }
      ]
    }]
  }

  constructor(props: Props, context: AppContextType) {
    super(props);
    this.options.title = {...context.chartTheme, text: '' }

    this.state = {
      options: HighChartsTheme(this.options)
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.contextCopy, this.context)) {
      this.contextCopy = this.context;
      this.setState({
        options: HighChartsTheme(this.state.options)
      })
    }
  }

  componentDidMount() {
    this.fetch()
  }

  private updateOptions = () => {
    const options = HighChartsTheme(this.state.options);
    
    options.legend = { enabled: this.props.legend === true };
    // options.legend!.enabled = this.props.legend === true;
    options.title = {...this.context.chartTheme, text: null };
    options.plotOptions!.column!.depth = this.props.width * 0.05;
    
    this.setState({ options })
  }

  public fetch = async () => {
    const { t } = this.props;
    const response = await axios.get(`/assets/lifecycle_chart?lang=${this.props.i18n.language}`);
    const items = response.data;
    const data = items.length === 0 ? [{ name: 'None', y: 0 }] : response.data;
    const options = HighChartsTheme(produce(this.state.options, options => {
      // options.title!.text = null;
      const categories = [] as string[];
      const seriesNames = [] as string[];

      data.forEach((i: any) => {
        if (!categories.includes(i.category)) {
          categories.push(i.category)
        }

        if (!seriesNames.includes(i.name)) {
          seriesNames.push(i.name)
        }
      });
        
      const series = [] as any[];
      let names = seriesNames[0] === 'EOL' ? seriesNames : seriesNames.reverse();
      names.forEach(serie => {
        const seriesIdx = seriesNames.findIndex(s => s === serie);
        categories.forEach(category => {
          const item = data.find((i: any) => i.category === category && i.name === serie);
          if (!series[seriesIdx]) {
            let color = '#4CCA93' // utilization;
            if (serie === 'EOL') {
              color = '#D78236'
            }

            series[seriesIdx] = {
              name: t(serie),
              data: [ item ? item.y : 0 ],
              color
            }
          } else {
            series[seriesIdx].data.push( item ? item.y : 0 )
          }
        })
      })
      
      options.xAxis = { categories };
      options.series = series;
    }));
    this.setState({ options })
  }

  public render() {
    const { height, width } = this.props;
    
    return (
      <div className="chart-container" style={{ height, width }}>
        <div className="chart-inner-title">
          {this.props.t('charts.lifecycle_chart')}
        </div>
        <div className="chart-inner-container">
          <HighchartsReact
            highcharts={Highcharts}
            options={this.state.options}
            immutable={true}
          />
        </div>
      </div>
    );
  }
}
