import * as React from 'react';
import { MainLayout } from '../layouts/main';
import { Tabs, Tab } from '@blueprintjs/core';
import { EOLManagement } from '../components/eol/management';
import { FlexColumn } from '@patterns/ui';
import { ExtendedContractAssets } from '../components/eol/extended';
import { ReclaimedContractAssets } from '../components/eol/reclaimed';
import { ReturnedContractAssets } from '../components/eol/returned';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export interface Props extends RouteComponentProps, WithTranslation{}

export interface State {
  tab: string
}

export class EOL extends React.Component<Props, State> {
  state = {
    tab: 'eol'
  }

  eolRef = React.createRef<EOLManagement>();
  extendedRef = React.createRef<ExtendedContractAssets>();
  reclaimedRef = React.createRef<ReclaimedContractAssets>();
  returnedRef = React.createRef<ReturnedContractAssets>();

  private reload = () => {
    this.eolRef.current?.table.current?.fetch();
    this.extendedRef.current?.table.current?.fetch();
    this.reclaimedRef.current?.table.current?.fetch();
    this.returnedRef.current?.table.current?.fetch();
  }

  private onTabChange = (tab: string) => this.setState({ tab });

  public render() {
    return (
      <MainLayout title="End of Life Management">
        <FlexColumn>
          <Tabs selectedTabId={this.state.tab} onChange={this.onTabChange} className="custom-tabs">
            <Tab id="eol" title="EOL Management" panel={<EOLManagement {...this.props} ref={this.eolRef} reload={this.reload} />}/>
            <Tab id="extended" title="Extended" panel={<ExtendedContractAssets {...this.props} ref={this.extendedRef} />}/>
            <Tab id="reclaimed" title="Reclaimed" panel={<ReclaimedContractAssets {...this.props} ref={this.reclaimedRef} />}/>
            <Tab id="returned" title="To be returned" panel={<ReturnedContractAssets {...this.props} ref={this.returnedRef} />}/>
          </Tabs>
        </FlexColumn>
      </MainLayout>
    );
  }
}

export default withRouter(withTranslation()(EOL))