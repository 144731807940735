import { BaseModel, Selectable, Resource, deserializeString, deserializeDate, formatDate } from '@patterns/core'

@Resource('budget_plans')
export class BudgetPlan extends BaseModel implements Selectable {
  name: string
  data: any
  start: Date
  end: Date

  constructor(data: any = {}) {
    super(data);
    this.name = deserializeString(data.name);
    this.start = deserializeDate(data.start);
    this.end = deserializeDate(data.end);
    this.data = data.data;
  }

  getLabel() { return `${formatDate(this.start)} - ${formatDate(this.end)}` }
  getTitle() { return this.name }
}
