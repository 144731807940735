import * as React from 'react';
import { Button } from '@blueprintjs/core';

export interface Props {
  onClick: () => void
}

export function CreateButton({ onClick }: Props) {
  return (
    <div style={{
      position: 'absolute',
      bottom: 60,
      right: 24,
      zIndex: 10
    }}>
      <Button 
        large
        onClick={onClick}
        icon="plus"
        intent="success"
      />
    </div>
  );
}
