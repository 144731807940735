import React from 'react';
import { RemoteSelect, RemoteSelectProps } from '../components/remote_select';
import { Asset } from '../models/asset';
import { Category } from '../models/category';
import { Contact } from '../models/contact';
import { CostCenter } from '../models/cost_center';
import { Contract } from '../models/contract';
import { User } from '../models/user';
import { axios } from '../common';
import { Organization } from '../models/organization';
import { Supplier } from '../models/supplier';
import { Lessee } from '../models/lessee';
import { LeasingCompany } from '../models/leasing_company';
import { PurchaseInvoice } from '../models/purchase_invoice';
import { Office } from '../models/office';
import { PickupPlace } from '../models/pickup_place';
import { Employee } from '../models/employee';
import { BudgetPlan } from '../models/budget_plan';
import { RemoteSuggest, RemoteSuggestProps } from './remote_suggest';

export const AssetSelect = (props: RemoteSelectProps<Asset>) => {
  const AssetSelect = RemoteSelect.ofType<Asset>();
  return <AssetSelect {...props} axios={axios} klass={Asset} />
}

export const BudgetPlanSelect = (props: RemoteSelectProps<BudgetPlan>) => {
  const BudgetPlanSelect = RemoteSelect.ofType<BudgetPlan>();
  return <BudgetPlanSelect {...props} axios={axios} klass={BudgetPlan} />
}

export const CategorySelect = (props: RemoteSelectProps<Category>) => {
  const CategorySelect = RemoteSelect.ofType<Category>();
  return <CategorySelect {...props} axios={axios} klass={Category} />
}

export const ContactSelect = (props: RemoteSelectProps<Contact>) => {
  const ContactSelect = RemoteSelect.ofType<Contact>();
  return <ContactSelect {...props} axios={axios} klass={Contact} />
}

export const ContractSelect = (props: RemoteSelectProps<Contract>) => {
  const ContractSelect = RemoteSelect.ofType<Contract>();
  return <ContractSelect {...props} axios={axios} klass={Contract} />
}

export const CostCenterSelect = (props: RemoteSelectProps<CostCenter>) => {
  const CostCenterSelect = RemoteSelect.ofType<CostCenter>();
  return <CostCenterSelect {...props} axios={axios} klass={CostCenter} />
}

export const CostCenterSuggest = (props: RemoteSuggestProps<CostCenter>) => {
  const CostCenterSuggest = RemoteSuggest.ofType<CostCenter>();
  return <CostCenterSuggest {...props} axios={axios} klass={CostCenter} />
}

export const EmployeeSelect = (props: RemoteSelectProps<Employee>) => {
  const EmployeeSelect = RemoteSelect.ofType<Employee>();
  return <EmployeeSelect {...props} axios={axios} klass={Employee} />
}

export const EmployeeSuggest = (props: RemoteSuggestProps<Employee>) => {
  const EmployeeSuggest = RemoteSuggest.ofType<Employee>();
  return <EmployeeSuggest {...props} axios={axios} klass={Employee}/>
}

export const LeasingCompanySelect = (props: RemoteSelectProps<LeasingCompany>) => {
  const LeasingCompanySelect = RemoteSelect.ofType<LeasingCompany>();
  return <LeasingCompanySelect {...props} axios={axios} klass={LeasingCompany} />
}

export const LesseeSelect = (props: RemoteSelectProps<Lessee>) => {
  const LesseeSelect = RemoteSelect.ofType<Lessee>();
  return <LesseeSelect {...props} axios={axios} klass={Lessee} />
}

export const OfficeSelect = (props: RemoteSelectProps<Office>) => {
  const OfficeSelect = RemoteSelect.ofType<Office>();
  return <OfficeSelect {...props} axios={axios} klass={Office} />
}

export const PickupPlaceSelect = (props: RemoteSelectProps<Office>) => {
  const PickupPlaceSelect = RemoteSelect.ofType<PickupPlace>();
  return <PickupPlaceSelect {...props} axios={axios} klass={PickupPlace} />
}

export const OrganizationSelect = (props: RemoteSelectProps<Organization>) => {
  const OrganizationSelect = RemoteSelect.ofType<Organization>();
  return <OrganizationSelect {...props} axios={axios} klass={Organization} />
}

export const PurchaseInvoiceSelect = (props: RemoteSelectProps<PurchaseInvoice>) => {
  const PurchaseInvoiceSelect = RemoteSelect.ofType<PurchaseInvoice>();
  return <PurchaseInvoiceSelect {...props} axios={axios} klass={PurchaseInvoice} />
}

export const SupplierSelect = (props: RemoteSelectProps<Supplier>) => {
  const SupplierSelect = RemoteSelect.ofType<Supplier>();
  return <SupplierSelect {...props} axios={axios} klass={Supplier} />
}

export const UserSelect = (props: RemoteSelectProps<User>) => {
  const UserSelect = RemoteSelect.ofType<User>();
  return <UserSelect {...props} axios={axios} klass={User} />
}
