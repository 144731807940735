import * as React from 'react';
import { Select } from '@blueprintjs/select'
import { CostCenter } from '../models/cost_center';
import { FormGroup, Button, MenuItem, ButtonGroup } from '@blueprintjs/core';
import { CostCenterRepository } from '../repository';
import { ColumnType } from '@patterns/datatable';

const BlueprintSelect = Select.ofType<CostCenter>();

export interface Props {
  disabled?: boolean;
  inline?: boolean
  label?: string
  costCenter: CostCenter
  onChange: (costCenter?: CostCenter) => void
  filterByLeases?: boolean
}

export function CostCenterSelect({ disabled, onChange, costCenter, label, inline = false, filterByLeases }: Props) {
  const [costCenters, setCostCenters] = React.useState([] as CostCenter[]);
  const [query, setQuery] = React.useState('');
  
  React.useEffect(() => {
    let _cancelToken: () => void = () => {};

    const fetch = async () => {
      const params = filterByLeases ? 'filter_by_leases' : '';
      const { items, cancelToken } = await CostCenterRepository.index(0, 10, 'name', 'ASC', {
        'name': {
          column: 'name',
          active: true,
          comparator: 'contains',
          type: ColumnType.Text,
          value: query
        }
      }, _cancelToken, params);
      _cancelToken = cancelToken as any;
      setCostCenters(items)
    };
  
    fetch()
  }, [query, filterByLeases]);

  const cancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setQuery('');
    setCostCenters([]);
    onChange(undefined);
  }

  return (
    <FormGroup 
      inline={inline} 
      disabled={disabled}
      label={label}>
      <BlueprintSelect
        filterable={true}
        disabled={disabled}
        items={costCenters}
        activeItem={costCenter}
        itemRenderer={(item, options) => <MenuItem
          key={item.id}
          text={item.name}
          label={item.parent?.name ?? ''}
          onClick={options.handleClick}
        />}
        query={query}
        onQueryChange={setQuery}
        onItemSelect={costCenter => {
          onChange(costCenter)}
        }
      >
        <ButtonGroup>
          <Button disabled={disabled} text={costCenter.name} rightIcon="chevron-down" minimal fill/>
          <Button disabled={disabled} icon="cross" minimal onClick={cancel}/>
        </ButtonGroup>
      </BlueprintSelect>
    </FormGroup>
  );
}
