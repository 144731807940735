import * as React from 'react';
import { FlexRow } from '@patterns/ui';
import { Button, InputGroup, NonIdealState, NumericInput, Spinner, TextArea } from '@blueprintjs/core';
import produce from 'immer';
import { axios } from '../../session';
import { deepEqual, formatDate, parseDate } from '@patterns/core';
import SiemensManagerInstance, { SiemensAsset, SiemensAssetSubType, SiemensAssetType } from '../../siemens.manager';
import PurchasePool from '../purchase_pool';
import { SiemensProposal } from './calculation.form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CostCenterSuggest, EmployeeSuggest } from '../selects';
import { Employee } from '../../models/employee';
import { CostCenter } from '../../models/cost_center';
import { CostCenterRepository, EmployeeRepository, PurchaseInvoiceRepository } from '../../repository';
import SiemensTypeSuggest from '../siemens_type_suggest';
import SiemensSubTypeSuggest from '../siemens_subtype_suggest';
import { DateInput } from '@blueprintjs/datetime';
import DataStore from '../../data_store';
import Queue from 'promise-queue';
import { precisionRound } from '../../common';

export interface Props extends WithTranslation {
  proposal?: SiemensProposal
  simple?: boolean;
  assets?: SiemensAsset[];
  onChange: (assets: SiemensAsset[], residualValue: number) => void
  showPurchasePool: boolean
  invoiceTotal?: number
  onAssetDelete?: (asset_id: number, proposal_id?: number) => void
  onUpdateRequest?: () => void
}

export interface State {
  assets: SiemensAsset[];
  costCenters: CostCenter[];
  employees: Employee[];
  isLoading: boolean;
  showPurchasePool: boolean;
  residualValue: number;
  typeQuery: string;
  subTypeQuery: string;
}

export class AssetListBuilder extends React.Component<Props, State> {
  state = {
    assets: this.props.assets || (this.props.showPurchasePool ? [] : [ {
      description: '',
      assetTypeId: 316,
      assetSubTypeId: 0,
      quantity: 1,
      valuePerAsset: '0',
      valuePerAssetNum: 0,
      totalValue: '0',
      serialNumber: '',
      deliveryDate: new Date().toISOString(),
      condition: 'NEW',
      residualValue: 0
    } as SiemensAsset ]),
      isLoading: false,
      residualValue: (this.props.assets && this.props.assets!.length > 0 ?
        (this.props.assets[0].residualValue || parseFloat((this.props.assets![0] as any).internKonto)) :
        0) || 0,
      costCenters: [],
      employees: [],
      showPurchasePool: false,
      typeQuery: '',
      subTypeQuery: ''
  } as State

  componentDidMount() {
    (window as any).SiemensManagerInstance = SiemensManagerInstance;

    if (!this.props.simple) {
      // const stored = localStorage.getItem('asset-builder');
      // if (stored) {
      //   this.setState(JSON.parse(stored), () => {
      //     this.props.onChange(this.state.assets, this.state.residualValue)
      //   })
      // }
    }
    void this.fetch()
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(prevProps.assets, this.props.assets)) {
      let residualValue = 0;
      if (this.props.assets && this.props.assets.length > 0) {
        residualValue = this.props.assets[0].residualValue || parseFloat((this.props.assets![0] as any).internKonto);
      }
      this.setState({ assets: this.props.assets || [], residualValue, isLoading: false })
    }
  }

  componentWillUnmount() {
    const state = JSON.stringify(this.state);
    localStorage.setItem('asset-builder', state);
  }

  private add = () => {
    const asset = {
      description: '',
      assetTypeId: 316,
      assetSubTypeId: 0,
      quantity: 1,
      valuePerAsset: '0',
      valuePerAssetNum: 0,
      totalValue: '0',
      proposalId: 0,
      deliveryDate: new Date().toISOString(),
      condition: 'NEW',
      residualValue: this.state.residualValue || 0,
      user: '',
      costCenter: ''
    } as SiemensAsset;

    this.setState({  assets: [...this.state.assets, asset] })
  }

  private fetch = async () => {
    const { items: employees } = await EmployeeRepository.index(0, 1000, 'name', 'asc');
    const { items: costCenters } = await CostCenterRepository.index(0, 1000, 'name', 'asc');

    this.setState({
      employees: [ new Employee({}), ...employees],
      costCenters
    });
  }

  private postAsset = async (asset: SiemensAsset) => {
    if (this.props.proposal) {
      if (asset.id) {
        await axios.put(`/siemens/assets/${this.props.proposal.id}/assets/${asset.id}`);
      } else {
        await axios.post(`/siemens/assets/${this.props.proposal.id}/assets`);
      }
    }
  }

  private onAssetNoteChange = (index: number, value: string) => {
    const assets = produce(this.state.assets, assets => {
      const asset = assets[index];
      asset.note = value;
      asset.location = value;
      this.postAsset(asset);
      assets.splice(index, 1, asset);
    });
    this.setState({ assets }, () => this.props.onChange( assets, this.state.residualValue ))
  }

  private onAssetSerialNumbersChange = (index: number, value: string) => {
    const assets = produce(this.state.assets, assets => {
      const asset = assets[index];
      asset.serialNumber = value;
      this.postAsset(asset);
      assets.splice(index, 1, asset);
    });
    this.setState({ assets }, () => this.props.onChange( assets, this.state.residualValue ))
  }

  private onAssetDescriptionChange = (index: number, value: string) => {
    const assets = produce(this.state.assets, assets => {
      const asset = assets[index];
      asset.description = value;
      this.postAsset(asset);
      assets.splice(index, 1, asset);
    });
    this.setState({ assets }, () => this.props.onChange( assets, this.state.residualValue ))
  }

  private onValueChange = (index: number, value: string, valueNum: number) => {
    const assets = produce(this.state.assets, assets => {
      const asset = produce(assets[index], asset => {
        asset.valuePerAsset = value;
        asset.valuePerAssetNum = valueNum;
        asset.totalValue = (parseFloat(value) * asset.quantity).toFixed(2);
      });
      this.postAsset(asset);
      assets.splice(index, 1, asset);
    })
    this.setState({ assets }, () => this.props.onChange( assets, this.state.residualValue ))
  }

  private onTotalValueChange = (index: number, value: string, valueNum: number) => {
    const assets = produce(this.state.assets, assets => {
      const asset = assets[index];
      asset.totalValue = value.length === 0 ? '0' : value.replace(',', '.');
      asset.valuePerAssetNum = (parseFloat(asset.valuePerAsset) / asset.quantity);
      asset.valuePerAsset = asset.valuePerAssetNum.toFixed(2);
      this.postAsset(asset);
      assets.splice(index, 1, asset);
    })
    this.setState({ assets }, () => this.props.onChange( assets, this.state.residualValue ))
  }

  private onDateChange = (index: number, value: Date) => {
    const assets = produce(this.state.assets, assets => {
      const asset = produce(assets[index], asset => {
        asset.deliveryDate = value.toISOString();
      });
      this.postAsset(asset);
      assets.splice(index, 1, asset);
    })
    this.setState({ assets }, () => this.props.onChange( assets, this.state.residualValue ))
  }

  private onTypeChange = (index: number, type: SiemensAssetType) => {
    const assets = produce(this.state.assets, assets => {
      const asset = assets[index];
      asset.assetTypeId = type.id;
      this.postAsset(asset);
      assets.splice(index, 1, asset);
    });
    this.setState({ assets }, () => this.props.onChange( assets, this.state.residualValue ))
  }

  private onSubTypeChange = (index: number, subType: SiemensAssetSubType) => {
    const assets = produce(this.state.assets, assets => {
      const asset = assets[index];
      asset.assetSubTypeId = subType.id;
      const category = DataStore.categories.find(c => c.subTypeId === subType.id);
      if (category) {
        asset.residualValue = category.residualValue
      }
      assets.splice(index, 1, asset);
      this.postAsset(asset);
    });
    this.setState({ assets }, () => this.props.onChange( assets, this.state.residualValue ))
  }

  private showPurchasePool = () => this.setState({ showPurchasePool: true })

  private onPurchasePoolClose = () => this.setState({ showPurchasePool: false })

  private onPoolSubmit = async (newAssets: SiemensAsset[], _residualValue: number) => {
    // newAssets.forEach(asset => asset.residualValue = residualValue);

    const residualValue = newAssets.length > 0 ? DataStore.categoryBySubtype(newAssets[0].assetSubTypeId)?.residualValue ?? 0 : 0;
    // console.log('assigning residual value', residualValue, 'datastore', DataStore.categoryBySubtype(newAssets[0].assetSubTypeId)?.residualValue);
    newAssets.forEach(asset => asset.residualValue = residualValue);
    const queue = new Queue(Infinity, 1);

    newAssets.forEach(async asset => {
      queue.add(async () => {
        const invoice = await PurchaseInvoiceRepository.get(asset.invoiceId!);
        const invoiceAsset = invoice.assets[asset.id!];
        invoiceAsset.proposalId = this.props.proposal?.id;
        await PurchaseInvoiceRepository.save(invoice);
        // console.log('should have handled asset changes -> added', invoice.id, '/', asset.id);
      }).then(() => {
        if (queue.getPendingLength() === 0 && queue.getQueueLength() === 0) {
          // console.log('queue empty, saving assets to siemens', newAssets, 'residualValue', residualValue);
          this.setState({ isLoading: true, showPurchasePool: false, residualValue }, async () => {
            await axios.post(`/siemens/proposals/${this.props.proposal!.id}/assets`, newAssets);
            this.setState({ isLoading: false }, () => {
              this.props.onUpdateRequest!()
            })
          })
        }
      })
    });
  }

  private onEmployeeSelect = async (index: number, employee: Employee) => {
    console.log('on employee select', index, employee);
    const assets = produce(this.state.assets, assets => {
      const asset = assets[index];
      asset.userId = employee.id;
      asset.user = employee.name;
      assets.splice(index, 1, asset);
      this.postAsset(asset);
    });
    this.setState({ assets }, () => this.props.onChange( assets, this.state.residualValue ))
  }

  private onCostCenterSelect = async (index: number, costCenter: CostCenter) => {
    const assets = produce(this.state.assets, assets => {
      const asset = assets[index];
      asset.costCenterId = costCenter.id;
      asset.costCenter = costCenter.name;
      assets.splice(index, 1, asset);
      this.postAsset(asset);
    })
    this.setState({ assets }, () => this.props.onChange( assets, this.state.residualValue ))
  }

  private remove = (index: number) => {
    if (this.props.onAssetDelete) {
      // in proposal editor
      const asset = this.props.assets![index]!;
      this.props.onAssetDelete(asset.id!, this.props.proposal?.id);
    } else {
      // in purchase invoice editor
      const assets = [...this.state.assets];
      assets.splice(index, 1);

      const newState = { assets } as State;
      if (assets.length === 0) {
        newState.residualValue = 0;
      }

      this.setState(newState, () => this.props.onChange( assets, this.state.residualValue ))
    }
  }

  private duplicate = (index: number) => {
    const assets = [...this.state.assets];
    const copy = {...assets[index]};
    assets.splice(index + 1, 0, copy);
    this.setState({ assets });
  }

  private renderAssets = () => {
    if (SiemensManagerInstance.types.length === 0) {
      console.log('SiemensManagerInstance.types not available, skipping assets render');
      return
    }

    const { t } = this.props;

    const readOnly = this.props.showPurchasePool;

    console.log('reducing', this.state.assets.length, 'assets');
    const assetsValue = this.state.assets.reduce((total: number, asset: SiemensAsset) => {
      const parsed = parseFloat((asset.totalValue.toString()).replace(',', '.'));
      const rounded = parsed.toFixed(2);
      console.log('asset value', asset.totalValue, 'parsed', parsed, 'rounded', rounded);
      return total += parsed;
    }, 0);

    console.log('assetsValue: ', assetsValue, 'invoceValue: ', this.props.invoiceTotal);

    return this.state.assets.map((asset, index) => {
      const type = this.props.showPurchasePool ?
        SiemensManagerInstance.getType((asset as any).assetSubType.assetTypeId) || SiemensManagerInstance.getType(316) || { id: 0, name: '' } :
        SiemensManagerInstance.getType(asset.assetTypeId) || SiemensManagerInstance.getType(316) || { id: 0, name: '' };
      let subType = { id: 0, name: 'Select SubType', assetTypeId: 0 };

      if (type.id !== 0) {
        const existingSub = this.props.showPurchasePool ?
          (asset as any).assetSubType :
          SiemensManagerInstance.getSubtypes(type.id).find(t => t.id === asset.assetSubTypeId)
        if (existingSub) {
          subType = existingSub;
        }
      }

      const employee = this.state.employees.find(e => e.name === asset.user) || new Employee({});
      const costCenter = this.state.costCenters.find(c => c.name === asset.costCenter) || new CostCenter({});
      // const subTypes = asset.assetTypeId ? SiemensManagerInstance.getSubtypes(asset.assetTypeId) : [];

      const color = typeof this.props.invoiceTotal === 'undefined' ? '' : (precisionRound(this.props.invoiceTotal, 2) === precisionRound(assetsValue, 2) ? 'bg-green2' : 'bg-red2');
      const className = readOnly ? 'asset-row' : `asset-row ${color}`;

      return <FlexRow className={className} key={`asset-builder-row-${index}`}>
        <div className="w-50 ai-c p-l-12">
          <span>{ index + 1 }</span>
        </div>
        <div className="f-2">
          <SiemensTypeSuggest
            activeItem={type}
            onSelect={item => this.onTypeChange(index, item) }
            disabled={readOnly}
          />
        </div>
        <div className="f-2">
          <SiemensSubTypeSuggest
            type={type}
            activeItem={subType}
            onSelect={item => this.onSubTypeChange(index, item) }
            disabled={readOnly}
          />
        </div>
        <div className="f-2">
          <InputGroup
            readOnly={readOnly}
            value={asset.description}
            fill={true}
            placeholder={t('assets.enter_asset_name')}
            className="m-b-0"
            onChange={(evt: React.FormEvent<HTMLInputElement>) => this.onAssetDescriptionChange(index, evt.currentTarget.value)}
          />
        </div>
        <div className="f-2">
          { readOnly && <span className="p-l-6">{ asset.note }</span> }
          { !readOnly && <TextArea
            readOnly={readOnly}
            value={asset.note}
            fill={true}
            placeholder={t('note')}
            className="m-b-0"
            rows={1}
            growVertically={true}
            maxLength={50}
            onChange={(evt: React.FormEvent<HTMLTextAreaElement>) => this.onAssetNoteChange(index, evt.currentTarget.value)}
          /> }
        </div>
        <div className="f-2">
          { readOnly && <span className="p-l-6">{ asset.serialNumber }</span> }
          { !readOnly && <InputGroup
            readOnly={readOnly}
            value={asset.serialNumber}
            fill={true}
            placeholder={t('assets.enter_serial_number')}
            className="m-b-0"
            onChange={(evt: React.FormEvent<HTMLInputElement>) => this.onAssetSerialNumbersChange(index, evt.currentTarget.value)}
          /> }
        </div>
        <div className="f-2">
          { readOnly && <span className="p-l-6">{ asset.user }</span> }
          { !readOnly && <EmployeeSuggest
            fill={true}
            disabled={readOnly}
            activeItem={employee}
            emptyTitle={this.props.t('select_user')}
            onSelect={(employee) => this.onEmployeeSelect(index, employee)}
          /> }
        </div>
        <div className="f-2">
          { readOnly && <span className="p-l-6">{ asset.costCenter }</span> }
          { !readOnly && <CostCenterSuggest
            fill={true}
            disabled={readOnly}
            activeItem={costCenter}
            emptyTitle={this.props.t('select_cost_center')}
            onSelect={(costCenter) => this.onCostCenterSelect(index, costCenter)}
          /> }
        </div>
        <div className="w-130">
          <DateInput
            fill={true}
            disabled={readOnly}
            value={asset.deliveryDate ? new Date(asset.deliveryDate) : null}
            onChange={date => this.onDateChange(index, date)}
            formatDate={formatDate}
            parseDate={parseDate}
          />
        </div>
        <div className="w-100">
          <NumericInput
            fill={true}
            readOnly={readOnly}
            value={(asset.totalValue || '').toString().replace('.', ',')}
            buttonPosition="none"
            locale="fi"
            style={{ width: 100, textAlign: 'right' }}
            onValueChange={(number, string) => this.onTotalValueChange(index, string, number)}
            selectAllOnFocus={true}
          />
        </div>
        <div className="w-100 d-f jc-e f-r">
          <Button minimal icon="cross" intent="primary" onClick={() => this.remove(index)} />
          <Button minimal icon="duplicate" intent="none" onClick={() => this.duplicate(index)} />
        </div>
      </FlexRow>
    })
  }

  public render() {
    const { t } = this.props;
    return (
      <div className="asset-builder">
        <h2 className="m-t-24">{t('assets.title')}</h2>

        { this.state.isLoading && <div>
          <NonIdealState className="loader-overlay">
            <Spinner size={96} className="m-t-24" intent="success"/>
          </NonIdealState>
        </div> }

        <div className="dark-box">
          <FlexRow className="p-t-6 p-b-6 ">
            <div className="w-50 ai-c p-l-12">
              <span>&nbsp;</span>
            </div>
            <div className="f-2 p-l-6">{t('type')}*</div>
            <div className="f-2 p-l-6">{t('subtype')}*</div>
            <div className="f-2 p-l-6">{t('assets.make_and_model')}*</div>
            <div className="f-2 p-l-6">{t('assets.additional_details')}</div>
            <div className="f-2 p-l-6">{t('assets.serial_number')}</div>
            <div className="f-2 p-l-6">{t('user')}</div>
            <div className="f-2 p-l-6">{t('cost_center')}</div>
            <div className="w-130 p-l-6">{t('delivery_date')}*</div>
            <div className="w-100 p-l-6 ta-r">{t('patterns.value')}*</div>
            <div className="w-100 d-f jc-e f-r">
              <span>&nbsp;</span>
            </div>
          </FlexRow>
          <div className="list m-b-24 separator-bottom">
            { this.renderAssets() }
          </div>

          <FlexRow className="toolbar m-l-12">
            {/* { this.props.showPurchasePool && <span>Residual Value: <strong>{ this.state.residualValue }%</strong></span> } */}
            {/* <Button minimal text="Import" intent="success" icon="cloud-upload" className="m-r-12" onClick={this.add} /> */}
            <div className="f-2 d-f f-r ai-c">
              <span>{ this.state.assets.length } {this.state.assets.length === 1 ? t('asset') : t('assets.title') }</span>
              { !this.props.showPurchasePool && <Button outlined text={t('assets.add_asset')} intent="primary" icon="plus" className="m-l-12" onClick={this.add} /> }
              { this.props.showPurchasePool && <Button outlined text={t('assets.add_asset')} intent="primary" icon="plus" className="m-l-12" onClick={this.showPurchasePool} /> }
            </div>

            {/* <Button minimal text="Duplicate" intent="primary" icon="duplicate" className="m-r-12" disabled={true}/> */}
            {/* <Button minimal text="Remove" intent="warning" icon="trash" className="m-r-12" disabled={true}/> */}
          </FlexRow>
        </div>

        <PurchasePool
          isOpen={this.state.showPurchasePool}
          onClose={this.onPurchasePoolClose}
          onSubmit={this.onPoolSubmit}
          residualValue={this.state.residualValue}
        />
      </div>
    )
  }
}

export default withTranslation()(AssetListBuilder)
