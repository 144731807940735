import { Button, Callout, FormGroup, InputGroup, NonIdealState } from '@blueprintjs/core';
import { formatPrice } from '@patterns/core';
import { FlexRow, Notifier } from '@patterns/ui';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppContext, AppContextType } from '../../app_context';
import { axios } from '../../common';
import { MainLayout } from '../../layouts/main';
import { ExtraCostItem } from '../../models/extra_cost';
import { Office } from '../../models/office';
import { session } from '../../session';
import { Breadcrumbs } from '../breadcrumb';
import { OfficeSelect } from '../selects';

export interface Props extends WithTranslation {

}

export interface State {
  isLoading: boolean;
  isAccepted: boolean;
  url: string;
  order: any;
  items: any[];
  name: string;
  company: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  email: string;
  office: Office;
  total: number;
  phone: string;
}

export class StoreConfirm extends React.Component<Props, State> {
  static contextType = AppContext;

  state = {
    isLoading: false,
    isAccepted: false,
    url: '',
    office: new Office({}),
    items: [] as any[],
    total: 0,
    order: {},
    name: '',
    company: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    email: '',
    phone: ''
  } as State


  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const items = this.getContext().shoppingCart.items;
    let total = 0;
    items.forEach(item => {
      total += item.item.price;
      if (item.extraCosts) {
        Object.keys(item.extraCosts).forEach((key, idx) => {
          const extra = item.extraCosts[key];
          if (extra) {
            const sum = item.extraCosts[key]!.items.reduce((accu, item) => accu += item.price, 0);
            total += sum;
          }
        });
      }
      if (item.extraServices) {
        Object.keys(item.extraServices).forEach((key, idx) => {
          const extra = item.extraServices[key];
          total += extra!.price;
        });
      }
    })
    this.setState({ items: this.getContext().shoppingCart.items, total, isLoading: false });
  }

  private getContext() {
    return this.context as AppContextType
  }

  private confirm = async () => {
    this.setState({ isLoading: true }, async () => {
      const { name, company, street, street2, postalCode, city, state, country, email, items, total, phone } = this.state;
      const data = { name, company, street, street2, postalCode, city, state, country, email, items, total, phone } as any;
      data.lessee_id = session.lesseeId;
      await axios.post(`/shopping_cart?lang=${this.props.i18n.language}`, data);
      Notifier.success(this.props.t('shopping_cart.order_sent'));
      this.getContext().shoppingCart.clear();
      this.setState({ isLoading: false, isAccepted: true })
    })
  }

  private onOfficeChange = (office: Office) => {
    this.setState({
      office,
      name: office.contactName,
      email: office.contactEmail,
      company: office.name,
      street: office.street,
      street2: office.street_2,
      city: office.city,
      postalCode: office.postalCode,
      phone: office.contactPhone
    })
  }

  private renderHeader = () => {
    const { t } = this.props;
    return <FlexRow className="m-b-12">
      <div className="f-3 header">{t('patterns.items_one')}</div>
      <div className="f-1 header">{t('price')}</div>
      <div className="f-5 ta-r header">&nbsp;</div>
      <div className="f-3 ta-r header">&nbsp;</div>
    </FlexRow>
  }
  private renderItems = () => {
    const { t } = this.props;
    const children = [] as React.ReactElement[];
    return this.state.items.map((i, itemIdx) => {
      children.push(<FlexRow key={`punchout-item-${itemIdx}`}>
        <div className="f-3">{ i.item.name }</div>
        <div className="f-1">{ formatPrice(i.item.price) }</div>
        <div className="f-5 ta-r">&nbsp;</div>
        <div className="f-3 ta-r">&nbsp;</div>
      </FlexRow>)

      if (i.extraCosts) {
        children.push(<FlexRow key={`punchout-item-extra-costs-header-${itemIdx}`}>
          <div className="f-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>{t('settings.extra_costs')}</strong>
          </div>
          <div className="f-1"></div>
          <div className="f-5 ta-r">&nbsp;</div>
          <div className="f-3 ta-r">&nbsp;</div>
        </FlexRow>)
        Object.keys(i.extraCosts).forEach((key, idx) => {
          const extra = i.extraCosts[key];
          if (!extra) {
            return
          }
          if (extra.items.length > 0) {
            children.push(<FlexRow key={`punchout-item-${itemIdx}-extra-${idx}`}>
              <div className="f-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ extra.cost.title }</div>
              <div className="f-1"></div>
              <div className="f-5 ta-r">&nbsp;</div>
              <div className="f-3 ta-r">&nbsp;</div>
            </FlexRow>)
          }
          extra.items.forEach((item: ExtraCostItem, i: number) => {
            children.push(<FlexRow key={`punchout-item-extra-${itemIdx}-${idx}-${i}`}>
            <div className="f-3">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              { this.props.i18n.language === 'en' ?  item.label_en : item.label_fi }</div>
            <div className="f-1">{ formatPrice(item.price) }</div>
            <div className="f-5 ta-r">&nbsp;</div>
            <div className="f-3 ta-r">&nbsp;</div>
          </FlexRow>)
          })
        });
      }

      if (Object.keys(i.extraServices).length > 0) {
        children.push(<FlexRow key={`punchout-item-extra-services-header-${itemIdx}`}>
          <div className="f-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>{t('extra_services')}</strong>
          </div>
          <div className="f-1"></div>
          <div className="f-5 ta-r">&nbsp;</div>
          <div className="f-3 ta-r">&nbsp;</div>
        </FlexRow>)
      }
      Object.keys(i.extraServices).forEach((key, idx) => {
        const extra = i.extraServices[key];
        children.push(<FlexRow key={`punchout-item-${itemIdx}-extra-service-${idx}`}>
          <div className="f-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ extra.name }</div>
          <div className="f-1">{ formatPrice(extra.price) }</div>
          <div className="f-5 ta-r">&nbsp;</div>
          <div className="f-3 ta-r">&nbsp;</div>
        </FlexRow>)
      });
      return children
    })
    // if (this.state.order && msg) {
    // const items = Array.isArray(msg.ItemIn) ? msg.ItemIn : [ msg.ItemIn ];
    //   return items.map((item: any, index: number) => {
    //     return <FlexRow key={`punchout-item-${index}`}>
    //       <div className="f-2">{ item.ItemDetail.ManufacturerName } { item.ItemDetail.Description }</div>
    //       <div className="f-1 ta-r">{ formatPrice(item.ItemDetail.UnitPrice.Money) }</div>
    //       <div className="f-1 ta-r">{ item.ItemDetail.UnitOfMeasure }</div>
    //       <div className="f-4 ta-r">&nbsp;</div>
    //     </FlexRow>
    //   })
    // }
  }

  private renderSummary = () => {
    return <FlexRow className='m-t-12'>
      <div className="f-2">{this.props.t('total')}</div>
      <div className="f-1 ta-r">{formatPrice(this.state.total)}</div>
      <div className="f-5"></div>
    </FlexRow>
  }

  public render() {
    const { t } = this.props;
    return (
      <MainLayout title="Order Confirmation">
        <Breadcrumbs path={`Store / Order Confirmation`} title={t('order_confirmation')}/>

        <div className="content-full">
          { this.state.isLoading && <NonIdealState title={t('patterns.please_wait')} description="Loading Order"/> }
          { !this.state.isLoading && <div className="m-24 verkkokauppa-order-confirmation">
            { this.state.order && <h2>{t('patterns.items_few')}</h2> }
            { this.renderHeader() }

            { this.renderItems() }
            { this.renderSummary() }
            
            { <div className="delivery-form m-t-24">
              <h2>{t('delivery_address')}</h2>

              <FormGroup inline={true} label={t('office')}>
                <OfficeSelect
                  activeItem={this.state.office}
                  onSelect={this.onOfficeChange}
                  hint={t('select_office')}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('company')}>
                <InputGroup
                  value={this.state.company}
                  onChange={evt => this.setState({ company: evt.currentTarget.value })}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('street')}>
                <InputGroup
                  value={this.state.street}
                  onChange={evt => this.setState({ street: evt.currentTarget.value })}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('street_2')}>
                <InputGroup
                  value={this.state.street2}
                  onChange={evt => this.setState({ street2: evt.currentTarget.value })}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('city')}>
                <InputGroup
                  value={this.state.city}
                  onChange={evt => this.setState({ city: evt.currentTarget.value })}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('state')}>
                <InputGroup
                  value={this.state.state}
                  onChange={evt => this.setState({ state: evt.currentTarget.value })}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('post_code')}>
                <InputGroup
                  value={this.state.postalCode}
                  onChange={evt => this.setState({ postalCode: evt.currentTarget.value })}
                />
              </FormGroup>

              {/* <FormGroup inline={true} label="Country">
                <InputGroup
                  value={this.state.country}
                  onChange={evt => this.setState({ country: evt.currentTarget.value })}
                />
              </FormGroup> */}

              <FormGroup inline={true} label={t('contact_name')}>
                <InputGroup
                  value={this.state.name}
                  onChange={evt => this.setState({ name: evt.currentTarget.value })}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('email')}>
                <InputGroup
                  value={this.state.email}
                  onChange={evt => this.setState({ email: evt.currentTarget.value })}
                />
              </FormGroup>

              <FormGroup inline={true} label={t('phone')}>
                <InputGroup
                  value={this.state.phone}
                  onChange={evt => this.setState({ phone: evt.currentTarget.value })}
                />
              </FormGroup>
            </div> }
            
            { (this.state.order) && <FlexRow className="m-t-24">
              <Button text={t('confirm')} intent="success" icon="tick" onClick={this.confirm}/>
            </FlexRow> }

            { this.state.isAccepted && <FlexRow className="m-t-24">
              <Callout intent="success">
                {t('order_create_success')}
              </Callout>
            </FlexRow> }
          </div> }
        </div>
      </MainLayout>
    );
  }
}

export default withTranslation()(StoreConfirm)