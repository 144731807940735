import * as React from 'react';
import { Dialog, Classes, Button, FormGroup, InputGroup, TextArea, NumericInput, Switch, Checkbox, MenuItem } from '@blueprintjs/core';
import {Form, FormField, FormFieldType } from '@patterns/form';
import { FlexColumn, FlexRow, Notifier } from '@patterns/ui';
import produce from 'immer';
import { Asset, ExtraCostItemMap } from '../../models/asset';
import CategorySelect from '../category_select';
import deepEqual from 'deep-equal';
import { OfficeSelect, EmployeeSelect } from '../selects';
import { CostCenterSelect } from '../cost_center_select';
import { AssetRepository } from '../../repository';
import { Category } from '../../models/category';
import { CustomFormField } from '../../models/custom_form_field';
import { DateInput } from '@blueprintjs/datetime';
import { formatDate, parseDate } from '@patterns/core';
import { ExtraCost, ExtraCostItem } from '../../models/extra_cost';
import { Select } from '@blueprintjs/select';
import { Office } from '../../models/office';
import { priceFormat } from '../../common';
import { withTranslation, WithTranslation } from 'react-i18next';
import { session } from '../../session';
import { ConfirmButton } from '../confirm_button';
import moment from 'moment';

const AssetForm = Form.ofType<Asset>();
const ExtraCostItemSelect = Select.ofType<ExtraCostItem>();

export interface Props extends WithTranslation {
  asset: Asset
  isOpen: boolean
  onClose: () => void
  onDelete: (asset: Asset) => void
}

export interface State {
  asset: Asset
  extraCostItemMap: ExtraCostItemMap;
  warrantyEndError?: string;
  purchaseDateError?: string;
}

export class AssetDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      asset: this.props.asset,
      extraCostItemMap: {}
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.asset, prevProps.asset)) {
      this.setState({ asset: this.props.asset })
    }
  }

  private getFields = () => {
    const { t } = this.props;
    const isOwned = this.state.asset.state === 'reclaimed';
    const editable = session.user.role !== 'user';  
    let disabled = !isOwned || !editable

    if (session.user.role === 'admin') {
      disabled = false
    }
    
    return [
      {
        id: 'name',
        type: FormFieldType.Text,
        disabled,
        label: t('name'),
        validation: (asset) => asset.name && asset.name.length > 0,
        validationErrorMessage: 'At least one character long'
      }, {
        id: 'category',
        type: FormFieldType.Custom,
        disabled: session.user.role !== 'admin',
        label: t('category'),
        customElement: (item: Asset, onChange: (asset: Asset) => void) => { 
         return <CategorySelect
            category={item.category}
            disabled={disabled}
            onChange={(category) => { 
              const newAsset = produce(item, newAsset => {
                newAsset.category = category ? category : new Category({});
                newAsset.fields = {}
              });
              onChange(newAsset)
            }}
          />
        }
      }, 
      // {
      //   id: 'contract',
      //   type: FormFieldType.Custom,
      //   label: t("contract"),
      //   customElement: (item: Asset, onChange: (asset: Asset) => void) => { 
      //    return <ContractSelect
      //       minimal={true}
      //       hint={t('select_contract')}
      //       activeItem={item.contract}
      //       onSelect={(contract) => { 
      //         const newAsset = produce(item, newAsset => {
      //           newAsset.contract = contract ? contract : new Contract({})
      //         });
      //         onChange(newAsset)
      //       }}
      //     />
      //   }
      // }, 
      {
        id: 'office',
        type: FormFieldType.Custom,
        label: t('office'),
        customElement: (item: Asset, onChange: (asset: Asset) => void) => {
         return <OfficeSelect
            minimal={true}
            disabled={session.user.role === 'user'}
            hint={t('select_office')}
            activeItem={item.office}
            onSelect={(office) => { 
              const newAsset = produce(item, newAsset => {
                newAsset.office = office ? office : new Office({})
              });
              onChange(newAsset)
            }}
          />
        }
      }, {
        id: 'serialNumber',
        type: FormFieldType.Custom,
        disabled,
        label: t('assets.serial_number'),
        customElement: (item, onChange) => {
          return <InputGroup 
            value={item.serialNumber}
            disabled={disabled}
            onChange={(evt) => {
              const asset = new Asset(this.state.asset);
              asset.serialNumber = evt.currentTarget.value;
              this.onChange(asset);
            }}
          />
        },
      }, {
        id: 'lifecycle',
        type: FormFieldType.Number,
        disabled,
        label: t('lifecycle')
      }, {
        id: 'purchasePrice',
        type: FormFieldType.Number,
        disabled,
        label: t('assets.purchase_price')
      }, {
        id: 'purchaseDate',
        type: FormFieldType.Custom,
        disabled,
        label: t('assets.purchase_date'),
        // validation: (asset) => asset.purchaseDate.getTime() !== 0,
        // validationErrorMessage: 'Purchase Date must be entered',
        customElement: (item, onChange) => {
          return <DateInput 
            canClearSelection={true}
            // inputProps={{
            //   className: item.purchaseDate.getTime() === 0 ? 'bp3-intent-danger' : 'bp3-intent-success'
            // }}
            fill
            value={item.purchaseDate.getTime() === 0 ? null : item.purchaseDate}
            parseDate={parseDate}
            formatDate={formatDate}
            disabled={disabled}
            onChange={(date) => {
              const asset = new Asset(this.state.asset);
              asset.purchaseDate = date;
              onChange(asset);
            }}
          />
        }
      }, {
        id: 'warrantyEnd',
        type: FormFieldType.Custom,
        label: t('assets.warranty_end'),
        // validation: (asset) => asset.warrantyEnd.getTime() !== 0,
        // validationErrorMessage: 'Warranty end must be entered',
        customElement: (item, onChange) => {
          return <DateInput
            canClearSelection={true}
            fill
            // inputProps={{
            //   className: item.warrantyEnd.getTime() === 0 ? 'bp3-intent-danger' : 'bp3-intent-success'
            // }}
            value={item.warrantyEnd.getTime() === 0 ? null : item.warrantyEnd}
            parseDate={parseDate}
            formatDate={formatDate}
            disabled={session.user.role === 'user'}
            maxDate={moment().add(10, 'years').toDate()}
            onChange={(date) => {
              const asset = new Asset(this.state.asset);
              asset.warrantyEnd = date;
              onChange(asset);
            }}
          />
        }
      }, {
        id: 'employee',
        type: FormFieldType.Custom,
        label: t('user'),
        customElement: (item: Asset, onChange: (asset: Asset) => void) => { 
          return <EmployeeSelect
            minimal={true}
            disabled={session.user.role === 'user'}
            activeItem={item.employee}
            onSelect={(employee) => { 
              if (employee) {
                const newAsset = produce(item, newAsset => {
                  newAsset.employee = employee
                });
                onChange(newAsset)
              }
            }}
          />
        }
      }, {
        id: 'costCenter',
        type: FormFieldType.Custom,
        label: t('cost_center'),
        customElement: (item: Asset, onChange: (asset: Asset) => void) => { 
          return <CostCenterSelect
             costCenter={item.costCenter}
             disabled={session.user.role === 'user'}
             onChange={(costCenter) => { 
               if (costCenter) {
                 const newAsset = produce(item, newAsset => {
                   newAsset.costCenter = costCenter
                 });
                 onChange(newAsset)
               }
             }}
          />
        }
      }, {
        id: 'note',
        type: FormFieldType.TextArea,
        disabled: session.user.role === 'user',
        label: t('note')
      }
    ]  as FormField<Asset>[]
  }

  private onChange = (asset: Asset) => {
    this.setState({ asset: new Asset(asset) })
  }

  private save = async () => {
    if (this.state.asset.name.length === 0) {
      Notifier.failure('Name must be entered');
      return
    }
    
    // if (this.state.asset.purchaseDate.getTime() === 0) {
    //   Notifier.failure('Purchase Date must be entered');
    //   return
    // }

    // if (this.state.asset.warrantyEnd.getTime() === 0) {
    //   Notifier.failure('Warranty End must be entered');
    //   return
    // }

    console.log('shoulds save asset', new Asset(this.state.asset));
    await AssetRepository.save(this.state.asset);
    Notifier.success(this.props.t('assets.save_success'));
    this.props.onClose()
  }

  private onFieldValueChange(field: CustomFormField, value: string | number | boolean) {
    this.setState({
      asset: produce(this.state.asset, newAsset => {
        if (!newAsset.fields || Object.keys(newAsset.fields).length === 0) {
          newAsset.fields = {}
        }
        newAsset.fields[field.key]= value
      })
    }, () => {
      console.log('asset updated', field.key, value, this.state.asset)
    })
  }

  private onDelete = async () => this.props.onDelete(this.state.asset);

  private renderCategoryField(field: CustomFormField) {
    const valueField = this.state.asset.fields[field.key];
    // const isOwned = this.state.asset.state === 'reclaimed';
    // const editable = session.user.role === 'admin';
    // const disabled = !isOwned || !editable
    const isOwned = this.state.asset.state === 'reclaimed';
    const editable = session.user.role !== 'user';
    // const disabled = !isOwned || !editable
    const disabled = false;
    switch (field.type) {
      case 'text':
        return <InputGroup 
          fill={true} 
          disabled={disabled}
          value={valueField as string || ''}
          onChange={(evt: any) => this.onFieldValueChange(field, evt.currentTarget.value)}
        />
      case 'textarea':
        return <TextArea
          fill={true} 
          disabled={disabled}
          value={valueField as string || ''}
          onChange={(evt: any) => this.onFieldValueChange(field, evt.currentTarget.value)}
        />
      case 'number':
        return <NumericInput 
          fill={true} 
          disabled={disabled}
          value={valueField as number || 0}
          onValueChange={value => this.onFieldValueChange(field, value)}
        />
      case 'switch':
        return <Switch 
          checked={valueField as boolean || false}
          disabled={disabled}
          onChange={(evt: any) => this.onFieldValueChange(field, evt.currentTarget.checked)}
        />
      case 'checkbox':
        return <Checkbox 
          checked={valueField as boolean || false}
          disabled={disabled}
          onChange={(evt: any) => this.onFieldValueChange(field, evt.currentTarget.checked)}
        />
      case 'date':
        const value = valueField ? new Date(valueField as string) : new Date();
        return <DateInput 
          formatDate={formatDate}
          parseDate={parseDate}
          value={value}
          disabled={disabled}
          onChange={(date: Date) => this.onFieldValueChange(field, date.toISOString())}
          fill={true}
        />
    }
  }

  private renderCategoryFields = () => {
    return this.state.asset.category.fields.map(field => <FormGroup
      key={`category-field-${field.key}`}
      label={this.props.i18n.language === 'fi' ? field.label_fi : field.label_en}
      inline={true}
    >{ this.renderCategoryField(field) }
    </FormGroup>);
  }

  private extraCostItemRenderer = (item: ExtraCostItem, options: any) => <MenuItem
    key={`extra-cost-item-${item.label_en.replace(/ /g, '_')}`}
    text={item.label_en}
    label={priceFormat(item.price)}
    onClick={options.handleClick}
  />

  private renderDeleteButton = () => {
    const { t } = this.props;
    if (session.user.isAdmin) {
      return <ConfirmButton 
        onConfirm={this.onDelete}
        title={t('delete')}
        confirmTitle={t('are_you_sure')}
        icon="trash"
      />
    } else if (session.user.isSuperuser) {
      if (this.state.asset.state === 'reclaimed') {
        return <ConfirmButton 
          onConfirm={this.onDelete}
          title={t('delete')}
          confirmTitle={t('are_you_sure')}
          icon="trash"
        />
      }
    }

    return null
  }

  private renderCategoryExtraCosts = () => {
    const types = {} as { [id:string]: ExtraCost[]};
    const extraCosts = this.state.asset.category.categoryExtraCosts.map(c => c.extraCost);
    extraCosts.forEach(extraCost => {
      if (!types[extraCost.type]) {
        types[extraCost.type] = []
      }
      types[extraCost.type].push(extraCost)
    });

    return Object.keys(types).map((type, index) => {
      return <React.Fragment key={`category-extra-cost-item-${index}`}>
        <FormGroup
          inline={true}
          label={type}
          className="patterns-form-field-group patterns-form-field-group-fill long-label">
        </FormGroup>

        { types[type].map(extraCost => { 
          return <FormGroup
            key={`category-extra-costs-field-${extraCost.id}`}
            label={extraCost.title}
            inline={true}>
            <ExtraCostItemSelect
              items={extraCost.items}
              itemRenderer={this.extraCostItemRenderer}
              activeItem={this.state.asset.extraCostItems[extraCost.id]}
              onItemSelect={item => {
                const asset = produce(this.state.asset, asset => {
                  asset.extraCostItems[extraCost.id] = item
                })
                this.onChange(asset);
              }}
            >
              <Button rightIcon="chevron-down" text={this.state.asset.extraCostItems[extraCost.id] ? this.state.asset.extraCostItems[extraCost.id].label_en : this.props.t('select_item')} minimal={true}/>
            </ExtraCostItemSelect>
          </FormGroup> 
        })}
      </React.Fragment>
    })
  }

  public render() {
    const { t } = this.props;
    return <Dialog 
      title={t('asset')} 
      isOpen={this.props.isOpen} 
      onClose={this.props.onClose} 
      canOutsideClickClose={false}
      className="asset-dialog bp3-dark">
      <div className={Classes.DIALOG_BODY}>
        <FlexRow>
          <AssetForm
            className="f-1"
            fields={this.getFields()}
            item={this.state.asset}
            onChange={this.onChange}
            inline={true}
            fill={true}
          />
          <FlexColumn className="patterns-form wider">
            <FormGroup 
              inline={true} 
              label={t('assets.category_specific_fields')}
              className="patterns-form-field-group patterns-form-field-group-fill long-label">
            </FormGroup>
            { this.renderCategoryFields() }
            { this.renderCategoryExtraCosts() }
          </FlexColumn>
        </FlexRow>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <FlexRow flex={1}>
            { this.renderDeleteButton()}
          </FlexRow>
          <FlexRow flex={1} className="jc-e">
            <Button intent="none" text={t('cancel')} onClick={this.props.onClose}/>
            <Button intent="primary" icon="tick" text={t('save')} onClick={this.save}/>
          </FlexRow>
        </div>
      </div>
    </Dialog>;
  }
}

export default withTranslation()(AssetDialog);
