import * as React from 'react';
import { AutoSizer } from 'react-virtualized';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LeasesReport } from '../components/leases.report';
import { LeaseShare } from '../components/reports/lease_share';
import { FlexColumn, FlexRow } from '@patterns/ui';
import MonthSelect from '../components/month_select';
import moment from 'moment';
import { axios, monthDiff, priceFormat } from '../common';
import { NumberWidget } from '../components/number_widget';
import ContractCostCentersChart from '../components/contracts/cost_centers_chart';
import { WithTranslation, withTranslation } from 'react-i18next';
import { capitalize } from '@patterns/core';

export interface Props extends WithTranslation, RouteComponentProps{
  since: Date
  till: Date
}

export interface State {
  months: number
  assets: number
  assetsValue: number
  contracts: number
  since: Date
  till: Date
}

export class ContractReports extends React.Component<Props, State> {
  leaseShare = React.createRef<LeaseShare>()
  leasesReport = React.createRef<LeasesReport>()

  state = {
    assets: 0,
    assetsValue: 0,
    contracts: 0,
    since: moment().startOf('month').toDate(),
    till: moment().endOf('month').toDate(),
    months: 1
  } as State

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.since.getTime() !== this.state.since.getTime() || prevState.till.getTime() !== this.state.till.getTime()) {
      this.fetch()
    }
  }

  private onSinceChange = (since: Date) => {
    const till = this.state.till.getTime() < since.getTime() ? moment(since).endOf('month').toDate() : this.state.till;
    const months = monthDiff(since, till);
    this.setState({
      since,
      till,
      months
    })
  }

  private onTillChange = (till: Date) => {
    const since = till.getTime() <= this.state.since.getTime() ? moment(till).startOf('month').toDate() : this.state.since;
    const months = monthDiff(since, till);
    this.setState({ 
      since,
      till,
      months
    }, this.fetch)
  }

  private fetch = async () => {
    this.leaseShare.current?.fetch();
    
    const params = [
      `since=${this.state.since.toISOString()}`,
      `till=${this.state.till.toISOString()}`
    ].join('&');

    const response = await axios.get(`/contracts/stats?${params}`);

    this.setState({
      assets: response.data.assets,
      assetsValue: parseFloat(response.data.assetsValue),
      contracts: response.data.contracts,
    }, () => {
      console.log('state', this.state);
    })
  }

  public render() {
    const { t } = this.props;
    return <div className="reports-container">
      <div className="blue-alpha" style={{ margin: 24, paddingBottom: 24, borderRadius: 8, paddingTop: 24 }}>
        {/* <h1 className="m-l-24 m-t-24 p-t-24" style={{ color: 'white', marginBottom: 12, fontSize: 24  }}>{t('leases_report.status_update')}</h1> */}
        <div className="d-f f-r" style={{ height: 480 }}>
          <FlexColumn flex={2} className="m-l-24">
            <NumberWidget value={this.state.assets.toString()} title={t('leases_report.leased_assets')} icon="mobile-phone" color=""  className="blue-alpha"/>
            <NumberWidget value={priceFormat(this.state.assetsValue)} title={t('leases_report.lease_per_month')} icon="layers" color="" className="blue-alpha m-t-24"/>
            <NumberWidget value={this.state.contracts.toString()} title={t('contracts.title')} icon="document-share" color="" className="blue-alpha m-t-24"/>
          </FlexColumn>
          <div className="f-3 m-l-24 m-r-24 chart-container blue-alpha f-3" style={{ flex: 3 }}>
            <div className="chart-inner-title">
              {t('leases_report.lease_share')}
            </div>
            <div style={{ height: 425, overflowX: 'hidden', overflowY: 'auto' }}>
              <LeaseShare {...this.props} ref={this.leaseShare} since={this.state.since} till={this.state.till} />
            </div>
          </div>
          <div className="f-4 m-r-24">
            <AutoSizer>{({ height, width }) => (
              <ContractCostCentersChart legend={true} height={height} width={width} params={`since=${this.state.since.toISOString()}&till=${this.state.till.toISOString()}`}/>
            )}</AutoSizer>
          </div>
        </div>
      </div>

      <div className="blue-alpha" style={{ margin: 24, paddingBottom: 24, borderRadius: 8 }}>
        {/* <h1 className="m-l-24 m-t-24 p-t-24" style={{ color: 'white', marginBottom: 0, fontSize: 24  }}>{t('leases_report.cashflow')}</h1> */}
        <FlexRow className="jc-e p-t-24">
          <div className="darker d-f ai-c jc-c white p-l-24 fs-12">
            {capitalize(t('period'))}
          </div>
          <div className="w-190 darker p-l-24 p-t-12 p-b-12">
            <MonthSelect date={this.state.since} onSelect={this.onSinceChange} />
          </div>
          <div className="w-50 ai-c jc-c d-f darker p-b-12 p-t-12" style={{ fontSize: 24, color: 'white' }}>-</div>
          <div className="w-190 p-r-24 m-r-24 darker p-b-12 p-t-12">
            <MonthSelect date={this.state.till} onSelect={this.onTillChange} isEndDate/>
          </div>
        </FlexRow>
        <div className="m-l-24 m-r-24">
          <LeasesReport
            {...this.props}
            ref={this.leasesReport}
            months={this.state.months}
            since={this.state.since}
            till={this.state.till}
          />
        </div>
      </div>
    </div>
  }
}

export default withRouter(withTranslation()(ContractReports))