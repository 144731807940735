import * as React from 'react';
import { Dialog, Classes, FormGroup, InputGroup, Button } from '@blueprintjs/core';
import { CostCenter } from '../../models/cost_center';
import { FlexColumn } from '@patterns/ui';
import deepEqual from 'deep-equal';
import produce from 'immer';
import { CostCenterSelect } from '../cost_center_select';
import { FlexRow } from '@patterns/ui';
import { ConfirmDelete } from '../confirm_delete';

export interface Props {
  isOpen: boolean
  onClose: () => void
  onSave: (costCenter: CostCenter) => void
  onDelete: (costCenter: CostCenter) => void
  costCenter: CostCenter
}

export interface State {
  name: string
  parent?: CostCenter
}

export class CostCenterDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { name, parent } = this.props.costCenter;
    this.state = { name, parent }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.costCenter, prevProps.costCenter)) {
      const { name, parent } = this.props.costCenter;
      this.setState({ name, parent })
    }
  }

  async save() {
    const costCenter = produce(this.props.costCenter, costCenter => {
      costCenter.name = this.state.name.trim();
      costCenter.parent = this.state.parent;
    });
    this.props.onSave(costCenter);
  }

  public render() {
    return (
      <Dialog 
        title="Cost Center"
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        canOutsideClickClose={false}
        className="bp3-dark">
          <div className={Classes.DIALOG_BODY}>
            <FlexColumn flex={1}>
              <FormGroup inline label="Name EN">
                <InputGroup 
                  fill
                  value={this.state.name}
                  onChange={(evt: any) => this.setState({ name: evt.currentTarget.value })}
                />
              </FormGroup>

              <CostCenterSelect 
                label="Parent"
                inline={true}
                costCenter={this.state.parent || new CostCenter({})}
                onChange={parent => this.setState({ parent })}
              />
            </FlexColumn>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <FlexRow className="f-1">
              <FlexRow className="f-1 jc-s">
                <ConfirmDelete
                  outlined={true}
                  title="Delete"
                  onConfirm={() => this.props.onDelete(this.props.costCenter)}
                />
              </FlexRow>
              <FlexRow className="f-2 jc-e">
                <Button outlined text="Close" onClick={this.props.onClose} className="m-r-12"/>
                <Button outlined text="Save" intent="primary" onClick={() => this.save()}/>
              </FlexRow>
            </FlexRow>
          </div>
      </Dialog>
    );
  }
}
