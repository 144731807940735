import { BaseModel, Selectable, Resource } from '@patterns/core'
import { deserializeString } from '@patterns/core'
import { User } from './user';
import { Contract } from './contract';

export enum ContactType {
  ORGANIZATION = 0,
  SUPPLIER = 1,
  LESSEE = 2,
  LEASING_COMPANY = 3,
  ACCEPTANCE_TEMPLATE = 3
}

@Resource('contacts')
export class Contact extends BaseModel implements Selectable {
  name: string;
  description: string;
  email: string;
  street: string;
  street2: string;
  city: string;
  postCode: string;
  country: string;
  businessID: string;
  type: number
  note: string
  // createdBy: User
  users: User[]
  supplierContracts: Contract[]
  leasingCompanyContracts: Contract[]
  lesseeContracts: Contract[]

  constructor(data: any = {}) {
    if (data === null) {
      data = {}
    }
    super(data);
    this.name = deserializeString(data.name);
    this.description = deserializeString(data.description);
    this.email = deserializeString(data.email);
    this.street = deserializeString(data.street);
    this.street2 = deserializeString(data.street2);
    this.city = deserializeString(data.city);
    this.postCode = deserializeString(data.postCode);
    this.country = deserializeString(data.country);
    this.businessID = deserializeString(data.businessID);
    this.type = data.type || 0;
    this.note = deserializeString(data.note);

    if (data.users) {
      this.users = data.users.map((u: any) => new User(u))
    } else {
      this.users = []
    }

    if (data.supplierContracts) {
      this.supplierContracts = data.supplierContracts.map((contract: any) => new Contract(contract));
    } else {
      this.supplierContracts = []
    }

    if (data.leasingCompanyContracts) {
      this.leasingCompanyContracts = data.leasingCompanyContracts.map((contract: any) => new Contract(contract));
    } else {
      this.leasingCompanyContracts = []
    }
    
    if (data.lesseeContracts) {
      this.lesseeContracts = data.lesseeContracts.map((contract: any) => new Contract(contract));
    } else {
      this.lesseeContracts = []
    }
  }

  get isOrganization() {
    return this.type === 0
  }

  get isSupplier() {
    return this.type === 1
  }

  get isLessee() {
    return this.type === 2
  }

  get isLeasingCompany() {
    return this.type === 3
  }

  getLabel() { return null }
  getTitle() { return this.name }
}
