import * as React from 'react';
import { Select } from '@blueprintjs/select'
import { Selectable, Constructable, deepEqual } from '@patterns/core'
import { MenuItem, Button, IconName, Intent, Icon } from '@blueprintjs/core';
import EventBus from '../event_bus';
import { withTranslation } from 'react-i18next';

export interface RemoteSelectProps<T> {
  activeItem: T
  axios?: any
  cancelable?: boolean
  fetch?: (query: string) => Promise<T[]>
  fill?: boolean
  hint?: string
  icon?: IconName
  klass?: Constructable
  minimal?: boolean
  outlined?: boolean
  filterable?: boolean
  disabled?: boolean
  intent?: Intent
  onSelect: (item: T) => void
  ref?: React.Ref<any>
}

export interface State<T> {
  activeItem: T;
  items: T[];
  query: string;
}

export class RemoteSelect<T extends Selectable> extends React.Component<RemoteSelectProps<T>, State<T>> {
  private Select = Select.ofType<T>();
  private cancelToken: any;
  
  state = {
    activeItem: new this.props.klass!({}) as T,
    items: [],
    query: ''
  }

  componentDidMount() {
    this.onQueryChange('');
    EventBus.observe(this.onEvent);
  }

  componentDidUpdate(prevProps: RemoteSelectProps<T>) {
    const { activeItem } = this.props;
    if (!deepEqual(activeItem, this.state.activeItem)) {
      this.setState({ activeItem })
    }
  }

  private onEvent = (event?: string, data?: any) => {
    if (this.props.klass?.resource === 'organizations' && event === 'organizations:change') {
      this.localFetch('')
    }
  }

  public static ofType<T extends Selectable>() {
    return RemoteSelect as new (props: RemoteSelectProps<T>) => RemoteSelect<T>
  }
  
  private propFetch = async (query: string) => {
    if (!this.props.fetch) {
      return
    }

    const items = await this.props.fetch(query);
    this.setState({ items })
  }

  private localFetch = async (_query: string) => {
    if (!this.props.klass) {
      console.error('Patterns RemoteSelect => unable to perform localFetch, klass prop not specified');
      return
    }

    if (!this.props.axios) {
      console.error('Patterns RemoteSelect => unable to perform localFetch, axios prop not specified');
      return
    }
    
    const Klass = this.props.klass;
    const resource = Klass.resource;

    if (!resource || resource.length === 0) {
      console.error('Patterns RemoteSelect => unable to perform localFetch, resource is not set');
      return
    }
    
    const query = _query.trim().toLowerCase();

    if (this.cancelToken) {
      this.cancelToken();
    }

    const CancelToken = this.props.axios.CancelToken;

    try {
      const response = await this.props.axios.get(`/${resource}/search?query=${query}`, {
        cancelToken: new CancelToken((token: any) => {
          this.cancelToken = token
        })
      });

      const items = response.data[resource].map((i: any) => new Klass(i)) as T[];
      this.setState({ items })
    } catch (e) {
      if (!this.props.axios.isCancel(e)) {
        throw e
      }
    }
  }

  private onQueryChange = (query: string) => {
    this.setState({ query });

    if (this.props.fetch) {
      this.propFetch(query);
      return
    }

    if (this.props.klass) {
      this.localFetch(query);
      return
    }
  }

  private cancel = () => {
    if (!this.props.klass) {
      return
    }

    const Klass = this.props.klass;
    this.props.onSelect(new Klass({}) as T);
  }

  private onActiveItemChange = (item: T | null) => {
    if (item && item.id !== this.state.activeItem.id) {
      this.setState({ activeItem: item })
    }
  }

  private itemRenderer = (item: T, options: any) => <MenuItem
    key={`remote-select-item-${item.id}`}
    text={item.getTitle()}
    label={item.getLabel() || ''}
    onClick={options.handleClick}
    active={item.id === this.state.activeItem.id}
  />

  public render() {
    const BlueprintSelect = this.Select;
    const hint = this.props.hint || 'Select';
    const { activeItem } = this.props;
    const text = activeItem.exists ? activeItem.getTitle() : hint;
    return (
      <BlueprintSelect
        activeItem={this.state.activeItem}
        onActiveItemChange={this.onActiveItemChange}
        filterable={this.props.filterable}
        items={this.state.items}
        disabled={this.props.disabled}
        itemRenderer={this.itemRenderer}
        onItemSelect={this.props.onSelect}
        onQueryChange={this.onQueryChange}>
        <Button
          outlined={this.props.outlined}
          minimal={this.props.minimal}
          disabled={this.props.disabled}
          fill={this.props.fill}
          icon={this.props.cancelable && this.props.activeItem.exists ? 'chevron-down' : this.props.icon }
          // rightIcon="chevron-down"
          rightIcon={(this.props.cancelable && this.props.activeItem.exists) ?
            <Icon
              icon="cross"
              intent="warning"
              onClick={this.cancel}
            /> :
            <Icon
              icon="chevron-down"
              intent={this.props.intent || (this.props.activeItem.exists ? 'primary' : 'none')}
              onClick={this.cancel}
            />
          }
          text={text}
          intent={this.props.intent || 'none'}
        />
      </BlueprintSelect>
    );
  }
}

export default withTranslation()(RemoteSelect)