import { BaseModel, Selectable, Resource } from '@patterns/core'
import { deserializeString, Contact } from '@patterns/core'
import { Asset } from './asset';
import { RoleIdentifier } from './role';
import { Organization } from './organization';

@Resource('users')
export class User extends BaseModel implements Selectable {
  email: string;
  name: string;
  role: RoleIdentifier;
  password?: string;
  contactApproval: boolean
  is_active: boolean;
  assets: Asset[]
  contacts: Contact[]
  organization: Organization
  organizations: string[]

  constructor(data: any = {}) {
    super(data)
    this.email = deserializeString(data.email);
    this.name = deserializeString(data.name);
    this.role = (data.role ? deserializeString(data.role) : 'user') as RoleIdentifier;
    this.password = deserializeString(data.password);

    this.contactApproval = data.contactApproval || false;
    this.is_active = data.is_active === true;

    if (data.assets) {
      this.assets = data.assets.map((asset: any) => new Asset(asset))
    } else {
      this.assets = []
    }

    if (data.contacts) {
      this.contacts = data.contacts.map((contact: any) => new Contact(contact))
    } else {
      this.contacts = []
    }

    if (data.organization) {
      this.organization = new Organization(data.organization)
    } else {
      this.organization = new Organization({})
    }

    if (data.organizations) {
      this.organizations = data.organizations
    } else {
      this.organizations = []
    }
  }

  get isAdmin() {
    return this.role === 'admin'
  }

  get isSuperuser() {
    return this.role === 'superuser'
  }

  get isUser() {
    return this.role === 'user'
  }

  getLabel() { return this.email }
  getTitle() { return this.name }
}
