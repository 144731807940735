import { FormGroup, Slider } from '@blueprintjs/core';
import { FlexRow } from '@patterns/ui';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { priceFormat } from '../../common';
import { axios } from '../../session';
import { NumberWidget } from '../number_widget';
import TCOReportRow from './tco_row';

export type TCOAssetItem = {

}

export interface Props extends WithTranslation {
  since: Date
  till: Date
}

export interface State {
  months: number
  extraCostsPerEmployee: number
  leasingCostPerEmployee: number
  totalCostsPerEmployee: number
  showInvisible: boolean
}

export class TCOReports extends React.Component<Props, State> {
  state = {
    extraCostsPerEmployee: 0,
    leasingCostPerEmployee: 0,
    months: 0,
    totalCostsPerEmployee: 0
  } as State

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.since.getTime() !== prevProps.since.getTime() || this.props.till.getTime() !== prevProps.till.getTime()) {
      this.fetch()
    }
  }

  private fetch = async () => {
    const costs = (await axios.get('/assets/costs_summary')).data;
    const leasingCostPerEmployee = costs.leasingCostPerEmployee;
    const extraCostsPerEmployee = costs.extraCostPerEmployee;
    const totalCostsPerEmployee = parseFloat(leasingCostPerEmployee as string) + parseFloat(extraCostsPerEmployee);

    this.setState({
      leasingCostPerEmployee,
      extraCostsPerEmployee,
      totalCostsPerEmployee
    })
  }

  private onSliderChange = (months: number) => this.setState({ months })

  public render() {
    const { t } = this.props;
    return (
      <div className="m-l-24 m-r-24 m-t-24">
        <div className="d-f f-r">
          <div className="f-1">
            <NumberWidget value={priceFormat(this.state.totalCostsPerEmployee * (this.state.months || 1))} title={t('tco_reports.total_costs_per_employee')} icon="cloud-upload" color=""  className="m-r-24 blue-alpha"/>
          </div>
          <div className="f-1">
            <NumberWidget value={priceFormat(this.state.leasingCostPerEmployee * (this.state.months || 1))} title={t('tco_reports.leasing_costs_per_employee')} icon="cloud-download" color=""  className="m-r-24 blue-alpha"/>
          </div>
          <div className="f-1">
            <NumberWidget value={priceFormat(this.state.extraCostsPerEmployee * (this.state.months || 1) )} title={t('tco_reports.extra_costs_per_employee')} icon="cloud" color="" className="m-l-12 blue-alpha"/>
          </div>
        </div>
        <div className="months-slider d-f jc-c ai-c p-24">
          <FormGroup inline={true} className="w-100p" label={t('months')}>
            <Slider
              min={0}
              max={63}
              stepSize={3}
              labelRenderer={value => value === 0 ? `${value + 1}` : value.toString()}
              value={this.state.months}
              labelStepSize={3}
              onChange={this.onSliderChange}
              className="m-l-24"
            />
          </FormGroup>
        </div>
        {/* <FlexRow>
          <Switch
            label="Show invisible categories" 
            style={{ color: 'white' }}
            className="m-l-24"
            checked={this.state.showInvisible}
            onChange={evt => this.setState({ showInvisible: evt.currentTarget.checked })}
          />
        </FlexRow> */}
        <FlexRow>
          <TCOReportRow months={this.state.months} showInvisible={true}/>
        </FlexRow>
      </div>
    )
  }
}
  
export default withTranslation()(TCOReports)