import { Eventable } from './eventable'
import { Category } from './models/category';
import { CostCenter } from './models/cost_center';
import { User } from './models/user'
import { CategoryRepository, CostCenterRepository, UserRepository } from './repository'
import SiemensManagerInstance from './siemens.manager'

export enum DataStoreEvents {
  CATEGORIES_CHANGED = 'categories:changed',
  COST_CENTERS_CHANGED = 'cost_centers:changed',
  USERS_CHANGED = 'users:changed'
}

class DataStore extends Eventable {
  users: User[] = [];
  categories: Category[] = [];
  allCategories: Category[] = [];
  costCenters: CostCenter[] = [];
  
  public initialize = async () => {
    await this.updateCategories();
    await this.updateCostCenters();
    await this.updateUsers();
    SiemensManagerInstance.fetch()
  }

  public async updateCategories() {
    const params = `show_invisible=true`;
    const { items } = await CategoryRepository.index(0, 1000, 'name_en', 'ASC', undefined, undefined, params);
    this.allCategories = items;
    this.categories = items.filter(c => c.visible);
    this.notify(DataStoreEvents.CATEGORIES_CHANGED)
  }

  public async updateCostCenters() {
    const { items } = await CostCenterRepository.index(0, 1000);
    this.costCenters = items;
    const dummy = new CostCenter();
    dummy.name = 'Unassigned';
    this.costCenters.push(dummy);
    this.notify(DataStoreEvents.COST_CENTERS_CHANGED)
  }

  public async updateUsers() {
    const { items } = await UserRepository.index(0, 500);
    this.users = items;
    this.notify(DataStoreEvents.USERS_CHANGED)
  }

  public categoryBySubtype(subTypeId: number) {
    console.log('categories', this.allCategories, 'subtype', subTypeId);
    return this.allCategories.find(c => c.subTypeId === subTypeId);
  }

  searchUsers(query: string) {
    return this.users.filter(user => user.name.toLowerCase().match(query.trim().toLowerCase()))
  }
}

const instance = new DataStore();
(window as any).dataStore = instance;

export default instance
