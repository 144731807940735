import * as React from 'react';
import { ComingSoon } from '../components/coming_soon';
import { MainLayout } from '../layouts/main';

export interface Props {
}

export class Invoices extends React.Component<Props> {
  public render() {
    return (
      <MainLayout title="Invoices">
        <div className="full-height-tabbed">
          <ComingSoon />
        </div>
      </MainLayout>
    );
  }
}
