import * as React from 'react';
import { FlexColumn, FlexRow } from '@patterns/ui';
import { MainLayout } from '../layouts/main';
import { NumberWidget } from '../components/number_widget';
import { axios, endOfQuarter } from '../common';
import { AutoSizer } from 'react-virtualized'
import AssetCategoryChart from '../components/assets/category_chart';
import { AssetLifecycleChart } from '../components/assets/lifecycle_chart';
import { ProgressChart } from '../components/progress_chart';
import { session } from '../session';
import { motion } from 'framer-motion';
import { MapView } from '../components/map';
import { Icon } from '@blueprintjs/core';
import { formatDate } from '@patterns/core';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface State {
  users: number,
  assets: number,
  contracts: number,
  costCenters: number,
  eolCount: number;
  inUse: number;
  deadline: string;
  chart1Loaded: boolean,
  chart2Loaded: boolean,
  chart3Loaded: boolean,
  chart4Loaded: boolean
}

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

const breadcrumbs = {
  hidden: { opacity: 0, scale: 1, x: -200 },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
}

export interface Props extends WithTranslation {}

export class Dashboard extends React.Component<Props, State> {
  state = {
    users: 0,
    assets: 0,
    contracts: 0,
    costCenters: 0,
    eolCount: 0,
    inUse: 0,
    deadline: '',
    chart1Loaded: false,
    chart2Loaded: false,
    chart3Loaded: false,
    chart4Loaded: false
  } as State

  componentDidMount() {
    this.fetch()
  }

  private fetch = async () => {
    const response = await axios.get('/dashboard');
    const data = response.data;

    const now = new Date().getTime();
    let deadline = moment(endOfQuarter(new Date())).subtract(1, 'month').toDate();

    if (deadline.getTime() < now) {
      deadline = moment(endOfQuarter(moment(deadline).add(2, 'month').toDate())).subtract(1, 'month').toDate();
    }

    // const eolResponse = await axios.get('/assets/stats');
    // console.log('assets data', eolResponse.data);

    const eolData = await axios.get('/assets/eol?pageSize=1');
    const contracts =  data.contracts < 0 ? 0 : data.contracts;
    
    this.setState({
      users: data.users,
      assets: data.assets,
      contracts,
      costCenters: data.costCenters,
      eolCount: eolData.data.count,
      inUse: data.inUse,
      deadline: formatDate(moment(deadline).endOf('month').toDate())
    })
  }

  public render() {
    const { t } = this.props;
    const chartsReady = this.state.chart1Loaded && this.state.chart2Loaded && this.state.chart3Loaded && this.state.chart4Loaded;

    return (
      <MainLayout title="Dashboard">
        {/* <div className="background-video" style={{ marginLeft: 280, width: 'calc(100vw - 290px)', overflowY: 'hidden'}}>
          <video className='videoTag' autoPlay loop muted>
            <source src={sample} type='video/mp4' />
          </video>
        </div> */}
{/* 
        <div className="background-video" style={{ zIndex: 3, filter: 'blur(24px)', backgroundColor: 'rgba(0, 0, 0, 0.5)', marginLeft: 280, width: 'calc(100vw - 290px)', overflowY: 'hidden'}}>
        </div> */}

        {/* <div style={{ position: 'absolute', zIndex: 4, width: 'calc(100vw - 300px)', overflowY: 'hidden' }}> */}
        {/* <div style={{ position: 'absolute', zIndex: 4, width: 'calc(100vw - 300px)', overflowY: 'hidden' }}> */}
          <motion.div className="m-24 white" variants={breadcrumbs} initial="hidden" animate="visible">
            <h1>{t('dashboard.title')}</h1>
            <p className="medium">{t('dashboard.welcome_back')}, { session.user.name }</p>
          </motion.div>

          <motion.ul className="container m-24 d-f f-r"
              variants={container}
              initial="hidden"
              animate="visible" 
              style={{ maxHeight: 110, height: 110 }}>
            <motion.li key={`dash-users`} className="item m-r-24 blue-alpha f-1 d-f" variants={item}>
              <NumberWidget title={t('users')} value={this.state.users} color="" icon="user"/>
            </motion.li>
            <motion.li key={`dash-assets`} className="item m-r-24 blue-alpha f-1 d-f" variants={item}>
              <NumberWidget title={t('assets.title')} value={this.state.assets} color="" icon="mobile-phone"/>
            </motion.li>
            <motion.li key={`dash-contracts`} className="item m-r-24 blue-alpha f-1 d-f" variants={item}>
              <NumberWidget title={t('contracts.title')} value={this.state.contracts} color="" icon="document"/>
            </motion.li>
            <motion.li key={`dash-cost-centers`} className="item blue-alpha f-1 d-f" variants={item}>
              <NumberWidget title={t('cost_centers')} value={this.state.costCenters} color="" icon="euro" />
            </motion.li>
          </motion.ul>
          
          <FlexRow style={{ minHeight: 480 }} className="m-l-24 m-r-24 m-b-24">
            <motion.div className="f-1 m-r-24" initial="hidden" animate="visible">
              <AutoSizer>{({ height, width }) => (
                <div className="f-1 m-r-24">
                  <AssetCategoryChart height={height} width={width}/>
                </div>
              )}</AutoSizer>
            </motion.div>

            <FlexColumn className="blue-alpha m-r-32" style={{ marginRight:24, WebkitBackdropFilter: 'blur(24px)', borderRadius: 8, border: '1px solid rgba(255, 255, 255, 0.1)' }}>
              <div className="f-1 d-f f-c">
                <div className="dashboard-widget-wrapper" style={{ height: 232, justifyContent: 'center' }}>
                  <div className="dashboard-widget-number" style={{ alignItems: 'center' }}>
                    <FlexColumn style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <FlexRow className="ai-c">
                        { this.state.eolCount <= 0 && <h1 style={{ fontSize: 18, marginRight: 6 }}>{t('dashboard.no_expiring_leases')}</h1> }
                        { this.state.eolCount > 0 && <div className="d-f f-r jc-c ai-c blob">
                          <h1 style={{ fontSize: 18, marginRight: 6 }}>{t('dashboard.expiring_leases')}</h1>
                          <Icon iconSize={28} icon="warning-sign" style={{ margin: '12px auto' }} color="yellow"/>
                        </div> }
                      </FlexRow>
                      
                      <FlexRow className="f-1 w-100p">
                        <div className="f-1 d-f f-c ai-c" style={{ borderRight: '1px solid rgba(255, 255, 255, 0.3)'}}>
                          <h1 style={{ fontSize: 18, opacity: 0.7 }}>{t('eol_assets')}</h1>
                          <strong style={{ fontSize: 22, color: 'white' }}>{ this.state.eolCount === 0 ? '-' : this.state.eolCount }</strong>
                        </div>
                        <div className="f-1 d-f f-c ai-c">
                          <h1 style={{ fontSize: 18, opacity: 0.7 }}>{t('dashboard.take_action_until')}</h1>
                          <strong style={{ fontSize: 22, color: 'white' }}>{ this.state.eolCount === 0  ? '-' : this.state.deadline }</strong>
                        </div>
                      </FlexRow>
                    </FlexColumn>
                  </div>
                </div>

                <div className="dashboard-widget-wrapper" style={{ height: 232, justifyContent: 'center' }}>
                  <div className="dashboard-widget-number" style={{ alignItems: 'center' }}>
                    <FlexColumn style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <FlexRow className="ai-c">
                        <h1 style={{ fontSize: 18, marginRight: 6 }}>{t('dashboard.utilization_rate')}</h1>
                      </FlexRow>
                      
                      <FlexRow className="f-1 w-100p">
                        <div className="f-1 d-f f-c ai-c" style={{ borderRight: '1px solid rgba(255, 255, 255, 0.3)'}}>
                          <h1 style={{ fontSize: 18, opacity: 0.7 }}>{t('dashboard.assets_in_use')}</h1>
                          <strong style={{ fontSize: 22, color: 'white' }}>{ this.state.inUse === 0 ? '-' : this.state.inUse } / { this.state.assets }</strong>
                        </div>
                        <div className="f-1 d-f f-c ai-c">
                          <h1 style={{ fontSize: 18, opacity: 0.7 }}>{t('dashboard.efficiency')}</h1>
                          <strong style={{ fontSize: 22, color: 'white' }}>{ this.state.inUse === 0 ? '-' : (this.state.inUse / ( this.state.assets * 0.01)).toFixed(1) + '%' }</strong>
                        </div>
                      </FlexRow>
                    </FlexColumn>
                  </div>
                </div>
              </div>
              {/* <motion.div className="f-1 f-r d-f" initial="hidden" animate="visible">
                <motion.li key={`dash-assets2`} className="item f-1 d-f" variants={item} style={{ height: 244, borderRight: '1px solid rgba(255, 255, 255, 0.1)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                  <NumberWidgetVertical title="Users" value={this.state.assets} color="" icon="user"/>
                </motion.li>
                <motion.li key={`dash-assets3`} className="item f-1 d-f" variants={item}  style={{ height: 244, borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                  <NumberWidgetVertical title="Assets" value={this.state.assets} color="" icon="mobile-phone"/>
                </motion.li>
              </motion.div>
              <motion.div className="f-1 f-r d-f" initial="hidden" animate="visible">
                <motion.li key={`dash-assets4`} className="item f-1 d-f" variants={item}  style={{ height: 244, borderRight: '1px solid rgba(255, 255, 255, 0.1)'  }}>
                  <NumberWidgetVertical title="Contracts" value={this.state.assets} color="" icon="document"/>
                </motion.li>
                <motion.li key={`dash-assets5`} className="item f-1 d-f" variants={item}  style={{ height: 244 }}>
                  <NumberWidgetVertical title="Cost Centers" value={this.state.assets} color="" icon="euro"/>
                </motion.li>
              </motion.div> */}
            </FlexColumn>

            <motion.div className="f-1" initial="hidden" animate="visible">
              <MapView lat={60.652859} lng={25.310471} zoom={4}/>
            </motion.div>
            {/* <div className="f-2" style={{ minHeight: 480 }}>
              <AutoSizer>{({ height, width }) => (
                <ContractLeasingCostsChart height={height} width={width}/>
              )}</AutoSizer>
            </div> */}
          </FlexRow>

          <FlexRow style={{ minHeight: 480 }} className="m-24">
            <div className="f-1 m-r-24">
              <AutoSizer>{({ height, width }) => (
                <AssetLifecycleChart height={height} width={width} {...this.props}/>
              )}</AutoSizer>
            </div>
            <div className="f-1 d-f f-c chart-container">
              <div className="chart-inner-title">
                {t('dashboard.device_overview')}
              </div>
              <motion.div variants={container} initial="hidden" animate="visible" className="p-l-12 p-r-12 p-t-6 d-f f-r">
                <div style={{ marginRight: 6, flex: 1 }} className={`progress-charts-container charts-${ chartsReady ? 'ready' : 'loading'}`}>
                  <ProgressChart url="/devices/operating_system_chart" title={t('dashboard.operating_systems')} intent="success" onLoad={(hasItems) => this.setState({ chart1Loaded: hasItems })}/>
                  <ProgressChart url="/devices/manufacturer_chart" title={t('dashboard.manufacturers')} intent="warning" onLoad={(hasItems) => this.setState({ chart2Loaded: hasItems })}/>
                </div>
                <div style={{ marginLeft: 6, flex: 1 }} className={`progress-charts-container charts-${ chartsReady ? 'ready' : 'loading'}`}>
                  <ProgressChart url="/devices/cpu_chart" title={t('dashboard.cpus')} intent="primary" onLoad={(hasItems) => this.setState({ chart3Loaded: hasItems })}/>
                  <ProgressChart url="/devices/gpu_chart" title={t('dashboard.gpus')} intent="danger" onLoad={(hasItems) => this.setState({ chart4Loaded: hasItems })}/>
                </div>
                { !chartsReady && <div className="white" style={{ position: 'absolute', zIndex: 10, width: '100%', height: 'calc(100% - 80px)', fontSize: 18, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>{t('waiting_for_data')}</div> }
              </motion.div>
            </div>
          </FlexRow>
        {/* </div> */}
      </MainLayout>
    )
  }
}

export default withTranslation()(Dashboard);