import * as React from 'react';
import { DataTable, Column, ColumnType, FilterStates, Alignment } from '@patterns/datatable';
import { Asset } from '../../models/asset';
import { convertFileToBase64, download } from '@patterns/core';
import { Tag, Intent, Checkbox, Icon, Button, Dialog, Spinner, Alert } from '@blueprintjs/core';
import { axios } from '../../common';
import produce from 'immer';
import { AssetRepository } from '../../repository';
import CategoryCell from '../category.cell';
import { withTranslation, WithTranslation } from 'react-i18next';
import { session } from '../../session';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { InlineEditor } from '../inline_editor';
import { formatDate } from '@patterns/core';
import AssetPreviewDialog from '../assets/asset_preview.dialog';
import pdfIcon from '../../assets/icon-pdf.svg';
import {FormEvent} from "react";

const AssetTable = DataTable.ofType<Asset>();

export interface Props extends RouteComponentProps, WithTranslation {}

export interface State {
  selection: Asset[];
  isUploading: boolean;
  uploadAsset?: Asset;
  showAlert: boolean;
  selected: Asset;
  showDialog: boolean;
  isRemoving: boolean;
}

export class ReturnedContractAssets extends React.Component<Props, State> {
  state = {
    selection: [],
    selected: new Asset(),
    isUploading: false,
    showAlert: false,
    showDialog: false,
    isRemoving: false
  } as State

  fileInput = React.createRef<HTMLInputElement>();
  table = React.createRef<DataTable<Asset>>();

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'name',
        title: t('name'),
        type: ColumnType.Text,
        flex: 1,
        visible: true,
        format: asset => <span>{ asset.name }</span>
      },
      {
        id: 'category',
        title: t('category'),
        type: ColumnType.Text,
        width: 130,
        visible: true,
        format: asset => <CategoryCell category={asset.category}/>
      },
      {
        id: 'fields.serial',
        title: t('fleet.serial_number'),
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        visible: true,
        alignment: Alignment.Left,
        width: 130,
        format: asset => <span>{ asset.serialNumber }</span>
      },
      {
        id: 'employee',
        title: t('user'),
        type: ColumnType.Text,
        flex: 1,
        visible: true,
        format: asset => <span>{ asset.employee.name }</span>
      },
      // {
      //   id: 'employee',
      //   title: t('user'),
      //   type: ColumnType.Custom,
      //   sortable: false,
      //   filterable: false,
      //   visible: true,
      //   alignment: Alignment.Left,
      //   width: 170,
      //   format: asset => {
      //     // if (asset.employee.exists) {
      //     //   return <div onClick={async (evt: any) => {
      //     //     evt.preventDefault();
      //     //     evt.stopPropagation();
      //     //     const device = await DeviceRepository.get(`by_serial_number/${asset.serialNumber}`);
      //     //     this.setState({ device, showDevice: true })
      //     //   }}>

      //     //   </div>
      //     // }
      //     if (asset.employee && asset.employee.name && asset.employee.name.length > 0) {
      //       return <><Icon icon="user" className='m-r-6'/>
      //         {asset.employee.name}
      //       </>
      //     }
      //     return <span/>
      //   }
      // },

      {
        id: 'time_left',
        title: t('days_left'),
        type: ColumnType.Text,
        flex: 1,
        visible: false,
        format: asset => {
          const months = asset.contract.remainingMonths;
          let intent = 'success' as Intent;
          if (months < 2) {
            intent = 'danger'
          } else if (months >= 2 && months <= 3) {
            intent = "warning"
          }
          return <Tag intent={intent} minimal>{ asset.contract.remainingDays } days ({ months })</Tag>
        }
      },
      // {
      //   id: 'end_date',
      //   title: t('assets.end_date'),
      //   type: ColumnType.Text,
      //   width: 130,
      //   visible: true,
      //   format: asset => <span>{ asset.contract.exists ? formatDate(asset.contract.validTill) : 'No Contract' }</span>
      // },
      {
        id: 'state',
        title: t('state'),
        type: ColumnType.Custom,
        width: 150,
        visible: true,
        format: asset => {
          if (asset.isReplaced) {
            return <span>{ t('assets.extend_states.erased') }</span>
          }

          return <span>{ t(`assets.extend_states.${asset.extendState}`) }</span>
        }
      },
      {
        id: 'picked_up',
        title: t('asset_returns.picked_up'),
        type: ColumnType.Custom,
        width: 100,
        visible: true,
        format: asset => <span onClick={evt => { evt.stopPropagation() }} >
          <Checkbox checked={asset.isPickedUp} onClick={evt => { evt.preventDefault(); evt.stopPropagation() }} onChange={async (evt: FormEvent<HTMLInputElement>) => {
            evt.preventDefault();
            evt.stopPropagation();
            const newAsset = produce(asset, newAsset => {
              newAsset.isPickedUp = evt.currentTarget.checked;
            })
            await AssetRepository.save(newAsset);
            this.table.current?.fetch()
          }}/>
        </span>
      },
      {
        id: 'replaced',
        title: t('asset_returns.replaced'),
        type: ColumnType.Custom,
        width: 130,
        visible: true,
        format: asset => <span onClick={evt => { evt.stopPropagation(); }} >
          <Checkbox checked={asset.isReplaced} onChange={async (evt: FormEvent<HTMLInputElement>) => {
            evt.preventDefault();
            evt.stopPropagation();
            const newAsset = produce(asset, newAsset => {
              newAsset.isReplaced = evt.currentTarget.checked;
            });
            await AssetRepository.save(newAsset);
            this.table.current?.fetch()
          }}/>
        </span>
      },
      {
        id: 'replacement_img',
        title: 'PDF',
        type: ColumnType.Text,
        width: 72,
        visible: true,
        format: asset => {
          if (!asset.hasReport) {
            return <Button intent="primary" minimal icon={<Icon color="rgba(255,255,255,0.5)" icon="cloud-upload"/>} onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              this.setState({ uploadAsset: asset });
              this.fileInput.current?.click();
            }}/>
          }

          return <img
            src={pdfIcon}
            alt="pdf"
            style={{ height: 28 }}
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              download(`/api/assets/${asset.id}/report.pdf`, session.token);
            }}
          />
        }
      },
      {
        id: 'comments',
        title: t('comment'),
        type: ColumnType.Text,
        flex: 1,
        visible: true,
        format: _asset => {
          return <InlineEditor
            value={_asset.comments}
            onSave={async value => {
              const asset = new Asset(_asset);
              asset.comments = value;
              await AssetRepository.save(asset);
              const existing = this.table.current?.state.items.find(item => item.id === asset.id);
              if (existing) {
                const items = [...(this.table.current?.state.items ?? [])];
                this.table.current?.setState({
                  items
                }, () => this.table.current?.fetch())
              }
            }}
          />
        }
      },
      {
        id: 'decision_date',
        title: t('decision_date'),
        type: ColumnType.Date,
        alignment: Alignment.Right,
        width: 150,
        visible: true,
        format: asset => <span>{asset.decisionDate ? formatDate(asset.decisionDate) : ''}</span>
      },
      {
        id: 'actions',
        title: t('actions'),
        type: ColumnType.Text,
        alignment: Alignment.Right,
        width: 250,
        visible: true,
        headerClass: 'p-r-12 ta-r',
        format: asset => {
          const children = [];

          if (session.user.isSuperuser && asset.contract.validTill.getTime() > new Date().getTime()) {
            children.push(<Button intent="danger" small text={t('revert')} onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              this.revert(asset);
            }}/>)
          }

          if ((session.user.isAdmin)) {
            children.push(<Button intent="danger" small text={t('revert')} onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              this.revert(asset);
            }}/>)
          }

          if ((session.user.isSuperuser || session.user.isAdmin)) {
            children.push(<Button intent="danger" icon="trash" className="m-l-12" small text={t('remove_pdf')} onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              this.removePDF(asset);
            }}/>)
          }

          return children
        }
      }
    ] as Column<Asset>[]
  }

  private fetch = async (page: number, pageSize: number, sort: string, sortDir: string, filters: FilterStates) => {
    let params = [
      `page=${page}`,
      `pageSize=${pageSize}`,
      `sort=${sort}`,
      `sortDir=${sortDir.toUpperCase()}`,
    ].join('&')

    const response = await axios.get(`/assets/returned?${params}`);
    const assets = response.data.assets.map((a: any) => new Asset(a));
    return { items: assets, total: 0 }
  }

  private onSelectionChange = (selection: Asset[]) => this.setState({ selection })

  private onSelect = (asset: Asset) => {
    this.setState({
      selected: asset,
      showDialog: true
    })
  }

  private handleFileSelect = (fileList: FileList | null) => {
    if (!fileList) {
      return
    }

    if (!this.state.uploadAsset) {
      return
    }

    // const file = fileList[0];
    this.setState({ isUploading: true }, async () => {
      const file = fileList[0];
      const data = await convertFileToBase64(file);
      await axios.post(`/assets/${this.state.uploadAsset!.id}/upload_report`, { data });
      this.table.current?.fetch();
      this.setState({ isUploading: false, uploadAsset: undefined })
    });
  }

  private onAlertClose = () => {
    this.setState({ showAlert: false })
  }

  private onAlertConfirm = async () => {
    this.setState({ isRemoving: true })
    await axios.get(`/assets/${this.state.selected?.id}/remove_pdf`);
    this.setState({ showAlert: false, isRemoving: false });
    this.table.current?.fetch();
  }

  private removePDF = async (asset: Asset) => {
    this.setState({ selected: asset, showAlert: true })
  }

  private revert = async (asset: Asset) => {
    await axios.get(`/assets/${asset.id}/revert`);
    this.table.current?.fetch();
  }

  private onDialogClose = () => {
    this.setState({ showDialog: false }, () => this.table.current?.fetch())
  }

  public render() {
    const { t } = this.props;
    return (
      <div className="m-24 returned-assets">
        <AssetTable
          id="returned-assets-table"
          ref={this.table}
          columns={this.getColumns()}
          fetch={this.fetch}
          onItemSelect={this.onSelect}
          onSelectionChange={this.onSelectionChange}
          multiple={false}
          sort="decisionDate"
          sortDir="desc"
        />

        <input
          accept="application/pdf"
          type="file"
          ref={this.fileInput}
          onChange={e => this.handleFileSelect(e.target.files)}
          style={{ display: 'none' }}
        />

        <Dialog
          isOpen={this.state.isUploading}
          isCloseButtonShown={false}
          canEscapeKeyClose={false}
          title={t('patterns.please_wait')}>
            <div style={{ padding: 48, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Spinner size={34}/>
            </div>
        </Dialog>

        <Alert
            cancelButtonText={t('cancel')}
            confirmButtonText={t('delete')}
            icon="trash"
            intent={Intent.DANGER}
            isOpen={this.state.showAlert}
            loading={this.state.isRemoving}
            onCancel={this.onAlertClose}
            onConfirm={this.onAlertConfirm}
        >{ `${t('remove_pdf')} ?`}</Alert>

        <AssetPreviewDialog
          asset={this.state.selected}
          isOpen={this.state.showDialog}
          onClose={this.onDialogClose}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation()(ReturnedContractAssets))
