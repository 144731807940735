import { BaseModel } from '@patterns/core'
import { deserializeString } from '@patterns/core'
import { Contract } from './contract';

export class ContractAttachment extends BaseModel {
  name: string;
  type: string;
  path: string;
  contract?: Contract

  constructor(data: any = {}) {
    super(data);
    this.name = deserializeString(data.name);
    this.type = deserializeString(data.type);
    this.path = deserializeString(data.path);
    
    if (data.contract) {
      this.contract = new Contract(data.contract);
    }
  }
}