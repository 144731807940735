import { Button, Icon } from '@blueprintjs/core';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PurchaseInvoice } from '../../models/purchase_invoice';
import { formatDateTime } from '@patterns/core';

export type PurchaseInvoiceListType = 'pending' | 'rejected' | 'accepted'

export interface Props extends WithTranslation {
  type: PurchaseInvoiceListType
  invoices: PurchaseInvoice[]
  onAccept: (invoice: PurchaseInvoice) => void
  onEdit: (invoice: PurchaseInvoice) => void
  onReject: (invoice: PurchaseInvoice) => void
  onReason: (invoice: PurchaseInvoice) => void
  onSelect: (invoice: PurchaseInvoice) => void
}

export class PurchaseInvoiceList extends React.Component<Props> {
  public render() {
    const { t } = this.props;
    return (
      <div>
        { this.props.invoices.filter(i => {
          if (this.props.type === 'pending') {
            return !i.isAccepted && !i.isRejected
          } else if (this.props.type === 'accepted') {
            return i.isAccepted
          } else if (this.props.type === 'rejected') {
            return i.isRejected
          }
          return false
        }).map((item, index) => <div className="purchase-invoice" key={`purchase-invoice-${index}`}>
          <div className="icon" onClick={() => this.props.onSelect(item)}></div>
          
          <div className="filename">
            <div>{ item.supplier?.name ?? item.title }</div>
            <div>{ item.code }</div>
            <div>{ formatDateTime(item.createdAt) }</div>
            { item.acceptedAt && <div><Icon icon="tick" intent="primary" className='m-r-12'/>{ formatDateTime(item.acceptedAt) }</div> }
          </div>

          <div className="buttons">
            { this.props.type !== 'accepted' && <Button
              text={this.props.type === 'rejected' ? t('purchase_invoices.reason') : t('purchase_invoices.accept')}
              intent={this.props.type === 'rejected' ? 'primary' : 'success'}
              icon={this.props.type === 'rejected' ? 'info-sign' : 'tick'}
              onClick={() => this.props.type === 'rejected' ? this.props.onReason(item) : this.props.onAccept(item)}
              className="m-r-24"
            /> }

            <Button 
              text={t('edit')}
              intent="none"
              icon="edit"
              onClick={() => this.props.onEdit(item)}
              minimal
            />
          </div>
        </div> )}
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default withTranslation()(PurchaseInvoiceList)