export type RoleIdentifier = 'user' | 'superuser' | 'admin'

export class Role {
  code: RoleIdentifier
  name: string

  constructor(code: RoleIdentifier, name: string) {
    this.code = code
    this.name = name
  }

  getLabel() { return null }
  getTitle() { return this.name }
}

export const Roles = [
  new Role('admin', 'Administrator'),
  new Role('superuser', 'Superuser'),
  new Role('user', 'User'),
]
