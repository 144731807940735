import { Button, Checkbox, Collapse, Colors, Menu, MenuDivider } from '@blueprintjs/core';
import { FlexColumn, FlexRow } from '@patterns/ui';
import produce from 'immer';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppContext, AppContextType } from '../../app_context';
import { priceFormat } from '../../common';
import { ExtraCost, ExtraCostItem } from '../../models/extra_cost';
import { ExtraService } from '../../models/extra_service';
import { ExtraServiceRepository } from '../../repository';
import { session } from '../../session';
import { CountedProduct } from '../../shopping_cart';

export type ToggleMap = { [id:string]: boolean }

export interface Props extends WithTranslation, RouteComponentProps {}

export interface State {
  extraServices: ExtraService[]
  tag: number
  toggleMap: ToggleMap
}

export class ShoppingCartView extends React.Component<Props, State> {
  state = {
    extraServices: [] as ExtraService[],
    tag: 0,
    toggleMap: {} as ToggleMap
  }

  static contextType = AppContext;

  componentDidMount() {
    (window as any).cart = this.getContext().shoppingCart;
    this.getContext().shoppingCart.observeAll(this.increaseTag);
    this.fetch()
  }

  componentWillUnmount() {
    this.getContext().shoppingCart.cancelAll(this.increaseTag);
  }

  private fetch = async () => {
    const { items } = await ExtraServiceRepository.index(1, 1000, 'name', 'ASC');
    this.setState({ extraServices: items })
  }

  private getContext() {
    return this.context as AppContextType
  }

  private increaseTag = () => { 
    this.setState({ tag: this.state.tag + 1 })
  }

  private sendCart = async () => {
    const data = this.getContext().shoppingCart.json;
    (data as any).lessee_id = session.lesseeId;
    this.props.history.push('/store/confirm');
    // await axios.post('/shopping_cart', data);
    // Notifier.success(this.props.t('shopping_cart.order_sent'));
    // this.getContext().shoppingCart.clear();
  }

  private toggleExtras = (idx: number) => {
    const index = `${idx}`;
    const toggleMap = produce(this.state.toggleMap, toggleMap => {
      toggleMap[index] = !toggleMap[index]
    });
    this.setState({ toggleMap })
  }

  private toggleExtraCost = (index: number, extraCost: ExtraCost, item: ExtraCostItem) => {
    this.getContext().shoppingCart.toggleExtraCost(index, extraCost, item)
  }

  private toggleExtraService = (index: number, extraService: ExtraService) => {
    this.getContext().shoppingCart.toggleExtraService(index, extraService)
  }

  private renderItem(item: CountedProduct, index: number) {
    const { t } = this.props;
    const cart = this.getContext().shoppingCart;
    return <div className="m-l-12" key={`item-${index}`}>
      <FlexRow>
        {/* <ImageLoader
          className="m-r-12"
          url={item.item.image}
          width={64}
          height={64}
        /> */}
        <div className="ai-s d-f f-r w-100p m-r-12 m-l-12">
          <FlexColumn flex={1}>
            <div className="bold">{item.item.name}</div>
            <div className="m-t-2 f-1 m-r-12" style={{ width: 82 }}>
              { priceFormat(item.total || 0) }
            </div>
          </FlexColumn>
        </div>
      </FlexRow>

      <FlexRow>
        <Button 
          className="white"
          text={t('shopping_cart.configure_extras')}
          rightIcon={ this.state.toggleMap[`${index}`] ? 'chevron-down' : 'chevron-right'} 
          minimal 
          onClick={() => this.toggleExtras(index)}
          style={{
            marginTop: 12
          }}
        />

        <div className="f-1" />

        <Button 
          className="white"
          text={t('shopping_cart.remove')}
          rightIcon="trash"
          minimal 
          onClick={() => this.context.shoppingCart.removeItemAtIndex(item, index)}
          style={{
            marginTop: 12
          }}
        />
      </FlexRow>

      <Collapse isOpen={this.state.toggleMap[`${index}`]}>
        { item.item.category.categoryExtraCosts.map((ce) => ce.extraCost).map((extra, catIdx) =>
        <div key={`extra-cost-item-${index}-${catIdx}`}>
          <div className="p-l-6 m-t-24 bold" style={{ color: Colors.GRAY5, textTransform: 'uppercase' }}>
            { extra.title }
          </div>
          <div className="p-l-6 m-t-6">
            { extra.items.map((item, idx) => <FlexRow className="m-l-12" key={`extra-cost-item-${index}-${catIdx}-${idx}`}>
              <div className="f-1 jc-s d-f">
                <Checkbox
                  checked={cart.hasExtraCost(index, extra, item)}
                  onChange={() => this.toggleExtraCost(index, extra, item)}
                  label={item.label_en}
                />
              </div>
              <div className="f-1 jc-e d-f m-r-12">{ priceFormat(item.price) }</div>
            </FlexRow> )}
          </div>
        </div> )}

        <div className="p-l-6 m-t-24 bold" style={{ color: Colors.GRAY5, textTransform: 'uppercase' }}>
          {t('extra_services')}
        </div>
        { this.state.extraServices.map((extra, catIdx) =>
          <div key={`extra-cost-item-${index}-${catIdx}`}>
            <div className="p-l-6 m-t-6 d-f f-r ai-s">
              <div className="f-1 jc-s d-f m-l-12">
                <Checkbox 
                  label={extra.name} 
                  checked={cart.hasExtraService(index, extra)}
                  onChange={() => this.toggleExtraService(index, extra)}
                />
              </div>
              <div className="f-1 jc-e d-f m-r-12">{ priceFormat(extra.price) }</div>
            </div>
          </div> 
        )}
      </Collapse>
      <MenuDivider className="m-t-24 m-b-24" />
    </div>
  }

  private renderItems() {
    return (this.context as AppContextType).shoppingCart.items.map((item, index) => this.renderItem(item, index))
  }

  public render() {
    const { t } = this.props;
    return (
      <div>
        <Menu className="shopping-cart-menu">
          <h3 style={{
            color: Colors.LIGHT_GRAY3,
            fontWeight: 600,
            fontSize: 18,
            textAlign: 'center',
            marginTop: 24,
            marginBottom: 24,
            paddingTop: 0
          }}>{t('shopping_cart.title')}</h3>
          { this.renderItems() }
          <FlexRow className="jc-e ai-c">
            <div className="f-1 jc-s ai-c m-l-24">
              {t('total')}: <span style={{ fontSize: 22, marginLeft: 12, marginRight: 12, fontWeight: 'bold' }}>{ priceFormat(this.context.shoppingCart.total) }</span>
            </div>
            <div className="jc-e ai-e d-f" style={{ width: 110 }}>
              <Button
                text={t('proceed')}
                rightIcon="chevron-right"
                intent="primary"
                className="m-t-12 m-b-12 m-r-12"
                onClick={this.sendCart}
              />
            </div>
          </FlexRow>
        </Menu>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ShoppingCartView))