import * as React from 'react';
import { Dialog, Classes, FormGroup, InputGroup, Button } from '@blueprintjs/core';
import { Contact, ContactType } from '../models/contact';
import { FlexColumn, FlexRow } from '@patterns/ui';
import deepEqual from 'deep-equal';
import produce from 'immer';
import { ConfirmDelete } from './confirm_delete';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Lessee } from '../models/lessee';
import { SiemensSignatory } from './contracts/calculation.form';

export interface Props extends WithTranslation {
  contact: Contact
  isOpen: boolean
  onClose: () => void
  onSave: (contact: Contact) => void
  onDelete: (contact: Contact) => void
}

export interface IContact {
  name: string;
  description: string;
  email: string;
  street: string;
  street2: string;
  city: string;
  postCode: string;
  country: string;
  businessID: string;
  note: string;
  title: string;
}

export interface State {
  name: string;
  description: string;
  email: string;
  street: string;
  street2: string;
  city: string;
  postCode: string;
  country: string;
  businessID: string;
  note: string;
  title: string;
  signatories: SiemensSignatory[];

  sigFirstName: string;
  sigLastName: string;
  sigEmail: string;

  emailRecipient: string;
  storeRecipient: string;

  verkkokauppaIdentity: string;
  verkkokauppaSecret: string;
}

export class LesseeDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { 
      name, description, email, street, street2, city, postCode, country ,businessID, note, type, signatories, storeRecipient, emailRecipient, verkkokauppaIdentity, verkkokauppaSecret
    } = this.props.contact as Lessee;

    this.state = { 
      name, description, email, street, street2, city, postCode, country ,businessID, note, signatories, storeRecipient, emailRecipient, verkkokauppaIdentity, verkkokauppaSecret,
      title: this.getTitle(type),
      sigFirstName: '', 
      sigLastName: '', 
      sigEmail: ''
    }
    
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.contact, prevProps.contact)) {
      const { 
        name, description, email, street, street2, city, postCode, country ,businessID, note, type, signatories, storeRecipient, emailRecipient, verkkokauppaIdentity, verkkokauppaSecret
      } = this.props.contact as Lessee;
      this.setState({ 
        name, description, email, street, street2, city, postCode, country ,businessID, note, signatories, storeRecipient, emailRecipient, verkkokauppaIdentity, verkkokauppaSecret,
        title: this.getTitle(type),
        sigFirstName: '',
        sigLastName: '',
        sigEmail: ''
      })
    }
  }

  getTitle(type: ContactType) {
    const { t } = this.props;
    switch(type) {
      case ContactType.ORGANIZATION:
        return t('organization');
      case ContactType.SUPPLIER:
        return t('supplier');
      case ContactType.LESSEE:
        return t('lessee');
      case ContactType.LEASING_COMPANY:
        return t('leasing_company');
      case ContactType.ACCEPTANCE_TEMPLATE:
        return t('acceptance_template');
    }
  }

  async save() {
    const contact = produce(this.props.contact, contact => {
      contact.name = this.state.name;
      contact.description = this.state.description;
      contact.email = this.state.email;
      contact.street = this.state.street;
      contact.street2 = this.state.street2;
      contact.city = this.state.city;
      contact.postCode = this.state.postCode;
      contact.country = this.state.country;
      contact.businessID = this.state.businessID;
      contact.note = this.state.note;
      
      const lessee = contact as Lessee;
      lessee.signatories = this.state.signatories;
      lessee.storeRecipient = this.state.storeRecipient;
      lessee.emailRecipient = this.state.emailRecipient;
      lessee.verkkokauppaIdentity = this.state.verkkokauppaIdentity;
      lessee.verkkokauppaSecret = this.state.verkkokauppaSecret;
    });

    this.props.onSave(contact);
  }

  private update = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const key = evt.currentTarget.name as keyof IContact;
    const state = {} as IContact
    state[key] = evt.currentTarget.value;
    this.setState(state)
  }

  private onRemove = (index: number) => {
    const signatories = produce(this.state.signatories, signatories => {
      signatories.splice(index, 1)
    }); 
    this.setState({ signatories })
  }

  private onSigFirstNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => this.setState({ 
    sigFirstName: evt.currentTarget.value
  })

  private onSigLastNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => this.setState({ 
    sigLastName: evt.currentTarget.value
  })

  private onSigEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => this.setState({ 
    sigEmail: evt.currentTarget.value
  })

  private onStoreRecipientChange = (evt: React.ChangeEvent<HTMLInputElement>) => this.setState({ 
    storeRecipient: evt.currentTarget.value
  })

  private onEmailRecipientChange = (evt: React.ChangeEvent<HTMLInputElement>) => this.setState({ 
    emailRecipient: evt.currentTarget.value
  })

  private onVerkkokauppaIdentityChange = (evt: React.ChangeEvent<HTMLInputElement>) => this.setState({ 
    verkkokauppaIdentity: evt.currentTarget.value
  })

  private onVerkkokauppaSecretChange = (evt: React.ChangeEvent<HTMLInputElement>) => this.setState({ 
    verkkokauppaSecret: evt.currentTarget.value
  })

  private onAdd = () => {
    const signatory = {
      firstName: this.state.sigFirstName,
      lastName: this.state.sigLastName,
      email: this.state.sigEmail
    } as SiemensSignatory;

    const signatories = produce(this.state.signatories, signatories => {
      signatories.push(signatory)
    });

    this.setState({
      sigFirstName: '',
      sigLastName: '',
      sigEmail: '',
      signatories
    })
  }

  private renderForm = () => {
    const { sigFirstName, sigLastName, sigEmail } = this.state;
    const { t } = this.props;
    const valid = sigFirstName.length > 0 && sigLastName.length > 0 && sigEmail.length > 0;

    return <div>
      <FlexColumn className="m-24">
        <h3 style={{ marginTop: 0 }}>{t('new_signatory')}</h3>
        <div className="f-1">
          <FormGroup inline={true} label={t('first_name')}>
            <InputGroup 
              value={sigFirstName}
              onChange={this.onSigFirstNameChange}
            />
          </FormGroup>
        </div>
        <div className="f-1">
          <FormGroup inline={true} label={t('last_name')}>
            <InputGroup
              fill
              value={sigLastName}
              onChange={this.onSigLastNameChange}
            />
          </FormGroup>
        </div>
        <div className="f-1">
          <FormGroup inline={true} label="Email">
            <InputGroup 
              value={sigEmail}
              onChange={this.onSigEmailChange}
            />
          </FormGroup>
        </div>

        <Button
          text={t('add_new_signatory')}
          disabled={!valid}
          icon="add"
          onClick={this.onAdd}
          intent="primary"
        />
      </FlexColumn>
      <div className="f-1">
        
      </div>
    </div>
  }
  
  private renderSignatories = () => {
    return this.state.signatories.map((signatory, index) => {
      return <FlexRow key={`signatory-${index}`} className="ai-c">
        <div className="f-1">{ signatory.firstName } { signatory.lastName }</div>
        <div className="f-1">{ signatory.email }</div>
        <div className="f-1"><Button minimal icon="trash" onClick={() => this.onRemove(index)} intent="warning"/></div>
      </FlexRow>
    })
  }

  public render() {
    const { t } = this.props;
    return (
      <Dialog 
        title={this.state.title}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        canOutsideClickClose={false}
        className="bp3-dark"
        style={{ width: 960 }}>
        <div className={Classes.DIALOG_BODY}>
          <FlexRow flex={1}>
            <div className="dark-box f-1">
              <h2>{t('basic_information')}</h2>
              <FormGroup inline label={t('name')}>
                <InputGroup 
                  fill
                  name="name"
                  value={this.state.name}
                  onChange={this.update}
                />
              </FormGroup>

              <FormGroup inline label={t('description')}>
                <InputGroup 
                  fill
                  name="description"
                  value={this.state.description}
                  onChange={this.update}
                />
              </FormGroup>

              <FormGroup inline label={t('email')}>
                <InputGroup 
                  fill
                  name="email"
                  value={this.state.email}
                  onChange={this.update}
                />
              </FormGroup>

              <FormGroup inline label={t('street')}>
                <InputGroup 
                  fill
                  name="street"
                  value={this.state.street}
                  onChange={this.update}
                />
              </FormGroup>

              <FormGroup inline label={t('street_2')}>
                <InputGroup 
                  fill
                  name="street2"
                  value={this.state.street2}
                  onChange={this.update}
                />
              </FormGroup>

              <FormGroup inline label={t('post_code')}>
                <InputGroup 
                  fill
                  name="postCode"
                  value={this.state.postCode}
                  onChange={this.update}
                />
              </FormGroup>

              <FormGroup inline label={t('city')}>
                <InputGroup 
                  fill
                  name="city"
                  value={this.state.city}
                  onChange={this.update}
                />
              </FormGroup>

              <FormGroup inline label={t('country')}>
                <InputGroup 
                  fill
                  name="country"
                  value={this.state.country}
                  onChange={this.update}
                />
              </FormGroup>

              <FormGroup inline label={t('business_id')}>
                <InputGroup 
                  fill
                  name="businessID"
                  value={this.state.businessID}
                  onChange={this.update}
                />
              </FormGroup>

              <FormGroup inline label={t('note')}>
                <InputGroup 
                  fill
                  name="note"
                  value={this.state.note}
                  onChange={this.update}
                />
              </FormGroup>
            </div>
            <div className="dark-box f-1">
              <h2>{t('document_signatories')}</h2>
              { this.renderSignatories() }
              { this.renderForm() }

              <h2>{t('email_contacts')}</h2>

              <FormGroup inline label={t('store_recipient')} className="m-24">
                <InputGroup 
                  fill
                  name="store_recipient"
                  value={this.state.storeRecipient}
                  onChange={this.onStoreRecipientChange}
                />
              </FormGroup>

              <FormGroup inline label={t('email_recipient')} className="m-24">
                <InputGroup 
                  fill
                  name="email_recipient"
                  value={this.state.emailRecipient}
                  onChange={this.onEmailRecipientChange}
                />
              </FormGroup>

              <h2>{t('verkkokauppa')}</h2>

              <FormGroup inline label={'Identity'} className="m-24">
                <InputGroup 
                  fill
                  name="verkkokappaIdentity"
                  value={this.state.verkkokauppaIdentity}
                  onChange={this.onVerkkokauppaIdentityChange}
                />
              </FormGroup>

              <FormGroup inline label={'Secret'} className="m-24">
              <InputGroup 
                  fill
                  name="verkkokappaSecret"
                  type="password"
                  value={this.state.verkkokauppaSecret}
                  onChange={this.onVerkkokauppaSecretChange}
                />
              </FormGroup>

            </div>
          </FlexRow>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <FlexRow className="f-1">
            <FlexRow className="f-1 jc-s">
              <ConfirmDelete
                outlined={true}
                title={t('delete')}
                onConfirm={() => this.props.onDelete(this.props.contact)}
              />
            </FlexRow>
            <FlexRow className="f-2 jc-e">
              <Button 
                outlined 
                icon="cross"
                text={t('close')}
                onClick={this.props.onClose} 
                className="m-r-12"
              />
              <Button 
                outlined 
                icon="tick"
                text={t('save')}
                intent="primary" 
                onClick={() => this.save()}
              />
            </FlexRow>
          </FlexRow>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(LesseeDialog)