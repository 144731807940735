import * as React from 'react';
import { DataTable, Column, ColumnType, Alignment, FilterStates } from '@patterns/datatable';
import ProposalDialog from './proposal.dialog';
import { Tag } from '@blueprintjs/core';
import { CreateButton } from '@patterns/ui';
import CreateProposalDialog from './create_proposal.dialog';
import { axios } from '../../session';
import Axios from 'axios';
import { SiemensProposal } from './calculation.form';
import { priceFormat } from '../../common';
import { withTranslation, WithTranslation } from 'react-i18next';

const ProposalsTable = DataTable.ofType<SiemensProposal>();

export interface Props extends WithTranslation {
  onReload: () => void;
}

export interface State {
  selected: SiemensProposal;
  showCreateDialog: boolean;
  showDialog: boolean;
}

export class Proposals extends React.Component<Props, State> {
  table = React.createRef<DataTable<SiemensProposal>>();
  state = {
    showCreateDialog: false,
    showDialog: false,
    selected: {} as SiemensProposal
  }
   
  cancelToken: any

  private getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'created',
        type: ColumnType.DateTime,
        visible: true,
        sortable: false,
        filterable: false,
        title: t('created_at'),
        width: 130,
        alignment: Alignment.Left
      },
      {
        id: 'updated',
        type: ColumnType.DateTime,
        visible: true,
        sortable: false,
        filterable: false,
        title: t('updated_at'),
        width: 130,
        alignment: Alignment.Left
      },
      {
        id: 'proposalNumber',
        type: ColumnType.Text,
        visible: true,
        sortable: false,
        filterable: false,
        title: t('number'),
        width: 130,
        alignment: Alignment.Left
      },
      {
        id: 'customer',
        type: ColumnType.Custom,
        visible: true,
        sortable: false,
        filterable: false,
        title: t('lessee'),
        flex: 1,
        alignment: Alignment.Left,
        format: proposal => <span>
          { (proposal.customer && proposal.customer.businessPartner) ? proposal.customer.businessPartner.name : '' }
        </span>
      },
      {
        id: 'label',
        type: ColumnType.Text,
        visible: true,
        sortable: false,
        filterable: false,
        title: t('label'),
        flex: 1,
        alignment: Alignment.Left
      },
      {
        id: 'assetCount',
        type: ColumnType.Text,
        visible: true,
        sortable: false,
        filterable: false,
        title: t('assets.title'),
        width: 130,
        alignment: Alignment.Left
      },
      {
        id: 'assetValue',
        type: ColumnType.Text,
        visible: true,
        sortable: false,
        filterable: false,
        title: t('proposals.assets_value'),
        width: 130,
        alignment: Alignment.Left,
        format: proposal => <span>{ priceFormat(proposal.assetValue || 0) }</span>
      },
      {
        id: 'statusLabel',
        type: ColumnType.Text,
        visible: true,
        sortable: false,
        filterable: false,
        title: t('status'),
        flex: 1,
        alignment: Alignment.Left,
        format: proposal => <span>
          { proposal.status === 'PROSPECT' && <Tag minimal intent="warning">PROSPECT</Tag> }
          { proposal.status === 'WAITING_FOR_DECISION' && <Tag minimal intent="warning">WAITING FOR DECISION</Tag> }
          { !(['PROSPECT', 'WAITING_FOR_DECISION'].includes(proposal.status)) && proposal.status }
        </span>
      }
    ] as Column<SiemensProposal>[];
    
  }
  private fetch = async (page: number, pageSize: number, sort: string, sortDir: 'asc' | 'desc', filters: FilterStates) => {
    if (this.cancelToken) { this.cancelToken() };

    const params = [
      `page=${page - 1}`,
      `pageSize=${pageSize}`,
      `sort=${sort}`,
      `sortDir=${sortDir}`
    ].join('&')
    try { 
      const { data } = await axios.get(`/siemens/proposals?${params}`, {
        cancelToken: new (Axios as any).CancelToken((token: any) => {
          this.cancelToken = token
        })
      });

      return { items: data.proposals, total: data.total }
    } catch (e) {
      console.error('siemens error', e);
      // alert(this.props.t('proposals.siemens_failure'))
    }

    return { items: [], total: 0 }
  }

  private onSelect = (selected: SiemensProposal) => this.setState({ selected, showDialog: true })

  private onCreateClose = (proposal?: SiemensProposal) => { 
    if (proposal) {
      this.setState({ showCreateDialog: false, showDialog: true, selected: proposal })
    } else {
      this.setState({ showCreateDialog: false })
    }
  }

  private close = (reload?: boolean) => this.setState({ showDialog: false }, () => {
    if (reload) {
      this.table.current?.fetch();
      this.props.onReload();
    }
  })

  private create = () => this.setState({ showCreateDialog: true })

  public render() {
    return (
      <div className="proposals-table m-l-24 m-r-24">
        <ProposalsTable   
          id="proposals-table"
          ref={this.table}
          columns={this.getColumns()}
          fetch={this.fetch}
          onItemSelect={this.onSelect}
          sort="updated"
          sortDir="desc"
          
        />

        <ProposalDialog 
          isOpen={this.state.showDialog}
          proposal={this.state.selected}
          onClose={this.close}
        />

        <CreateButton 
          onClick={this.create}
        />

        <CreateProposalDialog 
          isOpen={this.state.showCreateDialog}
          onClose={this.onCreateClose}
        />
      </div>
    );
  }
}

export default withTranslation()(Proposals)