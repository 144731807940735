
import * as React from 'react';
import { Icon, IconName } from '@blueprintjs/core';
import { FlexRow, FlexColumn } from '@patterns/ui';

export interface Props {
  icon: IconName
  color: string
  title: string
  value: number | string
  className?: string
  onClick?: () => void
  isActive?: boolean
}

export function NumberWidget ({ color, title, value, icon, className, onClick, isActive }: Props) {
  let _className = className ? `dashboard-widget-wrapper ${className}` : 'dashboard-widget-wrapper';
  if (onClick) {
    _className += ' pointer'
  }

  if (isActive) {
    _className += ' active'
  }

  return (
    <div className={_className} onClick={() => onClick ? onClick() : () => {}}>
      <div className="dashboard-widget-number" style={{ backgroundColor: color }}>
        <FlexRow style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div style={{ flex: 1 }}></div>
          <div style={{ flex: 4, display: 'flex', flexDirection: 'row', alignContent: 'flex-end' }}>
            <Icon iconSize={64} icon={icon} style={{ margin: '0 auto' }} color="white"/>
          </div>
          <FlexColumn flex={6}>
            <h1>{ title }</h1>
            <div className="dashboard-widget-number-content">
              { value }
            </div>
          </FlexColumn>
          <div className="f-1"></div>
        </FlexRow>
      </div>
    </div>
  );
}