import * as React from 'react';
import { Select } from '@blueprintjs/select'
import { Role, Roles } from '../models/role';
import { FormGroup, Button, MenuItem } from '@blueprintjs/core';
import { session } from '../session';

const BlueprintSelect = Select.ofType<Role>();

export interface Props {
  inline?: boolean
  label?: string
  role: string
  onChange: (role: Role) => void
}

export function RoleSelect({ onChange, role, label, inline = false }: Props) {
  const [ currentRole, setCurrentRole ] = React.useState({} as Role);
  const [ roles, setRoles ] = React.useState([] as Role[]);

  React.useEffect(() => {
    const _role = Roles.find(r => r.code === role)
    if (_role) {
      setCurrentRole(_role)
    }
  }, [role]);

  React.useEffect(() => {
    const filter = session.user.isAdmin ? (i: Role) => i : (i: Role) => i.code !== 'admin';
    setRoles(Roles.filter(filter));
  }, []);

  if (!currentRole) {
    return null
  }

  return (
    <FormGroup inline={inline} label={label}>
      <BlueprintSelect
        filterable={false}
        items={roles}
        activeItem={currentRole}
        itemRenderer={(item, options) => <MenuItem 
          key={item.code}
          text={item.name}
          onClick={options.handleClick}
        />}
        onItemSelect={role => {
          onChange(role)}
        }
      >
        <Button text={currentRole.name} rightIcon="chevron-down" minimal/>
      </BlueprintSelect>
    </FormGroup>
  );
}
